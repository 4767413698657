import SIPSwitchAPI, { SIPSwitchService } from '../services/sipSwitch.service'
import ItemBase from './itemBase'

class SipSwitch extends ItemBase {
  mutations () {
    const mutations = super.mutations()

    mutations.setAPILink = function (state, API_LINK) {
      state.API = new SIPSwitchService(API_LINK)
    }

    return mutations
  }
}

export const sipSwitch = new SipSwitch(SIPSwitchAPI).getModule()
