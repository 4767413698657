<template>
  <Details
    :current-module="currModule"
    :no-save="true"
  >
    <template v-slot="{ item }">
      <List
        current-module="sip/domain"
        :headers="dataHeaders"
        :has-add="false"
      >
        <template v-slot:item.is_connected="context">
          <v-switch
            inset
            @change="connectDomain(context.item, item, $event)"
            :value="isDomainConnected(context, item)"
          >
          </v-switch>
        </template>
      </List>
    </template>
  </Details>
</template>

<script>
import List from '../../components/CRUD/List'
import Details from '../../components/CRUD/Details'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    currModule: null
  },
  data: () => ({
    dataHeaders: [
      { text: 'Domain', value: 'domain' },
      { text: 'Created on', value: 'created_on' },
      { text: 'Is Connected', value: 'is_connected' }
    ]
  }),
  components: {
    List,
    Details
  },
  computed: {
    ...mapGetters({
      domainErrorMessage: 'sip/domain/errorMessage'
    }),
    moduleToField () {
      switch (this.currModule) {
        case 'callControl':
          return 'sip_control_app'
        case 'sipConnections':
          return 'sip_connection'
        case 'outboundVoiceProfiles':
          return 'outbound_voice_profile'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapActions({
      patchDomain: 'sip/domain/patch',
      notify: 'notification/notify'
    }),
    ...mapMutations({
      setDoRedirect: 'sip/domain/setDoRedirect'
    }),
    isDomainConnected (context, item) {
      return item.sip_domains ? item.sip_domains.find(el => context.item.domain === el.domain) : false
    },
    connectDomain (domain, item, event) {
      const domainPayload = { ...domain }
      domainPayload[this.moduleToField] = event ? item.uuid : null
      domainPayload.doNotRedirect = true

      this.patchDomain(domainPayload).then(res => {
        if (res) {
          this.notify({ text: `Domain ${domain.domain} was ${event ? 'connected' : 'disconnected'}` })
        } else {
          this.notify({ text: this.domainErrorMessage, type: 'error' })
        }
      })
    }
  }
}
</script>
