<template>
  <v-dialog
    v-model="localValue"
    width='600'
  >
    <v-card class='pa-5'>
      <v-container>
        <v-form
          ref='form'
          v-if="currentItem"
        >
          <v-row class='px-4'>
            <v-col cols='auto'>
              <h2 v-if="isNew">Add SIP User</h2>
              <h2 v-else>Modify SIP User</h2>
            </v-col>
            <v-spacer/>
            <v-col cols='auto'>
              <v-btn
                text
                class='pa-0'
                dense
                :min-width="0"
                width=25
                :ripple="false"
                @click="localValue=false"
              >
                <v-icon>
                  mdi-close-thick
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12' md='6'>
              <o-text-field
                v-model="currentItem.login"
                input-label='Login'
                :disabled="!isNew"
                :rules="isNew ? [loginValidator] : []"
                :hide-details="false"
              />
            </v-col>
            <v-col cols='12' md='6'>
              <o-text-field
                v-model="currentItem.password"
                input-label='Password'
                :placeholder="isNew ? 'set password' : 'change password'"
                :rules="[passwordValidator]"
                :hide-details="false"
              />
            </v-col>
          </v-row>

          <CountryCodeSelect
            v-model="currentItem.countries"
            label="Allowed countries"
            label2="All countries allowed"
            select-label="a2"
            :multiple="true"
            type="chips"
          />

          <v-row>
            <v-col cols='12' md='12'>

              <List
                :name="'Outbound from number: ' + (beautifyNumber(currentItem.tn_lease?.tn.tn) || 'none')"
                current-module='tnlease'
                :has-add="false"
                :use-search="true"
                :multi-sort="true"
                :headers="headers"
                :has-created="false"
                :has-filtration="false"
                :has-export="false"
              >
                <template v-slot:item.features="{ item }">
                  <NumberFeatures
                    :editable="false"
                    :value=iconsArr(item)
                  />
                </template>
                <template v-slot:item.tn="{ item }">
                  <span class="numbers" @click="select(item)">
                    {{ beautifyNumber(item.tn.tn) }}
                  </span>
                </template>
              </List>

            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                text
                block
                class='primary white--text mr-n3 no-left-border-radius'
                :ripple='false'
                height='40px'
                @click='createOrSave'
                :loading="saving"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import List from '@/components/CRUD/List.vue'
import NumberFeatures from '@/components/NumberFeatures.vue'
import BeautifyNumber from '@/services/beautify/number.service'
import CountryCodeSelect from '@/components/CountryCodeSelect.vue'

export default {
  components: {
    CountryCodeSelect,
    NumberFeatures,
    List
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    uuid: null
  },
  computed: {
    ...mapGetters({
      errorMessage: 'sipUsers/errorMessage',
      saving: 'sipUsers/saving',
      currentItem: 'sipUsers/current',
      list: 'sipUsers/list',
      countriesList: 'dict/countries/list'
    }),
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },
    connectionsErrorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    }
  },
  data: () => ({
    listKey: 1,
    headers: [
      {
        text: 'Number',
        value: 'tn'
      },
      {
        text: 'Features',
        value: 'features',
        width: '160px',
        sortable: false
      }
    ]
  }),
  methods: {
    ...mapActions({
      requestOne: 'sipUsers/requestOne',
      create: 'sipUsers/create',
      patch: 'sipUsers/patch',
      notify: 'notification/notify'
    }),
    ...mapMutations({
      clearCurrent: 'sipUsers/clearCurrent',
      setCurrent: 'sipUsers/setCurrent',
      setDefault: 'sipUsers/setDefault'
    }),
    loginValidator (val = '') {
      if (val.length < 3 || val.length > 60) {
        return 'Length must be more than 3 and less than 60'
      }

      if (!val.match(/^[a-zA-Z][.a-zA-Z0-9_-]{2,59}$/)) {
        return 'Value is wrong'
      }

      return true
    },
    passwordValidator (val = '') {
      if (!this.isNew && val.length === 0) {
        return true
      }

      if (val.length < 3) {
        return 'Length must be more than 3'
      }

      return true
    },
    onStart () {
      this.clearCurrent()
      if (!this.isNew) {
        if (this.list && this.list.length) {
          this.$nextTick(function () {
            this.setCurrent(this.uuid)
          })
        }
        this.requestOne(this.uuid)
      } else {
        this.setDefault()
      }
    },
    select (val) {
      this.currentItem.tn_lease = val || null
    },
    createOrSave () {
      if (this.$refs.form.validate()) {
        if (this.isNew) {
          this.create().then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.notify({
                text: 'User created successfully',
                type: 'success'
              })
              this.localValue = false
            }
          })
        } else {
          this.patch(this.currentItem).then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.notify({
                text: 'User saved successfully',
                type: 'success'
              })
              this.localValue = false
            }
          })
        }
      }
    },
    iconsArr (item) {
      if (!item.subscriptions || !item.subscriptions.length) {
        return []
      }
      return item.subscriptions.map(el => el.type)
    },
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  mounted () {
    // this.$refs.form.resetValidation()
    this.onStart()
  }
}
</script>

<style scoped>
.numbers {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  align-items: center;
  min-width: 120px;
}
</style>
