<template>
   <crud-details-section
  >
    <b>{{actionName}} </b>
    <span
      v-if="item[`${action}_by`]"
    >
      <b>by</b> {{item[`${action}_by`].first_name + ' ' + item[`${action}_by`].last_name}}
    </span>
    <b>on</b> {{beautifyDate(item[`${action}_on`])}}
  </crud-details-section>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      default: 'created'
    }
  },
  computed: {
    actionName () {
      return this.action.charAt(0).toUpperCase() + this.action.slice(1)
    }
  },
  methods: {
    beautifyDate: unformatted => (new Date(unformatted)).toLocaleDateString()
  }
}
</script>
