import CallFlowAPI from '../services/callFlow.service'

const state = {
  compiling: false,
  loading: false,
  deleting: false,
  errorMessage: ''
}

const getters = {
  compiling (state) {
    return state.compiling
  },

  loading (state) {
    return state.loading
  },

  deleting (state) {
    return state.deleting
  },

  errorMessage (state) {
    return state.errorMessage
  }
}
const mutations = {
  save (state) {
    state.compiling = true
  },

  saved (state) {
    state.compiling = false
  },

  error (state, error) {
    state.compiling = false
    state.errorMessage = error.message
  },

  load (state) {
    state.loading = true
  },

  loaded (state) {
    state.loading = true
  },

  errorLoading (state, error) {
    state.loading = false
    state.errorMessage = error.message
  },

  delete (state) {
    state.deleting = true
  },

  deleted (state) {
    state.deleting = true
  },

  errorDeleting (state, error) {
    state.deleting = false
    state.errorMessage = error.message
  }
}

const actions = {
  async save ({ commit }, { appUUID, text }) {
    commit('save')

    try {
      const response = await CallFlowAPI.create(appUUID, text)
      commit('saved')
      return response
    } catch (error) {
      commit('error', error)
      return undefined
    }
  },

  async get ({ commit }, appUUID) {
    commit('load')

    try {
      const response = await CallFlowAPI.get(appUUID)
      const code = decodeURIComponent(response.payload)

      commit('loaded')
      return code
    } catch (error) {
      if (error.response.status === 404) {
        return 404
      }

      commit('errorLoading', error.response.data.message)

      return false
    }
  },

  async delete ({ commit }, appUUID) {
    commit('delete')

    try {
      const response = await CallFlowAPI.delete(appUUID)
      commit('deleted')
      return response
    } catch (error) {
      commit('errorDeleting', error)

      return false
    }
  }
}

export const callFlow = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
