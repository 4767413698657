import ItemBase from './itemBase.service'

class CallControlAPI extends ItemBase {
  buildRequest (item) {
    return {
      name: item.name ?? undefined,
      product: item.product ? item.product.id : undefined,
      s3_settings: item.s3_settings ? item.s3_settings.uuid : undefined,
      tts_settings: item.tts_settings ? item.tts_settings.uuid : undefined,
      webhook_url: item.webhook_url ?? null,
      webhook_method: item.webhook_method ?? undefined,
      webhook_request_timeout: item.webhook_request_timeout ?? undefined,
      call_flow_url: item.call_flow_url ?? undefined,
      call_flow_method: item.call_flow_method ?? undefined,
      call_flow_request_timeout: item.call_flow_request_timeout ?? undefined,
      record_calls: item.record_calls ?? undefined,
      shaken_minimum_accepted_attest_lvl: item.shaken_minimum_accepted_attest_lvl ?? undefined,
      shaken_verify_policy: item.shaken_verify_policy ?? undefined,
      auto_answer: item.auto_answer ?? undefined
    }
  }
}

export default new CallControlAPI('/rest/sip/app')
