<template>
<span>
  <label v-if="hasLabel" class="input-label">
    {{inputLabel}}
    <slot name='input-label'></slot>
  </label>
  <vue-ip-input
    class="ip_input v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--single-line"
    :ip="(ipValue !== undefined) && (ipValue !== null) ? ipValue : ''"
    :on-change="onIpChange"
  />
</span>
</template>

<script>
import VueIpInput from 'vue-ip-input'
export default {
  props: {
    value: null,
    inputLabel: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    ipValue: ''
  }),
  components: {
    'vue-ip-input': VueIpInput
  },
  methods: {
    onIpChange (ip) {
      this.$emit('input', ip)
    }
  },
  watch: {
    value (value) {
      if (this.ipValue !== value) {
        this.ipValue = value
      }
    },
    ipValue (value) {
      this.$emit('input', value)
    }
  },
  created () {
    if (this.ipValue !== null && this.ipValue !== this.value) {
      this.ipValue = this.value
    }
  },
  computed: {
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    }
  }
}
</script>

<style scoped>
    .ip_input {
        display: inline-flex !important;
        border-radius: 0.25rem;
        height: 40px !important;
        margin: 0 0;
    }
</style>
<style>
    .ip-segment {
        margin: 6px 10px !important;
    }
</style>
