<template>
  <ODashboardChart
    :header-type="headerType"
    :header-text="headerText"
    :data-series="series"
    :loading="loading"
  >
  </ODashboardChart>
</template>

<script>
import ODashboardChart from './ODashboardChart'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
  props: {
    headerType: {
      type: String,
      default: 'Chart'
    },
    headerText: null,
    refType: null
  },
  data: () => ({
    key: 1,
    data: {
      count: 0,
      cost: 0,
      report: [],
      loading: false
    }
  }),
  components: {
    ODashboardChart
  },
  computed: {
    ...mapGetters({
      report: 'report/chartByRef'
    }),

    results () {
      return this.report(this.refType)
    },

    loading () {
      return this.report(this.refType) ? this.report(this.refType).loading : false
    },

    series () {
      const data = [0, 0, 0, 0, 0, 0, 0, 0]

      if (this.results && this.results.report && this.results.report.report) {
        this.results.report.report.forEach(el => {
          const date = new Date(el.date)
          date.setHours(0, 0, 0, 0)
          const today = new Date()
          today.setHours(0, 0, 0, 0)

          const diffTime = Math.abs(today - date)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

          if (diffDays < data.length) {
            data[data.length - diffDays - 1] = el.count
          }
        })
      }
      if (this.data && this.data.report) {
        this.data.report.forEach(el => {
          const date = new Date(el.date)
          date.setHours(0, 0, 0, 0)
          const today = new Date()
          today.setHours(0, 0, 0, 0)

          const diffTime = Math.abs(today - date)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

          if (diffDays < data.length) {
            data[data.length - diffDays - 1] = el.count
          }
        })
      }
      return [{
        name: 'Count',
        data
      }]
    }
  },
  methods: {
    ...mapActions({
      request: 'report/requestChart'
    })

  },
  created () {
    if (this.refType === 'sms_incoming') {
      axios
        .post('rest/sms/search?take=100&skip=0')
        .then(res => {
          let arr = res.data.payload.data.filter(el => el.type === 'smsi').map(item => {
            return {
              ...item,
              date: moment(item.created_on).format('YYYY/MM/DD')
            }
          }).sort((a, b) => new Date(a.date) - new Date(b.date))
          let prevData = null
          let count = 1
          arr = arr.map(item => {
            if (prevData === (moment(item.created_on).format('YYYY/MM/DD'))) {
              count++
            } else {
              count = 1
            }
            const obj = {
              ...item,
              count
            }
            prevData = moment(item.created_on).format('YYYY/MM/DD')
            return obj
          }).sort((a, b) => b.count - a.count)
          arr = _.uniqBy(arr, item => item.date)
          this.data = {
            count: arr.length,
            report: arr,
            cost: 0,
            loading: false
          }
        })
        .catch((err) => {
          throw new Error('Cannot load chart data for ' + this.refType + ':\n' + err.message)
        })
    } else {
      this.request({ refType: this.refType }).catch(err => {
        alert(err.message)
      })
    }
  }
}
</script>
