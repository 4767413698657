<template>
  <span>
    <List
      :headers="dataHeaders"
      current-module="sipConnections"
      :has-created="true"
      name='SIP Connections'
    >
        <template v-slot:extraButton="{ }">
            <v-col cols='auto' >
            <o-select
                    v-model="searchBy"
                    :items="searchByOptions"
                    hide-details
            >
                </o-select>
            </v-col>
      </template>
      <template v-slot:item.domain="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              class='primary white--text'
              :to="`/app/connections/${item.uuid}/domain`"
              v-on="on"
            >
              <v-icon>mdi-domain</v-icon>
            </v-btn>
          </template>
          Assign domains for the item
        </v-tooltip>
      </template>
      <template v-slot:item.numbers="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              class='secondary white--text'
              :to="`/app/connections/${item.uuid}/tnlease`"
              v-on="on"
            >
              <v-icon>mdi-dialpad</v-icon>
            </v-btn>
          </template>
          Manage SIP numbers
        </v-tooltip>
      </template>
      <template v-slot:item.copy="{ item }">
         <v-tooltip
           bottom
         >
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              v-on="on"
              class='warning white--text'
              append
              @click="copyUUIDItem(item)"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          Copy sip uuid
        </v-tooltip>
      </template>
    </List>
   </span>
</template>

<script>
import List from '../../components/CRUD/List'
import { mapActions } from 'vuex'

export default {
  name: 'SIPConnections',
  data: () => ({
    dataHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'type' },
      { text: 'IP Address', value: 'ip' },
      { text: 'Port', value: 'port' },
      { text: 'Copy', value: 'copy', width: 100, align: 'center', sortable: false },
      { text: 'Numbers', value: 'numbers', width: 100, align: 'center', sortable: false },
      // { text: 'Domains', value: 'domain', width: 100, align: 'center', sortable: false },
      { text: 'Edit', value: 'edit', width: 100, align: 'center', sortable: false },
      { text: 'Delete', value: 'delete', width: 100, align: 'center', sortable: false }
    ],
    searchBy: 'all',
    searchByOptions: [
      { text: 'All', value: 'all' },
      { text: 'Inbound', value: 'inbound' },
      { text: 'Outbound', value: 'outbound' }
    ]
  }),
  components: {
    List
  },
  methods: {
    ...mapActions({
      notify: 'notification/notify'
    }),
    copyUUIDItem (item) {
      const el = document.createElement('textarea')
      el.value = item.uuid
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.notify({ text: 'The ID has been copied!' })
    }
  }
}
</script>
