import SearchService from '../services/search.service'

const state = {
  searchInProgress: false,
  searchError: '',
  searchResults: [],
  resultsCount: 0,
  page: 0
}

const getters = {
  isInProgress (state) {
    return state.searchInProgress
  },
  getResults (state) {
    return state.searchResults
  },
  getCount (state) {
    return state.resultsCount
  },
  errorMessage (state) {
    return state.searchError
  }
}

const mutations = {
  searchStarted (state) {
    state.searchInProgress = true
  },
  searchSucceeded (state, results) {
    state.searchInProgress = false
    state.searchResults = results.data
    state.resultsCount = results.total
  },
  searchFailed (state, message) {
    state.searchInProgress = false
    state.searchError = message
  },
  clearResults (state) {
    state.searchInProgress = false
    state.searchError = ''
    state.searchResults = []
    state.resultsCount = 0
    state.page = 0
  }
}

const actions = {
  async searchNumbers ({ commit }, { searchParams, take }) {
    try {
      commit('searchStarted')

      const results = await SearchService.searchNumbers(searchParams, take)

      commit('searchSucceeded', results)
      return results
    } catch (error) {
      console.error('Search failed: ' + error.message)
      commit('searchFailed', error.message)
      return false
    }
  }
}

export const search = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
