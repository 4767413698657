<template>
  <div id='spartez-support-chat-container' v-if="enabled"/>
</template>

<style lang='scss'>
#spartez-support-chat-container .primary-color.support-chat-icon {
  background-color: $primary !important;
  outline-color: $primary !important;
}

#spartez-support-chat-container .primary-color button.new-conversation {
  background-color: $primary !important;
}

#spartez-support-chat-container .primary-color .top-header {
  background-color: $primary !important;
}

#spartez-support-chat-container .primary-color .top-header {
  background-color: $primary !important;
}

@media only screen and (max-device-width: 800px) {
  .support-chat-icon.open {
    display: block !important;
  }
}
</style>

<script>
export default {
  computed: {
    enabled () {
      return typeof process.env.VUE_APP_SUPPORT_CHAT === 'boolean'
        ? process.env.VUE_APP_SUPPORT_CHAT
        : process.env.VUE_APP_SUPPORT_CHAT === 'true'
    }
  },
  mounted () {
    if (this.enabled) {
      window.spartezSupportChat = {
        url: 'https://support.opentact.org',
        portal: 2,
        delay: 100, // delay between page load and chat load in milliseconds
        container: 'spartez-support-chat-container', // ID of the page element that will be replaced by chat
        iconClass: 'primary-color', // additional class added to the chat icon
        chatClass: 'primary-color' // additional class added to the chat widget
      }
    }

    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://support.opentact.org/download/resources/com.spartez.jira.plugins.support-chat/frontend/js/chat.js')
    document.head.appendChild(recaptchaScript)
  }
}
</script>
