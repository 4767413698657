<template>
  <span>
    <v-card flat class='px-2'>
      <v-container>
        <v-row>
          <v-col cols='12'>
            <o-btn-toggle
              v-model='searchBy'
              :items='searchByOptions'
              input-label='Search By:'
              hide-details
            >
            </o-btn-toggle>
          </v-col>
        </v-row>
        <v-row v-if="searchBy==='timestamp'">
          <v-col cols='12' sm='6'>
            <o-date-time
              input-label='Start Time'
              v-model="startDateTime"
              two-line
            >
            </o-date-time>
          </v-col>
          <v-col cols='12' sm='6'>
            <o-date-time
              input-label='End Time'
              v-model="endDateTime"
              two-line
            >
            </o-date-time>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols='12' sm='6'>
            <o-text-field
              v-model="recordingUUID"
              input-label='Recording UUID'
              label='Recording UUID'
            >
            </o-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              text
              class='primary white--text'
              :ripple='false'
            >
              Apply Filters
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
     <v-card flat class='my-5 pa-5'>
      <v-container>
        <v-row>
          <v-col cols='auto' class='pa-0'>
            <v-btn
              outlined
              color='info'
              :ripple='false'
              disabled
            >
            <v-icon class='mr-1' size='1.2rem'>mdi-delete-outline</v-icon>
            Bulk Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table>
      </v-data-table>
    </v-card>
  </span>
</template>

<script>
export default {
  data: () => ({
    searchBy: 'timestamp',
    searchByOptions: [
      { text: 'Recording ID', value: 'recordingID' }
    ],
    recordingUUID: ''
  })
}
</script>
