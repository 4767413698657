import { render, staticRenderFns } from "./Switches.vue?vue&type=template&id=409f1ed4&"
import script from "./Switches.vue?vue&type=script&lang=js&"
export * from "./Switches.vue?vue&type=script&lang=js&"
import style0 from "./Switches.vue?vue&type=style&index=0&id=409f1ed4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports