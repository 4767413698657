import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const lightTheme = {
  primary: '#6658dd',
  secondary: '#00acc1',
  error: '#f1556c',
  success: '#1abc9c',
  info: '#4fc6e1',
  warning: '#f7b84b',
  u_blue: '#4a81d4',
  u_grey: '#6e768e'
}

export default new Vuetify({
  theme: {
    themes: {
      light: lightTheme
    }
  }
})
