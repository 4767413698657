import ApiService from './api.service'

const CALL_FLOW_ENDPOINT = '/rest/sip/app/call/flow'

const CallFlowAPI = {
  async create (appUUID, text) {
    const payload = new FormData()
    payload.append('file', new Blob([text]))

    try {
      const response = await ApiService.post(`${CALL_FLOW_ENDPOINT}/${appUUID}`, payload)

      return response.data
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  },

  async get (appUUID) {
    const response = await ApiService.get(`${CALL_FLOW_ENDPOINT}/${appUUID}`)

    return response.data
  },

  async getByURL (url) {
    try {
      const response = await ApiService.get(url)

      return response.data
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  },

  async delete (appUUID) {
    try {
      const response = await ApiService.delete(`${CALL_FLOW_ENDPOINT}/${appUUID}`)

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }
}

export default CallFlowAPI
