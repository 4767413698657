
const actions = {
  notify (context, { text, type }) {
    switch (type) {
      case 'error':
        this._vm.$awn.alert(text) //  this._vm.$snackbar(text, 'error darken-3')
        break
      default:
        this._vm.$awn.success(text) //  this._vm.$snackbar(text, 'primary darken-3')
        break
    }
  }
}

export const notification = {
  namespaced: true,
  actions
}
