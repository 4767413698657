<template>
  <v-dialog
    v-model="localValue"
    width='500'
    :retain-focus="false"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    <v-card class='pa-5'>
      <v-container>
        <v-row>
          <v-col>
            Do you confirm you want to delete <b>{{nameToDisplay}}</b>?
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              text
              block
              class='error'
              @click="$emit('confirm');localValue=false"
            >
              Delete
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              text
              block
              class='primary'
              @click="localValue=false"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: null
  },
  computed: {
    localValue: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    nameToDisplay () {
      return this.name ? 'this item ' + this.name : 'this item'
    }
  }
}
</script>
