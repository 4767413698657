<template>
  <div>
<!--    <SubBar-->
<!--      :items='subViews.filter(elem => {return elem.noDisplay === undefined})'-->
<!--    >-->
<!--    </SubBar>-->
    <router-view></router-view>
  </div>
</template>

<script>
import SubBar from '@/components/SubBar'
import ConnectionsList from './ConnectionsList'
import ConnectionDetails from './ConnectionDetails'
import TNLeases from './TNLeases'
// import ManageDomains from '../SIPDomain/ManageDomains'

export default {
  name: 'SIPConnections',
  displayName: 'SIP Connections',
  data: () => ({
    subViews: [
      { name: 'List', path: '/app/connections', component: ConnectionsList, noDisplay: true },
      { name: 'Connection Details', path: '/app/connections/:uuid', component: ConnectionDetails, noDisplay: true },
      { name: 'New Connection', path: '/app/connections/new', component: ConnectionDetails, noDisplay: true },
      { name: 'SIP Connection Numbers', path: '/app/connections/:uuid/tnlease', component: TNLeases, noDisplay: true }
      // { name: 'SIP Connection Domains', path: '/app/connections/:uuid/domain', component: ManageDomains, props: { currModule: 'sipConnections' }, noDisplay: true }
    ]
  }),
  components: {
    SubBar
  }
}
</script>
