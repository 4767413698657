<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <v-container>
      <v-row class='pt-0'>
        <v-col cols='12' md='auto' class='pt-0 d-flex'>
          <o-date-picker
            v-model="date1"
          >
              </o-date-picker>
          <span  class="pa-3">to</span>
          <o-date-picker
            v-model="date2"
          >
              </o-date-picker>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols ='auto'>
        <h4>Summary</h4>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersSummary"
      :items="items"
      hide-default-header
      hide-default-footer
      class="elevation-1"
    ></v-data-table>
    <v-divider/>
    <List
      :current-module="'Number'"
      :has-add="false"
      :use-search="true"
      :headers="headers"
      :has-created="true"
      :has-filtration="false"
    >
      <template v-slot:item.features="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.messaging_profile="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.tn="{ item }">
        <span>
          {{item}}
        </span>
      </template>
    </List>
    <v-divider/>
    <List
      :current-module="'Outbound Call'"
      :has-add="false"
      :use-search="true"
      :headers="headers2"
      :has-created="true"
      :has-filtration="false"
    >
      <template v-slot:item.features="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.messaging_profile="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.tn="{ item }">
        <span>
          {{item}}
        </span>
      </template>
    </List>
    <v-divider/>
    <List
      :current-module="'Inbound Call'"
      :has-add="false"
      :use-search="true"
      :headers="headers2"
      :has-created="true"
      :has-filtration="false"
    >
      <template v-slot:item.features="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.messaging_profile="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.tn="{ item }">
        <span>
          {{item}}
        </span>
      </template>
    </List>
    <List
      :current-module="'Outbound SMS'"
      :has-add="false"
      :use-search="true"
      :headers="headers2"
      :has-created="true"
      :has-filtration="false"
    >
      <template v-slot:item.features="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.messaging_profile="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.tn="{ item }">
        <span>
          {{item}}
        </span>
      </template>
    </List>
    <v-divider/>
    <List
      :current-module="'Inbound SMS'"
      :has-add="false"
      :use-search="true"
      :headers="headers2"
      :has-created="true"
      :has-filtration="false"
    >
      <template v-slot:item.features="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.messaging_profile="{ item }">
        <span>
          {{item}}
        </span>
      </template>
      <template v-slot:item.tn="{ item }">
        <span>
          {{item}}
        </span>
      </template>
    </List>
  </v-card>
</span>
</template>

<script>
import List from '../../components/CRUD/List'
export default {
  data: () => ({
    date1: '',
    date2: '',
    headers: [
      { text: 'Country', value: 'Country', sortable: false },
      { text: 'Type', value: 'Type', sortable: false },
      { text: 'Count', value: 'Count', sortable: false },
      { text: 'MRC', value: 'MRC', sortable: false },
      { text: 'NRC', value: 'NRC', sortable: false },
      { text: 'P2P SMS', value: 'P2P_SMS', sortable: false },
      { text: 'A2P SMS', value: 'A2P_SMS', sortable: false },
      { text: 'Total', value: 'Total', sortable: false }
    ],
    headers2: [
      { text: 'Country', value: 'Country', sortable: false },
      { text: 'Type', value: 'Type', sortable: false },
      { text: 'Count', value: 'Count', sortable: false },
      { text: 'MRC', value: 'MRC', sortable: false },
      { text: 'NRC', value: 'NRC', sortable: false },
      { text: 'Total', value: 'Total', sortable: false }
    ],
    items: [
      {
        name: 'Number',
        value: ''
      },
      {
        name: 'Outbound Call',
        value: ''
      },
      {
        name: 'Inbound Call',
        value: ''
      },
      {
        name: 'Outbound SMS',
        value: ''
      },
      {
        name: 'Inbound SMS',
        value: ''
      }
    ],
    headersSummary: [
      {
        align: 'start',
        value: 'name'
      },
      { text: 'Value', value: 'value' }
    ]
  }),
  components: {
    List
  }
}
</script>

<style>
  .elevation-1 > div>table>tbody>tr>.text-start{
    border: 0.1px solid #e0e0e0;
    width: 50%;
  }
</style>
