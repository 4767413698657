<template>
  <Card
    :title="title"
    :count="count"
    :icon="icon"
    :loading="loading"
    :color="color"
    :cost="cost"
  >
  </Card>
</template>

<script>
import Card from './Card'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    Card
  },
  props: {
    refType: null,
    title: null,
    icon: null,
    color: null
  },
  data: () => ({
    data: {
      count: 0,
      cost: 0,
      loading: false
    }
  }),
  computed: {
    ...mapGetters({
      report: 'report/cardByRef'
    }),

    count () {
      return this.refType === 'sms_incoming' ? this.data.count : this.report(this.refType) ? this.report(this.refType).count : 'no data'
    },

    cost () {
      return this.refType === 'sms_incoming' ? this.data.cost : this.report(this.refType) ? this.report(this.refType).cost : undefined
    },

    loading () {
      return this.refType === 'sms_incoming' ? this.data.loading : this.report(this.refType) ? this.report(this.refType).loading : true
    }
  },
  methods: {
    ...mapActions({
      request: 'report/requestCard'
    })
  },
  mounted () {
    if (this.refType === 'sms_incoming') {
      axios
        .post('rest/sms/search?take=100&skip=0')
        .then(res => {
          this.data = {
            count: res.data.payload.data.filter(el => el.type === 'smsi').length,
            cost: 0,
            loading: false
          }
        })
        .catch((err) => {
          throw new Error('Cannot load chart data for ' + this.refType + ':\n' + err.message)
        })
    } else {
      this.request({ refType: this.refType }).catch(err => {
        alert(err)
      })
    }
  }
}
</script>
