<template>
<span>
  <v-file-input
    v-if="!loading"
    :prepend-icon="null"
    outlined
    solo
    dense
    flat
    single-line
    v-model="filename"
    :label="addOrRemove.charAt(0).toUpperCase() + addOrRemove.slice(1) + ' CSV'"
  >
    <template v-slot:append>
      <v-btn
        text
        block
        class='primary white--text mr-n3 px-0 px-sm-2 px-md-4 mr-n3 no-left-border-radius'
        :ripple='false'
        height='40px'
        @click='clicked'
        :disabled="!filename"
      >
        <v-icon>
          mdi-file-upload
        </v-icon>
      </v-btn>
    </template>
  </v-file-input>
  <v-progress-linear
    v-else
    height='40px'
    color='primary'
    :value="progress"
    rounded
    :indeterminate="!progress"
    dark
  >
      {{ 'Saving in progress' + (progress ? `: ${progress}%` : '') }}
  </v-progress-linear>
</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    currentModule: null,
    addOrRemove: {
      type: String,
      default: 'add' // alternative value = 'remove'
    },
    uuid: null
  },
  data: () => ({
    filename: null
  }),
  computed: {
    // getters
    ...mapGetters({
      list: 'tnlease/list',
      count: 'tnlease/count',
      leaseErrorMessage: 'tnlease/errorMessage'
    }),
    progress () {
      return this.$store.getters[`${this.currentModule}/leasesProgress`]
    },
    leasesPatching () {
      return this.$store.getters[`${this.currentModule}/leasesPatching`]
    },
    sipErrorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    },
    //

    loading () {
      return this.leasesPatching === this.addOrRemove + '_list'
    },
    disabled () {
      return !!this.leasesPatching
    }
  },
  methods: {
    // actions
    ...mapActions({
      notify: 'notification/notify'
    }),
    async assignList (data) {
      return await this.$store.dispatch(`${this.currentModule}/assignList`, data)
    },
    //

    clicked () {
      // step 1 - read file
      const reader = new FileReader()
      reader.readAsText(this.filename)
      const arr = []
      reader.onload = () => {
        const lines = reader.result.split('\n')
        const indexOfTN = lines[0].split(',').indexOf('TN')
        if (indexOfTN !== -1) {
          lines.slice(1).forEach(line => {
            const tn = line.split(',')[indexOfTN]
            if (tn) {
              arr.push(tn)
            }
          })

          this.assignList({ uuid: this.uuid, addOrRemove: this.addOrRemove, list: arr }).then(res => {
            if (!res) {
              alert('Error')
            } else {
              this.$nextTick(() => {
                this.notify({ text: 'Saved successfully', type: 'success' })
              })
            }

            this.filename = null
            this.$emit('done')
          })
        } else {
          this.notify({ text: 'Error: no field "tn" found in provided .csv', type: 'error' })
        }
      }
    }
  }
}
</script>
