import AddressesAPI from '../services/addresses.service'
import ItemBase from './itemBase'

class Addresses extends ItemBase {
  mutations () {
    const mutations = super.mutations()

    const oldUpdateCurrent = mutations.updateCurrent
    mutations.updateCurrent = function (state, details) {
      oldUpdateCurrent(state, details)
      state.current.country = state.current.country.a2
    }

    return mutations
  }
}

export const addresses = new Addresses(AddressesAPI).getModule()
