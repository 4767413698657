export default class {
  constructor (API) {
    this.API = API
  }

  state () {
    return {
      list: [],
      loading: false,
      errorMessage: '',
      API: this.API
    }
  }

  getters () {
    return {
      list (state) {
        return state.list
      },

      loading (state) {
        return state.loading
      },

      errorMessage (state) {
        return state.errorMessage
      },

      API (state) {
        return state.API
      }
    }
  }

  mutations () {
    return {
      request (state) {
        state.loading = true
      },

      requestSucceeded (state, list) {
        state.list = list
        state.loading = false
      },

      requestFailed (state, error) {
        state.errorMessage = error.error
        state.loading = false
      }
    }
  }

  actions () {
    return {
      async requestList ({ commit, getters }, param) {
        commit('request')

        try {
          const items = await getters.API.requestList(param)
          commit('requestSucceeded', items)

          return items
        } catch (error) {
          commit('requestFailed', error)
          return false
        }
      }
    }
  }

  modules () {
    return {}
  }

  getModule () {
    return {
      namespaced: true,
      state: this.state(),
      getters: this.getters(),
      mutations: this.mutations(),
      actions: this.actions(),
      modules: this.modules()
    }
  }
}
