<template>
        <crud-details
                current-module='messaging/campaign'
                name='Campaign'
                :no-save="true"
                :no-need-name="true"
                :customName="'Carrier Status'"
        >
            <template >
                <v-data-table
                        :headers="dataHeaders"
                        :items="list"
                        :loading="loading"
                >
                    <template v-slot:no-data>
                        No addresses saved.
                    </template>
                </v-data-table>
            </template>
        </crud-details>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    dataHeaders: [
      { text: 'MNO', value: 'mno' },
      { text: 'Qualify', value: 'qualify' },
      { text: 'STATUS', value: 'status' },
      { text: 'MNO Review', value: 'mnoReview' },
      { text: 'SURCHARGE', value: 'SURCHARGE' },
      { text: 'TPM', value: 'tpm' },
      { text: 'MESSAGE CLASS', value: 'msgClass' }
    ]
  }),
  methods: {
    ...mapActions({
      requestOne: 'messaging/campaign/requestOne'
    })
  },
  computed: {
    list () {
      const obj = this.$store.getters['messaging/campaign/current']
      let item = {}
      if (obj && obj.mnoIds && obj.mnoIds.length) {
        item = obj[obj.mnoIds[0]]
        return [item]
      } else {
        const arr = []
        if (!!obj && typeof obj === 'object') {
          Object.keys(obj).map(el => {
            if (typeof (+el) === 'number') {
              arr.push(obj[el])
            }
          })
        }
        return arr
      }
    },
    loading () {
      return this.$store.getters['messaging/campaign/loading']
    }
  },
  created () {
    this.requestOne(this.$route.params.uuid + '/mno')
  }
}
</script>
