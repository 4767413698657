<template>
  <crud-list
    name='campaigns'
    :headers="headers"
    current-module='messaging/campaign'
    :has-edit="true"
    :has-delete="true"
    :use-search="true"
    custom-key='campaignId'
    :filter-page-request="filterPageRequest"
    condition-delete-action="item.is_active"
    success-delete-message="Campaign has been successfully deactivated"
  >
      <template v-slot:item.created_on="{ item }">
          <span style="white-space: nowrap">{{beautifyNumber(item.created_on)}}</span>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="item.is_active"
              color='success'
              v-on="on"
              @click="showStatus(item)"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              v-on="on"
              color='error'
              @click="showStatus(item)"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <span style="white-space: nowrap">{{item.is_active ? ' APPROVED' : ' DECLINED'}}</span>
        </v-tooltip>
      </template>
  </crud-list>
</template>

<script>
import BeautifyNumber from '../../../services/beautify/number.service'

export default {
  data: () => ({
    filterPageRequest: {},
    headers: [
      { text: 'Campaign Id', value: 'campaignId' },
      { text: 'Use-Case', value: 'usecase' },
      { text: 'Vertical', value: 'vertical' },
      { text: 'Registered On', value: 'created_on', width: '150px' },
      { text: 'Campaign Description', value: 'description' },
      { text: 'Status', value: 'is_active' },
      { text: 'Edit', value: 'edit' },
      { text: 'Delete', value: 'delete' }
    ]
  }),
  methods: {
    beautifyNumber: num => BeautifyNumber.beautify(num),
    showStatus (item) {
      this.$router.push(`/app/messaging/campaign/${item.campaignId}/status`)
    }
  },
  created () {
    const userData = this.$store.getters['user/userData']
    this.filterPageRequest.account = userData?.account?.uuid || null
  }
}
</script>
