<template>
  <Details
    v-model="localValue"
    current-module="sipSwitch"
    ref='details'
    :is-dialog="true"
    :is-new-prop="isNew"
    :identificator="identificator"
    @close="localValue=false"
  >
    <template v-slot="{ item }">
      <v-form
        ref='form'
      >
        <v-row>
          <v-col  cols='12' md='12'>
            <v-row>
              <v-col  cols='12' md='6'>
                <o-text-field
                  v-model="item.name"
                  input-label='Name'
                  :hide-details="false"
                  :validate-on-blur="true"
                >
                </o-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col v-if="radios === 'hostName'" cols='12' md='6'>
            <o-text-field
              v-model="item.remote_hostname"
              input-label='Hostname'
              :hide-details="false"
              :rules="[moreThan6]"
              :validate-on-blur="true"
            >
            </o-text-field>
          </v-col> -->
           <v-col cols="12">
            <v-radio-group
              class="mt-0"
              v-model="radios"
              row
            >
              <v-radio
                label="IP Address"
                value="ipAddress"
              ></v-radio>
              <v-radio
                label="Hostname"
                value="hostName"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols='12' md='6' v-if="radios === 'hostName'">
            <o-text-field
              v-model="item.remote_domain"
              input-label='Hostname'
              :hide-details="true"
            >
            </o-text-field>
          </v-col>
            <v-col cols='12' md='12' v-if="radios === 'ipAddress'">
                <vue-IP-Input
                        input-label='IP Address'
                        v-model="item.remote_ip"
                />
                <!--            <o-text-field-->
                <!--              v-model="item.remote_ip"-->
                <!--              input-label='Remote IP'-->
                <!--              :hide-details="false"-->
                <!--              :rules="[isIP]"-->
                <!--              :validate-on-blur="true"-->
                <!--            >-->
                <!--            </o-text-field>-->
            </v-col>
        </v-row>
      </v-form>
    </template>
  </Details>
</template>

<script>
import vueIpInput from '@/components/vueIPInput'
import Details from '../../components/CRUD/Details'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    identificator: null
  },
  data: () => ({
    radios: 'ipAddress',
    isIP: function (val) {
      let result = true
      if (val) {
        const octets = val.split('.')
        if (octets.length === 4) {
          octets.forEach(el => {
            const num = +el
            // also check for the empty string:
            // isNaN('') == false
            // parseInt('') == NaN
            // isNan(parseInt('')) == true
            if (!(!isNaN(el) && !isNaN(parseInt(el)) && num >= 0 && num < 256)) {
              result = false
            }
          })
        } else {
          result = false
        }
      } else {
        result = false
      }
      return result || 'Please enter a valid IP address'
    },
    moreThan6 (val) {
      return (val && val.length > 6) || 'At least 7 chars long'
    }
  }),
  computed: {
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  components: {
    Details,
    'vue-IP-Input': vueIpInput
  }
}
</script>
