<template>
  <List
    current-module='sipCall'
    :headers="headers"
    :use-search="true"
    :has-add="false"
    :has-created="true"
    :add-search-props="searchProps"
  >
    <template v-slot:item.tn.tn="{ item }">
      {{beautifyNumber(item.tn.tn)}}
    </template>
    <template v-slot:item.finished_on="{ item }">
      {{beautifyDate(item.finished_on)}}
    </template>
  </List>
</template>

<script>
import List from '../../components/CRUD/List'
import BeautifyNumber from '../../services/beautify/number.service'

export default {
  components: {
    List
  },
  data: () => ({
    headers: [
      { text: 'Number', value: 'tn.tn' },
      { text: 'Started on', value: 'created_on' },
      { text: 'Finished on', value: 'finished_on' }
    ]
  }),
  methods: {
    beautifyNumber: num => BeautifyNumber.beautify(num),
    beautifyDate: unformatted => (new Date(unformatted)).toLocaleString()
  },
  computed: {
    searchProps () {
      return {
        sip_control_app: this.$route.params.uuid
      }
    }
  }
}
</script>
