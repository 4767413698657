<template>
  <v-card flat class='my-5 pa-2'>
    <v-container>
      <v-row>
        <v-col>
          <h4>{{header}}</h4>
          {{subheader}}
        </v-col>
      </v-row>
      <v-divider class='my-5'/>
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    header: {
      type: String
    },
    subheader: {
      type: String
    }
  }
}
</script>
