<template>
   <v-card flat class='pa-5'>
    <v-container>
      <v-row>
        <v-col cols='auto' v-if="hasHeader">
          <h4>{{headerType}}: <b>{{headerText}}</b></h4>
        </v-col>
        <v-spacer/>
        <!-- <v-col cols='auto' align-self='end'>
          <o-select
            :disabled="true"
            hide-details
            v-model='timePeriod'
            :items='showPeriods'
          >
          </o-select>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col>
          <template v-if="loading">
            <v-skeleton-loader v-if="loading" type='image' :height="200">
            </v-skeleton-loader>
            </template>
          <apexchart
            v-else
            :height="200"
            :options="chartOptions"
            :series="dataSeries"
          >
          </apexchart>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    headerType: {
      type: String
    },
    headerText: {
      type: String
    },
    dataSeries: null,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: 'vuechart-example',
          type: 'line',
          toolbar: {
            show: false
          }
        },
        markers: {
          size: 8,
          shape: 'square'
        },
        xaxis: {
          categories: this.getAxisForPeriod(this.period)
        }
      },
      timePeriod: '7',
      showPeriods: [
        { text: 'Last 24 hours', value: '1' },
        { text: 'Last 7 days', value: '7' },
        { text: 'Last 30 days', value: '30' }
      ]
    }
  },
  methods: {
    getAxisForPeriod: function (period) {
      const axis = []
      if (period === '1') {
        for (let i = 0; i < 8; i++) {
          const newDate = new Date()
          newDate.setHours(newDate.getHours() - 8 + i)
          const formatString = ('0' + (newDate.getMonth() + 1)).slice(-2) +
            '/' + ('0' + newDate.getDate()).slice(-2) +
            ' ' + ('0' + newDate.getHours()).slice(-2) + ':00'
          axis.push(formatString)
        }
      } else if (period === '7') {
        for (let i = 0; i < 8; i++) {
          const newDate = new Date()
          newDate.setDate(newDate.getDate() - 7 + i)
          const formatString = ('0' + (newDate.getMonth() + 1)).slice(-2) +
            '/' + ('0' + newDate.getDate()).slice(-2)
          axis.push(formatString)
        }
      }

      return axis
    },
    updateAxis: function (period) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.getAxisForPeriod(period)
          }
        }
      }
    }

  },
  watch: {
    timePeriod: function (period) {
      this.updateAxis(period)
    }
  },
  computed: {
    hasHeader: function () {
      const hasProp = this.headerType || this.headerText

      return hasProp
    }
  },
  created: function () {
    this.updateAxis(this.timePeriod)
  }
}
</script>
