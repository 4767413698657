import { DictBase } from '../dict.service'

class CampaignUsecaseDict extends DictBase {
  parseResponse (data) {
    const resultArr = []
    for (const field in data) {
      resultArr.push({
        value: field,
        text: data[field].displayName,
        type: data[field].classification,
        validSubUsecase: data[field].validSubUsecase,
        minSubUsecases: data[field].minSubUsecases,
        maxSubUsecases: data[field].maxSubUsecases
      })
    }
    return resultArr
  }
}

export default CampaignUsecaseDict
