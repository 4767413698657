import ApiService from './api.service'

const ProfilesService = {
  requestProfiles: async function () {
    try {
      const response = await ApiService.get('/rest/profile/tn')

      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

export default ProfilesService
