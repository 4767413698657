<template>
  <crud-details
    current-module='ticket'
    :no-need-name="true"
  >
    <template v-slot="{ item }">
      <v-form
        ref='form'
      >
        <v-row>
          <v-col>
            <o-select
              input-label='Ticket Type'
              v-model="item.type"
              :items="ticketTypes"
              @input="typeInput"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <component
              :is="ticketType"
              :item="item"
            >
            </component>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </crud-details>
</template>

<script>
import TicketDefault from './TicketDefault'
import TicketTNOrderBulk from './TicketTNOrderBulk'
import TicketAccountLevel from './TicketAccountLevel'

export default {
  data: () => ({
    ticketTypes: [
      { text: 'Default', value: 'TicketDefault' },
      { text: 'TN Bulk Order', value: 'TicketTNOrderBulk' },
      { text: 'Account Level', value: 'TicketAccountLevel' }
    ],
    ticketType: '',
    notEmpty: val => !!val || 'Required'
  }),
  methods: {
    typeInput (val) {
      this.ticketType = val
    }
  },
  components: {
    TicketDefault,
    TicketTNOrderBulk,
    TicketAccountLevel
  }
}
</script>
