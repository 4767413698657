<template>
  <span>
    <v-card flat tile class='px-4 mt-5'>
      <v-container>
        <v-row class='mx-2' v-if='hasHeader'>
          <v-col cols='auto'>
            <h2 class="text-capitalize">{{name ? name : trim(currentModule)}}</h2>
          </v-col>
          <v-spacer/>
                 <slot name="extraButton">
                  </slot>
          <v-col cols='auto' v-if="hasAdd">
            <v-btn
              text
              class='u_blue white--text'
              :ripple='false'
              :append="true"
              :to="customAdd ? '' : 'new'"
              @click="$emit('add')"
              :height="40"
            >
            <v-icon class='mr-1'>mdi-plus-circle</v-icon>
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="hasFiltration">
          <v-col>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>Filters</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <slot name="filtration" :item="filterParams">
                  </slot>
                  <v-btn
                    @click="doSearch"
                    text
                    class="primary white--text mt-5"
                    :ripple="false"
                    :block="true"
                  >
                    Filter
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row v-if="useSearch&&hasExport">
          <v-col>
            <Export
              :current-module="currentModule"
              :filter-params="filterParams"
              ref='export'
            >
            </Export>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="localHeaders"
              :items="list"
              :loading="loading"
              :server-items-length="useSearch ? count : undefined"
              :options.sync="tableOptions"
              :multi-sort="multiSort"
              :footer-props="{ itemsPerPageOptions: [ 5, 10, 20, 50, 100 ] }"
              @dblclick:row="openPopup"
            >
              <template v-if="hasCreated" v-slot:item.created_on="{ item }">
                {{beautifyDate(item.created_on)}}
              </template>
              <template v-if="hasModified" v-slot:item.modified_on="{ item }">
                {{beautifyDate(item.modified_on)}}
              </template>
              <template v-slot:item.edit="{ item }">
                 <v-tooltip
                   bottom
                 >
                  <template v-slot:activator="{ on }">
                      <v-btn
                        :ripple="false"
                        text
                        v-on="on"
                        class='warning white--text'
                        append
                        @click="editItem(item)"
                        :to="`${item[customKey]}`"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                  </template>
                     Edit
               </v-tooltip>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-tooltip
                  bottom
                  v-if="!conditionDeleteAction || _condition(item, conditionDeleteAction)"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :ripple="false"
                      text
                      v-on="on"
                      class='error white--text'
                      :loading="item.deleting"
                      @click="askToDelete(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
              </template>
              <template v-slot:item.status="{ item }">
                 <v-tooltip
                   bottom
                 >
                  <template v-slot:activator="{ on }">
                      <v-btn
                        :ripple="false"
                        text
                        v-on="on"
                        class='warning white--text'
                        append
                        @click="editItem(item)"
                        :to="`${item[customKey]}`"
                      >
                        <v-icon class='warning white--text' v-if="item.status === 'Pending'">mdi-alert-circle</v-icon>
                        <v-icon class="success white--text" v-if="item.status === 'Sent'">mdi-checkbox-marked-circle</v-icon>
                        <v-icon class="error white--text" v-if="item.status === 'Fail'">mdi-close-circle</v-icon>
                      </v-btn>
                  </template>
                      {{item.status}}
               </v-tooltip>
              </template>
               <template v-slot:item.showstatus="{ item }">
                <v-tooltip>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :ripple="false"
                      text
                      v-on="on"
                      class="status-btn"
                      :loading="item.deleting"
                      @click="askToDelete(item)"
                    >
                     {{item.showstatus}}
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
              <!-- bypass all slots from a parent -->
              <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
              <template v-for="(_, slot) of scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope"/>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <DeleteDialog
      v-model="deleteDialog"
      :name="itemToDelete ? itemToDelete.name : null"
      @confirm="deleteI(itemToDelete)"
    >
    </DeleteDialog>
  </span>
</template>

<style lang='scss'>
@import "~vuetify/src/styles/styles.sass";
.v-data-footer {
  float: left !important;
}
.v-data-footer > .v-data-footer__select {
  margin-left: 0 !important;
}
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1 !important;
}
.small_text {
  font-size: 0.75rem !important;
  text-transform: none !important;
}
.v-btn-toggle {
  padding-top: 1px;
  padding-bottom: 1px;
}
.v-input,
.v-label {
  font-size: 1rem !important;
}
.v-input > label {
  font-size: 1rem !important;
}
.v-simple-checkbox i.v-icon::before {
  font-size: 1.1rem !important;
}
</style>

<style scoped>
.status-btn{
  border: 1px solid #babdc5;
}
.v-application .warning {
  background-color: transparent !important;
  border-color: #f7b84b !important;
  color: #f7b84b !important;
}
.v-application .warning {
  background-color: transparent !important;
  border-color: #f7b84b !important;
  color: #f7b84b !important;
}
.v-application .success {
  background-color: transparent !important;
  color: #1abc9c !important;
  border-color: #1abc9c !important;
}
.v-application .error {
  background-color: transparent !important;
  color: #f1556c !important;
  border-color: #f1556c !important;
}
.v-btn {
  text-transform: none !important;
  font-size: 1rem !important;
  letter-spacing: normal !important;
}
/deep/ .v-data-table-header__sort-badge {
  display: none !important;
}
</style>

<script>
import DeleteDialog from './DeleteDialog'
import Export from './Export'
import moment from 'moment'

export default {
  name: 'CRUDList',
  data: () => ({
    deleteDialog: false,
    itemToDelete: null,
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [
        'created_on'
      ],
      sortDesc: [
        true
      ]
    },
    filterParams: {}
  }),
  props: {
    currentModule: null,
    hasDelete: {
      type: Boolean,
      default: true
    },
    conditionDeleteAction: {
      type: String,
      default: ''
    },
    multiSort: {
      type: Boolean,
      default: true
    },
    hasEdit: {
      type: Boolean,
      default: true
    },
    hasCreated: {
      type: Boolean,
      default: true
    },
    hasModified: {
      type: Boolean,
      default: true
    },
    customAdd: {
      type: Boolean,
      default: false
    },
    customKey: {
      type: String,
      default: 'uuid'
    },
    hasAdd: {
      type: Boolean,
      default: true
    },
    hasHeader: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    useSearch: {
      type: Boolean,
      default: false
    },
    hasFiltration: {
      type: Boolean,
      default: false
    },
    hasExport: {
      type: Boolean,
      default: false
    },
    addSearchProps: {
      type: Object,
      required: false
    },
    filterPageRequest: {
      type: Object,
      required: false
    },
    deletedParam: {
      type: String,
      default: ''
    },
    searchEndpoint: {
      type: String,
      default: ''
    },
    sortByProp: {
      type: Array,
      default: null
    },
    sortDesc: {
      type: Array,
      default: null
    },
    headers: null,
    successDeleteMessage: null
  },
  methods: {
    // actions
    _condition (item, string) {
      // eslint-disable-next-line no-eval
      return eval(string)
    },
    async requestList (request) {
      if (this.useSearch) {
        if (request.from) {
          request.from = request.from.toString()
        }
        if (request.to) {
          request.to = request.to.toString()
        }
        return await this.$store.dispatch(`${this.currentModule}/search`, { ...request, ...this.addSearchProps, ...this.filterPageRequest, endpoint: this.searchEndpoint })
      } else {
        return await this.$store.dispatch(`${this.currentModule}/requestList`)
      }
    },
    openPopup (row) {
      this.$emit('clickRow', row)
    },
    async deleteItem (uuid) {
      return await this.$store.dispatch(`${this.currentModule}/deleteItem`, uuid)
    },
    notify (message, type) {
      return this.$store.dispatch('notification/notify', {
        text: message,
        type: type
      })
    },

    askToDelete (item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },

    deleteI (item) {
      this.$set(item, 'deleting', true)
      this.deleteItem(this.deletedParam ? item[this.deletedParam] : item.identificator).then(res => {
        if (!res) {
          this.$set(item, 'deleting', false)
          alert(this.api_error_message)
        } else {
          this.notify({ text: this.successDeleteMessage ? this.successDeleteMessage : 'Successfully deleted', type: 'success' })
        }
      })
    },
    beautifyDate (unformatted) {
      return moment(unformatted).format('YYYY/MM/DD hh:mm:ss')
    },
    doSearch () {
      this.tableOptions.page = 0
      this.tableOptions.itemsPerPage = 10
      const val = this.tableOptions
      const searchParams = { page: val.page, perPage: val.itemsPerPage, order: {}, ...this.filterParams }
      for (let i = 0; i < val.sortBy.length; ++i) {
        searchParams.order[val.sortBy[i]] = val.sortDesc[i] ? 'DESC' : 'ASC'
      }
      if (searchParams.created_on_from) {
        searchParams.created_on_from = moment(searchParams.created_on_from).format('YYYY-MM-DD 00:00:00')
      }
      if (searchParams.created_on_to) {
        searchParams.created_on_to = moment(searchParams.created_on_to).format('YYYY-MM-DD 23:59:59')
      }
      if (this.useSearch) {
        this.requestList(searchParams).then(res => {
          if (!res) {
            this.notify(this.errorMessage, 'error')
          }
        })
      }
    },
    trim (text) {
      return text.replace(/([A-Z])/g, ' $1').trim()
    }
  },
  computed: {
    // getters
    list () {
      return this.$store.getters[`${this.currentModule}/list`]
    },
    count () {
      return this.$store.getters[`${this.currentModule}/count`]
    },
    loading () {
      return this.$store.getters[`${this.currentModule}/loading`]
    },
    errorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    },
    api_error_message () {
      return this.$store.getters[`${this.currentModule}/api_error_message`]
    },
    //

    localHeaders () {
      let localHeaders = []

      if (this.headers && this.headers.length) {
        localHeaders = localHeaders.concat(this.headers)
      }

      return localHeaders
    },

    scopedSlots () {
      const result = Object.keys(this.$scopedSlots)
        .filter(field => field !== 'filtration')
        .reduce((obj, key) => {
          obj[key] = this.$scopedSlots[key]
          return obj
        }, {})

      return result
    }
  },
  watch: {
    tableOptions (val) {
      const searchParams = { page: val.page, perPage: val.itemsPerPage, order: {}, ...this.filterParams }
      for (let i = 0; i < val.sortBy.length; ++i) {
        searchParams.order[val.sortBy[i]] = val.sortDesc[i] ? 'DESC' : 'ASC'
      }

      if (this.useSearch) {
        this.requestList(searchParams)
      }
    }
  },
  created () {
    if (this.sortByProp && this.sortByProp.length) {
      this.tableOptions.sortBy = this.sortByProp
    }
    if (this.sortDesc && this.sortDesc.length) {
      this.tableOptions.sortDesc = this.sortDesc
    }
    if (!this.useSearch) {
      this.requestList().then(res => {
        if (!res) {
          alert(this.errorMessage)
        }
      })
    }
  },
  components: {
    DeleteDialog,
    Export
  }
}
</script>
