import ApiService from './api.service'
import ItemBase from './itemBase.service'

class AccountsUserAPI extends ItemBase {
  buildRequest (item) {
    return {
      email: item.email ?? undefined,
      password: item.password ?? undefined,
      first_name: item.first_name ? item.first_name : undefined,
      last_name: item.last_name ? item.last_name : undefined,
      account: {
        name: item.first_name
          ? item.first_name + ' ' + item.last_name
          : item.last_name
            ? item.last_name
            : undefined,
        email: item.email
      }
    }
  }

  async activate (uuid) {
    try {
      const response = await ApiService.put(`${this.apiLink}/${uuid}`)

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message ?? error.response.statusText)
    }
  }
}

export default new AccountsUserAPI('/rest/account/user')
