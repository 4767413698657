<template>
  <v-card outlined class='pa-5'>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <o-select
            input-label='Connection or Application'
            label='Name'
            :items="connections"
            hide-details
          >
          </o-select>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row>
        <v-col>
          <h4>SMS Messaging</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12'>
          <o-select
            input-label='Messaging Profile'
            label='Select Messaging Profile'
            :items="messagingProfiles"
            hide-details
          >
          </o-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='6' align-self='end'>
          <v-row>
            <v-col cols='6'>
              <b>Number Type</b>
            </v-col>
            <v-col cols='6'>
              {{numberType}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <b>Country</b>
            </v-col>
            <v-col cols='6'>
              {{country}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <b>Traffic Type</b>
            </v-col>
            <v-col cols='6'>
              {{trafficType}}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols='12' md='6'>
          <v-row>
            <v-col cols='6'>
              <b>Deliverability</b>
            </v-col>
            <v-col cols='3'>
              <b>SMS</b>
            </v-col>
            <v-col cols='3'>
              <b>MMS</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              Domestic IB & OB
            </v-col>
            <v-col cols='3'>
            </v-col>
            <v-col cols='3'>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              International Reach IB
            </v-col>
            <v-col cols='3'>
            </v-col>
            <v-col cols='3'>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              International Reach OB
            </v-col>
            <v-col cols='3'>
            </v-col>
            <v-col cols='3'>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    connections: [
      { text: 'Forward Only', value: 'forward' }
    ],
    messagingProfiles: [],
    numberType: 'Toll-Free',
    country: 'USA',
    trafficType: 'A2P'
  })
}
</script>
