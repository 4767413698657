<template>
  <v-card
    flat
    :class="`pa-0 ${color} white--text`"
    min-width='225px'
  >
    <v-row class='ma-0 pa-0'>
      <v-col cols='auto' :class="`left-col ${color} darken-1 ma-0 pa-3`">
        <v-icon v-if="loading" class='mdi-spin' color='white' size='3.2rem'>
          mdi-loading
        </v-icon>
        <v-icon v-else :v-html="icon" color='white' size='3.2rem'>
          {{icon}}
        </v-icon>
      </v-col>
      <v-col v-if='!loading' class='ma-0 pa-3'>
        <v-row class='ma-0 pa-0'>
          <v-col class='ma-0 pa-0'>
            <b>{{count}} {{title}}</b>
          </v-col>
        </v-row>
        <v-row v-if="cost" class='ma-0 pa-0'>
          <v-col class='ma-0 pa-0'>
            Total: ${{parseFloat(localCost).toFixed(5)}}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.v-card >>> .left-col {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>

<script>
export default {
  props: {
    count: null,
    title: {
      type: String,
      default: 'Items'
    },
    icon: {
      type: String,
      default: 'mdi-information-outline'
    },
    color: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    cost: null
  },
  computed: {
    localCost () {
      return this.cost ? Math.abs(parseFloat(this.cost)) : ''
    }
  }
}
</script>
