<template>
  <v-app>
  <v-main class='register_bg'>
    <v-container fill-height>
      <v-row justify='center' align='center'>
        <v-col cols='auto'>
          <v-card width="450" class='pa-8'>
            <router-view>
            </router-view>
         </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Notification>
    </Notification>
  </v-main>
  </v-app>
</template>

<style lang='scss'>
.register_bg {
  background-color: $primary !important;
  background-image: url('../../assets/images/bg-login.png') !important;
}
</style>

<script>
import Notification from '../../components/Notification'

export default {
  components: {
    Notification
  }
}
</script>
