<template>
  <span>
    <label
      v-if="hasLabel"
      class="input-label"
    >
      {{inputLabel}}
    </label>
    <v-input
      :persistent-hint="hasHint"
      :messages="hint === '' ? 'stub' : hint"
      :hide-details="!hasHint"
    >
      <v-btn-toggle
        v-model="localValue"
        :multiple="multiple"
        flat
        dense
        :mandatory="mandatory"
        color="grey lighten-2"
      >
        <v-btn
          v-for="item in items"
          :key="item.value"
          :value="item.value"
          color="grey lighten-4 u_grey--text"
          :ripple="false"
          min-width="0"
          :class="narrow?'small_text':''"
        >{{item.text}}</v-btn>
      </v-btn-toggle>
      <template v-slot:message>
        <slot name='hint'></slot>
      </template>
    </v-input>
  </span>
</template>

<style scoped>
.small_text {
  font-size: 0.75rem !important;
  text-transform: none !important;
}
</style>

<script>
export default {
  props: {
    value: null,
    inputLabel: {
      type: String
    },
    hint: {
      type: String,
      default: ''
    },
    items: {
      type: Array
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    narrow: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    },
    hasHint: function () {
      const hasSlot = !!this.$slots.hint
      const hasProp = this.hint !== ''

      return hasSlot || hasProp
    }
  }
}
</script>
