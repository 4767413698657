import ItemBase from './itemBase.service'

class SIPDomainAPI extends ItemBase {
  buildRequest (item) {
    return {
      sip_connection: item.sip_connection || item.sip_connection === null ? item.sip_connection : undefined,
      sip_control_app: item.sip_control_app || item.sip_control_app === null ? item.sip_control_app : undefined,
      outbound_voice_profile: item.outbound_voice_profile || item.outbound_voice_profile === null ? item.outbound_voice_profile : undefined,
      countries: Array.isArray(item.countries)
        ? item.countries.map(_ => _.a2)
        : undefined
    }
  }

  buildCreateRequest (item) {
    let ret = this.buildRequest(item)
    ret = { domain: item.domain ?? undefined, ...ret }
    return ret
  }

  parseResponse (item) {
    item.sip_connection = item.sip_connection ? item.sip_connection.uuid : undefined
    item.sip_control_app = item.sip_control_app ? item.sip_control_app.uuid : undefined
    item.outbound_voice_profile = item.outbound_voice_profile ? item.outbound_voice_profile.uuid : undefined

    return item
  }
}

export default new SIPDomainAPI('/rest/sip/domain')
