import SecretServiceAPI from '../services/secret.service'
import { SecretService } from '../services/storage.service'

const state = {
  stripe: null,
  paypal: null,
  google: null,
  apple: null,
  facebook: null,
  loading: false,
  errorMessage: null
}

const getters = {
  stripe (state) {
    return state.stripe
  },

  paypal (state) {
    return state.paypal
  },

  google (state) {
    return state.google
  },

  apple (state) {
    return state.apple
  },

  facebook (state) {
    return state.facebook
  },

  loading (state) {
    return state.loading
  },

  errorMessage (state) {
    return state.errorMessage
  }
}

const mutations = {
  setSecrets (state, data) {
    state.stripe = data.payments.stripe
    state.paypal = data.payments.paypal
    state.google = data.auth.google
    state.apple = data.auth.apple
    state.facebook = data.auth.facebook
    state.loading = false
  },

  request (state) {
    state.loading = true
  },

  requestFailed (state, error) {
    state.errorMessage = error.message
    state.loading = false
  }
}

const actions = {
  async requestSecrets ({ commit }) {
    const secrets = SecretService.getSecrets()
    if (secrets) {
      commit('setSecrets', secrets)

      return true
    } else {
      try {
        commit('request')

        const results = await SecretServiceAPI.requestSecrets()
        SecretService.saveSecrets(results)

        commit('setSecrets', results)
        return true
      } catch (error) {
        console.error('Get secrets failed: ' + error.message)
        commit('requestFailed', error.message)
        return false
      }
    }
  }
}

export const secret = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
