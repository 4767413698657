import ApiService from './api.service'

const TokenService = {
  async createToken (name, ttl) {
    const payload = {
      name: name,
      ttl: ttl
    }

    try {
      const response = await ApiService.post('/rest/token', payload)

      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  },

  async requestTokens () {
    try {
      const response = await ApiService.get('/rest/token/list')

      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  },

  async deleteAllTokens () {
    try {
      await ApiService.delete('/rest/token/all')
    } catch (error) {
      throw new Error(error.message)
    }
  },

  async deleteToken (token) {
    try {
      await ApiService.delete(`/rest/token/${token}`)
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

export default TokenService
