<template>
  <v-dialog
    v-model="localValue"
    width='600'
  >
    <v-card class='pa-5'>
      <v-container>
        <v-row class='px-4'>
          <v-col cols='auto'>
            <h2>Create New Messaging Profile</h2>
          </v-col>
          <v-spacer/>
          <v-col cols='auto'>
            <v-btn
              text
              class='pa-0'
              dense
              :min-width="0"
              width=25
              :ripple="false"
              @click="localValue=false"
            >
              <v-icon>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
            <v-form
              ref='form'
            >
        <v-row>
          <v-col>
              <o-text-field
                v-model="name"
                input-label='Name'
                label='Enter messaging profile name...'
                :hide-details="false"
                :rules="[moreThanFive]"
              >
                <template v-slot:append>
                  <v-btn
                    text
                    class='primary white--text mr-n3 no-left-border-radius'
                    :ripple='false'
                    height='40px'
                    @click='createProfile'
                    :loading='saving'
                  >
                    Add New Profile
                  </v-btn>
                </template>
              </o-text-field>
          </v-col>
        </v-row>
            </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'messaging/profiles/errorMessage',
      saving: 'messaging/profiles/saving'
    }),
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  data: () => ({
    name: '',
    moreThanFive: val => val.length >= 5 || 'At least 5 characters'
  }),
  methods: {
    ...mapActions({
      create: 'messaging/profiles/create'
    }),
    ...mapMutations({
      clearProfile: 'messaging/profiles/clearCurrent'
    }),
    createProfile: async function () {
      if (this.$refs.form.validate()) {
        const res = await this.create({ name: this.name })
        if (!res) {
          alert(this.errorMessage)
        }
        this.localValue = false
      }
    }
  },
  watch: {
    localValue (val) {
      if (val) {
        this.name = ''
        this.clearProfile()
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>
