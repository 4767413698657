<template>
  <span>
    <meta name="google-signin-scope" content="profile email">
    <meta name="google-signin-client_id" :content="client_id">
    <div id='gs2' :data-width='0'/>
  </span>
</template>

<style scoped>
#gs2 >>> div.abcRioButtonBlue {
  background-color: #6658dd !important;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    width: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      google_secret: 'secret/google'
    }),
    client_id () {
      return this.google_secret ? this.google_secret.client_id : null
    }
  },
  methods: {
    ...mapActions({
      googleAuth: 'auth/googleAuth'
    }),
    onFailure: function (error) {
      alert('Google Log In Failed')
      console.error(error)
    },
    initGoogle () {
      const googleLoginScript = document.createElement('script')
      googleLoginScript.setAttribute('src', 'https://apis.google.com/js/platform.js')
      document.head.appendChild(googleLoginScript)
      var that = this
      googleLoginScript.onload = function () {
        window.gapi.signin2.render('gs2', {
          scope: 'profile email',
          longtitle: true,
          width: 'auto',
          theme: 'dark',
          onsuccess: that.googleAuth,
          onfailure: that.onFailure
        })
      }
    }
  },
  watch: {
    client_id (val) {
      if (val) {
        this.initGoogle()
      }
    }
  },
  mounted () {
    if (this.client_id) {
      this.initGoogle()
    }
  }
}
</script>
