import ItemBase from './itemBase.service'

class OutboundVoiceProfilesAPI extends ItemBase {
  buildCreateRequest (item) {
    return {
      name: item.name ?? undefined,
      product: item.product ?? undefined,
      is_active: item.is_active ?? undefined
    }
  }

  buildPatchRequest (item) {
    return {
      name: item.name ?? undefined,
      product: item.product ?? undefined,
      is_active: item.is_active ?? undefined
    }
  }

  parseResponse (item) {
    item.product = item.product ? item.product.id : undefined
    return item
  }
}

export default new OutboundVoiceProfilesAPI('/rest/profile/outbound_voice')
