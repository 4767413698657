<template>
  <v-app-bar id="main-app-bar" app clipped-left flat :height="topBarHeight" class="pl-0">
    <v-list v-if="$vuetify.breakpoint.smAndUp" :width="nameWidth - APP_BAR_PADDING" flat class="transparent">
      <v-list-item to="/" :ripple="false">
        <v-toolbar flat class="transparent">
          <v-spacer />
          <v-toolbar-title>
            <v-img
              :src="logo"
            >
            </v-img>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-list-item>
    </v-list>
    <v-container>
      <v-row>
        <v-col cols ='auto' align-self='center'>
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.xsOnly"
            class='local-toolbar'
            @click='navSwitchVisibility'
            :ripple="false"
          >
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="auto" md='4'  v-if="$vuetify.breakpoint.mdAndUp">
          <v-breadcrumbs :items="breadcrumbs" large>
            <template v-slot:divider>
              <div id="divider">/</div>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-spacer/>
        <v-col cols='auto' align-self='center'>
          <v-menu bottom left :offset-y="true" min-width="200">
            <template v-slot:activator="{ on }">
              <v-card class="bg_inherit" :min-width="$vuetify.breakpoint.smAndUp ? 200 : 0" flat>
                <v-row flat class="local-background" v-on="on">
                  <v-col cols="auto">
                    <v-avatar size="3rem" class="mr-2">
                      <img class='preserve_aspect' alt="Avatar" :src="userAvatar" />
                    </v-avatar>
                  </v-col>
                  <v-col align-self="center" v-if='$vuetify.breakpoint.smAndUp'>
                    <v-skeleton-loader
                      :loading="loadingUserData"
                      type="text"
                      dark
                      :width="100"
                    >
                      <span class="large">{{ full_name }}</span>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="auto" align-self="center" v-if='$vuetify.breakpoint.smAndUp'>
                    <v-icon size="1.2rem" class="local-toolbar">mdi-chevron-down</v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <v-list>
              <v-list-item :ripple="false" to="/app/account">
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-divider class='mx-3'/>
              <v-list-item :ripple="false" href="https://support.opentact.org/" target="_blank">
                <v-list-item-title>Support</v-list-item-title>
              </v-list-item>
              <v-list-item :ripple="false" href="https://doc.opentact.org/" target="_blank">
                <v-list-item-title>API Docs</v-list-item-title>
              </v-list-item>
              <v-divider class='mx-3'/>
              <OLogoutButton>
              </OLogoutButton>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" align-self="center" :class="$vuetify.breakpoint.xsOnly ? 'px-0' :''">
          <v-row>
            <v-col cols='auto' class='pr-4 pr-md-0'>
              <v-btn icon :ripple="false" width='3rem' height="3rem" class="local-toolbar mr-2" to="/app/account/billing/make-payment">
                <v-icon size="2.5rem">mdi-cash-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols='auto' align-self="center" class='pl-0 balanceShow'>
              <b>${{balance}}</b>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" align-self="center" :class="$vuetify.breakpoint.xsOnly ? 'px-0' :''">
          <v-btn icon :ripple="false" width='3rem' height="3rem" class="local-toolbar mr-2" to="/app/cart">
            <v-icon size="2rem">mdi-cart</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<style lang="scss" scoped>
    @media  (max-width: 1035px) {
        .balanceShow{
           display: none !important;
        }
    }
.v-toolbar,
.local-toolbar {
  color: rgba(255, 255, 255, 0.6) !important;
}
.v-card.bg_inherit {
  background-color: inherit !important;
  color: inherit !important;
  font-weight: inherit !important;
}
.v-toolbar__title {
  font-weight: 700 !important;
  font-size: 26px !important;
}
#divider {
  color: rgba(255, 255, 255, 0.6) !important;
}
.local-background {
  background-color: transparent !important;
}
.large {
  font-size: 16px;
}
.preserve_aspect {
  height: 42px !important;
  width: 42px;
  object-fit: cover;
}
</style>

<script>
import { mapGetters, mapMutations } from 'vuex'
import OLogoutButton from './OLogoutButton'

export default {
  name: 'AppNavigation',
  props: {
    topBarHeight: Number,
    nameWidth: Number
  },
  computed: {
    breadcrumbs: function () {
      const crumbs = []
      if (this.$route && this.$route.matched) {
        this.$route.matched.forEach((el, i) => {
          if (i === 0 || i > 2) {
            return
          }
          crumbs.push({ text: el.name })
        })
      }

      return crumbs
    },
    ...mapGetters('user', [
      'full_name',
      'avatar',
      'loadingUserData',
      'balance'
    ]),
    userAvatar: function () {
      return this.avatar || require('@/assets/images/no-avatar.png')
    },
    logo: function () {
      return require('@/assets/images/logo.png')
    }
  },
  created () {
    this.APP_BAR_PADDING = 16
    // this.requestUserData()
  },
  methods: {
    ...mapMutations({
      navSetVisibility: 'navigation/setVisibility',
      navSwitchVisibility: 'navigation/switchVisibility'
    })
  },
  components: {
    OLogoutButton
  }
}
</script>
