<template>
  <v-dialog
    v-model="localValue"
    width='1000'
  >
    <v-card>
      <v-container>
        <v-row class='px-4'>
          <v-col cols='auto'>
            <h2>Voice Options</h2> {{number}}
          </v-col>
          <v-spacer/>
          <v-col cols='auto'>
            <v-btn
              text
              class='pa-0'
              dense
              :min-width="0"
              width=25
              :ripple="false"
              @click="localValue=false"
            >
              <v-icon>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs
              v-model="currentTab"
              hide-slider
            >
              <v-tab
                :hidden="$vuetify.breakpoint.smAndDown"
                active-class='tab-active'
                v-for="item in tabs"
                :key="item.value"
                :ripple="false"
                :href="'#' + item.value"
              >
                <v-btn
                  text
                  class='grey white--text'
                  :ripple="false"
                >
                  {{item.text}}
                </v-btn>
              </v-tab>
              <v-menu
                v-if="$vuetify.breakpoint.smAndDown"
                offset-y
                top
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    block
                    text
                    class="align-self-center mr-4 primary white--text"
                    v-on="on"
                    :ripple="false"
                  >
                    {{tabs.find(el => el.value === currentTab).text}}
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in tabs"
                    :key="item.value"
                    :ripple="false"
                    @click="switchTab(item)"
                  >
                    <v-list-item-title>
                      {{item.text}}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tabs-items
                v-model="currentTab"
              >
                <v-tab-item
                  v-for="item in tabs"
                  :key="item.value"
                  :value="item.value"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-container>
                    <component :is="item.value"/>
                  </v-container>
                </v-tab-item>
                  <v-container>
                    <v-row justify-md='end'>
                      <v-col cols='auto'>
                        <v-btn
                          text
                          class='error white--text'
                          :ripple='false'
                        >
                          Cancel
                        </v-btn>
                      </v-col>
                      <v-col cols='auto'>
                        <v-btn
                          text
                          class='success white--text'
                          :ripple='false'
                        >
                          Save Changes
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang='scss' scoped>
.tab-active .v-btn {
  color: white !important;
  background-color: $primary !important;
}
</style>

<script>
import emergency from './Emergency'
import routing from './Routing'
import tags from './Tags'
import advanced from './Advanced'
import porting from './Porting'
import recording from './Recording'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    number: {
      type: String,
      default: ''
    },
    initialTab: {
      type: String,
      default: 'emergency'
    }
  },
  computed: {
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  data: () => ({
    tabs: [
      { text: 'Emergency', value: 'emergency' },
      { text: 'Routing', value: 'routing' },
      { text: 'Tags', value: 'tags' },
      { text: 'Advanced', value: 'advanced' },
      { text: 'Porting', value: 'porting' },
      { text: 'Recording', value: 'recording' }
    ],
    currentTab: 'emergency'
  }),
  methods: {
    switchTab: function (item) {
      this.$nextTick(() => {
        this.currentTab = item.value
      })
    }
  },
  created: function () {
    this.currentTab = this.initialTab
  },
  components: {
    emergency,
    routing,
    tags,
    advanced,
    porting,
    recording
  }
}
</script>
