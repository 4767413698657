<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

import SubBar from '@/components/SubBar'
import Applications from '@/views/CallControl/Applications'
import ApplicationDetails from '@/views/CallControl/ApplicationDetails'
import TNLeases from '../Connections/TNLeases'
import Hook from './Hook'
import ManageDomains from '../SIPDomain/ManageDomains'

export default {
  name: 'SIPControlApp',
  data: () => ({
    subViews: [
      { name: 'Applications', path: '/app/call-control/applications', component: Applications },
      { name: 'NewApplication', path: '/app/call-control/applications/new', component: ApplicationDetails, noDisplay: true },
      { name: 'EditApplication', path: '/app/call-control/applications/:uuid', component: ApplicationDetails, noDisplay: true },
      { name: 'Control App Numbers', path: '/app/call-control/applications/:uuid/tnlease', component: TNLeases, noDisplay: true, props: { currentModule: 'callControl' } },
      { name: 'Control App Hooks', path: '/app/call-control/applications/:uuid/hook', component: Hook, noDisplay: true, props: { currModule: 'callControl' } },
      { name: 'CallControl Domains', path: '/app/call-control/applications/:uuid/domain', component: ManageDomains, noDisplay: true, props: { currModule: 'callControl' } }
    ]
  }),
  components: {
    SubBar
  }
}

</script>
