import ApiService from './api.service'

const CartStorage = {
  async addItem (item) {
    try {
      const response = await ApiService.post('/rest/user/cart', { payload: item })

      return response.data.payload
    } catch (error) {
      console.error(error.message)
      return []
    }
  },
  async updateItem (uuid, item) {
    try {
      const response = await ApiService.patch(`/rest/user/cart/${uuid}`, item)

      return response.data.payload
    } catch (error) {
      console.error(error.message)
      return []
    }
  },
  async deleteNumber (item) {
    try {
      const response = await ApiService.delete(`/rest/user/cart/${item.uuid}`)

      return response.data.payload
    } catch (error) {
      console.error(error.message)
      return []
    }
  },
  async retreiveCart () {
    try {
      const response = await ApiService.get('/rest/user/cart')

      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  },
  async clearNumbersCart () {
    try {
      await ApiService.delete('/rest/user/cart')
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

function prepareOrderRequest (items) {
  return {
    items: items.map(item => ({
      tn: item.tn ?? undefined,
      features: item.features ?? undefined
    }))
  }
}

const CartAPI = {
  async requestNumbersPrices (numbers) {
    if (!numbers.length) {
      return null
    }

    const payload = prepareOrderRequest(numbers)

    try {
      const response = await ApiService.post('/rest/order/price', payload)
      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  },
  async requestOneNumberPrice (item) {
    const payload = prepareOrderRequest([item])

    try {
      const response = await ApiService.post('/rest/order/price', payload)
      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  },
  async placeNumbersOrder (numbers) {
    if (!numbers.length) {
      return null
    }

    const payload = prepareOrderRequest(numbers)

    try {
      const response = await ApiService.post('/rest/order', payload)
      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }
}

export { CartStorage, CartAPI }
