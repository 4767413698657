<template>
  <span>
    <label class="input-label">
      {{inputLabel}}
      <slot name='input-label'></slot>
    </label>
    <vue-tel-input
      v-model='localValue'
      :placeholder="label"
      input-classes="project"
      @keypress.native="alphaOnly($event)"
    >
    </vue-tel-input>
  </span>
</template>

<style lang ='scss'>
@import "~vuetify/src/styles/styles.sass";
.vue-tel-input {
  border: 1px solid map-get(map-get($material-light, 'text-fields'), 'outlined') !important;
  height: 40px !important;
}
</style>

<script>
import { VueTelInput } from 'vue-tel-input'

export default {
  props: {
    value: null,
    inputLabel: {
      type: String
    },
    label: {
      type: String
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  components: {
    VueTelInput
  },
  methods: {
    alphaOnly (e) {
      if (!/^[0-9]+$/.test(e.key)) {
        e.preventDefault()
      }
    }
  }
}
</script>
