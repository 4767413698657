import { UserService, AuthenticationError, RegistrationError } from '../services/user.service'
import { TokenService } from '../services/storage.service'
import router from '../router'

const state = {
  authenticating: false,
  registering: false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: '',
  registrationErrorCode: 0,
  registrationError: '',
  registrationErrorFields: null,
  authVerified: false,

  reseting: false,
  resetError: ''
}

const getters = {
  loggedIn: (state) => {
    return !!state.accessToken
  },

  authVerified: (state) => {
    return state.authVerified
  },

  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode
  },

  authenticationError: (state) => {
    return state.authenticationError
  },

  authenticating: (state) => {
    return state.authenticating
  },

  registrationErrorCode: (state) => {
    return state.registrationErrorCode
  },

  registrationError: (state) => {
    return state.registrationError
  },

  registrationErrorFields: (state) => {
    return state.registrationErrorFields
  },

  registering: (state) => {
    return state.registering
  },

  reseting: (state) => {
    return state.reseting
  },

  resetError: (state) => {
    return state.resetError
  }
}

const actions = {
  async login ({ commit }, { email, password }) {
    commit('loginRequest')

    try {
      const response = await UserService.login(email, password)
      commit('loginSuccess', response.token)
      commit('user/setUserData', response, { root: true })

      // Redirect the user to the page he first tried to visit or to the home view
      router.push(router.history.current.query.redirect || '/')

      return true
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
      }

      return false
    }
  },

  async refreshLogin ({ commit, getters, dispatch }) {
    // If a user hasn't singed an agreement yet - redirect him to the license page
    if (!getters.authVerified) {
      if (TokenService.getToken()) {
        try {
          const response = await UserService.refreshLogin()
          commit('authVerified', response.token)
          commit('user/setUserData', response, { root: true })
        } catch (e) {
          dispatch('logout')
        }
      } else {
        dispatch('logout')
      }
    }
  },

  async register ({ commit }, userInfo) {
    commit('registerRequest')

    try {
      const response = await UserService.register(userInfo)
      commit('registerSuccess', response.token)
      commit('user/setUserData', response, { root: true })

      // Redirect the user to the page he first tried to visit or to the home view
      router.push(router.history.current.query.redirect || '/')

      return true
    } catch (e) {
      if (e instanceof RegistrationError) {
        commit('registerError', { errorCode: e.errorCode, errorMessage: e.message, fields: e.fields })
      }

      return false
    }
  },

  async logout ({ commit }) {
    try {
      await UserService.logout()
      commit('logoutSuccess')
      router.push('/auth')
    } catch (error) {
      console.error(error.message)
    }
  },

  async googleAuth ({ commit }, userData) {
    commit('loginRequest')
    try {
      const response = await UserService.googleAuth(userData)
      commit('loginSuccess', response.token)
      commit('user/setUserData', response, { root: true })

      // Redirect the user to the page he first tried to visit or to the home view
      router.push(router.history.current.query.redirect || '/')

      return true
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
      }

      return false
    }
  },

  async facebookAuth ({ commit }, state) {
    commit('loginRequest')
    try {
      const response = await UserService.facebookAuth(state)
      commit('loginSuccess', response.token)
      commit('user/setUserData', response, { root: true })

      // Redirect the user to the page he first tried to visit or to the home view
      router.push(router.history.current.query.redirect || '/')

      return true
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
      }

      return false
    }
  },

  async passwordReset ({ commit }, data) {
    commit('resetPassword')
    try {
      await UserService.resetPassword(data)
      commit('resetSuccess')

      return true
    } catch (e) {
      commit('resetError', e)
      return false
    }
  },

  async newPassword ({ commit }, data) {
    commit('resetPassword')
    try {
      await UserService.newPassword(data)
      commit('resetSuccess')

      return true
    } catch (e) {
      commit('resetError', e)
      return false
    }
  }
}

const mutations = {
  loginRequest (state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  loginSuccess (state, accessToken) {
    state.accessToken = accessToken
    state.authenticating = false
  },

  loginError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
  },

  registerRequest (state) {
    state.registering = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  registerSuccess (state, accessToken) {
    state.accessToken = accessToken
    state.registering = false
  },

  registerError (state, { errorCode, errorMessage, fields }) {
    state.registering = false
    state.registrationErrorCode = errorCode
    state.registrationError = errorMessage
    state.registrationErrorFields = fields
  },

  logoutSuccess (state) {
    state.accessToken = ''
  },

  authVerified (state, token) {
    state.authVerified = true
    state.token = token
  },
  changeAuthVerified (state, bool) {
    state.authVerified = bool
  },

  resetPassword (state) {
    state.reseting = true
  },

  resetError (state, error) {
    state.reseting = false
    state.resetError = error.message
  },

  resetSuccess (state) {
    state.reseting = false
  }
}

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
