<template>
  <div>
    <label v-if="useLabel" class='input-label'>{{label}}</label>
    <v-select
      v-model="localValue"
      :label="label2"
      :items="countries"
      item-value="a2"
      return-object
      :multiple="multiple"
      :loading="loading"
      single-line
      solo
      dense
      flat
      tile
      hide-details
      outlined
    >

      <template v-slot:item="{ item }">
<!--        <country-flag :country="item.code" />-->
        {{ item[listLabel] }}
      </template>
      <template v-slot:selection="{ item }">
        {{ item[selectLabel] }}
<!--        {{ item.dial_code }}-->
<!--        <country-flag :country="item.code" />-->
      </template>
    </v-select>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: null,
    availableCountries: {
      type: Array,
      default: () => []
    },
    useLabel: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    listLabel: {
      type: String,
      default: 'name'
    },
    selectLabel: {
      type: String,
      default: 'name'
    },
    label: {
      type: String,
      default: 'Country (Required)'
    },
    label2: {
      type: String,
      default: 'Select...'
    }
  },
  data: () => ({
    // countries: []
  }),
  computed: {
    ...mapGetters({
      countries: 'dict/countries/list',
      loading: 'dict/countries/loading'
    }),
    localValue: {
      get () {
        if (this.value === null) {
          this.$emit('input', this.countries[0])
          return this.countries[0]
        } else {
          return this.value
        }
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions({
      requestList: 'dict/countries/requestList'
    })
  },
  components: {
    CountryFlag
  },
  mounted: function () {
    this.requestList()
  }
}
</script>

<style lang='scss' scoped>
@import '~vuetify/src/styles/styles.sass';
.f {
  height: 36px !important;
}
.v-sheet {
  background-color: map-get($material-light, 'background') !important;
}
.input-label {
  display: block !important;
  font-weight: 600;
  margin-bottom: 0.5rem !important;
  color: map-get(map-get($material-light, 'text'), 'secondary');
}
</style>
