const TOKEN_KEY = 'access_token'
const SECRETS_KEY = 'secret_key'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/
const TokenService = {
  getToken () {
    return localStorage.getItem(TOKEN_KEY)
  },

  saveToken (accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken () {
    localStorage.removeItem(TOKEN_KEY)
  }
}

const SecretService = {
  getSecrets () {
    return JSON.parse(localStorage.getItem(SECRETS_KEY))
  },

  saveSecrets (secrets) {
    localStorage.setItem(SECRETS_KEY, JSON.stringify(secrets))
  },

  removeSecrets () {
    localStorage.removeItem(SECRETS_KEY)
  }
}

export { TokenService, SecretService }
