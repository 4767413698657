import SIPDomainHotkeysAPI, { SIPHotkeysService } from '../../../services/sipDomainHotkey.service'
import ItemBase from '../../itemBase'

class SIPDomainHotkeys extends ItemBase {
  mutations () {
    const mutations = super.mutations()
    mutations.setAPILink = function (state, API_LINK) {
      state.API = new SIPHotkeysService(API_LINK)
    }

    return mutations
  }
}

export const hotkeys = new SIPDomainHotkeys(SIPDomainHotkeysAPI).getModule()
