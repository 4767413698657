<template>
  <o-text-field
    v-model="localValue"
    input-label='Domain'
    :rules="[notEmpty, isSubdomain]"
    :disabled="disabled"
    :hide-details="false"
  >
    <template v-slot:append>
      {{subdomainPostfix}}
    </template>
    <template v-slot:append-outer>
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        Should contain only latin characters and numbers
      </v-tooltip>
    </template>
  </o-text-field>
</template>

<script>
export default {
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    notEmpty: val => !!val || 'Required',
    isSubdomain: function (val) {
      const re = /^[a-zA-Z][a-zA-Z0-9]{0,79}$/
      return Boolean(val.match(re)) || 'Should contain only latin characters and numbers'
    }
  }),
  computed: {
    subdomainPostfix () {
      // api.stage.opentact.org
      // api.opentact.org
      // dev.alucky.dev
      const domain = process.env.VUE_APP_ROOT_API.match(/http[s]?:\/\/([a-zA-Z0-9]+)?\.?(?<domain>[a-zA-Z0-9.]+)/)?.groups
      return `.sip.${domain?.domain || 'stage.opentact.org'}`
      // const re = /http(s?):\/\/\w\.(\w+)/
      // return process.env.VUE_APP_ROOT_API.replace(re, '.sip.$2')
    },
    localValue: {
      set (val) {
        this.$emit('input', val
          ? val + this.subdomainPostfix
          : '')
      },
      get () {
        return this.value?.replace(this.subdomainPostfix, '') || ''
      }
    }
  }
}
</script>
