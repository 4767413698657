<template>
  <v-navigation-drawer
    v-model="navVisible"
    :permanent="$vuetify.breakpoint.smAndUp"
    app
    clipped
    :width="width"
    outlined='false'
    floating
    class='pt-5 app-navigation'
  >
    <VuePerfectScrollbar
      v-once
      :settings="settings"
      class="scroll-area h-100"
    >
     <v-list
        dense
        flat
        subheader
        class='pl-5'
      >
        <v-list-item-group color='secondary'>
          <div v-for="item in items" :key="item.title">
            <v-list-item
              v-if="!item.subLinks"
              link
              :to="item.route"
              :ripple='false'
              class='pl-0 px-2 pb-2 pt-2'
            >
            <v-list-item-icon class='ml-1 mr-2 mb-0 mt-0'>
              <v-icon size='1.0857143rem' class='ma-0'>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class='py-0'>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-group
              v-else
              no-action
              :value="false"
              class="sub-link-list-group"
              link
              :to="item.route"
              :ripple='false'
            >
              <template v-slot:activator>
                <v-list-item-icon class='ml-1 mr-2 '>
                  <v-icon size='1.0857143rem' class='ma-0'>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>

              <v-list-item
                v-for="sublink in item.subLinks"
                :to="sublink.path"
                :key="sublink.name"
                class='pl-8 px-2 pb-2 pt-2'
              >
                <v-list-item-title>{{ sublink.name }}</v-list-item-title>
              </v-list-item>

            </v-list-group>
          </div>
        </v-list-item-group>
      </v-list>
    </VuePerfectScrollbar>
  </v-navigation-drawer>
</template>

<style lang='scss' scoped>
.v-list-item {
  min-height: 0px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.v-list-item__title {
  font-family: "Cerebri Sans,sans-serif" !important;
  font-size: 1.0857143rem !important;
  font-weight: 400 !important;
  line-height: 1.0857143rem !important;
}
.v-subheader {
  letter-spacing: 0.55px;
  font-size: .7857143 !important;
  font-weight: 600 !important;
  height: auto !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

</style>
<style lang='scss'>
.h-100 {
  height: 100%;
}
.ps .ps__rail-y:hover {
  width: 6px !important;
  background-color: transparent !important;
}
</style>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ConnectionsList from '../views/Connections/ConnectionsList'
import ConnectionDetails from '../views/Connections/ConnectionDetails'
import TNLeases from '../views/Connections/TNLeases'
// import ManageDomains from '../views/SIPDomain/ManageDomains'

export default {
  name: 'AppNavigation',
  props: ['width'],
  data: () => ({
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/app/dashboard' },
      { title: 'Numbers', icon: 'mdi-dialpad', route: '/app/numbers' },
      { title: 'Messaging', icon: 'mdi-message-text-outline', route: '/app/messaging' },
      { title: 'SIP Control App', icon: 'mdi-phone-forward', route: '/app/call-control' },
      {
        title: 'SIP Connections',
        icon: 'mdi-lan',
        route: '/app/connections',
        subLinks: [
          { name: 'List', path: '/app/connections', component: ConnectionsList },
          { name: 'New Connection', path: '/app/connections/new', component: ConnectionDetails },
          { name: 'SIP Connection Numbers', path: '/app/connections/:uuid/tnlease', component: TNLeases }
          // { name: 'SIP Connection Domains', path: '/app/connections/:uuid/domain', component: ManageDomains, props: { currModule: 'sipConnections' } }
        ]
      },
      { title: 'SIP Domains', icon: 'mdi-domain', route: '/app/sip-domain' },
      { title: 'Outbound Voice Profiles', icon: 'mdi-phone-in-talk', route: '/app/outbound-profiles' },
      { title: 'Logs', icon: 'mdi-chart-pie', route: '/app/reporting' },
      { title: 'Debugging', icon: 'mdi-bug', route: '/app/debugging' },
      { title: 'Auth', icon: 'mdi-account-key', route: '/app/auth' },
      { title: 'Tickets', icon: 'mdi-ticket-account', route: '/app/ticket' }
      // { title: 'Networking', icon: 'mdi-earth-off', route: '/app/networking' },
      // { title: 'Telco Data', icon: 'mdi-flag-checkered', route: '/app/telcodata' }
    ],
    settings: {
      minScrollbarLength: 60
    }
  }),
  computed: {
    navVisible: {
      set: function (val) {
        this.$store.commit('navigation/setVisibility', val)
      },
      get: function () {
        return this.$store.getters['navigation/isVisible']
      }
    }
  },
  components: {
    VuePerfectScrollbar
  },
  created: function () {
    if (this.$vuetify.breakpoint.xsOnly) {
      this.$store.commit('navigation/setVisibility', false)
    } else {
      this.$store.commit('navigation/setVisibility', true)
    }
  }
}

</script>
