<template>
<span>
  <router-view></router-view>
  <SupportChat/>
</span>
</template>

<style lang='scss'>
@import "~vuetify/src/styles/styles.sass";
.v-content__wrap {
  padding: 0px 15px 5px 15px !important;
}
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid rgba(map-get($shades, 'black'), 0.38) !important;
}
.v-input button.v-icon {
  color: map-get(map-get($material-light, 'text'), 'primary') !important;
}
.cut-right-border {
  border-top-right-radius: $border-radius-root !important;
  border-bottom-right-radius: $border-radius-root !important;
}
.cut-border-radius-all {
  border-radius: $border-radius-root !important;
}
.no-right-border-radius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.no-left-border-radius {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.v-btn {
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  letter-spacing: normal;
}
.theme--light.v-btn.v-btn--disabled {
  color: white !important;
  opacity: 0.40 !important;
}
.theme--light.v-btn.v-btn--disabled.v-btn--outlined {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: #cccccc !important;
}
h2 {
  font-size: 1.6rem !important;
  font-weight: 800 !important;
}
h2 {
  font-size: 1.4rem !important;
}
h4 {
  font-size: 1.2rem !important;
}
h5 {
  font-size: 1.1rem !important;
}
.theme--light.v-input {
  margin-bottom: .8rem !important;
}
.input-label {
  display: block !important;
  font-weight: 600;
  margin-bottom: 0.5rem !important;
  color: map-get(map-get($material-light, "text"), "secondary");
}
.v-btn.v-btn--outlined {
  border-width: 2px !important;
}
</style>

<script>
import { mapActions } from 'vuex'
import SupportChat from './components/SupportChat'

export default {
  methods: {
    ...mapActions({
      requestSecrets: 'secret/requestSecrets'
    })
  },
  created () {
    this.requestSecrets()
  },
  components: {
    SupportChat
  }
}
</script>
