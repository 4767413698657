<template>
<span>
  <v-card
    v-if="exporting"
    flat
  >
    <v-progress-linear
      height='36px'
      color='info'
      :value="exportProgress"
      rounded
      :indeterminate="!exportProgress"
    >
      {{ 'Export in progress' + (exportProgress ? `: ${exportProgress}%` : '') }}
    </v-progress-linear>
  </v-card>
  <v-btn
    v-else-if="exportLink"
    :block="true"
    outlined
    color='success'
    :ripple='false'
    :href="exportLink"
    download
  >
    <v-icon class='mr-1' size='1.2rem'>mdi-download</v-icon>
    Download CSV
  </v-btn>
  <v-btn
    v-else
    block
    outlined
    color='info'
    :ripple='false'
    @click="exportCSV"
  >
    Click to start export to CSV
  </v-btn>
</span>
</template>

<script>
export default {
  props: {
    currentModule: null,
    filterParams: null
  },
  methods: {
    // actions
    async exportCSV () {
      return await this.$store.dispatch(`${this.currentModule}/exportCSV`, this.filterParams)
    },
    clear () {
      this.$store.dispatch(`${this.currentModule}/exportClear`, {})
    }
  },
  computed: {
    // getters
    exportProgress () {
      return this.$store.getters[`${this.currentModule}/exportProgress`]
    },
    exporting () {
      return this.$store.getters[`${this.currentModule}/exporting`]
    },
    exportLink () {
      return this.$store.getters[`${this.currentModule}/exportLink`]
    }
    //
  },

  watch: {
    filterParams: {
      deep: true,
      handler (val) {
        this.clear()
      }
    }
  },

  mounted () {
    this.clear()
  }
}
</script>
