<template>
  <v-container class='pa-3 pa-md-7'>
    <v-row>
      <v-col cols='9' md='3' align-self='center' class='mb-6 ml-6'>
        <h4>Make Payment</h4>
      </v-col>
      <v-col cols='12' md='3' class='pr-md-12'>
        <o-text-field
          v-model="addAmount"
          type='number'
          :rules="[checkNumber]"
          :hint="typeof(checkNumber(addAmount)) === 'string' ? checkNumber(addAmount) : ' ' "
        >
          <template v-slot:prepend-inner>
            <b class='pt-1'>$</b>
          </template>
        </o-text-field>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row class='pb-0'>
      <v-col cols='12' md='6'>
        <v-expansion-panels flat>
<!--          <v-expansion-panel active-class='lighten-4'>-->
<!--            <v-expansion-panel-header>-->
<!--              <h4 class='mb-2'>Proceed with PayPal</h4>-->
<!--            </v-expansion-panel-header>-->
<!--            <v-expansion-panel-content>-->
<!--              <PayPal-->
<!--                @success="paymentSucceeded"-->
<!--                @failure="paymentFailed"-->
<!--                :amount="addAmount"-->
<!--              >-->
<!--              </PayPal>-->
<!--            </v-expansion-panel-content>-->
<!--          </v-expansion-panel>-->
          <v-expansion-panel active-class='lighten-4'>
            <v-expansion-panel-header>
              <h4 class='mb-2'>Proceed with Stripe:</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Stripe
                @success="paymentSucceeded"
                @failure="paymentFailed"
                :amount="addAmount"
              >
              </Stripe>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-snackbar
      top
      color='success'
      v-model="successSnackbar"
      :timeout="5000"
    >
      Payment of ${{addedAmount}} succeeded!
      <v-btn
        text
        :ripple=false
        @click="successSnackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar
      top
      color='error'
      v-model="errorSnackbar"
      :timeout="5000"
    >
      Payment failed
      <v-btn
        text
        :ripple=false
        @click="errorSnackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import PayPal from './PayPal'
import Stripe from './Stripe'

export default {
  name: 'MakePayment',
  components: {
    // PayPal,
    Stripe
  },
  data: () => ({
    addAmount: 10,
    addedAmount: null,
    successSnackbar: false,
    errorSnackbar: false,
    checkNumber: value => {
      const v = parseFloat(value)
      return ((!isNaN(v) && v > 0) || 'Invalid number')
    }
  }),
  methods: {
    paymentSucceeded (amount) {
      this.addedAmount = amount
      this.successSnackbar = true
    },
    paymentFailed () {
      this.errorSnackbar = true
    }
  }
}
</script>
