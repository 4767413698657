import TNLeaseSubscriptionVoiceAPI from '../../../services/tn/tnlease/subscription/sms.service'
import ItemBase from '../../itemBase'

class TNLeaseSubscriptionsVoice extends ItemBase {
  actions () {
    const actions = super.actions()
    actions.get = () => { throw new Error('Unsupported methods get for tnlease subscription') }

    return actions
  }
}

export const voice = new TNLeaseSubscriptionsVoice(TNLeaseSubscriptionVoiceAPI, '/app/numbers/my-numbers').getModule()
