<template>
  <span>
    <v-container class='py-0'>
      <v-row>
        <v-col class='pa-0'>
          <label
            v-if="inputLabel!==''"
            class="input-label"
          >
            {{inputLabel}}
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class='pa-0' cols='5'>
          <v-autocomplete
            v-model='hours'
            :items='availHours'
            label='h.'
            dense
            outlined
            single-line
            hide-details
            :disabled="disabled"
          >
          </v-autocomplete>
        </v-col>
        <v-spacer/>
        <v-col cols='1'>
          <span> : </span>
        </v-col>
        <v-spacer/>
        <v-col class='pa-0' cols='5'>
          <v-autocomplete
            v-model='minutes'
            :items='availMinutes'
            label='m.'
            dense
            outlined
            single-line
            hide-details
            :disabled="disabled"
          >
          </v-autocomplete>
         </v-col>
      </v-row>
    </v-container>
  </span>
</template>

<script>
export default {
  props: {
    value: null,
    inputLabel: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    availHours: [],
    availMinutes: []
  }),
  computed: {
    hours: {
      get () {
        if (this.value === null || this.value.hours === null) {
          return null
        } else {
          return this.value.hours
        }
      },
      set (val) {
        const newVal = { hours: val, minutes: this.minutes }
        this.$emit('input', newVal)
      }
    },
    minutes: {
      get () {
        if (this.value === null || this.value.minutes === null) {
          return null
        } else {
          return this.value.minutes
        }
      },
      set (val) {
        const newVal = { hours: this.hours, minutes: val }
        this.$emit('input', newVal)
      }
    }
  },
  created: function () {
    this.initHours()
    this.initMinutes()
  },
  methods: {
    initHours: function () {
      for (let i = 0; i < 24; i++) {
        this.availHours.push(i)
      }
    },
    initMinutes: function () {
      for (let i = 0; i < 60; i++) {
        this.availMinutes.push(i)
      }
    }
  }
}
</script>
