<template>
  <v-row justify="start">
    <v-col cols='12'>
      <o-select
        width='150px'
        :items="list"
        :loading="loading"
        item-text='name'
        item-value='uuid'
        class='select'
        v-model="selectValue"
        :disabled="disabled"
        @input="update"
      >
      </o-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  data: () => ({
    selectValue: null
  }),
  computed: {
    ...mapGetters({
      list: 'callControl/list',
      error: 'callControl/errorMessage',
      loading: 'callControl/loading'
    })
  },
  methods: {
    ...mapActions({
      requestList: 'callControl/requestList'
    }),
    update (uuid) {
      this.$emit('input', this.list.find(_ => _.uuid === uuid))
    }
  },
  created () {
    this.requestList({
      order: {
        hotkey: 'ASC'
      }
    }).then(res => {
      if (!res) {
        return this.notify({ text: this.error, type: 'error' })
      }
      this.selectValue = this.value?.uuid
    })
  }
}
</script>

<style scoped>
.select >>> .v-input {
  margin-bottom: 0 !important;
}
</style>
