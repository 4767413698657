<template>
  <v-card outlined class='pa-5'>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <o-select
            input-label='Billing Group'
            label='Select Billing Group'
            :items="billingGroups"
            hide-details
          >
          </o-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12'>
          <o-select
            v-model="billingMethod"
            input-label='Voice Billing Method'
            :items="billingMethods"
            hide-details
          >
          </o-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='6'>
          <v-checkbox
            label='Enable CNAM Listing'
            disabled
          >
          </v-checkbox>
          <o-text-field
            label='Define Custom Name'
            disabled
            hide-details
          >
          </o-text-field>
        </v-col>
        <v-col cols='12' md='6' align-self='end'>
          <o-tel-input
            input-label='Forwards To'
          >
          </o-tel-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='auto'>
          <v-checkbox
            label='Enable Caller ID Name'
          >
          </v-checkbox>
        </v-col>
      </v-row>
      <v-expansion-panels tile flat class='mt-5 pa-0'>
        <v-expansion-panel class='pa-0'>
          <v-expansion-panel-header class='pa-0'>
            <h4>Expert Configuration</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content class='pa-0'>
            <v-row>
              <v-col cols='12' md='6'>
                <o-text-field
                  input-label='Translated Number'
                  hide-details
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <o-select
                  v-model="mediaHandlingMode"
                  :items="mediaHandlingModes"
                  input-label='Media Handling Mode'
                  hide-details
                >
                </o-select>
              </v-col>
             </v-row>
             <v-row>
               <v-col>
                <v-checkbox
                  label='Enable Tech Prefix'
                >
                </v-checkbox>
                <v-checkbox
                  label='Disable RTP Auto Adjust'
                >
                </v-checkbox>
                <v-checkbox
                  label='Accept any RTP packets'
                >
                </v-checkbox>
                <v-checkbox
                  label='Enable T.38 FAX Gateway'
                >
                </v-checkbox>
              </v-col>
             </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>
  </v-card>
</template>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

<script>
export default {
  data: () => ({
    billingGroups: [],
    billingMethods: [
      { text: 'Pay Per Minute', value: 'perMinute' },
      { text: 'Channel (Not available)', value: 'channel' }
    ],
    billingMethod: 'perMinute',
    mediaHandlingModes: [
      { text: 'Default', value: 'default' },
      { text: 'Proxy', value: 'proxy' },
      { text: 'Bypass', value: 'bypass' }
    ],
    mediaHandlingMode: 'default'
  })
}
</script>
