<template>
  <List
    current-module="numbersOrders"
    :has-add="false"
    :use-search="true"
    :headers="dataHeaders"
    :has-created="true"
    :has-filtration="true"
    :add-search-props="{ features: [] }"
  >
    <template v-slot:filtration="{ item }">
      <v-row>
        <v-col cols='12' md='6'>
          <o-date-time
            input-label="Created After"
            v-model="item.created_on_from"
          >
          </o-date-time>
        </v-col>
        <v-col cols='12' md='6'>
          <o-date-time
            input-label="Created Before"
            v-model="item.created_on_to"
          >
          </o-date-time>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.state="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="item.state==='success'"
            color='success'
            v-on="on"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else-if="item.state==='failed'"
            color='error'
            v-on="on"
          >
            mdi-close-circle
          </v-icon>
          <v-icon
            v-else
            color='warning'
            v-on="on"
          >
            mdi-clock
          </v-icon>
        </template>
        <span>{{item.state}}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.details="{ item }">
      <v-btn
        :ripple="false"
        text
        v-on="on"
        class='info white--text'
        append
        :to="`${item.uuid}`"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>
  </List>
</template>

<script>
import List from '../../components/CRUD/List'

export default {
  data: () => ({
    dataHeaders: [
      { text: 'State', value: 'state', sortable: false },
      { text: 'Order Date', value: 'created_on' },
      { text: 'Total Numbers', value: 'total', align: 'center', sortable: false },
      { text: 'Num Processing', value: 'processing', align: 'center', sortable: false },
      { text: 'Num Success', value: 'success', align: 'center', sortable: false },
      { text: 'Num Failed', value: 'failed', align: 'center', sortable: false },
      { text: 'Details', value: 'details', sortable: false }
    ]
  }),
  methods: {
    beautifyDate: (unformatted) => {
      const date = new Date(unformatted)
      return date.toLocaleString()
    }
  },
  components: {
    List
  }
}
</script>
