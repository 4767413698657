import ItemBase from '../../../itemBase.service'

class TNLeaseSubscriptionSMSAPI extends ItemBase {
  buildRequest (item) {
    return {
      messaging_profile: item.messaging_profile,
      sms_campaign: item.sms_campaign
    }
  }

  buildApiLink (keyValue) {
    return super.buildApiLink(keyValue + '/subscription/sms')
  }
}

export default new TNLeaseSubscriptionSMSAPI('/rest/lease/tn')
