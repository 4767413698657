import UserService from '../services/user.service'
import store from '../store'

const state = {
  userData: {
    type: Object,
    default: null
  },
  loadingUserData: false,
  saving: false,
  errorMessage: ''
}

const getters = {
  userData: (state) => {
    return state.userData
  },

  balance: (state) => {
    return store.getters['user/userData']?.account?.balance || ''
  },

  uuid: (state) => {
    return state.userData.uuid
  },

  email: (state) => {
    return state.userData.email
  },

  phone_number: (state) => {
    return state.userData.phone_number
  },

  first_name: (state) => {
    return state.userData.first_name
  },

  last_name: (state) => {
    return state.userData.last_name
  },

  full_name: (state) => {
    return state.userData.first_name + ' ' + state.userData.last_name
  },

  avatar: (state) => {
    return state.userData.avatar
  },

  role: (state) => {
    return state.userData.role
  },

  level: (state) => {
    return state.userData ? state.userData.account.level : undefined
  },

  loadingUserData: (state) => {
    return state.loadingUserData
  },

  saving: (state) => {
    return state.saving
  },

  errorMessage: (state) => {
    return state.errorMessage
  }
}

const mutations = {
  setUserData (state, userData) {
    state.userData = userData
    state.loadingUserData = false
  },

  setAccountBalance (state, accountBalanceData) {
    state.userData = {
      ...state.userData || {},
      account: {
        ...state.userData.account || {},
        balance: accountBalanceData.balance || ''
      }
    }
  },

  requestUserData (state) {
    state.loadingUserData = true
  },

  requestUserDataFailed (state) {
    state.userData = {
      first_name: '',
      last_name: ''
    }
    state.loadingUserData = false
  },

  save (state) {
    state.saving = true
  },

  saveSucceeded (state, userData) {
    state.userData = userData
    state.saving = false
  },

  saveFailed (state, error) {
    state.errorMessage = error.message
    state.saving = false
  }
}

const actions = {
  async requestUserData ({ commit }) {
    commit('requestUserData')
    try {
      const userData = await UserService.requestUserData()
      commit('setUserData', userData)
      return true
    } catch (e) {
      commit('requestUserDataFailed')
      return false
    }
  },
  async patch ({ getters, commit, dispatch }, userData) {
    commit('save')
    try {
      const responseData = await UserService.patchUser(userData, getters.uuid)

      commit('saveSucceeded', responseData)
      dispatch('notification/notify',
        { text: 'User data has been successfuly updated.' },
        { root: true })
      return true
    } catch (e) {
      dispatch('notification/notify',
        { text: 'User data update failed.', type: 'error' },
        { root: true })
      commit('saveFailed', e)
      return false
    }
  },
  async uploadAvatar ({ getters, commit }, { file }) {
    try {
      const userData = await UserService.uploadAvatar(getters.uuid, file)

      commit('setUserData', userData)
      return true
    } catch (e) {
      return false
    }
  },
  async deleteAvatar ({ getters, commit }) {
    try {
      const userData = await UserService.deleteAvatar(getters.uuid)

      commit('setUserData', userData)
      return true
    } catch (e) {
      return false
    }
  }
}

export const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
