<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import Tickets from './Tickets'
import TicketDetails from './TicketDetails'

export default {
  name: 'Tickets',
  data: () => ({
    subViews: [
      { name: 'Tickets List', path: '/app/ticket', component: Tickets },
      { name: 'Edit Ticket', path: '/app/ticket/:uuid', component: TicketDetails },
      { name: 'New Ticket', path: '/app/ticket/new', component: TicketDetails }
    ]
  }),
  components: {
    Tickets,
    TicketDetails
  }
}
</script>
