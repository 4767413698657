<template>
  <APIAutocomplete
    v-model="localValue"
    v-bind="$attrs"
    v-on="listeners"

    current-module='tnlease'
    :item-value="useUuid ? 'uuid' : 'tn.tn'"
    item-text='tn.tn'
    pattern-key='tn'
    :filterResults="filterResults"
  >
    <template v-slot:item="{ item }">
      {{beautifyNumber(item.tn.tn)}}
    </template>
    <template v-slot:selection="{ item }">
      {{beautifyNumber(item.tn.tn)}}
    </template>

    <!-- bypass all slots from a parent -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
  </APIAutocomplete>
</template>

<script>
import APIAutocomplete from './APIAutocomplete'
import BeautifyNumber from '../services/beautify/number.service'

export default {
  props: {
    useUuid: {
      type: Boolean,
      default: false
    },
    value: null,
    smsOnly: {
      type: Boolean,
      default: false
    },
    onlySMS: {
      type: Boolean,
      default: false
    }
  },
  components: {
    APIAutocomplete
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    listeners () {
      // we have to remove the 'input' event as we process it ourselves
      return Object.keys(this.$listeners)
        .filter(field => field !== 'input')
        .reduce((obj, key) => {
          obj[key] = this.$listeners[key]
          return obj
        }, {})
    }
  },
  created () {
    this.filterResults = (arr) => {
      let result = arr
      if (this.onlySMS) {
        result = result.filter(item => item.subscriptions.length === 1)
      }
      return result.filter(el => el.subscriptions.some(el2 => el2.type === 'sms'))
    }
  },
  methods: {
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  data: () => ({
    filterResults: null
  })
}
</script>
