import ApiService from './api.service'

export default class SIPLeasesBaseAPI {
  constructor (apiLink) {
    this.apiLink = apiLink
  }

  async patchLeases (uuid, tnleases) {
    try {
      const response = await ApiService.post(`${this.apiLink}/${uuid}/tnlease`, tnleases)

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message ?? error.response.statusText)
    }
  }

  async assignAllLeases (uuid) {
    try {
      const response = await ApiService.put(`${this.apiLink}/${uuid}/tnlease`)

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message ?? error.response.statusText)
    }
  }
  // TODO: patchAllLeases HERE
}
