import { SIPInvitesAPI } from '../services/sipInvites.service'
import ItemBase from './itemBase'

class SipInvites extends ItemBase {
  mutations () {
    const mutations = super.mutations()

    mutations.setAPILink = function (state, API_LINK) {
      state.API = new SIPInvitesAPI(API_LINK)
    }

    return mutations
  }
}

export const sipInvites = new SipInvites().getModule()
