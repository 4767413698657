import Vue from 'vue'
import router from '../router'
import axios from 'axios'

export default class {
  constructor (API, APP_LINK, MODULE_FOR_EXPORT) {
    this.API = API
    this.APP_LINK = APP_LINK
    this.MODULE = MODULE_FOR_EXPORT // required for export
  }

  state () {
    return {
      API: this.API,
      APP_LINK: this.APP_LINK,
      MODULE: this.MODULE,
      loading: false,
      list: [],
      current: null,
      error: '',
      error_message: '',
      saving: false,
      unique_key: 'uuid',
      count: 0,
      deleting: false,

      exportProgress: 0,
      exporting: false,
      exportLink: ''
    }
  }

  getters () {
    return {
      unique_key (state) {
        return state.unique_key
      },

      loading (state) {
        return state.loading
      },

      deleting (state) {
        return state.deleting
      },

      list (state) {
        return state.list
      },

      count (state) {
        return state.count
      },

      errorMessage (state) {
        return state.error
      },
      api_error_message (state) {
        return state.error_message
      },

      itemByKey (state) {
        return function (keyValue) {
          const item = state.list.find(el => el.identificator === keyValue)
          if (item) {
            return JSON.parse(JSON.stringify(item))
          } else {
            return undefined
          }
        }
      },

      current (state) {
        return state.current
      },

      saving (state) {
        return state.saving
      },

      API (state) {
        return state.API
      },

      APP_LINK (state) {
        return state.APP_LINK
      },

      MODULE (state) {
        return state.MODULE
      },

      exportProgress (state) {
        return state.exportProgress
      },

      exporting (state) {
        return state.exporting
      },

      exportLink (state) {
        return state.exportLink
      }
    }
  }

  mutations () {
    return {
      request (state) {
        state.loading = true
      },

      set (state, list) {
        state.list = list
        state.list.forEach(sch => {
          Vue.set(sch, 'loaded', true)
          Vue.set(sch, 'identificator', sch[state.unique_key])
        })
        state.loading = false
      },

      setCount (state, count) {
        state.count = count
      },

      failRequest (state, error) {
        state.loading = false
        state.error = 'Cannot load the list of items: ' + error.message
      },

      failDelete (state, error) {
        state.error = 'Cannot delete item: ' + error.message
        state.error_message = error.message
      },

      add (state, item) {
        Vue.set(item, 'loaded', true)
        Vue.set(item, 'identificator', item[state.unique_key])
        state.list.unshift(item)
      },
      addNew (state, item) {
        state.list.unshift(item)
      },

      deleted (state, keyValue) {
        const index = state.list.findIndex(el => el.identificator === keyValue)
        if (index > -1) {
          state.list.splice(index, 1)
        }
      },

      deletedSingle (state) {
        state.deleting = false
      },

      update (state, item) {
        Vue.set(item, 'loaded', true)
        Vue.set(item, 'identificator', item[state.unique_key])
        const index = state.list.findIndex(el => el.identificator === item[state.unique_key])
        if (index > -1) {
          Vue.set(state.list, index, item)
        } else {
          state.list.push(item)
        }
      },

      deleting (state, keyValue) {
        const item = state.list.find(el => el.identificator === keyValue)
        if (item) {
          Vue.set(item, 'deleting', true)
        }
      },

      deletingSingle (state) {
        state.deleting = true
      },

      setCurrent (state, uniqueKeyValue) {
        const item = state.list.find(el => el.identificator === uniqueKeyValue)
        if (item) {
          state.current = JSON.parse(JSON.stringify(item))
        }
      },

      setCurrentValue (state, item) {
        state.current = item
      },

      updateCurrent (state, details) {
        if (!state.current) {
          state.current = JSON.parse(JSON.stringify(details))
        } else {
          for (const prop in details) {
            if (state.current[prop] === undefined) {
              Vue.set(state.current, prop, details[prop])
            }
          }
        }
        Vue.set(state.current, 'identificator', state.current[state.unique_key])
        state.loading = false
      },

      clearCurrent (state) {
        state.current = {}
      },

      setDefault (state) {
        state.current = {}
      },

      save (state) {
        state.saving = true
      },

      failSaving (state, error) {
        state.saving = false
        state.error = error.message
      },

      create (state) {
        state.saving = true
      },

      saved (state, item) {
        state.saving = false
        if (item) {
          state.current = item
        }
      },

      failCreating (state, error) {
        state.saving = false
        state.error = error.message
      },

      exportStart (state) {
        state.exportLink = ''
        state.exporting = true
      },

      exportProgress (state, progress) {
        state.exportProgress = progress
      },

      exportEnd (state, link) {
        state.exportProgress = 0
        state.exporting = false
        state.exportLink = link
      },

      exportClear (state) {
        state.exportProgress = 0
        state.exporting = false
        state.exportLink = ''
        state.exportError = ''
      },

      exportFail (state, error) {
        state.exportProgress = 0
        state.exporting = false
        state.exportLink = ''
        state.exportError = error.message
      }
    }
  }

  actions () {
    return {
      checkToken ({ commit, getters }, token) {
        return new Promise((resolve, reject) => {
          axios
            .get(`rest/app/invite/${token}`)
            .then(res => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      async requestList ({ commit, getters }) {
        commit('request')
        commit('exportClear')

        try {
          const items = await getters.API.requestList()
          commit('set', items)
          commit('setCount', items.length)

          return true
        } catch (error) {
          commit('failRequest', error)
          return false
        }
      },

      async requestSingle ({ commit, getters }) {
        commit('request')

        try {
          const item = await getters.API.requestSingle()
          commit('updateCurrent', item)

          return true
        } catch (error) {
          commit('failRequest', error)
          return false
        }
      },

      async search ({ commit, getters }, searchParams) {
        commit('request')
        commit('exportClear')

        try {
          const searchResult = await getters.API.search(searchParams)
          commit('set', searchResult.data)
          commit('setCount', searchResult.total)

          return true
        } catch (error) {
          commit('failRequest', error)
          return false
        }
      },

      async requestOne ({ commit, getters }, keyValue) {
        try {
          const details = await getters.API.requestOne(keyValue)
          commit('updateCurrent', details)
          commit('update', details)

          return true
        } catch (error) {
          commit('failRequest', error)
          return false
        }
      },

      async deleteSingle ({ commit, getters }) {
        try {
          commit('deletingSingle')
          await getters.API.delete()
          commit('setDefault')
          commit('deletedSingle')

          return true
        } catch (error) {
          commit('failDelete', error)
          return false
        }
      },

      async deleteItem ({ commit, getters, dispatch }, keyValue) {
        try {
          commit('deleting', keyValue)
          await getters.API.delete(keyValue)
          commit('deleted', keyValue)
          if (!keyValue.doNotRedirect) {
            dispatch('navigateToApp')
          }

          return true
        } catch (error) {
          commit('failDelete', error)
          return false
        }
      },

      async create ({ commit, getters, dispatch }, inputItem) {
        commit('create')
        const itemToCreate = (inputItem && JSON.parse(JSON.stringify(inputItem))) ?? getters.current
        // eslint-disable-next-line no-prototype-builtins
        if (inputItem && inputItem.hasOwnProperty('doNotRedirect')) {
          delete itemToCreate.doNotRedirect
        }
        try {
          const item = await getters.API.create(itemToCreate)

          commit('saved', item)
          commit('add', item)
          if (inputItem && !inputItem.doNotRedirect) {
            dispatch('navigateToApp')
          }
          return true
        } catch (error) {
          commit('failCreating', error)
          return false
        }
      },

      async patch ({ commit, getters, dispatch }, inputItem) {
        commit('save')

        try {
          const item = await getters.API.patch(inputItem.identificator, inputItem)

          commit('saved', item)
          commit('update', item)
          if (!inputItem.doNotRedirect) {
            dispatch('navigateToApp')
          }

          return true
        } catch (error) {
          commit('failSaving', error)
          return false
        }
      },

      async patchSingle ({ commit, getters, dispatch }, inputItem) {
        commit('save')

        try {
          const item = await getters.API.patchSingle(inputItem)

          commit('saved', item)
          commit('update', item)
          if (!inputItem.doNotRedirect) {
            dispatch('navigateToApp')
          }

          return true
        } catch (error) {
          commit('failSaving', error)
          return false
        }
      },

      async patchActive ({ commit, getters }, keyValue) {
        try {
          const current = {
            is_active: getters.itemByKey(keyValue).is_active
          }
          const item = await getters.API.patch(keyValue, current)

          commit('update', item)

          return true
        } catch (error) {
          commit('failSaving', error)
          return false
        }
      },

      navigateToApp ({ getters }) {
        if (getters.APP_LINK && getters.APP_LINK !== router.currentRoute.fullPath) {
          router.push(getters.APP_LINK)
        }
      },

      async exportCSV ({ getters, commit, dispatch }, params) {
        commit('exportStart')

        try {
          const response = await getters.API.exportCSV(params)

          commit('exportModule/addToMap', {
            module: getters.MODULE,
            id: response.id
          },
          { root: true })

          return true
        } catch (error) {
          commit('exportFail', error)
          dispatch('notification/notify', {
            text: `Export failed: ${error.message}`,
            type: 'error'
          }, { root: true })
          return false
        }
      },

      exportClear ({ getters, commit }) {
        commit('exportModule/removeFromMap', getters.MODULE, { root: true })
        commit('exportClear')
      }
    }
  }

  modules () {
    return {}
  }

  getModule () {
    return {
      namespaced: true,
      state: this.state(),
      getters: this.getters(),
      mutations: this.mutations(),
      actions: this.actions(),
      modules: this.modules()
    }
  }
}
