<template>
  <span>
    <label v-if="hasLabel" class="input-label">
      {{inputLabel}}
      <slot name='input-label'></slot>
    </label>
    <v-autocomplete
      v-model="localValue"
      v-bind="$attrs"
      v-on="listeners"
      outlined
      solo
      dense
      flat
      single-line

      :items="list"
      :loading="loading"
      :search-input.sync="searchQuery"
      :hide-details="hideDetails"
    >
      <!-- bypass all slots from a parent -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: null,
    inputLabel: {
      type: String,
      default: ''
    },
    hideDetails: {
      default: true
    },
    currentModule: {
      type: String,
      default: ''
    },
    numberOfResults: {
      type: Number,
      default: 100
    },
    patternKey: {
      type: String,
      default: 'pattern'
    },
    additionalProps: {
      type: Object,
      default: () => { return {} }
    },
    filterResults: null
  },

  data: () => ({
    searchQuery: ''
  }),

  computed: {
    // getters
    list () {
      let tmpList = this.$store.getters[`${this.currentModule}/list`]
      if (this.filterResults) {
        tmpList = this.filterResults(tmpList)
      }

      return tmpList
    },
    loading () {
      return this.$store.getters[`${this.currentModule}/loading`]
    },
    errorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    },
    //

    listeners () {
      // we have to remove the 'input' event as we process it ourselves
      return Object.keys(this.$listeners)
        .filter(field => field !== 'input')
        .reduce((obj, key) => {
          obj[key] = this.$listeners[key]
          return obj
        }, {})
    },

    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    }
  },

  watch: {
    searchQuery (val) {
      const searchParams = {
        take: this.numberOfResults,
        ...this.additionalProps
      }
      searchParams[this.patternKey] = `*${val ?? ''}*`
      this.search(searchParams).then(res => {
        if (!res) {
          this.notify({ text: this.errorMessage, type: 'error' })
        }
      })
    }
  },

  methods: {
    // actions
    search (params) {
      const methodName = `${this.currentModule}/search`
      const actionExists = Object.keys(this.$store._actions).findIndex(key => key === methodName) !== -1

      if (!actionExists) {
        throw new Error(`Module ${this.currentModule} must support "search" action to be used with the APIAutocomplete component`)
      }

      return this.$store.dispatch(methodName, params)
    },
    ...mapActions({
      notify: 'notification/notify'
    })
  }
}
</script>
