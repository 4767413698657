<template>
  <span>
    <label v-if="hasLabel" class="input-label">
      {{inputLabel}}
      <slot name='input-label'></slot>
    </label>
    <v-switch
      v-model="localValue"
      v-bind="$attrs"
      v-on="listeners"
      @blur="$emit('blur')"
      :hide-details="hideDetails"
      :disabled="disabled"
      outlined
      solo
      flat
      inset
    >
    </v-switch>
  </span>
</template>

<script>
export default {
  props: {
    value: null,
    inputLabel: {
      type: String,
      default: ''
    },
    hideDetails: {
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    listeners () {
      // we have to remove the 'input' event as we process it ourselves
      return Object.keys(this.$listeners)
        .filter(field => field !== 'input')
        .reduce((obj, key) => {
          obj[key] = this.$listeners[key]
          return obj
        }, {})
    },
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    }
  }
}
</script>
