<template>
<v-row justify="start">
  <v-col cols='10'>
    <o-select
      :items="items"
      width='250px'
      :value="sipValue.module"
      :loading="loading"
      :disabled="edit"
      class='select'
    >
      <template v-slot:item="{ item, on }">
        <v-list-item :input-value="false" class='pa-0' width='max'>
          <v-list width='250px' class='py-0'>
            <v-subheader class='px-2'>
              <b>{{item.text}}</b>
            </v-subheader>
            <v-divider class='mx-2'/>
            <v-list-item
              v-on="on"
              v-for="el in list(item.value)"
              @click="choose(item, el)"
              :key="el.uuid"
            >
              <v-list-item-content>
                {{el.name ? el.name : el.domain}}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-item>
      </template>
      <template v-slot:append-outer>
      </template>
      <template v-slot:selection>
        {{prepareSelection}}
      </template>
    </o-select>
  </v-col>
  <v-col cols='1' class='pa-0' align-self='center'>
    <v-tooltip
      bottom
      v-if="!edit"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="edit=true"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
    Set up SIP Connection/Call Control App
    </v-tooltip>
    <v-tooltip
      bottom
      v-else
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="save"
          :loading="saving"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
    Save SIP Connection/Call Control App
    </v-tooltip>
  </v-col>
  <v-spacer/>
</v-row>
</template>

<style scoped>
.select >>> .v-input {
  margin-bottom: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    lease: null
  },
  data: () => ({
    edit: false,
    items: [
      // { text: 'Outbound Voice Profile', value: 'outboundVoiceProfiles' },
      { text: 'Call Control APP', value: 'callControl' },
      { text: 'SIP Connection', value: 'sipConnections' },
      { text: 'SIP Domain', value: 'sip/domain' }],
    sipValue: {
      module: null,
      item: null
    }
  }),
  computed: {
    ...mapGetters({
      scError: 'sipConnections/errorMessage',
      scaError: 'callControl/errorMessage',
      ovError: 'outboundVoiceProfiles/errorMessage',
      sdError: 'sip/domain/errorMessage'
    }),
    saving () {
      return this.$store.getters[`${this.sipValue.module}/saving`]
    },
    loading () {
      return this.$store.getters['sipConnections/loading'] || this.$store.getters['sipConnections/loading']
    },
    prepareSelection () {
      if (this.sipValue.module === 'sipConnections') {
        return `SC: ${this.sipValue.item.name}`
      } else if (this.sipValue.module === 'callControl') {
        return `CCA: ${this.sipValue.item.name}`
      } else if (this.sipValue.module === 'sip/domain') {
        return `SD: ${this.sipValue.item.domain}`
      } else if (this.sipValue.module === 'outboundVoiceProfiles') {
        return `OVP: ${this.sipValue.item.name}`
      }

      return undefined
    }
  },
  methods: {
    ...mapActions({
      requestSC: 'sipConnections/requestList',
      requestSCA: 'callControl/requestList',
      requestSD: 'sip/domain/requestList',
      requestOV: 'outboundVoiceProfiles/requestList',
      notify: 'notification/notify'
    }),
    choose (item, el) {
      this.sipValue.module = item.value
      this.sipValue.item = el
    },
    list (moduleName) {
      return this.$store.getters[`${moduleName}/list`]
    },
    async save () {
      let patchItem = {}
      if (this.sipValue.module === 'outboundVoiceProfiles') {
        patchItem = {
          identificator: this.sipValue.item.identificator,
          tn_lease: this.sipValue.item.tn_lease
        }
      } else {
        patchItem = {
          uuid: this.sipValue.item.uuid,
          leases: {
            add: [this.lease.uuid]
          }
        }
      }

      const res = await this.$store.dispatch(
        this.sipValue.module === 'outboundVoiceProfiles'
          ? `${this.sipValue.module}/patch`
          : `${this.sipValue.module}/patchLeases`,
        patchItem
      )
      if (!res) {
        this.notify({ text: this.$store.getters[`${this.sipValue.module}/errorMessage`], type: 'error' })
      } else {
        this.notify({ text: 'Successfully added', type: 'success' })
      }
      this.edit = false
    },
    updateSipValue (val) {
      if (val.sip_connection) {
        this.sipValue = {
          module: 'sipConnections',
          item: val.sip_connection
        }
      }
      if (val.sip_control_app) {
        this.sipValue = {
          module: 'callControl',
          item: val.sip_control_app
        }
      }
    }
  },
  watch: {
    lease: {
      deep: true,
      handler (val) {
        this.updateSipValue(val)
      }
    }
  },
  created () {
    this.requestSC().then(res => {
      if (!res) {
        this.notify({ text: this.scError, type: 'error' })
      }
    })
    this.requestSCA().then(res => {
      if (!res) {
        this.notify({ text: this.scaError, type: 'error' })
      }
    })
    this.requestOV().then(res => {
      if (!res) {
        this.notify({ text: this.ovError, type: 'error' })
      }
    })

    this.requestSD().then(res => {
      if (!res) {
        this.notify({ text: this.sdError, type: 'error' })
      }
    })
    this.updateSipValue(this.lease)
  }
}
</script>
