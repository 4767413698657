<template>
  <span>
    <v-container fluid class='py-0 mt-5'>
      <v-row id='filter-row' justify='start' align='start' class='pa-0'>
        <v-col>
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
    <v-container class='px-0'>
      <v-row v-if='resultsReady' id='search-results' justify='center'>
        <v-col md='12'>
          <slot name='dataResults'></slot>
        </v-col>
      </v-row>
    </v-container>
  </span>
</template>

<style scoped>
#filter-row {
    background-color: white !important;
}
</style>

<script>

export default {
  name: 'SearchForm',
  data: () => ({
    resultsReady: true
  })
}

</script>
