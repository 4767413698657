const state = {
  visible: {
    type: Boolean,
    default: false
  }
}

const getters = {
  isVisible: (state) => {
    return state.visible
  }
}

const mutations = {
  switchVisibility (state) {
    state.visible = !state.visible
  },
  setVisibility (state, visibility) {
    state.visible = visibility
  }
}

export const navigation = {
  namespaced: true,
  state,
  getters,
  mutations
}
