<template>
  <span>
    <v-form
      ref='form'
      @keyup.native.enter="handleSubmit"
    >
      <v-row justify='center'>
        <v-col cols='auto'>
          <h1>OPENTACT</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' class='pb-0'>
          <o-text-field
            v-model="email"
            input-label='Email address'
            :rules="rules"
            autocomplete='email'
          >
          </o-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' class='pb-4'>
          <o-text-field
            v-model="password"
            input-label='Password'
            type='password'
            :rules="rules"
            autocomplete='current-password'
          >
          </o-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' class='pb-0'>
          <v-btn
            text
            :ripple="false"
            block
            class="primary white--text"
            @click='handleSubmit'
          >
            Log In
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' class='pb-0'>
          <v-btn
            text
            :ripple="false"
            block
            class="primary white--text mb-3"
            to='password_reset'
          >
            Reset Password
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols='12' class='pb-0'>
        <OGoogleLoginButton>
        </OGoogleLoginButton>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' class='pb-0'>
        <OFacebookLoginButton>
        </OFacebookLoginButton>
      </v-col>
    </v-row>
     <v-row justify='center'>
      <v-col cols='auto'>
        Don't have an account yet? <router-link to='register'>Register Now</router-link>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import OGoogleLoginButton from '@/components/OGoogleLoginButton'
import OFacebookLoginButton from '@/components/OFacebookLoginButton'
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    email: '',
    password: '',
    rules: [
      function (val) {
        return !!val
      }
    ]
  }),
  methods: {
    ...mapActions({
      login: 'auth/login',
      notify: 'notification/notify'
    }),
    handleSubmit () {
      // Perform a simple validation that email and password have been typed in
      if (this.$refs.form.validate()) {
        this.login({ email: this.email, password: this.password }).then(res => {
          this.password = ''
          if (!res) {
            this.notify({ text: `Cannot login: ${this.authenticationError}` })
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      authenticating: 'auth/authenticating',
      authenticationError: 'auth/authenticationError'
    })
  },
  components: {
    OGoogleLoginButton,
    OFacebookLoginButton
  }
}
</script>
