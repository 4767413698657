<template>
  <span>
    <v-container class='px-0'>
      <v-row>
        <v-col cols='12' md='4'>
          <v-card flat class='pa-5'>
            <v-row>
              <v-col>
                <h4>Account Information</h4>
              </v-col>
            </v-row>
            <v-divider class='mb-4'/>
            <v-row>
              <v-col cols='12'>
                <v-row>
                  <v-col cols='12'>
                    <o-text-field
                      :value="currentItem && currentItem.account ? currentItem.account.level : undefined"
                      input-label='Account Level'
                      hide-details
                      readonly
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-information</v-icon>
                          </template>
                          Create a ticket to change your account level
                        </v-tooltip>
                      </template>
                    </o-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols='12'>
                    <o-text-field
                      v-model="currentItem.first_name"
                      input-label='First Name'
                      hide-details
                      autocomplete='given-name'
                    >
                    </o-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols='12'>
                    <o-text-field
                      v-model="currentItem.last_name"
                      input-label='Last Name'
                      hide-details
                      autocomplete='family-name'
                    >
                    </o-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols='12'>
                    <o-text-field
                      v-model="currentItem.phone_number"
                      input-label='Contact Phone Number'
                      hide-details
                      autocomplete='tel'
                    >
                    </o-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <o-text-field
                  v-model="currentItem.email"
                  hide-details
                  input-label='Email'
                  autocomplete='email'
                >
                </o-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  text
                  class="primary white--text"
                  :ripple="false"
                  :height="40"
                  :loading="loadingData"
                  @click="updateUserData"
                >
                  Update Data
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols='12' md='4'>
          <v-card flat class='pa-5'>
            <v-row>
              <v-col>
                <h4>Change Password</h4>
              </v-col>
            </v-row>
            <v-divider class='mb-4'/>
            <UpdatePassword
              v-model="password"
              ref='password'
            >
            </UpdatePassword>
            <v-row>
              <v-col>
                <v-btn
                  block
                  text
                  class="primary white--text"
                  :ripple="false"
                  :height="40"
                  :loading="loadingPassword"
                  @click="updateUserPassword"
                >
                  Update Password
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols='12' md='4'>
          <v-card flat class='pa-5'>
            <v-row>
              <v-col>
                <h4>Upload Avatar Image</h4>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col cols='12' class='pt-6'>
                <o-file-input
                  input-label="Choose a file"
                  v-model='avatarFile'
                >
                </o-file-input>
              </v-col>
              <v-col cols='6' class='pt-0'>
                <v-btn
                  block
                  text
                  class="success white--text"
                  :ripple="false"
                  :height="40"
                  :disabled="!avatarFile"
                  @click="uploadUserAvatar"
                >
                  Upload
                </v-btn>
              </v-col>
              <v-col cols='6' class='pt-0'>
                <v-btn
                  block
                  text
                  class="error white--text"
                  :ripple="false"
                  :height="40"
                  @click="deleteUserAvatar"
                >
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      top
      color='success'
      v-model="successSnackbar"
      :timeout="5000"
    >
      {{snackbarText}}
      <v-btn
        text
        :ripple=false
        @click="successSnackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar
      top
      color='error'
      v-model="errorSnackbar"
      :timeout="5000"
    >
      {{snackbarText}}
      <v-btn
        text
        :ripple=false
        @click="errorSnackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </span>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import UpdatePassword from '../Login/UpdatePassword'

export default {
  data: () => ({
    loadingData: false,
    loadingPassword: false,
    password: '',
    avatarFile: null,
    successSnackbar: false,
    errorSnackbar: false,
    snackbarText: '',
    currentItem: {}
  }),
  methods: {
    ...mapActions('user', [
      'uploadAvatar',
      'deleteAvatar',
      'patch'
    ]),
    ...mapActions({
      refreshLogin: 'auth/refreshLogin'
    }),
    ...mapMutations({
      changeAuthVerified: 'auth/changeAuthVerified'
    }),
    updateUserData: function () {
      this.loadingData = true
      this.patch({
        first_name: this.currentItem.first_name ?? undefined,
        last_name: this.currentItem.last_name ?? undefined,
        phone_number: this.currentItem.phone_number ?? undefined,
        email: this.currentItem.email ?? undefined
      }).then(() => {
        this.loadingData = false
      })
    },
    updateUserPassword: function () {
      if (this.$refs.password.validate()) {
        this.loadingPassword = true
        this.patch({
          password: this.password
        }).then(() => {
          this.password = ''
          this.loadingPassword = false
        })
      }
    },
    uploadUserAvatar: function () {
      var that = this
      this.uploadAvatar({ file: this.avatarFile }).then(retval => {
        that.$nextTick(function () {
          if (retval) {
            that.changeAuthVerified(false)
            that.refreshLogin()
            that.changeAuthVerified(false)
            that.showSuccess('Avatar uploaded successfully')
          } else {
            that.showError('Error: avatar was not uploaded')
          }
        })
      })
    },
    deleteUserAvatar: function () {
      var that = this
      this.deleteAvatar().then(retval => {
        that.$nextTick(function () {
          if (retval) {
            that.showSuccess('Avatar deleted successfully')
          } else {
            that.showError('Error: avatar was not deleted')
          }
        })
      })
    },
    showSuccess: function (message) {
      this.snackbarText = message
      this.successSnackbar = true
    },
    showError: function (message) {
      this.snackbarText = message
      this.errorSnackbar = true
    }
  },
  computed: {
    ...mapGetters('user', [
      'first_name',
      'last_name',
      'phone_number',
      'email',
      'saving',
      'userData'
    ])
  },
  watch: {
    userData: {
      deep: true,
      handler (val) {
        this.currentItem = JSON.parse(JSON.stringify(val))
      }
    }
  },
  mounted () {
    this.currentItem = JSON.parse(JSON.stringify(this.userData))
  },
  components: {
    UpdatePassword
  }
}
</script>
