import SIPDomainAPI from '../../../services/sipDomain.service'
import ItemBase from '../../itemBase'
import { hotkeys } from './hotkeys.module'

class SIPDomain extends ItemBase {
  state () {
    const state = super.state()

    state.unique_key = 'domain'

    return state
  }

  modules () {
    return {
      ...super.modules(),
      hotkeys
    }
  }
}

export const domain = new SIPDomain(SIPDomainAPI, '/app/sip-domain').getModule()
