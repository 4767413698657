import ItemBase from './itemBase'
// import router from '../router'
import Vue from 'vue'

export default class SipBase extends ItemBase {
  constructor (API, APP_LINK, LEASES_API) {
    super(API, APP_LINK)
    this.LEASES_API = LEASES_API
  }

  state () {
    const state = super.state()

    state.loadingLeases = false
    state.leasesAPI = this.LEASES_API
    state.leasesProgress = 0
    state.leasesPatching = ''

    return state
  }

  getters () {
    const getters = super.getters()

    getters.loadingLeases = function (state) {
      return state.loadingLeases
    }

    getters.LEASES_API = function (state) {
      return state.leasesAPI
    }

    getters.leasesProgress = function (state) {
      return state.leasesProgress
    }

    getters.leasesPatching = function (state) {
      return state.leasesPatching
    }

    return getters
  }

  actions () {
    const actions = super.actions()

    actions.patchLeases = async function ({ commit, getters }, { uuid, leases }) {
      commit('save')

      try {
        await getters.LEASES_API.patchLeases(uuid, leases)

        commit('saved')
        // if (getters.APP_LINK) {
        //   router.push(getters.APP_LINK)
        // }

        return true
      } catch (error) {
        commit('failSaving', error)
        return false
      }
    }

    actions.assignAll = async function ({ commit, getters }, { uuid }) {
      try {
        commit('leasesPatch', 'add_all')

        await getters.LEASES_API.assignAllLeases(uuid)

        commit('leasesPatchEnd')
        return true
      } catch (error) {
        commit('leasesPatchEnd')
        return false
      }
    }

    actions.assignAllOld = async function ({ commit, getters, dispatch }, { uuid, addOrRemove }) {
      try {
        commit('leasesPatch', addOrRemove + '_all')
        let processed = 0
        let count = 0
        do {
          // search by 100
          const params = {
            order: {
              created_on: 'ASC'
            },
            skip: processed,
            take: 100
          }
          const res = await dispatch('tnlease/searchAll', params, { root: true })
          count = res.total

          // patch
          if (res.data.length) {
            const data = {
              uuid: uuid,
              leases: {}
            }
            data.leases[addOrRemove] = res.data.map(el => el.tn.tn)
            const patchRes = await dispatch('patchLeases', data)
            if (!patchRes) {
              break
            }
          } else {
            break
          }
          processed += res.data.length
          commit('leasesProgress', Math.floor(100 * processed / count))
        } while (processed < count)

        commit('leasesPatchEnd')
        return true
      } catch (error) {
        commit('leasesPatchEnd')
        return false
      }
    }

    actions.assignList = async function ({ commit, getters, dispatch }, { uuid, addOrRemove, list }) {
      try {
        commit('leasesPatch', addOrRemove + '_list')
        let processed = 0
        const count = list.length
        do {
          const increment = 500
          const partialList = list.slice(processed, processed + increment)

          // patch
          if (partialList.length) {
            const data = {
              uuid: uuid,
              leases: {}
            }
            data.leases[addOrRemove] = partialList
            const patchRes = await dispatch('patchLeases', data)
            if (!patchRes) {
              break
            }
          } else {
            break
          }
          processed += partialList.length
          commit('leasesProgress', Math.floor(100 * processed / count))
        } while (processed < count)

        commit('leasesPatchEnd')
        return true
      } catch (error) {
        commit('leasesPatchEnd')
        return false
      }
    }

    return actions
  }

  mutations () {
    const mutations = super.mutations()

    mutations.updateLeases = function (state, { uuid, leases }) {
      const connection = state.list.find(el => el.uuid === uuid)

      Vue.set(connection, 'tnleases', leases)
      if (state.current.uuid === uuid) {
        Vue.set(state.current, 'tnleases', leases)
      }

      state.loadingLeases = false
    }

    mutations.requestLeases = function (state, error) {
      state.loadingLeases = true
    }

    mutations.failRequestLeases = function (state, error) {
      state.errorMessage = error.message
      state.loadingLeases = false
    }

    mutations.leasesProgress = function (state, progress) {
      state.leasesProgress = progress
    }

    mutations.leasesPatch = function (state, addOrRemove) {
      state.leasesProgress = 0
      state.leasesPatching = addOrRemove
    }

    mutations.leasesPatchEnd = function (state) {
      state.leasesPatching = ''
    }

    return mutations
  }
}
