<template>
  <span>
    <v-card flat>
      <List
        current-module="outboundVoiceProfiles"
        name='Outbound Voice Profiles'
        :headers="dataHeaders"
        :has-created="true"
      >
        <template v-slot:item.domain="{ item }">
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                text
                class='primary white--text'
                :to="`/app/outbound-profiles/${item.uuid}/domain`"
                v-on="on"
              >
                <v-icon>mdi-domain</v-icon>
              </v-btn>
            </template>
            Assign domains for the item
          </v-tooltip>
        </template>
      </List>
    </v-card>
  </span>
</template>

<script>
import List from '../../components/CRUD/List'

export default {
  name: 'OutboundVoiceProfiles',
  displayName: 'Outbound Voice Profiles',
  data: () => ({
    newDialog: false,
    dataHeaders: [
      { text: 'Name', value: 'name' },
      // { text: 'Auth Type', value: 'class4_ingress_trunk_settings.auth_type', sortable: false },
      // { text: 'Billing Methods', value: 'class4_ingress_trunk_settings.billing_method', sortable: false },
      { text: 'Created On', value: 'created_on' },
      { text: 'Domains', value: 'domain', width: 100, align: 'center', sortable: false },
      { text: 'Edit', value: 'edit', width: 100, align: 'center', sortable: false },
      { text: 'Delete', value: 'delete', width: 100, align: 'center', sortable: false }
    ]
  }),
  components: {
    List
  }
}
</script>
