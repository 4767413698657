import { sms } from '@/store/tn/subscriptions/sms.module'
import { voice } from '@/store/tn/subscriptions/voice.module'

export const subscriptions = {
  namespaced: true,
  modules: {
    sms,
    voice
  }
}
