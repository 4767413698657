<template>
  <span>
    <v-container>
      <v-row>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
          <CardCredits></CardCredits>
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
          <ReportCard
            title='Payment(s)'
            icon='mdi-cash'
            ref-type='payment'
            color='success'
          >
          </ReportCard>
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
          <card-call-control-apps></card-call-control-apps>
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
          <ReportCard
            title='TTS'
            ref-type='tts'
          >
          </ReportCard>
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
          <ReportCard
            title='LERG'
            ref-type='lerg'
          >
          </ReportCard>
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
              <Card
                      :title="'Inbound SMS'"
                      :count="i_sms_count"
                      :icon="'mdi-message-arrow-left-outline'"
                      :color="'blue'"
                      :cost="sms.i_cost"
              >
              </Card>
<!--          <ReportCard-->
<!--            title='Inbound SMS'-->
<!--            icon='mdi-message-arrow-left-outline'-->
<!--            ref-type='sms_incoming'-->
<!--            color='blue'-->
<!--          >-->
<!--          </ReportCard>-->
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
              <Card
                      :title="'Outbound SMS'"
                      :count="o_sms_count"
                      :icon="'mdi-message-arrow-right-outline'"
                      :color="'blue'"
                      :cost="sms.o_cost"
              >
              </Card>
<!--          <ReportCard-->
<!--            title='Outbound SMS'-->
<!--            icon='mdi-message-arrow-right-outline'-->
<!--            ref-type='sms_outgoing'-->
<!--            color='blue'-->
<!--          >-->
<!--          </ReportCard>-->
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
              <Card
                      :title="'Incoming Voice'"
                      :count="i_voice_count"
                      :icon="'mdi-message-arrow-right-outline'"
                      :color="'blue'"
                      :cost="voice.i_cost"
              >
              </Card>
<!--          <ReportCard-->
<!--            title='Incoming Voice'-->
<!--            icon='mdi-message-arrow-right-outline'-->
<!--            ref-type='voice_incoming'-->
<!--            color='blue'-->
<!--          >-->
<!--          </ReportCard>-->
        </v-col>
        <v-col
          cols='12' sm='6' md='4' xl='3'
        >
              <Card
                      :title="'Outbound Voice'"
                      :count="o_voice_count"
                      :icon="'mdi-message-arrow-right-outline'"
                      :color="'blue'"
                      :cost="voice.i_cost"
              >
              </Card>
<!--          <ReportCard-->
<!--            title='Outbound Voice'-->
<!--            icon='mdi-message-arrow-right-outline'-->
<!--            ref-type='voice_outgoing'-->
<!--            color='blue'-->
<!--          >-->
<!--          </ReportCard>-->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='6' xl='3'>
          <ChartOSMS></ChartOSMS>
        </v-col>
        <v-col cols='12' md='6' xl='3'>
          <ChartISMS></ChartISMS>
        </v-col>
        <v-col cols='12' md='6' xl='3'>
          <ReportChart
            header-type='Voice'
            header-text='Inbound Calls'
            ref-type='voice_incoming'
          >
          </ReportChart>
        </v-col>
        <v-col cols='12' md='6' xl='3'>
          <ReportChart
            header-type='Voice'
            header-text='Outbound Calls'
            ref-type='voice_outgoing'
          >
          </ReportChart>
        </v-col>
       </v-row>
    </v-container>
  </span>
</template>

<script>
import Card from './Card'
import ReportCard from './ReportCard'
import ReportChart from './ReportChart'
import CardCredits from './CardCredits'
import CardCallControlApps from './CardCallControlApps'
import ChartOSMS from './ChartOSMS'
import ChartISMS from './ChartISMS'
import axios from 'axios'

export default {
  name: 'Dashboard',
  components: {
    Card,
    ReportCard,
    ReportChart,
    CardCredits,
    CardCallControlApps,
    ChartOSMS,
    ChartISMS
  },
  data: () => ({
    sms: {},
    voice: {},
    report: []
  }),
  computed: {
    i_sms_count () {
      return this.report.length && this.getByKey('sms_campaign_mrc') ? this.getByKey('sms_campaign_mrc').count : null
    },
    o_sms_count () {
      return this.report.length && this.getByKey('sms_outgoing') ? this.getByKey('sms_outgoing').count : null
    },
    i_voice_count () {
      return this.report.length && this.getByKey('voice_incoming') ? this.getByKey('voice_incoming').count : null
    },
    o_voice_count () {
      return this.report.length && this.getByKey('voice_outgoing') ? this.getByKey('voice_outgoing').count : null
    }
  },
  methods: {
    getByKey (type) {
      return this.report.find(item => item.type === type)
    }
  },
  created () {
    axios
      .get('rest/transaction/report/invoice?from=' + (new Date(-1000000000).toISOString()))
      .then(res => {
        this.report = res.data.payload.report
        this.sms.i_count = this.getByKey('sms_campaign_mrc') ? this.report.find(item => item.type === 'sms_campaign_mrc').count : 0
        this.sms.i_cost = this.getByKey('sms_campaign_mrc') ? this.report.find(item => item.type === 'sms_campaign_mrc').amount : 0
        this.sms.o_count = this.getByKey('sms_outgoing') ? this.report.find(item => item.type === 'sms_outgoing').count : 0
        this.sms.o_cost = this.getByKey('sms_outgoing') ? this.report.find(item => item.type === 'sms_outgoing').amount : 0
        this.voice.i_count = this.getByKey('voice_incoming') ? this.report.find(item => item.type === 'voice_incoming').count : 0
        this.voice.i_cost = this.getByKey('voice_incoming') ? this.report.find(item => item.type === 'voice_incoming').amount : 0
        this.voice.o_count = this.getByKey('voice_outgoing') ? this.report.find(item => item.type === 'voice_outgoing').count : 0
        this.voice.o_cost = this.getByKey('voice_outgoing') ? this.report.find(item => item.type === 'voice_outgoing').amount : 0
      })
      .catch((err) => {
        throw new Error('Cannot load chart data for ' + this.refType + ':\n' + err.message)
      })
  }
}
</script>
