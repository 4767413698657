<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <crud-list
      ref='list'
      name='SMS Messages'
      current-module='messaging/sms'
      :has-add="false"
      :use-search="true"
      :headers="headers"
      :has-created="true"
      :has-filtration="true"
      :has-export="true"
      class='table'
    >
      <template v-slot:filtration="{ item }">
        <v-row>
          <v-col cols='12' md='6'>
            <o-select
              ref='smsType'
              input-label="Incoming/Outgoing"
              v-model="item.type"
              :items="smsTypes"
              mandatory
              required
            >
            </o-select>
          </v-col>
          <v-col cols='12' md='6'>
            <o-tel-text-input
              input-label="Number"
              v-model="item.tn"
              clearable
            >
            </o-tel-text-input>
          </v-col>
          <v-col cols='12' md='6'>
            <o-date-picker
              input-label="Created On"
              v-model="item.created_on_from"
            >
            </o-date-picker>
          </v-col>
          <v-col cols='12' md='6'>
            <o-date-picker
              input-label="Created Till"
              v-model="item.created_on_to"
            >
            </o-date-picker>
          </v-col>
       </v-row>
      </template>
      <template v-slot:item.type="{ item }">
        {{
          item.type === 'smso' ? 'Outgoing'
            : item.type === 'smsi'? 'Incoming'
              : item.type
        }}
      </template>
      <template v-slot:item.from="{ item }">
          <span style="white-space: nowrap">{{beautifyNumber(item.from)}}</span>
      </template>
      <template v-slot:item.to="{ item }">
          <span style="white-space: nowrap">{{beautifyNumber(item.to)}}</span>
      </template>
    </crud-list>
  </v-card>
</span>
</template>

<style scoped>
.table >>> td.text-start.messageCell {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 250px !important;
}
</style>

<script>
import BeautifyNumber from '../../services/beautify/number.service'

export default {
  data: () => ({
    headers: [
      { text: 'State', value: 'state', class: 'noWrap' },
      { text: 'Type', value: 'type', class: 'noWrap' },
      { text: 'From', value: 'from', class: 'noWrap' },
      { text: 'To', value: 'to', class: 'noWrap' },
      { text: 'Message', value: 'message', width: 250, cellClass: 'messageCell', class: 'message noWrap' },
      { text: 'Date Created', value: 'created_on', class: 'noWrap' }
    ],
    smsTypes: [
      { text: 'Select', value: null },
      { text: 'Incoming', value: 'smsi' },
      { text: 'Outgoing', value: 'smso' }
    ]
  }),
  methods: {
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  mounted () {
    this.$refs.list.filterParams.type = null
  }
}
</script>
