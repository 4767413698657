<template>
  <v-dialog
    v-model="localValue"
    v-if="currentItem"
    width='600'
  >
    <v-card class='pa-5'>
      <v-container>
        <v-form
          ref='form'
        >
          <v-row class='px-4'>
            <v-col cols='auto'>
              <h2>Create new hotkey</h2>
            </v-col>
            <v-spacer/>
            <v-col cols='auto'>
              <v-btn
                text
                class='pa-0'
                dense
                :min-width="0"
                width=25
                :ripple="false"
                @click="localValue=false"
              >
                <v-icon>
                  mdi-close-thick
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-spacer/>

          <v-row>
            <v-col cols='12' md='12'>
              <o-select
                input-label='Hotkey'
                :items="freeHotkeys"
                :loading="loading"
                v-model="currentItem.hotkey"
                :return-object="false"
              >
              </o-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12' md='12'>
              <CallControlApps
                v-model="currentItem.sip_control_app"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                text
                block
                class='primary white--text mr-n3 mt-6 no-left-border-radius'
                :ripple='false'
                height='40px'
                @click='save'
                :loading="saving"
              >
                Create
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import List from '@/components/CRUD/List.vue'
import CallControlApps from '@/views/CallControl/CallControlApps.vue'

export default {
  components: {
    CallControlApps,
    List
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    domain: {
      type: String,
      default: null
    }
  },
  data: () => ({
    freeHotkeys: []
  }),
  computed: {
    ...mapGetters({
      errorMessage: 'sip/domain/hotkeys/errorMessage',
      saving: 'sip/domain/hotkeys/saving',
      loading: 'sip/domain/hotkeys/loading',
      list: 'sip/domain/hotkeys/list',
      currentItem: 'sip/domain/hotkeys/current'
    }),
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    ...mapActions({
      requestList: 'sip/domain/hotkeys/requestList',
      create: 'sip/domain/hotkeys/create',
      notify: 'notification/notify'
    }),
    ...mapMutations({
      clearCurrent: 'sip/domain/hotkeys/clearCurrent',
      setCurrent: 'sip/domain/hotkeys/setCurrent',
      setDefault: 'sip/domain/hotkeys/setDefault'
    }),

    // loginValidator (val = '') {
    //   if (val.length < 3 || val.length > 60) {
    //     return 'Length must be more than 3 and less than 60'
    //   }
    //
    //   if (!val.match(/^[a-zA-Z][.a-zA-Z0-9_-]{2,59}$/)) {
    //     return 'Value is wrong'
    //   }
    //
    //   return true
    // },

    // },
    save () {
      if (this.$refs.form.validate()) {
        this.create().then(res => {
          if (!res) {
            alert(this.errorMessage)
          } else {
            this.notify({
              text: 'SIPDomain hotkey created successfully',
              type: 'success'
            })
            this.localValue = false
          }
        })
      }
    }
  },
  mounted () {
    this.requestList()
      .then(() => {
        this.freeHotkeys = Array.from(Array(100).keys())
          .filter(_ => !this.list.find(__ => __.hotkey === _))
      })
    this.setDefault()
  }
}
</script>
