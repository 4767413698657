<template>
  <span>
    <v-container>
      <v-row>
        <v-col class='pa-0'>
          <v-card flat class='ma-5 pa-5'>
            <v-container>
              <v-row>
                <v-col>
                  <h2>Number Orders</h2>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="numbersHeaders"
                    :items="numbersInCart"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:item.payload.tn="{ item }">
                      {{beautifyNumber(item.payload.tn)}}
                    </template>
                     <!-- <template v-slot:item.call_control="{ item }">
                      <o-select
                        label=' '
                        :value="item.call_control ? item.call_control.name : ''"
                        :items="sipControlApps"
                        item-text='name'
                        return-object
                      >
                      </o-select>
                    </template>
                    <template v-slot:item.sip_connection="{ item }">
                      <o-select
                        label=' '
                        :value="item.sip_connection ? item.sip_connection.name : ''"
                        :items="sipConnections"
                        item-text='name'
                        return-object
                      >
                      </o-select>
                    </template> -->

                    <template v-slot:item.features="{ item }">
                      <NumberFeatures
                        @input="featuresChanged(item)"
                        v-model="item.payload.features"
                        :editable="true"
                      >
                      </NumberFeatures>
                    </template>
                    <template v-slot:item.delete="{ item }">
                      <v-btn
                        :ripple="false"
                        :loading="item.loading"
                        text
                        class='error white--text'
                        @click="deleteNumber(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:no-data>
                      <v-row justify="center">
                        <v-col cols='auto' class='pb-0'>
                          <h4>You have no numbers in your cart.</h4>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols='auto' class='py-0'>
                          <span>Search and provision local, national, and toll-free phone numbers from around the world.</span>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols='auto'>
                          <v-btn
                            text
                            class='primary white--text'
                            :ripple="false"
                            to='/app/numbers/search-numbers'
                          >
                            Search for Numbers
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-if="numbersInCart.length" v-slot:body.append>
                      <tr>
                        <td>
                          <b>Summary</b>
                          <span v-if="$vuetify.breakpoint.xsOnly">
                            {{(totalNRC + totalMRC).toFixed(2)}}
                          </span>
                        </td>
                        <template v-if="$vuetify.breakpoint.smAndUp">
                          <!-- <td></td>
                          <td></td>
                          <td></td> -->
                          <td><b>{{totalNRC.toFixed(2)}}</b></td>
                          <td><b>{{totalMRC.toFixed(2)}}</b></td>
                          <td></td>
                          <td></td>
                        </template>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row justify-md='end'>
                <v-col>
                  <v-btn
                    text
                    block
                    class='error white--text'
                    :ripple="false"
                    :loading="clearing"
                    @click="clearNumbersCart"
                  >
                    Clear Cart
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    :disabled="!numbersInCart.length"
                    text
                    block
                    class='primary white--text'
                    :ripple="false"
                    :loading="placingOrder"
                    @click="placeNumbersOrder"
                  >
                  Place Order
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
   </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { parsePhoneNumber } from 'libphonenumber-js'
import NumberFeatures from '../components/NumberFeatures'

export default {
  data: () => ({
    placingOrder: false,
    callControls: [],
    numbersHeaders: [
      { text: 'Number', value: 'payload.tn' },
      // { text: 'Call Control', value: 'call_control' },
      // { text: 'SIP Connection', value: 'sip_connection' },
      { text: 'Setup Fee', value: 'payload.price.nrc' },
      { text: 'Monthly Fee', value: 'payload.price.items[0].mrc' },
      // { text: 'Inbound Min Fee', value: 'payload.price.items[0].sms.per_usage_in' },
      { text: 'Inbound SMS', value: 'payload.price.items[0].sms.per_usage_in' },
      { text: 'SMS Enable Fee', value: 'payload.price.items[0].sms.per_usage_out' },
      { text: 'Features', value: 'features', align: 'center' },
      { text: 'Delete', value: 'delete' }
    ]
  }),
  computed: {
    ...mapGetters({
      numbersInCart: 'cart/getNumbersInCart',
      loadingNumbersPrices: 'cart/getLoadingNumbersPrices',
      // sipConnections: 'sipConnections/list',
      // messagingProfiles: 'messaging/profiles/profiles',
      // sipControlApps: 'callControl/list',
      clearing: 'cart/clearing'
    }),
    ...mapGetters('user', [
      'balance'
    ]),

    totalNRC () {
      const reducer = (accumulator, el) => accumulator + el.payload.price.items[0].nrc
      return this.numbersInCart.reduce(reducer, 0)
    },
    totalMRC () {
      const reducer = (accumulator, el) => accumulator + el.payload.price.mrc
      return this.numbersInCart.reduce(reducer, 0)
    }
  },
  methods: {
    ...mapActions({
      updateInCart: 'cart/updateInCart',
      deleteNumberFromCart: 'cart/deleteNumberFromCart',
      placeNOrder: 'cart/placeNumbersOrder',
      clearNumbersCart: 'cart/clearNumbersCart',
      retreiveCart: 'cart/retreiveCart',
      notify: 'notification/notify'// ,
      // requestMessagingProfiles: 'messaging/profiles/requestProfiles',
      // requestSCA: 'callControl/requestList',
      // requestSC: 'sipConnections/requestList'
    }),
    async placeNumbersOrder () {
      if (this.balance <= 0) {
        this.notify({ text: "you don't have sufficient balance", type: 'error' })
      } else {
        this.placingOrder = true
        try {
          await this.placeNOrder()
          this.placingOrder = false
        } catch (error) {
          this.placingOrder = false
          alert(error.message)
        }
      }
    },
    deleteNumber (item) {
      this.$set(item, 'loading', true)
      this.deleteNumberFromCart(item).then(() => {
        this.notify({ text: 'Successfully deleted', type: 'success' })
      })
    },
    beautifyNumber (number) {
      try {
        const beautified = parsePhoneNumber('+' + number.toString())
        return beautified.formatInternational()
      } catch (error) {
        return number
      }
    },
    featuresChanged (item) {
      this.updateInCart(item).then(res => {
        if (!res) {
          alert('Error while updating prices!')
        }
      })
    }
  },
  created () {
    // this.requestMessagingProfiles()
    // this.requestSCA()
    // this.requestSC()
    this.retreiveCart(res => {
      if (!res) {
        this.notify({ text: 'Loading Cart failed', type: 'error' })
      }
    })
  },
  components: {
    NumberFeatures
  }
}
</script>
