<template>
<Details
  current-module='callControl'
  :no-header="true"
>
  <template v-slot:default="{ item }">
    <v-form ref='form'>
      <v-row>
        <v-col cols='12' md='6' class='px-6'>
          <o-text-field
            input-label='Web Hook URL'
            v-model="item.webhook_url"
          >
          </o-text-field>
        </v-col>
      </v-row>
    </v-form>
  </template>
</Details>
</template>

<script>
import Details from '../../components/CRUD/Details'

export default {
  components: {
    Details
  }
}
</script>
