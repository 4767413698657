import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import { user } from './user.module'
import { navigation } from './navigation.module'
import { billing } from './billing/index'
import { search } from './search.module'
import { tnlease } from '././tn/tnlease'
import { tn } from '././tn/index'
import { cart } from './cart.module'
import { numbersOrders } from './numbersOrders.module'
import { profiles } from './profiles.module'
import { tokens } from './tokens.module'
import { sipConnections } from './sipConnections.module'
import { smsCampaigns } from './smsCampaigns.module'
import { messaging } from './messaging/index'
import { call } from './call/index'
import { callControl } from './callControl.module'
import { addresses } from './addresses.module'
import { dict } from './dict/index'
import { sipUsers } from './sipUsers.module'
import { outboundVoiceProfiles } from './outboundVoiceProfiles.module'
import { sipInvites } from './sipInvites.module'
import { sipProducts } from './sipProducts.module'
import { secret } from './secret.module'
import { accountUsers } from './accountUsers.module'
import { notification } from './notification.module'
import { callFlow } from './callFlow.module'
import { sip } from './sip'
import { sipSwitch } from './sipSwitch.module'
import { report } from './report.module'
import { sipCall } from './sipCall.module'
import { s3settings } from './s3settings.module'
import { ttsSettings } from './ttsSettings.module'
import { exportModule } from './export.module'
import { ticket } from './ticket.module'
import { transaction } from './transaction.module'
import { webhook } from './webhook.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    navigation,
    tnlease,
    tn,
    sipConnections,
    smsCampaigns,
    billing,
    search,
    cart,
    numbersOrders,
    profiles,
    tokens,
    messaging,
    call,
    callControl,
    addresses,
    dict,
    sipUsers,
    sip,
    outboundVoiceProfiles,
    sipInvites,
    secret,
    sipProducts,
    accountUsers,
    notification,
    callFlow,
    sipSwitch,
    report,
    sipCall,
    s3settings,
    ttsSettings,
    exportModule,
    ticket,
    transaction,
    webhook
  }
})
