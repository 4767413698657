import ReportService from '../services/report.service'
import Vue from 'vue'

const state = {
  card: {},
  chart: {}
}

const getters = {
  cardByRef (state) {
    return (refType) => {
      return state.card[refType] ? state.card[refType] : undefined
    }
  },

  chartByRef (state) {
    return (refType) => {
      return state.chart[refType] ? state.chart[refType] : undefined
    }
  }
}

function prepareCard (report) {
  const count = report ? report.report.reduce((sum, el) => sum + el.count, 0) : 0
  const cost = report ? report.report.reduce((sum, el) => sum + el.sum, 0) : 0

  return {
    count,
    cost,
    loading: false
  }
}

function prepareChart (report) {
  return {
    report,
    loading: false
  }
}

const mutations = {
  loadingCard (state, refType) {
    if (!state.card[refType]) {
      Vue.set(state.card, refType, {})
    }
    Vue.set(state.card[refType], 'loading', true)
  },

  loadingChart (state, refType) {
    if (!state.chart[refType]) {
      Vue.set(state.chart, refType, {})
    }
    Vue.set(state.chart[refType], 'loading', true)
  },

  loadedCard (state, { refType, report }) {
    if (!state.card[refType]) {
      Vue.set(state.card, refType, {})
    }
    const card = prepareCard(report)
    Vue.set(state.card, refType, card)
  },

  loadedChart (state, { refType, report }) {
    if (!state.chart[refType]) {
      Vue.set(state.chart, refType, {})
    }
    const card = prepareChart(report)
    Vue.set(state.chart, refType, card)
  }
}

const actions = {
  async requestCard ({ commit }, { refType }) {
    commit('loadingCard', refType)
    try {
      // very old date
      const from = (new Date(-1000000000).toISOString())
      const report = await ReportService.requestReport(refType, from)
      commit('loadedCard', { refType, report })
    } catch (e) {
      throw new Error('Cannot load data for ' + refType + ':\n' + e.message)
    }
  },
  async requestChart ({ commit }, { refType }) {
    commit('loadingChart', refType)
    try {
      // one week from now
      const from = new Date()
      from.setDate(from.getDate() - 7)
      from.setHours(0, 0, 0, 0)

      const report = await ReportService.requestReport(refType, from.toISOString())
      commit('loadedChart', { refType, report })
    } catch (e) {
      throw new Error('Cannot load chart data for ' + refType + ':\n' + e.message)
    }
  }
}

export const report = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
