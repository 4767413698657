<template>
<span>
  <input
    id="link"
    class="copyInput">
  <v-card flat outlined class='my-5 mx-2 px-5'>
    <v-container>
      <v-row>
        <v-col>
          <h2>Invite History</h2>
        </v-col>
        <v-spacer/>

      </v-row>
    </v-container>
    <v-divider/>
    <v-data-table
      :items="list"
      :headers="dataHeaders"
    >
      <template v-slot:item.expired_on="{ item }">
        {{beautifyDate(item.expired_on)}}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn
          :ripple="false"
          text
          class='warning white--text'
          append
          @click="editItem(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.copy="{ item }">
        <v-btn
          :ripple="false"
          text
          class='warning white--text'
          append
          @click="copyLinkItem(item)"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn
          :ripple="false"
          text
          class='error white--text'
          :loading="item.deleting"
          @click="deleteI(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
  <InviteDetailsDialog
    v-model="detailsDialog"
    :isNew="isNew"
    :connectionUUID="connectionUUID"
    :uuid="uuid"
  >
  </InviteDetailsDialog>
</span>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import InviteDetailsDialog from './InviteDetailsDialog'
import axios from 'axios'

export default {
  data: () => ({
    detailsDialog: false,
    isNew: false,
    dataHeaders: [
      { text: 'Email', value: 'email' },
      { text: 'Expiration Date', value: 'expiration_date' },
      { text: 'Username', value: 'name' },
      { text: 'Invited On', value: 'invited_on' },
      { text: 'Responded On', value: 'responded_on' },
      { text: 'Status', value: 'status' }
    ],
    uuid: null
  }),
  methods: {
    ...mapActions({
      requestList: 'sipInvites/requestList',
      deleteItem: 'sipInvites/deleteItem',
      notify: 'notification/notify'
    }),
    ...mapMutations({
      setAPILink: 'sipInvites/setAPILink'
    }),
    copyLinkItem (item) {
      axios
        .get(`rest/app/invite/${item.token}`)
        .then(res => {
          const copyLink = document.getElementById('link')
          copyLink.value = res.data.payload.domain
          copyLink.select()
          document.execCommand('Copy')
          this.notify({ text: 'The link has been copied!' })
        })
        .catch((err) => {
          this.notify({ text: err, type: 'error' })
        })
    },
    onStart () {
      this.setAPILink(`/rest/sip/domain/${this.connectionUUID}/invite`)
      this.requestList().then(res => {
        if (!res) {
          // alert(this.errorMessage)
        }
      })
    },
    create () {
      this.isNew = true
      this.detailsDialog = true
    },
    editItem (item) {
      this.isNew = false
      this.uuid = item.uuid
      this.detailsDialog = true
    },
    deleteI (item) {
      this.$set(item, 'deleting', true)
      this.deleteItem(item.uuid).then(res => {
        if (!res) {
          this.$set(item, 'deleting', false)
          // alert(this.errorMessage)
        }
      })
    },
    beautifyDate: unformatted => (new Date(unformatted)).toLocaleDateString()
  },
  computed: {
    ...mapGetters({
      list: 'sipInvites/list',
      loading: 'sipInvites/loading',
      errorMessage: 'sipInvites/errorMessage'
    }),
    connectionUUID () {
      return this.$route.params.connection_uuid
    }
  },
  watch: {
    connectionUUID () {
      this.onStart()
    }
  },
  created () {
    this.onStart()
  },
  components: {
    InviteDetailsDialog
  }
}
</script>
<style>
.copyInput {
  position: absolute;
  left: -99999px;
}
</style>
