<template>
  <Card
    title='Available Credits'
    :count="'$'+count"
    icon='mdi-cash'
    :color="count < 0 ? 'error' : (count === 0 ? 'warning' : 'success' )"
  >
  </Card>
</template>

<script>
import Card from './Card'

export default {
  components: {
    Card
  },
  computed: {
    // getters
    count () {
      return this.$store.getters['user/balance']
    }
  }
}
</script>
