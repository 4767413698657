<template>
<v-row>
  <v-col cols='12'>
    <v-row>
      <v-col cols='12' class='pb-0'>
        <v-form ref='password1'>
          <o-text-field
            v-model="password"
            input-label='Password'
            type='password'
            :hide-details="false"
            :rules="[notEmpty, equalTo2]"
            validate-on-blur
            @blur="checkPassword2"
            autocomplete='new-password'
          >
          </o-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' class='pb-0 item-pass' >
        <v-form ref='password2'>
          <o-text-field
            v-model="confirmPassword"
            input-label='Confirm Password'
            type='password'
            :hide-details="false"
            :rules="[notEmpty, equalTo1]"
            validate-on-blur
            @blur="checkPassword1"
            autocomplete='new-password'
          >
          </o-text-field>
        </v-form>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<style>
.item-pass{
  margin-top: -16px;
}
</style>

<script>
export default {
  props: {
    value: null
  },
  data: () => ({
    password: '',
    confirmPassword: '',
    notEmpty: val => !!val || 'Required'
  }),
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    equalTo1 () {
      return val => {
        return (!this.password || val === this.password) || 'Passwords do not match'
      }
    },
    equalTo2 () {
      return val => {
        return (!this.confirmPassword || val === this.confirmPassword) || 'Passwords do not match'
      }
    }
  },
  methods: {
    async checkPassword1 () {
      if (this.$refs.password2.validate()) {
        await this.$nextTick()
        if (this.$refs.password1.validate()) {
          this.localValue = this.password
        }
      }
    },
    async checkPassword2 () {
      if (this.$refs.password1.validate()) {
        await this.$nextTick()
        if (this.$refs.password2.validate()) {
          this.localValue = this.password
        }
      }
    },
    validate () {
      const password1Valid = this.$refs.password1.validate()
      const password2Valid = this.$refs.password2.validate()
      return password1Valid && password2Valid
    },
    resetValidation () {
      this.$refs.password1.resetValidation()
      this.$refs.password2.resetValidation()
    }
  },
  watch: {
    localValue (val) {
      this.password = val
      this.confirmPassword = val
    }
  },
  inject: {
    form: {
      default: null
    }
  },
  created () {
    if (this.form) {
      this.form.register(this)
    }
  }
}
</script>
