import ItemBase from './itemBase.service'

export class SIPSwitchService extends ItemBase {
  buildRequest (item) {
    return {
      name: item.name ?? undefined,
      description: item.description ?? undefined,
      remote_ip: item.remote_ip ?? undefined,
      remote_domain: item.remote_domain ?? undefined
    }
  }
}

export default new SIPSwitchService('/rest/sip/domain/switch')
