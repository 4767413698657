<template>
<span>
  <v-row>
    <v-col class='my-0 py-0'>
      <div id="stripe-card">
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col class='my-0 py-0'>
      <v-btn
        block
        :rounded="false"
        class="primary"
        @click="payStripe"
        :loading="processing"
        :disabled="!stripeState || !stripeState.complete"
        height='45'
      >
        Pay with Stripe
      </v-btn>
    </v-col>
  </v-row>
</span>
</template>

<style scoped>
#stripe-card {
  border: 2px solid rgb(232, 232, 232);
  border-radius: 4px;
  padding-left: 5px;
  margin-bottom: 10px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    amount: {
      default: 0
    }
  },
  data: () => ({
    localSnackbar: false,
    addedAmount: 0,
    stripe: null,
    stripeCard: null,
    stripeState: null
  }),
  mounted () {
    if (this.publicKey) {
      this.initStripe()
    }
  },
  methods: {
    initStripe () {
      if (!window.Stripe) {
        const stripeScript = document.createElement('script')
        stripeScript.setAttribute('src', 'https://js.stripe.com/v3')
        document.head.appendChild(stripeScript)
        stripeScript.onload = this.setupStripe
      } else {
        this.setupStripe()
      }
    },
    setupStripe () {
      this.stripe = window.Stripe(this.publicKey)

      const elements = this.stripe.elements()

      const style = {
        base: {
          lineHeight: '45px',
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#32325d'
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      this.stripeCard = elements.create('card', { style, hidePostalCode: true })
      this.stripeCard.mount('#stripe-card')
      const that = this
      this.stripeCard.on('change', event => { that.stripeState = event })
    },
    async payStripe () {
      try {
        const res = await this.processStripe({
          amount: this.amount,
          stripe: this.stripe,
          card: this.stripeCard
        })

        if (res) {
          this.$emit('success', this.amount)
        } else {
          this.$emit('failure')
        }
      } catch {
        this.$emit('failure')
      }
    },
    ...mapActions({
      processStripe: 'billing/processStripe'
    })
  },
  computed: {
    ...mapGetters({
      processing: 'billing/processingStripe',
      secret: 'secret/stripe'
    }),
    publicKey () {
      return this.secret ? this.secret.public_key : null
    }
  },
  watch: {
    publicKey (val) {
      if (val) {
        this.initStripe()
      }
    }
  }
}
</script>
