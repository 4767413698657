import ItemBase from '../itemBase.service'

class MessagingBrandAPI extends ItemBase {
  buildPatchRequest (item) {
    return {
      altBusinessId: item.altBusinessId,
      altBusinessIdType: item.altBusinessIdType,
      displayName: item.displayName,
      brand_type: item.brand_type,
      vertical: item.vertical,
      phone: item.phone,
      email: item.email,
      website: item.website,
      stockExchange: item.stockExchange,
      stockSymbol: item.stockSymbol,
      country: item.country,
      state: item.state,
      city: item.city,
      postalCode: item.postalCode,
      street: item.street
    }
  }
}

export default new MessagingBrandAPI('/rest/sms/brand')
