<template>
  <span>
    <label
      v-if="inputLabel!==''"
      class="input-label"
    >
      {{inputLabel}}
    </label>
    <v-autocomplete
      v-model='localValue'
      :items='timezones'
      :disabled="disabled"
      label='Timezone...'
      dense
      outlined
      single-line
      hide-details
    >
    </v-autocomplete>
  </span>
</template>

<script>
import Timezones from '@/assets/timezones.json'

export default {
  props: {
    value: null,
    inputLabel: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  data: () => ({
    timezones: Timezones
  })
}
</script>
