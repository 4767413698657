import ApiService from './api.service'
import { TokenService } from './storage.service'
import loadScript from './load.service'
import store from '../store'
import WSS from './wss.service'

class AuthenticationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

class RegistrationError extends Error {
  constructor (errorCode, message, fields) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
    this.fields = fields ? {
      email: fields.email,
      username: fields.account ? fields.account.name : undefined,
      password: fields.password,
      phoneNumber: fields.phone_number,
      firstName: fields.first_name,
      lastName: fields.last_name,
      account: fields.account
    } : undefined
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns userData
   * @throws AuthenticationError
   **/
  login: async function (email, password) {
    const authData = btoa(`${email}:${password}`)
    const requestData = {
      method: 'get',
      url: '/rest/auth',
      headers: {
        Authorization: `Basic ${authData}`
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)

      const ws = new WSS()
      ws.connect()

      TokenService.saveToken(response.data.payload.token)
      ApiService.setHeader()

      ApiService.mount401Interceptor()

      return response.data.payload
    } catch (error) {
      throw new AuthenticationError(error.response.data.status, error.response.data.message)
    }
  },

  resetPassword: async function (data) {
    try {
      const callback = `${window.location.origin}/auth/confirm_reset?token={token}`

      const response = await
      ApiService.get(`/rest/user/password_reset?email=${data.email}&callback=${callback}`)

      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  },

  newPassword: async function (data) {
    try {
      const response = await ApiService.post('/rest/user/password_reset', data)

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  },

  googleAuth: async function (userInfo) {
    const requestData = {
      method: 'get',
      url: '/rest/auth',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Google-Token': userInfo.getAuthResponse().id_token
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)

      const ws = new WSS()
      ws.connect()

      TokenService.saveToken(response.data.payload.token)
      ApiService.setHeader()

      ApiService.mount401Interceptor()

      return response.data.payload
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  facebookAuth: async function (state) {
    const requestData = {
      method: 'get',
      url: '/rest/auth',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Facebook-Token': state.authResponse.accessToken
      }
    }

    try {
      const response = await ApiService.customRequest(requestData)

      const ws = new WSS()
      ws.connect()

      TokenService.saveToken(response.data.payload.token)
      ApiService.setHeader()

      ApiService.mount401Interceptor()

      return response.data.payload
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  refreshLogin: async function () {
    try {
      const response = await ApiService.get('/rest/auth')

      const ws = new WSS()
      ws.connect()

      TokenService.saveToken(response.data.payload.token)
      ApiService.setHeader()

      ApiService.mount401Interceptor()

      return response.data.payload
    } catch (error) {
      throw new AuthenticationError(error.response.data.status, error.response.data.message)
    }
  },

  /**
   * Register a new user, save auth token and user data
   *
   * @returns userData
   * @throws RegistrationError
   **/
  register: async function (userInfo) {
    const requestData = {
      method: 'post',
      url: '/rest/user',
      data: {
        email: userInfo.email,
        password: userInfo.password,
        phone_number: userInfo.phone_number,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        account: userInfo.account,
        failed_redirect_url: userInfo.failed_redirect_url || `${window.location.origin}/auth/fail`,
        success_redirect_url: userInfo.success_redirect_url || `${window.location.origin}/auth/login`
      }
    }

    try {
      return await ApiService.customRequest(requestData)
    } catch (error) {
      throw new RegistrationError(error.response.status, error.response.data.message, error.response.data.fields)
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  async logout () {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken()
    ApiService.removeHeader()
    window.localStorage.clear()
    ApiService.unmount401Interceptor()
    await this.googleLogout()
  },

  async googleLogout () {
    // Google API must be loaded and initialized
    // by the logout component before calling the logout
    if (!window.gapi) {
      await loadScript('https://apis.google.com/js/platform.js')
      await new Promise((resolve) => {
        window.gapi.load('auth2', resolve)
      })
      const clientId = store.getters['secret/google'].client_id
      await window.gapi.auth2.init({ client_id: clientId })
    }

    window.gapi.auth2.getAuthInstance().disconnect()
    await window.gapi.auth2.getAuthInstance().signOut()
  },

  /**
   * Request user data
   **/
  requestUserData: async function () {
    try {
      const response = await ApiService.get('/rest/user')
      return response.data.payload
    } catch (error) {
      throw new Error('Can\'t get user data: ' + error.message)
    }
  },

  patchUser: async function (data, uuid) {
    try {
      const response = await ApiService.patch(`/rest/user/${uuid}`, data)
      return response.data.payload
    } catch (error) {
      throw new Error('Can\'t patch user: ' + error.message)
    }
  },

  /**
   * Request user data
   **/
  uploadAvatar: async function (uuid, file) {
    try {
      // Build request
      const data = new FormData()
      data.append('file', file)

      const request = {
        method: 'post',
        url: '/rest/avatar',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const response = await ApiService.customRequest(request)
      return response.data.payload
    } catch (error) {
      throw new Error('Can\'t upload avatar: ' + error.message)
    }
  },
  deleteAvatar: async function (uuid) {
    try {
      const response = await ApiService.delete('/rest/avatar')

      return response.data.payload
    } catch (error) {
      throw new Error('Can\'t delete avatar: ' + error.message)
    }
  }
}

export default UserService

export { UserService, AuthenticationError, RegistrationError }
