<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <List
            name='Bulk Process'
            :current-module="currentModule"
            :has-add="false"
            :use-search="true"
            :headers="headers"
            :has-created="true"
            :has-filtration="true"
            :has-export="true"
            :key="listKey"
    >
        <template v-slot:extraButton="{ }">
            <v-col cols='auto' >
             <v-btn
                     text
                     class='u_blue white--text'
                     :ripple='false'
                     :append="true"
                     :height="40"
             >
              Bulk Update
            </v-btn>
            </v-col>
      </template>
      <template v-slot:filtration="{ item }">
        <v-row>
          <v-col cols='12' md='6'>
            <o-text-field
                    input-label="Number"
                    v-model="item.tn"
                    :value="''"
                    clearable
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='3'>
            <o-date-picker
                    input-label="Bought After"
                    v-model="item.created_on_from"
            >
            </o-date-picker>
          </v-col>
          <v-col cols='12' md='3'>
            <o-date-picker
                    input-label="Bought Before"
                    v-model="item.created_on_to"
            >
            </o-date-picker>
          </v-col>
          <v-col cols='12' md='6'>
            <o-select
                    label='Any'
                    :items="sipControlApps"
                    :loading="scaLoading"
                    input-label="SIP Control App"
                    v-model="item.sip_control_app"
                    :value="''"
                    clearable
                    item-text='name'
                    item-value='uuid'
            >
            </o-select>
          </v-col>
          <v-col cols='12' md='6'>
            <o-select
                    label='Any'
                    :items="sipConnections"
                    :loading="scaLoading"
                    input-label="SIP Connection"
                    v-model="item.sip_connection"
                    :value="''"
                    clearable
                    item-text='name'
                    item-value='uuid'
            >
            </o-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.features="{ item }">
        <NumberFeatures
                @change="featuresChanged($event, item)"
                :editable="true"
                :value=iconsArr(item)
        >
        </NumberFeatures>
      </template>
      <template v-slot:item.tn="{ item }">
        <span class="numbers">
          {{beautifyNumber(item.tn.tn)}}
        </span>
      </template>
      <template v-slot:item.profile="{ item }">
        <NumberMessageProfiles
                :lease="item"
                v-model="item.messaging_profile"
                @changeMessageProfile="changeMessageProfile($event, item)"
        >
        </NumberMessageProfiles>
      </template>
      <template v-slot:item.sip="{ item }">
        <NumberManageSIP
                :lease="item"
        >
        </NumberManageSIP>
      </template>
    </List>
  </v-card>
  <OptionsDialog
          v-model="dialog"
          :number="dialogNumber"
  >
  </OptionsDialog>
  <v-snackbar
          top
          v-model="savedLease"
          color='success'
          :timeout="2000"
  >
    Saved!
  </v-snackbar>
</span>
</template>

<script>
import axios from 'axios'
import SearchForm from '@/components/SearchForm'
import NumberFeatures from '@/components/NumberFeatures'
import OptionsDialog from './VoiceOptions/OptionsDialog'
import { mapActions, mapGetters } from 'vuex'
import List from '../../components/CRUD/List'
import BeautifyNumber from '../../services/beautify/number.service'
import NumberManageSIP from './NumberManageSIP'
import NumberMessageProfiles from './NumberMessageProfiles'

export default {
  data: () => ({
    currentModule: 'tnlease',
    savedLease: false,
    dialog: false,
    dialogNumber: '',
    headers: [
      { text: 'Number', value: 'tn' },
      { text: 'Features', value: 'features', width: '160px', sortable: false },
      { text: 'Message Profile', value: 'profile', width: '300px', sortable: false },
      { text: 'SIP', value: 'sip', width: '300px', sortable: false },
      { text: 'Date Created', value: 'created_on', width: '200px' },
      { text: 'Release', value: 'delete', sortable: false }
    ],
    listKey: 1,
    tableOptions: {
      page: 1,
      itemsPerPage: 10
    },
    getCount: false,
    list: null
  }),
  computed: {
    ...mapGetters({
      sipControlApps: 'callControl/list',
      sipConnections: 'sipConnections/list',
      scaLoading: 'callControl/loading',
      scLoading: 'sipConnections/loading'
    }),
    count () {
      return this.$store.getters[`${this.currentModule}/count`]
    }
  },
  watch: {
    count (val) {
      if (val) {
        this.getLists()
      }
    }
  },
  methods: {
    ...mapActions({
      requestSCA: 'callControl/requestList',
      requestSC: 'sipConnections/requestList',
      notify: 'notification/notify'
    }),
    iconsArr (item) {
      if (!item.subscriptions.length) {
        return ['voice', 'sms']
      }
      return item.subscriptions.map(el => el.type)
    },
    async changeMessageProfile (obj, item) {
      if (!obj) {
        return
      }
      const params = {
        messaging_profile: obj
      }
      axios
        .patch('rest/lease/tn/' + item.uuid + '/subscription/sms', params)
        .then(res => {
          this.notify({ text: 'Successfully saved ', type: 'success' })
        })
        .catch((err) => {
          this.notify({ text: Object.values(err.response.data.fields)[0].message, type: 'error' })
        }).finally(() => {
          this.listKey++
        })
    },
    async featuresChanged (obj, item) {
      const arr = item.subscriptions.map(el => el.type)
      const params = {
        sms: arr.includes('sms'),
        voice: arr.includes('voice')
      }
      params[obj.key] = obj.value
      axios
        .patch('rest/lease/tn/' + item.uuid, params)
        .then(res => {
        })
        .catch((err) => {
          this.notify({ text: err.message, type: 'error' })
        }).finally(() => {
          this.listKey++
        })
    },
    patchProfile (item, value) {
      this.savedLease = false
      this.patchNumbersLease({ uuid: item.uuid, profile: value }).then(res => {
        if (res) {
          this.savedLease = true
        } else {
          alert(this.errorMessage)
        }
      })
    },
    getLists () {
      axios
        .post('rest/lease/tn/search', { take: this.count })
        .then(res => {
          this.list = res.data.payload.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    exportToCsv () {
      this.getLists()
      const csvContent = 'data:text/csv;charset=utf-8,' +
                    this.list.map(e => {
                      return e.tn.tn
                    }).join('\n')

      var link = document.createElement('a')
      link.href = csvContent
      link.download = 'my_numbers.csv'
      link.click()
    },
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  components: {
    NumberMessageProfiles,
    SearchForm,
    OptionsDialog,
    NumberFeatures,
    List,
    NumberManageSIP
  },
  created () {
    this.requestSCA()
    this.requestSC()
  }
}
</script>

<style scoped>
    .numbers {
        display: flex;
        flex-wrap: nowrap;
        font-size: 14px;
        align-items: center;
        min-width: 120px;
    }
</style>
<style>
    .v-autocomplete.v-select.v-input--is-focused input {
        min-width: unset !important;
    }
</style>
