                                                                                                                                                                                                                                                                                                                                                                                                                              <template>
  <component
    :is="isDialog ? 'v-dialog' : 'v-card'"
    :flat="isDialog ? undefined : true"
    :tile="isDialog ? undefined : true"
    v-model="localDialog"
    :width="isDialog ? 800 : ''"
  >
    <v-card flat tile>
      <v-container>
        <v-row v-if='!noHeader' class='px-0 px-4'>
          <v-col cols='6' sm='10'>
            <h2 class="text-capitalize">
              {{ customName ? customName : ((isNew ? 'New' : 'Assign Numbers to') + ' ' + (name ? name : trim(currentModule))) }}
              {{ isNew ? '' : (': ' + (!!localItem && typeof localItem === 'object' && localItem.hasOwnProperty('name')  ? localItem.name : '')) }}
            </h2>
          </v-col>
          <v-spacer />
          <v-col sm='auto'>
            <v-btn
              text
              class='pa-0'
              dense
              :min-width="0"
              width=25
              :ripple="false"
              :to="$store.getters[`${this.currentModule}/APP_LINK`]"
              @click="$emit('close')"
            >
              <v-icon>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class='mx-4'>
            <slot name='after-name' :item="localItem"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class='px-4'>
            <v-card outlined class='pa-2 pa-md-5 mx-3'>
              <slot :item="localItem"></slot>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!noSave" class='mx-1'>
          <v-col>
            <v-btn
              text
              block
              class='primary white--text mr-n3'
              :ripple='false'
              height='40px'
              @click='createOrSave'
              :loading="saving"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </component>
</template>

<style lang='scss' scoped>
.tab-active .v-btn {
  color: white !important;
  background-color: $primary !important;
}
</style>

<script>
import { VDialog, VCard } from 'vuetify/lib'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentModule: null,
    noHeader: {
      type: Boolean,
      default: false
    },
    additionalParameters: {
      type: Object,
      default: null
    },
    saveByEmit: {
      type: Boolean,
      default: false
    },
    noNeedName: {
      type: Boolean,
      default: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    isNewProp: {
      type: Boolean,
      default: false
    },
    isNewDialog: {
      type: Boolean,
      default: false
    },
    customName: null,
    identificator: null,
    name: null
  },
  data: () => ({
    dialog: true,
    started: false
  }),
  methods: {
    // actions
    async create (item) {
      return await this.$store.dispatch(`${this.currentModule}/create`, item)
    },
    async patch (item) {
      return await this.$store.dispatch(`${this.currentModule}/patch`, item)
    },
    async requestOne (uuid) {
      return await this.$store.dispatch(`${this.currentModule}/requestOne`, uuid)
    },
    //

    // mutations
    clearCurrent () {
      return this.$store.commit(`${this.currentModule}/clearCurrent`)
    },
    setCurrent (uuid) {
      return this.$store.commit(`${this.currentModule}/setCurrent`, uuid)
    },
    setCurrentValue (item) {
      return this.$store.commit(`${this.currentModule}/setCurrentValue`, item)
    },
    setDefault () {
      return this.$store.commit(`${this.currentModule}/setDefault`)
    },
    //

    switchTab: function (item) {
      this.$nextTick(() => {
        this.currentTab = item.value
      })
    },
    onStart () {
      var that = this
      this.clearCurrent()
      if (!this.isNew && this.uuid) {
        if (this.list && this.list.length) {
          this.$nextTick(function () {
            that.setCurrent(this.uuid)
            that.started = true
            that.$emit('started')
          })
        }
        this.requestOne(this.uuid).then(function () {
          if (!that.started) {
            that.started = true
            that.$emit('started')
          }
          that.$emit('loaded')
        })
      } else {
        this.setDefault()
        this.$emit('started')
        this.$emit('loaded')
      }
    },
    validate () {
      const formSlot = this.$scopedSlots.default({ item: this.localItem })[0]
      return formSlot.context.$refs.form.validate()
    },
    notify (message, type) {
      return this.$store.dispatch('notification/notify', {
        text: message,
        type: type
      })
    },
    createOrSave () {
      if (this.validate()) {
        if (this.saveByEmit) {
          this.$emit('save', this.localItem)
        } else {
          if (this.isNew) {
            this.create(this.localItem).then(res => {
              if (!res) {
                if (this.localItem.remote_domain && this.name !== 'Campaign') {
                  alert('Please use a valid Hostname format')
                } else {
                  alert(this.errorMessage)
                }
              } else {
                this.notify({ text: 'Successfully added ', type: 'success' })
                this.localDialog = false
                this.$emit('parentEventAfterSuccess')
              }
            })
          } else {
            let obj = {
              identificator: this.localItem.identificator,
              uuid: this.localItem.uuid
            }
            if (this.currentModule === 'messaging/campaign') {
              obj = {
                ...this.localItem
              }
            }
            if (this.currentModule === 'callControl') {
              obj.name = this.localItem.name
              obj.auto_answer = this.localItem.auto_answer
              obj.webhook_method = this.localItem.webhook_method
              obj.webhook_url = this.localItem.webhook_url
              obj.webhook_request_timeout = this.localItem.webhook_request_timeout
            }
            // eslint-disable-next-line no-prototype-builtins
            if (this.localItem.hasOwnProperty('webhook_url') && this.localItem.webhook_url) {
              obj.webhook_url = this.localItem.webhook_url
            }
            // eslint-disable-next-line no-prototype-builtins
            if (this.localItem.hasOwnProperty('sms_campaign') && this.localItem.sms_campaign.uuid) {
              obj.sms_campaign = this.localItem.sms_campaign.uuid
            }
            this.patch(obj).then(res => {
              if (!res) {
                alert(this.errorMessage)
              } else {
                this.notify({ text: 'Successfully saved ', type: 'success' })
                this.localDialog = false
              }
            })
          }
        }
      }
    },
    trim (text) {
      text = text.replace(/([A-Z])/g, ' $1').trim()
      if (text.includes('sip')) {
        text = text.replace('sip', 'SIP')
      }
      if (text.includes('Connections')) {
        text = text.replace('Connections', 'Connection')
      }
      return text
    }
  },
  computed: {
    // getters
    current () {
      return this.$store.getters[`${this.currentModule}/current`]
    },
    saving () {
      return this.$store.getters[`${this.currentModule}/saving`]
    },
    list () {
      return this.$store.getters[`${this.currentModule}/list`]
    },
    errorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    },
    //

    localDialog: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },

    isNew () {
      if (this.isNewDialog) {
        return this.isNewDialog
      }
      if (this.isDialog) {
        return this.isNewProp
      } else {
        const slashesInPath =
          this.$store.getters[`${this.currentModule}/APP_LINK`].split('/').length
        return this.$route.fullPath.split('/')[slashesInPath] === 'new'
      }
    },
    uuid () {
      if (this.isDialog) {
        return this.identificator
      } else {
        return this.$route.params.uuid === ':uuid' ? null : this.$route.params.uuid
      }
    },
    localItem: {
      get () {
        const obj = {
          name: '',
          ...this.current
        }
        if (this.noNeedName) {
          delete obj.name
        }
        if (this.additionalParameters) {
          Object.keys(this.additionalParameters).map(el => {
            obj[el] = this.additionalParameters[el]
          })
        }

        return obj
      },
      set (item) {
        this.setCurrentValue(item)
      }
    }
  },
  watch: {
    $route (to, from) {
      this.onStart()
    },
    current (val) {
      this.localItem.uuid = val ? val.uuid : undefined
    },
    value (val) {
      if (val) {
        this.onStart()
      }

      const formSlot = this.$scopedSlots.default({ item: this.localItem })[0]
      if (formSlot.context.$refs.form) {
        return formSlot.context.$refs.form.resetValidation()
      }
    }
  },
  created: function () {
    this.currentTab = this.initialTab
    if (!this.isDialog) {
      this.onStart()
    }
  },
  components: {
    VDialog,
    VCard
  }
}
</script>
