import SIPProductsAPI from '../services/sipProducts.service'

const state = {
  list: [],
  loading: false,
  errorMessage: ''
}

const getters = {
  list (state) {
    return state.list
  },

  loading (state) {
    return state.loading
  },

  errorMessage (state) {
    return state.errorMessage
  }
}

const mutations = {
  request (state) {
    state.loading = true
  },

  success (state, list) {
    state.list = list
    state.loading = false
  },

  fail (state, error) {
    state.errorMessage = error.message
    state.loading = false
  }
}

const actions = {
  async requestList ({ commit }) {
    commit('request')

    try {
      const items = await SIPProductsAPI.requestProducts()
      commit('success', items)

      return true
    } catch (error) {
      commit('fail', error)
      return false
    }
  }
}

export const sipProducts = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
