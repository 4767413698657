import TNLeaseSubscriptionSMSAPI from '../../../services/tn/tnlease/subscription/sms.service'
import ItemBase from '../../itemBase'

class TNLeaseSubscriptionsSMS extends ItemBase {
  actions () {
    const actions = super.actions()
    actions.get = () => { throw new Error('Unsupported methods get for tnlease subscription') }

    return actions
  }
}

export const sms = new TNLeaseSubscriptionsSMS(TNLeaseSubscriptionSMSAPI, '/app/numbers/my-numbers').getModule()
