<template>
<span>
  <v-row justify='center'>
    <v-col cols='auto'>
      <h1>Confirm New Password</h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-form ref='password'>
        <UpdatePassword
          v-model="password"
        >
        </UpdatePassword>
      </v-form>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <v-btn
        text
        :ripple="false"
        block
        class="primary white--text"
        @click='handleSubmit'
      >
        Save Password
      </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <v-btn
        text
        :ripple="false"
        block
        class="primary white--text mb-3"
        to="login"
      >
        Back to Login page
      </v-btn>
    </v-col>
  </v-row>
</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UpdatePassword from './UpdatePassword'

export default {
  data: () => ({
    password: '',
    notEmpty: val => !!val || 'Required'
  }),
  methods: {
    ...mapActions({
      newPassword: 'auth/newPassword',
      notify: 'notification/notify'
    }),
    handleSubmit () {
      if (this.$refs.password.validate()) {
        this.newPassword({ password: this.password, token: this.$route.query.token }).then(res => {
          if (!res) {
            this.notify({ text: this.resetError, type: 'error' })
          } else {
            this.password = ''
            this.$nextTick(() => {
              this.$refs.password.resetValidation()
            })
            this.notify({ text: 'Success. You will be redirected to the Login page in 5 seconds', type: 'success' })
            setTimeout(() => {
              this.$router.push('login')
            }, 5000)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      reseting: 'auth/reseting',
      resetError: 'auth/resetError'
    })
  },
  components: {
    UpdatePassword
  }
}
</script>
