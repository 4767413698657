<template>
  <v-snackbar-queue
    top
    :color="`${color} ligthen-1`"
  >
  </v-snackbar-queue>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
