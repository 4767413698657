<template>
  <v-card tile class='pa-0'>
    <v-container class ='pa-0'>
      <v-row v-if="hasLabel">
        <v-col>
          <label class="input-label">
            {{inputLabel}}
            <slot name='input-label'></slot>
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col class='py-0'>
          <v-btn-toggle
            class='pa-0'
            v-model="currentStep"
            flat
            tile
            mandatory
            active-class="btn-acitve"
          >
            <v-btn
              active-class="btn-active"
              class="btn-class"
              v-for="step in steps"
              :key="step.value"
              :value="step.value"
              :ripple="false"
              :min-width="width/steps.length + 1"
            >{{step.text}}</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col class='py-0'>
          <span
            v-for="step in steps"
            :key="step.value"
          >
            <span
              v-if="currentStep===step.value"
            >
             <slot :name="step.value"></slot>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='auto' class='pl-8'>
          <v-btn
            text
            :ripple="false"
            class='primary white--text'
            @click='prevStep'
            :disabled='!isPrevStep'
          >
            Prev
          </v-btn>
        </v-col>
        <v-spacer/>
        <v-col cols='auto' class='pr-8'>
          <v-btn
            text
            :ripple="false"
            class='primary white--text'
            @click='nextStep'
            :disabled='!isNextStep'
          >
            Next
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang='scss' scoped>
@import "~vuetify/src/styles/styles.sass";

.btn-class.btn-active {
  border: none !important;
  background-color: #6658dd !important;
  color: white !important;
}
.btn-class {
  border: none !important;
  background-color: #f1f5f7!important;
  color: #6c757d !important;
}
</style>

<script>
export default {
  props: {
    inputLabel: {
      type: String,
      default: ''
    },
    steps: {
      type: Array
    },
    width: {
      type: Number,
      default: 600
    }
  },
  computed: {
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    },
    isNextStep: function () {
      var that = this
      return this.steps.findIndex(function (el) {
        return el.value === that.currentStep
      }) < this.steps.length - 1
    },
    isPrevStep: function () {
      var that = this
      return this.steps.findIndex(function (el) {
        return el.value === that.currentStep
      }) > 0
    }
  },
  data: () => ({
    currentStep: null
  }),
  methods: {
    nextStep: function () {
      if (this.isNextStep) {
        var that = this
        this.currentStep = this.steps[this.steps.findIndex(function (el) {
          return el.value === that.currentStep
        }) + 1].value
      }
    },
    prevStep: function () {
      if (this.isPrevStep) {
        var that = this
        this.currentStep = this.steps[this.steps.findIndex(function (el) {
          return el.value === that.currentStep
        }) - 1].value
      }
    }
  }
}
</script>
