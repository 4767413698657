<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <crud-list
      ref='list'
      name='SMS Messages'
      current-module='messaging/sms'
      :has-add="false"
      :use-search="true"
      :headers="headers"
      :has-created="true"
      :has-filtration="true"
      :has-export="true"
      class='table'
      :add-search-props='addSearchProps'
    >
      <template v-slot:filtration="{ item }">
        <v-row>
            <v-col cols='12' md='4'>
                <v-row>
                  <v-col cols='auto' class='pt-3 pb-1'>
                    Start date (UTC)
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class='pt-0'>
                       <o-date-picker
                               input-label=""
                               v-model="item.created_on_from"
                       >
                        </o-date-picker>
                  </v-col>
                </v-row>
            </v-col>
            <v-col cols='12' md='4'>
                  <v-row>
                    <v-col cols='auto' class='pt-3 pb-1'>
                      End date (UTC)
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class='pt-0'>
                        <o-date-picker
                                input-label=""
                                v-model="item.created_on_to"
                        >
                        </o-date-picker>
                    </v-col>
                  </v-row>
              </v-col>
              <v-col cols='12' md='4'>
                  <v-row>
                    <v-col cols='auto' class='pt-2 pb-1'>
                      GMT
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class='pt-0'>
                       <o-timezone-select
                               v-model="item.timezone"
                       >
                            </o-timezone-select>
                    </v-col>
                  </v-row>
              </v-col>
            <v-col cols='12' md='4'>
                   <o-text-field
                           input-label='Caller ID'
                           v-model="item.uuid"
                   >
                </o-text-field>
              </v-col>
            <v-col cols='12' md='4'>
                   <o-text-field
                           input-label='Dest Num'
                           v-model="item.to"
                   >
                </o-text-field>
              </v-col>
            <v-col cols='12' md='4'>
                <o-select
                        input-label="Status"
                        v-model="item.status"
                        :items="statuses"
                >
                </o-select>
              </v-col>
       </v-row>
      </template>
      <template v-slot:item.type="{ item }">
        {{
          item.type === 'smso' ? 'Outgoing'
            : item.type === 'smsi'? 'Incoming'
            : item.type
        }}
      </template>
      <template v-slot:item.from="{ item }">
          <span style="white-space: nowrap">{{beautifyNumber(item.from)}}</span>
      </template>
      <template v-slot:item.to="{ item }">
          <span style="white-space: nowrap">{{beautifyNumber(item.to)}}</span>
      </template>
    </crud-list>
  </v-card>
</span>
</template>

<style scoped>
.table >>> td.text-start.messageCell {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 250px !important;
}
</style>

<script>
import BeautifyNumber from '../../services/beautify/number.service'

export default {
  data: () => ({
    headers: [
      { text: 'State', value: 'state', class: 'noWrap' },
      { text: 'From', value: 'from', class: 'noWrap' },
      { text: 'To', value: 'to', class: 'noWrap' },
      { text: 'Message', value: 'message', width: 250, cellClass: 'messageCell', class: 'message noWrap' },
      { text: 'Date Created', value: 'created_on', class: 'noWrap' }
    ],
    statuses: [
      { text: 'Connected', value: 'connected' },
      { text: 'Failed', value: 'failed' },
      { text: 'All', value: 'all' }
    ],
    addSearchProps: {
      type: 'smsi'
    }
  }),
  methods: {
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  mounted () {
    this.$refs.list.filterParams.type = null
  }
}
</script>
