import ApiService from './api.service'

function prepareParams (from, to) {
  let params = ''
  if (from && to) {
    params = `?from=${from}&to=${to}`
  } else if (from) {
    params = `?from=${from}`
  } else if (to) {
    params = `?to=${to}`
  }

  return params
}

const ReportService = {
  requestReport: async function (ref, from, to) {
    try {
      const response =
      await ApiService.get(`/rest/dashboard/tx/${ref}${prepareParams(from, to)}`)

      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

export default ReportService
