<template>
<span>
  <crud-details
    class='mt-5'
    current-module="numbersOrders"
    custom-name='Order Details'
  >
    <template v-slot="{ item }">
      <v-row>
        <v-col cols='12' md='6'>
          <crud-details-section
            header='Order UUID'
          >
          {{item.uuid}}
          </crud-details-section>
        </v-col>
        <v-col cols='12' md='6'>
          <crud-details-section
            header='State'
          >
          {{item.state}}
          </crud-details-section>
        </v-col>
        <v-col cols='12' sm='6' lg='3'>
          <crud-details-section
            header='Total'
          >
          {{item.total}}
          </crud-details-section>
        </v-col>
        <v-col cols='12' sm='6' lg='3'>
          <crud-details-section
            header='Processing'
          >
          {{item.processing}}
          </crud-details-section>
        </v-col>
        <v-col cols='12' sm='6' lg='3'>
          <crud-details-section
            header='Success'
          >
          {{item.success}}
          </crud-details-section>
        </v-col>
        <v-col cols='12' sm='6' lg='3'>
          <crud-details-section
            header='Failed'
          >
          {{item.failed}}
          </crud-details-section>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <crud-details-section
            header='Number Details'
          >
            <v-data-table
              :headers="headers"
              :items='item.tn_items'
            >
              <template v-slot:item.tn="context">
                {{context.item.tn ? beautifyNumber(context.item.tn.tn) : '---'}}
              </template>
              <template v-slot:item.features="context">
                <NumberFeatures
                  :value="context.item.features"
                >
                </NumberFeatures>
              </template>
            </v-data-table>
          </crud-details-section>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='6'>
          <crud-date-section
            :item="item"
            action='created'
          >
          </crud-date-section>
        </v-col>
        <v-col cols='12' md='6'>
          <crud-date-section
            :item="item"
            action='modified'
          >
          </crud-date-section>
        </v-col>
      </v-row>
    </template>
  </crud-details>
</span>
</template>

<script>
import NumberFeatures from '../../components/NumberFeatures'
import BeautifyNumber from '../../services/beautify/number.service'

export default {
  data: () => ({
    headers: [
      { text: 'State', value: 'state' },
      { text: 'Reason', value: 'reason' },
      { text: 'Number', value: 'tn' },
      { text: 'Features', value: 'features' }
    ]
  }),
  methods: {
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  components: {
    NumberFeatures
  }
}
</script>
