<template>
<span>
  <v-row justify='center'>
    <v-col cols='auto' class='pb-0'>
      <h1>OPENTACT</h1>
    </v-col>
  </v-row>
  <v-row justify='center'>
    <v-col cols='auto' class='pt-0'>
      <h4>Register a New User Account</h4>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <o-text-field
        v-model="email"
        input-label='Email address'
        :errorMessages="emailErrors"
        autocomplete='email'
        type="email"
        :rules="[validEmail]"
        :hide-details="false"
        validate-on-blur
      >
      </o-text-field>
    </v-col>
  </v-row>
  <v-form
    ref='password'
  >
    <UpdatePassword
      v-model="password"
    >
    </UpdatePassword>
  </v-form>
  <v-row>
    <v-col cols='12' class='pb-6'>
      <o-tel-input
        v-model="phoneNumber"
        input-label='Phone Number'
        autocomplete='tel'
        :rules="[validToSubmit]"
      >
      </o-tel-input>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <o-text-field
        v-model="firstName"
        input-label='First Name'
        autocomplete='given-name'
      >
      </o-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <o-text-field
        v-model="lastName"
        input-label='Last Name'
        autocomplete='family-name'
      >
      </o-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <o-text-field
        v-model="companyName"
        input-label='Company Name'
        autocomplete='company-name'
        :rules="[validToSubmit]"
      >
      </o-text-field>
    </v-col>
  </v-row>
  <v-row>
     <v-col cols='12' class='pb-0'>
          <recaptcha @verify='recaptchaVerify'></recaptcha>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <v-btn
        text
        :ripple="false"
        block
        class="primary white--text"
        @click='handleSubmit'
        :disabled='!validToSubmit'
      >
        Register
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify='center'>
    <v-col cols='auto'>
      Already have an account? <router-link to='login'>Log In Here</router-link>
    </v-col>
  </v-row>
</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UpdatePassword from './UpdatePassword'
import Recaptcha from '../Recaptcha'
import ApiService from '../../services/api.service'

export default {
  data: () => ({
    email: '',
    emailErrors: [],
    password: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    companyName: '',
    token: ''
  }),
  methods: {
    ...mapActions({
      register: 'auth/register',
      notify: 'notification/notify'
    }),
    recaptchaVerify (token) {
      ApiService.addHeader('X-Recaptcha-Token', token)
    },
    handleSubmit: async function () {
      // Perform a simple validation
      if (this.validToSubmit) {
        await this.register({
          email: this.email,
          password: this.password,
          phone_number: this.number,
          first_name: this.firstName,
          last_name: this.lastName,
          account: {
            name: this.companyName,
            phone: this.number,
            email: this.email
          }
        })
        this.password = ''
        if (this.registrationErrorCode === 0) {
          this.notify({ text: 'Successfull registration. Please verify your email' })
        }
      }
    }
  },
  computed: {
    validToSubmit: function () {
      return this.email !== '' &&
      (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email)) &&
      this.password !== '' &&
      this.firstName !== '' &&
      this.lastName !== '' &&
      this.companyName !== '' &&
      this.phoneNumber !== ''
    },
    ...mapGetters('auth', [
      'registering',
      'registrationErrorCode',
      'registrationError',
      'registrationErrorFields'
    ]),
    number: function () {
      const n = this.phoneNumber.replace(/[^\d]/g, '')
      return n && n !== '' && n.length >= 3 ? n : undefined
    },
    validEmail () {
      return val => {
        return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email) || val !== this.email) || 'Email is not valid'
      }
    }
  },
  watch: {
    registrationErrorCode: function (val) {
      if (val === 400) {
        this.$router.push({ name: 'Fail', params: { errorMessage: 'Invalid Data Format' } })
      } else {
        this.$router.push({ name: 'Fail', params: { errorCode: this.registrationErrorCode, error: this.registrationError } })
      }
    },
    registrationErrorFields: function (val) {
      for (const field in val) {
        if (val[field] !== undefined) {
          this[field + 'Errors'].push(val[field].message)
        }
      }
    }
  },
  components: {
    UpdatePassword,
    Recaptcha
  }
}
</script>
