<template>
  <div>
    <sub-bar
      v-if="showSideBar"
      modify-route
      :items='subViews.filter(elem => {return elem.noDisplay === undefined})'
    >
    </sub-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import List from './List'
import Details from './Details'
import Switches from './Switches'
import Users from './Users'
import Invites from './Invites'
import InviteHistory from './InviteHistory'
import SubBar from '@/components/SubBar'
import Hotkeys from './Hotkeys.vue'

export default {
  name: 'SIPDomain',
  displayName: 'SIP Domains',
  components: { SubBar },
  data: () => ({
    subViews: [
      { name: 'Domain List', path: '/app/sip-domain', component: List, noDisplay: true },
      { name: 'Domain Details', path: '/app/sip-domain/:uuid', component: Details, noDisplay: true },
      { name: 'New Domain', path: '/app/sip-domain/new', component: Details, noDisplay: true },
      { name: 'Users', path: '/app/sip-domain/:connection_uuid/users', component: Users },
      { name: 'Hotkeys', path: '/app/sip-domain/:connection_uuid/hotkeys', component: Hotkeys },
      { name: 'Invite Users', path: '/app/sip-domain/:connection_uuid/invites', component: Invites },
      { name: 'Invite History', path: '/app/sip-domain/:connection_uuid/invite-history', component: InviteHistory },
      { name: 'Auth Access', path: '/app/sip-domain/:connection_uuid/auth-access', component: Switches, noDisplay: true }
    ]
  }),
  computed: {
    showSideBar: function () {
      return ['Invite Users', 'Users', 'Hotkeys', 'Invite History'].includes(this.$route.name)
    }
  }
}
</script>
