import Vue from 'vue'

const state = {
  moduleMap: {}
}

const getters = {
  getMap (state) {
    return state.moduleMap
  }
}

const mutations = {
  addToMap (state, item) {
    state.moduleMap[item.id] = item.module
    Vue.set(state.moduleMap, item.id, item.module)
  },

  removeFromMap (state, module) {
    for (const field in state.moduleMap) {
      if (state.moduleMap[field] === module) {
        Vue.delete(state.moduleMap, field)
      }
    }
  }
}

const actions = {
  notifyProgress ({ getters, commit }, { id, progress }) {
    if (getters.getMap[id]) {
      commit(`${getters.getMap[id]}/exportProgress`, progress, { root: true })
    }
  },
  notifyFinished ({ getters, commit }, { id, link }) {
    if (getters.getMap[id]) {
      commit(`${getters.getMap[id]}/exportEnd`, link, { root: true })
    }
  }
}

export const exportModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
