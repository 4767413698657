<template>
<span>
  <v-card flat outlined class='my-5 mx-2 px-5'>
    <v-container>
      <v-row>
        <v-col>
          <h2>Users</h2>
        </v-col>
        <v-spacer/>
        <v-col cols='auto'>
          <v-btn
            text
            class='u_blue white--text'
            :ripple='false'
            append
            @click="create"
          >
          <v-icon class='mr-1'>mdi-plus-circle</v-icon>
            Add New User
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider/>
    <List
      :current-module="currentModule"
      :has-add="false"
      :use-search="true"
      :headers="dataHeaders"
      :has-created="true"
      :has-filtration="true"
      :has-export="false"
      :has-header="false"
      :has-edit="true"
      :has-delete="true"
      :has-modified="true"
      ref="userList"
    >

      <template v-slot:filtration="{ item }">
        <v-row>
          <v-col cols='12' md='6'>
             <o-text-field
               input-label="Login"
               v-model="item.login"
               :value="''"
               clearable
               label='Login'
             >
            </o-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.state="{ item }">
        <v-tooltip
          bottom
        >
        <template v-slot:activator="{ on }">
           <v-icon
             v-if="item.state === 'online'"
             color='success'
             v-on="on"
           >
              mdi-check-circle
          </v-icon>
          <v-icon
            v-else
            color='grey'
            v-on="on"
          >
              mdi-close-circle
          </v-icon>
        </template>

        <span style="white-space: nowrap">{{ item.state === 'online' ? ' Online' : ' Offline' }}</span>

        </v-tooltip>
      </template>
        <template v-slot:item.name="{ item}">
          <div
            class='breakword'
          >
            {{ item.login }}
          </div>
        </template>
        <template v-slot:item.is_active="{ item }">
             <o-switch
               v-model="item.is_active"
               @click="patch(item)"
             />
        </template>
        <template v-slot:item.same_domain_calls_only="{ item }">
          <o-switch
            v-model="item.same_domain_calls_only"
            @click="patch(item)"
          />
        </template>

        <template v-slot:item.edit="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                text
                v-on="on"
                class='warning white--text'
                @click="editItem(item)"
              >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              Edit
          </v-tooltip>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                text
                v-on="on"
                class='error white--text'
                @click="askToDelete(item)"
              >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Delete
          </v-tooltip>
        </template>

    </List>
  </v-card>
  <UserDetailsDialog
    v-model="detailsDialog"
    :isNew="isNew"
    :connectionUUID="connectionUUID"
    :uuid="uuid"
    v-if="detailsDialog"
  />
</span>
</template>
<style>
.active-item {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #6658dd;
}

.btn-item {
  margin-right: 10px;
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import UserDetailsDialog from './UserDetailsDialog'
import List from '../../components/CRUD/List'

export default {
  data: () => ({
    currentModule: 'sipUsers',
    detailsDialog: false,
    isNew: false,
    selectedUUID: null,
    dataHeaders: [
      {
        text: 'Online',
        value: 'state'
      },
      {
        text: 'Username',
        value: 'name'
      },
      // { text: 'Password', value: 'password' },
      {
        text: 'Created On',
        value: 'created_on'
      },
      {
        text: 'Active',
        value: 'is_active'
      },
      {
        text: 'Same domains',
        value: 'same_domain_calls_only'
      },
      {
        text: 'Edit',
        value: 'edit',
        width: 100,
        align: 'center',
        sortable: false
      },
      {
        text: 'Delete',
        value: 'delete',
        width: 100,
        align: 'center',
        sortable: false
      }
    ],
    uuid: null
  }),
  props: {
    useSearch: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      requestList: 'sipUsers/requestList',
      deleteItem: 'sipUsers/deleteItem',
      patch: 'sipUsers/patch',
      search: 'sipUsers/search'
    }),
    ...mapMutations({
      setAPILink: 'sipUsers/setAPILink'
    }),
    askToDelete (item) {
      this.$refs.userList.askToDelete(item)
    },
    create () {
      this.uuid = null
      this.isNew = true
      this.detailsDialog = true
    },
    editItem (item) {
      this.uuid = item.uuid
      this.isNew = false
      this.detailsDialog = true
    },
    deleteI (item) {
      this.$set(item, 'deleting', true)
      this.deleteItem(item.uuid).then(res => {
        if (!res) {
          this.$set(item, 'deleting', false)
        }
      })
    },
    beautifyDate: unformatted => (new Date(unformatted)).toLocaleString()
  },
  computed: {
    ...mapGetters({
      list: 'sipUsers/list',
      loading: 'sipUsers/loading',
      errorMessage: 'sipUsers/errorMessage'
    }),
    connectionUUID () {
      return this.$route.params.connection_uuid
    },
    count () {
      return this.$store.getters[`${this.currentModule}/count`]
    },
    loading () {
      return this.$store.getters[`${this.currentModule}/loading`]
    },
    errorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    }
  },
  created () {
    this.setAPILink(`/rest/sip/domain/${this.connectionUUID}/user`)
  },
  components: {
    List,
    UserDetailsDialog
  }
}
</script>
