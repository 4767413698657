<template>
  <crud-details
    current-module='messaging/profiles'
    name='Messaging Profile'
  >
    <template v-slot="{ item }"
    >
      <v-form
        ref='form'
      >
        <v-card flat class="my-5" outlined>
          <v-container class="px-5">
            <v-row>
              <v-col>
                <h4>Profile Info</h4>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols='12' md='4'>
                <o-text-field
                  input-label='Profile Name'
                  label='Name of the messaging profile'
                  v-model="item.name"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='6' v-if="!isNewProfile" class='px-6'>
                <label class='input-label'>Profile ID</label>
                {{item.uuid}}
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card flat class="my-5" outlined>
          <v-container class="px-5">
            <v-row>
              <v-col>
                <h4>Inbound Settings</h4>
                <p>Control how your profile handles incoming messages</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col cols='12' md='6'>
                <o-text-field
                  input-label='Send a webhook to this URL:'
                  v-model="item.callback_url"
                ></o-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <o-text-field
                  input-label='If the primary URL fails, send webhook to'
                  v-model="item.callback_url2"
                ></o-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                If you wish to send and receive using an <a>SMPP bind</a>, please email <a>sales@opentact.com</a>.
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card flat class="my-5" outlined>
          <v-container class="px-5">
            <v-row>
              <v-col>
                <h4>Outbound Settings</h4>
                <p>Control how your profile sends messages</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col>
                <v-switch
                  v-model="item.number_pool_enable"
                  label='Number Pool'
                  hint='Deliver messages from a pool of multiple phone numbers to handle higher volume.'
                  persistent-hint
                  inset
                  :ripple="false"
                ></v-switch>
              </v-col>
            </v-row>
            <span v-if="item.number_pool_enable">
              <v-row>
                <v-col cols='12'>
                  The pool of numbers includes all long-code and toll-free
                  numbers assigned to the messaging profile you're using.
                  When you send a message, the "From" number is selected
                  from the numbers in your Number Pool that aren't currently
                  being used to send a message. <a>Learn more about Number Pooling</a>.
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h4>Type of Number</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols='12'>
                  <o-text-field
                    v-model="item.long_code_weight"
                    hide-details
                    type='number'
                    :step="1"
                  >
                    <template v-slot:input-label>
                      <span>Long Code (DID) Number Weight <i>(Required)</i></span>
                    </template>
                  </o-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols='12'>
                  <o-text-field
                    v-model="item.tollfree_weight"
                    hide-details
                    type='number'
                    :step="1"
                  >
                    <template v-slot:input-label>
                      <span>Toll-free Number Weight <i>(Required)</i></span>
                    </template>
                  </o-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="item.skip_unhealthy_tns"
                    hide-details
                    label='Skip Unhealthy Numbers'
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-model="item.sticky_sender"
                    hide-details
                    label='Sticky Sender'
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </span>
            <v-divider/>
            <v-row>
              <v-col>
                <h4>Rate Limit</h4>
              </v-col>
            </v-row>
            <v-row class='mb-0'>
              <v-col class='pb-0' cols='6' md='2'><b>Long Code</b></v-col>
              <v-col class='pb-0' cols='6' md='1'>10</v-col>
              <v-col class='pb-md-0' cols='12' md='auto'>messages per minute per number.</v-col>
            </v-row>
            <v-row>
              <v-col class='pt-0' cols='6' md='2'><b>Toll-free</b></v-col>
              <v-col class='pt-0' cols='6' md='1'>1200</v-col>
              <v-col class='pt-0' cols='12' md='auto'>messages per minute per number.</v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card flat class="my-5" outlined v-if="campaignOptions.length">
          <v-container class="px-5">
            <v-row>
              <v-col>
                <h4>10 DLC</h4>
                <p>You will need to associate your Number to a campaign if you intend to use the Number to send messages to the United States</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12' md='6'>
                <o-select
                  label='Any'
                  :items="campaignOptions"
                  input-label="Campaign"
                  :value="item.sms_campaign ? item.sms_campaign.uuid : ''"
                  @change="handleChange(item, $event)"
                  clearable
                  item-text='campaignId'
                  item-value='uuid'
                >
                  </o-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card flat class='my-5' outlined>
          <v-container class='px-5'>
            <v-row>
              <v-col cols='12' md='6'>
                <b>Created </b>
                <span
                  v-if="item.created_by"
                >
                  <b>by</b> {{item.created_by.first_name + ' ' + item.created_by.last_name}}
                </span>
                <b>on</b> {{beautifyDate(item.created_on)}}
              </v-col>
              <v-col cols='12' md='6'>
                <b>Last Modified </b>
                <span
                  v-if="item.modified_by"
                >
                  <b>by</b> {{item.modified_by.first_name + ' ' + item.modified_by.last_name}}
                </span>
                <b>on</b> {{beautifyDate(item.modified_on)}}
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </template>
  </crud-details>
</template>

<script>
export default {
  data: () => ({
    campaign: '',
    userData: {}
  }),
  computed: {
    isNewProfile () {
      const arr = this.$route.fullPath.split('/')
      return arr[arr.length - 1] === 'new'
    },
    campaignOptions () {
      return this.$store.getters['messaging/campaign/list']
    }
  },
  methods: {
    handleChange (obj, event) {
      obj.sms_campaign = {}
      this.$set(obj.sms_campaign, 'uuid', event)
    },
    beautifyDate: (unformatted) => {
      const date = new Date(unformatted)
      return date.toLocaleString()
    },
    async requestList () {
      this.userData = this.$store.getters['user/userData']
      return await this.$store.dispatch('messaging/campaign/search', { take: 100, skip: 0, created_by: this.userData.uuid })
    }
  },
  created () {
    this.requestList()
  }
}
</script>
