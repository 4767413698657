import ItemBase from '../itemBase.service'

class SMSAPIClass extends ItemBase {
  buildSearchRequest (searchParams) {
    searchParams = super.buildSearchRequest(searchParams)
    searchParams.tn = searchParams.tn ? searchParams.tn : undefined
    searchParams.type = searchParams.type ? searchParams.type : undefined

    return searchParams
  }

  buildCreateRequest (item) {
    return {
      ...super.buildCreateRequest(item),
      ...item,
      doNotRedirect: undefined
    }
  }
}

export const SMSAPI = new SMSAPIClass('/rest/sms')
