<template>
  <v-row class='pa-0'>
      <v-col cols='12'>
          <o-text-field
                  input-label='Company Name'
                  v-model="item.company_name"
                  :rules="[notEmpty,moreThanThree]"
          >
          </o-text-field>
      </v-col>
      <v-col cols='12'>
          <o-text-field
                  input-label='Company City'
                  v-model="item.company_city"
                  :rules="[notEmpty,moreThanThree]"
          >
          </o-text-field>
      </v-col>
      <v-col cols='12'>
          <h5>Phone</h5>
          <o-tel-text-input
                  input-label='Phone'
                  v-model.number="item.company_phone_number"
                  :as-text="false"
          >
          </o-tel-text-input>
<!--          <o-tel-input-->
<!--                  v-model.number="item.company_phone_number"-->
<!--                  autocomplete='tel'-->
<!--          >-->
<!--          </o-tel-input>-->

      </v-col>
      <v-col cols='12'>
          <o-text-field
                  input-label='Company Address'
                  v-model="item.company_address1"
                  :rules="[notEmpty,moreThanThree]"
          >
          </o-text-field>
      </v-col>
      <v-col cols='12'>
          <o-text-field
                  input-label='Company Postal code'
                  v-model="item.company_postal_code"
                  :rules="[notEmpty,moreThanThree]"
          >
          </o-text-field>
      </v-col>
    <v-col cols='12'>
      <o-text-field
        input-label='Subject'
        v-model="item.subject"
        :rules="[notEmpty]"
      >
      </o-text-field>
    </v-col>
    <v-col cols='12'>
      <o-select
        input-label='Level'
        label='Select Level'
        v-model="item.level"
        :rules="[notEmpty]"
        :items="filteredItems(item.level)"
      >
      </o-select>
    </v-col>
    <v-col cols='12'>
      <o-textarea
        input-label='Description'
        v-model="item.description"
      >
      </o-textarea>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: null
  },
  data: () => ({
    items: [
      // { text: 'Disabled', value: 'Disabled' },
      { text: 'Level 0', value: 'Level0' },
      { text: 'Level 1', value: 'Level1' },
      { text: 'Level 2', value: 'Level2' },
      { text: 'Service Account', value: 'ServiceAccount' }
    ],
    moreThanThree: val => {
      return (!val) || (val.length >= 3) || 'At least 3 characters are required'
    },
    userData: {},
    notEmpty: val => !!val || 'Required'
  }),
  computed: {
    filteredItems () {
      return (level) => {
        const arr = this.items.map((item, index) => {
          const findIdx = this.items.findIndex(el => el.value === this.userData.account.level)
          if (findIdx < index) {
            return item
          } else {
            return {
              ...item,
              disable: true
            }
          }
        })
        return arr
      }
    }
  },
  // methods: {
  //   filteredItems (level) {
  //     return this.items.map((item, index) => {
  //       const findIdx = this.items.findIndex(el => el.value === level)
  //       if (findIdx > index) {
  //         return item
  //       } else {
  //         return {
  //           ...item,
  //           disabled: true
  //         }
  //       }
  //         console.log(arr)
  //     })
  //   }
  // },
  created () {
    this.userData = this.$store.getters['user/userData']
    // this.item.level = this.userData.account.level
  }
}
</script>
