import TokenService from '../services/tokens.service'

const state = {
  loading: false,
  tokens: []
}

const getters = {
  getTokens (state) {
    return state.tokens
  },

  isLoading (state) {
    return state.loading
  }
}

const mutations = {
  requestingTokens (state) {
    state.loading = true
  },

  setTokens (state, tokens) {
    state.loading = false
    state.tokens = tokens
  },

  requestTokensFailed (state) {
    state.loading = false
  },

  addToken (state, item) {
    state.tokens.push(item)
  },

  deleteToken (state, item) {
    state.tokens.splice(state.tokens.indexOf(item), 1)
  },

  deleteAll (state) {
    state.tokens = []
  }
}

const actions = {
  async requestTokens ({ commit }) {
    commit('requestingTokens')

    try {
      const tokens = await TokenService.requestTokens()

      commit('setTokens', tokens)
    } catch (error) {
      console.error('Request tokens failed: ' + error.message)
      commit('requestTokensFailed')
    }
  },

  async createToken ({ commit }, { name, ttl }) {
    try {
      const token = await TokenService.createToken(name, ttl)

      commit('addToken', token)
    } catch (error) {
      console.error('Adding token ' + name + ' failed: ' + error.message)
    }
  },

  async deleteToken ({ commit }, item) {
    try {
      await TokenService.deleteToken(item.token)

      commit('deleteToken', item)
    } catch (error) {
      console.error('Deleting token ' + name + ' failed: ' + error.message)
    }
  },

  async deleteAll ({ commit }) {
    try {
      await TokenService.deleteAllTokens()

      commit('deleteAll')
    } catch (error) {
      console.error('Deleting token ' + name + ' failed: ' + error.message)
    }
  }

}

export const tokens = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
