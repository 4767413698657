<template>
    <div>
        <crud-list
                name='brand'
                :headers="headers"
                current-module='messaging/brand'
                :has-add="true"
                :custom-add="true"
                :has-edit="true"
                :has-delete="true"
                :use-search="true"
                custom-key='brandId'
                :filter-page-request="filterPageRequest"
                :deleted-param="'brandId'"
                :sort-by-prop="['brandId','displayName']"
                @add="add"
        >
        </crud-list>
        <v-dialog
                v-model="dialog"
        >
            <v-card flat tile>
                <v-card-title fixed>
                    <v-row class='px-0 px-md-4'>
                        <v-col cols='8' sm='10'>
                            <h2>
                                New Brand
                            </h2>
                        </v-col>
                        <v-spacer />
                        <v-col sm='auto'>
                            <v-btn
                                    text
                                    class='pa-0'
                                    dense
                                    :min-width="0"
                                    width=25
                                    :ripple="false"
                                    @click="dialog=false"
                            >
                                <v-icon>
                                    mdi-close-thick
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-container>
                    <v-row>
                        <v-col class='px-4'>
                            <v-card outlined class='pa-2 pa-md-5 mx-3'>
                                <BrandDetailsEdit
                                            :item="item"
                                            ref='form'
                                            :is-new="true"
                                          >
                                          </BrandDetailsEdit>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class='mx-1'>
                        <v-col>
                            <v-btn
                                    text
                                    block
                                    class='primary white--text mr-n3'
                                    :ripple='false'
                                    height='40px'
                                    @click='createOrSave'
                            >
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
<!--  <crud-single-->
<!--    current-module='messaging/brand'-->
<!--    name='Brand'-->
<!--    :has-delete="false"-->
<!--  >-->
<!--    <template v-slot:new="{ item }">-->
<!--      <BrandDetailsEdit-->
<!--        :item="item"-->
<!--        ref='form'-->
<!--        :is-new="true"-->
<!--      >-->
<!--      </BrandDetailsEdit>-->
<!--    </template>-->
<!--    <template v-slot:edit="{ item }">-->
<!--      <BrandDetailsEdit-->
<!--        :item="item"-->
<!--        ref='form'-->
<!--      >-->
<!--      </BrandDetailsEdit>-->
<!--    </template>-->
<!--    <template v-slot:default="{ item }">-->
<!--      <BrandDetailsView-->
<!--        :item="item"-->
<!--      >-->
<!--      </BrandDetailsView>-->
<!--    </template>-->
<!--  </crud-single>-->
</template>

<script>
import BrandDetailsEdit from './BrandDetailsEdit'
import BrandDetailsView from './BrandDetailsView'
import { mapActions } from 'vuex'

export default {
  components: {
    BrandDetailsEdit,
    BrandDetailsView
  },
  data: () => ({
    filterPageRequest: {},
    headers: [
      { text: 'Brand Name', value: 'displayName' },
      { text: 'Registered On', value: 'created_on' },
      { text: 'Brand ID', value: 'brandId' },
      { text: 'Entity Type', value: 'brand_type' },
      { text: 'Brand Relation SHIP', value: 'brandRelationship' },
      { text: 'Country', value: 'country.a2' }
    ],
    dialog: false,
    item: {}
  }),
  computed: {
    errorMessage () {
      return this.$store.getters['messaging/brand/errorMessage']
    }
  },
  methods: {
    ...mapActions({
      notify: 'notification/notify'
    }),
    async create (item) {
      return await this.$store.dispatch('messaging/brand/create', item)
    },
    validate () {
      return this.$refs.form.validate()
    },
    createOrSave () {
      if (this.validate()) {
        this.create(this.item).then(res => {
          if (!res) {
            this.notify({ text: this.errorMessage, type: 'error' })
          } else {
            this.dialog = false
            this.notify({ text: 'Successfully added', type: 'success' })
          }
        })
      }
    },
    add () {
      this.dialog = true
    }
  },
  created () {
    const userData = this.$store.getters['user/userData']
    this.filterPageRequest.account = userData?.account?.uuid || null
  }
}
</script>
