<template>
  <span>
    <v-card flat class="my-5">
      <v-container class="px-5">
        <v-row>
          <v-col>
            <h2>Create Hosted SMS Order</h2>
            <p>Create a new Hosted SMS Order</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card flat class="my-5">
      <v-container class="px-5">
        <v-row>
          <v-col>
            <h4>Order Data</h4>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col>
            <label class="input-label">Numbers (separated by semicolon, comma or carriage return) <i>(Required)</i></label>
            <v-textarea
              flat
              outlined
              dense
              single-line
              label="+1 111 111 1111; +1 111 111 1112; +1 111 111 1113"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="input-label">Messaging Profile</label>
              <o-select
                :items="sipMessageProfiles"
                :loading="smLoading"
                :value="''"
                clearable
                item-text='name'
                item-value='uuid'
              >
              </o-select>
          </v-col>
        </v-row>
        <v-row>
           <v-col cols='4' class='pt-6'>
                <o-file-input
                  input-label="Letter of Authorization from Number Owner"
                  v-model='pdfFile'
                  value=""
                >
                </o-file-input>
           </v-col>
        </v-row>
        <v-row>
             <v-col cols='12' md='6'>
                  <v-checkbox
                    v-model="checkedLetter"
                    label='Letter of Authorization to Opentact'
                    @change="openAgreement"
                  >
                  </v-checkbox>
             </v-col>
        </v-row>
        <v-row justify='center' justify-md='end'>
          <v-col cols='auto'>
            <v-btn
              width="100"
              text
              class='error white--text'
              :ripple='false'
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols='auto'>
            <v-btn
              width="120"
              text
              class='primary white--text'
              :ripple='false'
            >
              Create Order
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import OptionsDialog from '../Numbers/VoiceOptions/OptionsDialog'
import List from '../../components/CRUD/List'
import NumberManageSIP from '../Numbers/NumberManageSIP'
import BeautifyNumber from '../../services/beautify/number.service'
import NumberMessageProfiles from '../Numbers/NumberMessageProfiles'

export default {
  data: () => ({
    currentModule: 'tnlease',
    pdfFile: '',
    checkedLetter: '',
    savedLease: false,
    dialog: false,
    dialogNumber: '',
    getCount: false,
    list: null
  }),
  computed: {
    ...mapGetters({
      sipMessageProfiles: 'messaging/profiles/list',
      smLoading: 'messaging/profiles/loading'
    }),
    count () {
      return this.$store.getters[`${this.currentModule}/count`]
    }
  },
  watch: {
    count (val) {
      if (val) {
        this.getLists()
      }
    }
  },
  methods: {
    ...mapActions({
      requestSM: 'messaging/profiles/requestList'
    }),
    // patchProfile (item, value) {
    //   this.savedLease = false
    //   this.patchNumbersLease({ uuid: item.uuid, profile: value }).then(res => {
    //     if (res) {
    //       this.savedLease = true
    //     } else {
    //       alert(this.errorMessage)
    //     }
    //   })
    // },
    openAgreement () {
    },
    getLists () {
      axios
        .post('rest/lease/tn/search', { take: this.count })
        .then(res => {
          this.list = res.data.payload.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    exportToCsv () {
      this.getLists()
      const csvContent = 'data:text/csv;charset=utf-8,' +
        this.list.map(e => {
          return e.tn.tn
        }).join('\n')

      var link = document.createElement('a')
      link.href = csvContent
      link.download = 'my_numbers.csv'
      link.click()
    },
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  components: {
    NumberMessageProfiles,
    OptionsDialog,
    List,
    NumberManageSIP
  },
  created () {
    this.requestSM()
  }
}
</script>
