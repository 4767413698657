<template>
<span>
  <v-row justify="center">
    <v-col cols='auto' class='pa-0'>
      <h1>{{$route.params.errorMessage}}</h1>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols='auto' class='pa-0'>
      <h1>{{$route.params.errorCode}}</h1>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols='auto' class='pa-0'>
      <h3>{{$route.params.error}}</h3>
    </v-col>
  </v-row>
  <v-row justify="center" style="margin: 40px">
    <v-col cols='auto' class='pa-0'>
      <p class="failMessage">Your Token has expired or is invalid.</p>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-btn
        text
        :ripple="false"
        block
        class="primary white--text mb-3"
        @click="$router.go(-1)"
      >
        Go Back
      </v-btn>
    </v-col>
  </v-row>
</span>
</template>

<script>
export default {
  name: 'Fail'
}
</script>
<style scoped>
    .failMessage{
        color: black;
        font-size: 22px;
    }
</style>
