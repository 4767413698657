import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/materialUI'
import './assets/css/custom.css'
import 'vue-awesome-notifications/dist/styles/style.css'
import VueApexCharts from 'vue-apexcharts'
import VModal from 'vue-js-modal'
// Import project components
import AddSimToCart from '@/components/AddSimToCart'
import OBtnToggle from '@/components/OBtnToggle'
import OFormCard from '@/components/OFormCard'
import OSelect from '@/components/OSelect'
import OTextField from '@/components/OTextField'
import ODatePicker from '@/components/ODatePicker'
import OTimePicker from '@/components/OTimePicker'
import ODateTime from '@/components/ODateTime'
import OTimezoneSelect from '@/components/OTimezoneSelect'
import OTelInput from '@/components/OTelInput'
import ODialer from '@/components/ODialer'
import OStepper from '@/components/OStepper'
import OFileInput from '@/components/OFileInput'
import OState from '@/components/OState'
import OTelTextInput from '@/components/OTelTextInput'
import OTextarea from '@/components/OTextarea'
import OSelectModule from '@/components/OSelectModule'
import OSwitch from '@/components/OSwitch'
import CRUDList from '@/components/CRUD/List'
import CRUDDetails from '@/components/CRUD/Details'
import CRUDSingle from '@/components/CRUD/Single'
import CRUDDetailsSection from '@/components/CRUD/DetailsSection'
import CRUDDateSection from '@/components/CRUD/DateSection'

import ApiService from './services/api.service'
import { TokenService } from './services/storage.service'
// import VSnackbarQueue from '@tozd/vue-snackbar-queue'
import VueAWN from 'vue-awesome-notifications'

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_ROOT_API)

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader()
}
ApiService.mount401Interceptor()

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.use(VModal)
Vue.use(VueAWN)
Vue.component('apexchart', VueApexCharts)
// Vue.use(VSnackbarQueue)

// Register project components
Vue.component('add-sim-to-cart', AddSimToCart)
Vue.component('o-btn-toggle', OBtnToggle)
Vue.component('o-form-card', OFormCard)
Vue.component('o-select', OSelect)
Vue.component('o-text-field', OTextField)
Vue.component('o-date-picker', ODatePicker)
Vue.component('o-time-picker', OTimePicker)
Vue.component('o-date-time', ODateTime)
Vue.component('o-timezone-select', OTimezoneSelect)
Vue.component('o-tel-input', OTelInput)
Vue.component('o-dialer', ODialer)
Vue.component('o-stepper', OStepper)
Vue.component('o-file-input', OFileInput)
Vue.component('o-state', OState)
Vue.component('o-tel-text-input', OTelTextInput)
Vue.component('o-textarea', OTextarea)
Vue.component('o-select-module', OSelectModule)
Vue.component('o-switch', OSwitch)
Vue.component('crud-list', CRUDList)
Vue.component('crud-details', CRUDDetails)
Vue.component('crud-single', CRUDSingle)
Vue.component('crud-details-section', CRUDDetailsSection)
Vue.component('crud-date-section', CRUDDateSection)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
