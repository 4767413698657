import ItemBase from './itemBase.service'

class TicketAPI extends ItemBase {
  buildCreateRequest (request) {
    request = super.buildRequest(request)
    if (request.type === 'TicketTNOrderBulk') {
      request.params = {
        country: request.params.country || 'US',
        type: request.params.type === 'toll_free' ? 'toll_free' : 'long_code',
        npa: request.params.npa || undefined,
        nxx: request.params.nxx || undefined,
        line: request.params.line || undefined,
        pattern: request.params.type === 'toll_free' ? request.params.pattern ?? 'xxxxxxx' : undefined,
        state: request.params.state ? request.params.state : undefined,
        rate_center: request.params.rate_center ? request.params.rate_center : undefined,
        features: request.params.features ? request.params.features : undefined
      }
    }

    return request
  }
}

export default new TicketAPI('/rest/ticket')
