import { TokenService } from '../services/storage.service'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from '../Base.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import Numbers from '../views/Numbers/Numbers.vue'
import Messaging from '../views/Messaging/Messaging.vue'
import CallControl from '../views/CallControl/CallControl.vue'
import Connections from '../views/Connections/Connections.vue'
import SIPDomain from '../views/SIPDomain/Main.vue'
import OutboundProfiles from '../views/OutboundProfiles/OutboundProfiles.vue'
// import Wireless from '../views/Wireless/Wireless.vue'
import Reporting from '../views/Reporting/Reporting.vue'
import Debugging from '../views/Debugging/Debugging.vue'
import Auth from '../views/Auth/Auth.vue'
// import Networking from '../views/Networking/Networking.vue'
// import TelcoData from '../views/TelcoData/TelcoData.vue'
import ShoppingCart from '../views/ShoppingCart.vue'
import Account from '../views/Account/Account.vue'
import Login from '../views/Login/Login.vue'
import PasswordReset from '../views/Login/PasswordReset.vue'
import ConfirmReset from '../views/Login/ConfirmReset.vue'
import Register from '../views/Login/Register.vue'
import sipRegister from '../views/Login/sipRegister'
import Fail from '../views/Login/Fail.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Tickets from '../views/Tickets/Base.vue'

import store from '../store'

import AuthBase from '../views/Login/AuthBase.vue'

function registerNestedRoutes (path, viewComponent) {
  let nestedRoutes
  let redirectTo
  const routeName = viewComponent.displayName ? viewComponent.displayName : viewComponent.name
  if (viewComponent.data &&
  viewComponent.data().subViews &&
  viewComponent.data().subViews.length > 0) {
    nestedRoutes = viewComponent.data().subViews
    redirectTo = nestedRoutes[0].path
  }
  return {
    path: '/app' + path,
    name: routeName,
    redirect: redirectTo,
    children: nestedRoutes,
    component: viewComponent
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/app'
  },
  {
    path: '*',
    component: PageNotFound,
    meta: {
      public: true
    }
  },
  {
    path: '/app',
    redirect: '/app/dashboard',
    component: Base,
    children: [
      registerNestedRoutes('/dashboard', Dashboard),
      registerNestedRoutes('/numbers', Numbers),
      registerNestedRoutes('/messaging', Messaging),
      registerNestedRoutes('/connections', Connections),
      registerNestedRoutes('/sip-domain', SIPDomain),
      registerNestedRoutes('/call-control', CallControl),
      // registerNestedRoutes('/networking', Networking),
      registerNestedRoutes('/outbound-profiles', OutboundProfiles),
      // registerNestedRoutes('/wireless', Wireless),
      // registerNestedRoutes('/telcodata', TelcoData),
      registerNestedRoutes('/reporting', Reporting),
      registerNestedRoutes('/debugging', Debugging),
      registerNestedRoutes('/auth', Auth),
      registerNestedRoutes('/ticket', Tickets),
      registerNestedRoutes('/Account', Account),
      {
        name: 'My Cart',
        path: '/app/cart',
        component: ShoppingCart
      }
    ]
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: AuthBase,
    children: [
      {
        name: 'Login',
        path: '/auth/login',
        component: Login,
        meta: {
          public: true,
          notAvailabedForSigned: true
        }
      },
      {
        name: 'Password Reset',
        path: '/auth/password_reset',
        component: PasswordReset,
        meta: {
          public: true,
          notAvailabedForSigned: true
        }
      },
      {
        name: 'Confirm New Password',
        path: '/auth/confirm_reset',
        component: ConfirmReset,
        meta: {
          public: true,
          notAvailabedForSigned: true
        }
      },
      {
        name: 'Register',
        path: '/auth/register',
        component: Register,
        meta: {
          public: true,
          notAvailabedForSigned: true
        }
      },
      {
        name: 'Register Sip',
        path: '/register/sip/:token',
        component: sipRegister,
        meta: {
          public: true,
          notAvailabedForSigned: true
        },
        beforeEnter (to, from, next) {
          checkToken(to.params.token).then((res) => {
            if (res.data.success) {
              next()
            }
          })
        },
        props: route => ({ token: route.params.token })
      },
      {
        name: 'Fail',
        path: '/auth/fail',
        component: Fail,
        meta: {
          public: true,
          notAvailabedForSigned: true
        }
      }
    ]
  }
]
const checkToken = (val) => store.dispatch('sipInvites/checkToken', val)
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const notAvailabedForSigned = to.matched.some(record => record.meta.notAvailabedForSigned)
  const loggedIn = !!TokenService.getToken()

  if (!isPublic && !loggedIn) {
    return next({
      path: '/auth',
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && notAvailabedForSigned) {
    return next('/')
  }

  next()
})

export default router
