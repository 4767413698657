import ItemBase from './itemBase.service'

export class SIPInvitesAPI extends ItemBase {
  buildRequest (item) {
    return {
      name: item.name ?? undefined,
      expired_on: item.expired_on ?? undefined
    }
  }
}
