<template>
  <span>
    <v-card flat class='px-4 mt-5'>
      <v-container>
        <v-row>
          <v-col>
            <h2>Hosted SMS</h2>
            <p>Hosted SMS allows you to enable SMS/MMS services on numbers that have existing voice services from another provider.
              The numbers that could also be for landlines that traditionally have never had SMS capabilities</p>
            <v-btn
              text
              class='primary white--text'
              :append='true'
              to='new'
            >
              Add new number
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
      <v-card flat class='pa-5'>
        <crud-list
          :headers="requestHeaders"
          current-module='request pending approval'
          :has-add="false"
        >
        </crud-list>
      </v-card>
    <v-card flat class='pa-5'>
        <crud-list
          :headers="dataHeaders"
          current-module='approved numbers'
          :has-header="true"
          :has-edit="true"
          :has-delete="true"
          :has-created="true"
          :has-modified="true"
        >
        </crud-list>
    </v-card>
  </span>
</template>

<script>
export default {

  data: () => ({
    dataHeaders: [
      { text: 'Number', value: 'number' },
      { text: 'SMS Profile', value: 'sms_profile' },
      { text: 'Date Created', value: 'date_created' },
      { text: 'Release', value: 'delete' }
    ],
    requestHeaders: [
      { text: 'Requested On', value: 'requested_on' },
      { text: 'Numbers', value: 'numbers' },
      { text: 'Status', value: 'status' }
    ],
    newDialog: false
  }),
  computed: {
    noProfiles: () => {
      return true
    }
  }
}
</script>
