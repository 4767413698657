import ProfilesService from '../services/profiles.service'

const state = {
  loading: false,
  profiles: []
}

const getters = {
  isLoading (state) {
    return state.loading
  },

  getProfiles (state) {
    return state.profiles
  }
}

const mutations = {
  profilesRequested (state) {
    state.loading = true
  },
  profilesReceived (state, profiles) {
    state.profiles = profiles
    state.loading = false
  },
  profilesRequestFailed (state) {
    state.loading = false
  }
}

const actions = {
  async requestProfiles ({ commit }) {
    commit('profilesRequested')

    try {
      const profiles = await ProfilesService.requestProfiles()

      commit('profilesReceived', profiles)
    } catch (error) {
      console.error(error.message)
      commit('profilesRequestFailed')
    }
  }
}

export const profiles = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
