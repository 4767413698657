<template>
  <LoadingPage v-if='!authVerified'>
  </LoadingPage>
  <v-app v-else>
    <Notification>
    </Notification>
    <AppBar :top-bar-height='topBarHeight' :name-width='leftBarWidth'></AppBar>
    <AppNavigation :app-name='appName' :width='leftBarWidth'></AppNavigation>
    <v-main app>
      <v-divider/>
      <v-container :class="$vuetify.breakpoint.xsOnly ? 'px-0': ''">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from './components/AppNavigation'
import Notification from './components/Notification'
import AppBar from './components/AppBar'
import LoadingPage from './components/LoadingPage'
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    appName: 'OPENTACT',
    topBarHeight: 70,
    leftBarWidth: 240
  }),
  methods: {
    ...mapActions({
      refreshLogin: 'auth/refreshLogin'
    })
  },
  computed: {
    ...mapGetters({
      authVerified: 'auth/authVerified'
    })
  },
  components: {
    AppNavigation,
    AppBar,
    LoadingPage,
    Notification
  },
  created () {
    this.refreshLogin()
  }
}
</script>
