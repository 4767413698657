<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <crud-list
      name='Transactions'
      current-module='transaction'
      :has-add="false"
      :use-search="true"
      :headers="headers"
      :has-created="true"
      :has-filtration="true"
      :has-export="true"
    >
      <template v-slot:filtration="{ item }">
        <v-row>
          <v-col cols='12' md='6'>
            <o-date-picker
              input-label="From"
              v-model="item.created_on_from"
            >
            </o-date-picker>
          </v-col>
          <v-col cols='12' md='6'>
            <o-date-picker
              input-label="To"
              v-model="item.created_on_to"
            >
            </o-date-picker>
          </v-col>
          <v-col cols='12' sm='6' lg='4'>
            <o-select
              input-label="Transaction Type"
              v-model="item.type"
              label='Select'
              @change="handleChange(item)"
              clearable
              :items="refTypes"
            >
              <template v-slot:selection="context">
                <span v-if="context.index < maxDisplay">{{ context.item + ', ' }} &nbsp;</span>
                <span
                  v-if="context.index === maxDisplay"
                  class="grey--text caption"
                >(+{{ item.ref_types.length - maxDisplay }} type(s))</span>
              </template>
            </o-select>
          </v-col>
<!--          <v-col cols='12' md='6'>-->
<!--            <o-text-field-->
<!--              input-label="Number"-->
<!--              v-model="item.tn"-->
<!--              clearable-->
<!--            >-->
<!--              <template v-slot:append>-->
<!--                <v-tooltip-->
<!--                  bottom-->
<!--                >-->
<!--                  <template v-slot:activator="{ on }">-->
<!--                    <v-icon v-on="on">mdi-information</v-icon>-->
<!--                  </template>-->
<!--                  Mask supported-->
<!--                </v-tooltip>-->
<!--              </template>-->
<!--            </o-text-field>-->
<!--          </v-col>-->
          <v-col cols='12' sm='6' lg='4'>
            <o-text-field
              input-label="Amount, From"
              v-model="item.amount_min"
              clearable
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6' lg='4'>
            <o-text-field
              input-label="Amount, To"
              v-model="item.amount_max"
              clearable
            >
            </o-text-field>
          </v-col>
<!--          <v-col cols='12' md='6'>-->
<!--            <o-select-->
<!--              input-label='Search Mode'-->
<!--              v-model="item.mode"-->
<!--              :items="modes"-->
<!--              clearable-->
<!--            >-->
<!--            </o-select>-->
<!--          </v-col>-->
         </v-row>
      </template>
      <template v-slot:item.amount="{ item }">
        {{ prepareAmount(item.amount) }}
      </template>
      <template v-slot:item.account_balance_after="{ item }">
        {{ '$' + (item.account_balance_after) }}
      </template>
      <template v-slot:item.tn="{ item }">
        {{item.tn ? beautifyNumber(item.tn.tn) : ''}}
      </template>
    </crud-list>
  </v-card>
</span>
</template>

<script>
import BeautifyNumber from '../../services/beautify/number.service'

export default {
  data: () => ({
    headers: [
      { text: 'Amount', value: 'amount', sortable: false },
      { text: 'Balance After', value: 'account_balance_after', sortable: false },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Number', value: 'tn', sortable: false },
      { text: 'Date Created', value: 'created_on' }
    ],
    refTypes: [
      'payment',
      'manual',
      'tnlease_mrc',
      'tnlease_nrc',
      'sms_incoming',
      'sms_outgoing',
      'sms_nrc',
      'sms_mrc',
      'sms_brand_nrc',
      'sms_campaign_mrc',
      'voice_incoming',
      'voice_outgoing',
      'voice_nrc',
      'voice_mrc',
      'tts',
      'lerg'
    ],
    modes: [
      'AND', 'OR'
    ]
  }),
  computed: {
    maxDisplay () {
      return (this.$vuetify.breakpoint.mdAndUp ? 3 : this.$vuetify.breakpoint.smAndUp ? 2 : 1)
    }
  },
  methods: {
    handleChange (item) {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('ref_types') && !item.ref_types.length) {
        delete item.ref_types
      }
    },
    beautifyNumber: num => BeautifyNumber.beautify(num),
    prepareAmount (val) {
      if (val >= 0) {
        return '+ $' + val.toFixed(2)
      } else {
        return '- $' + Math.abs(val).toFixed(2)
      }
    }
  }
}
</script>
