<template>
  <div>
        <SubBar
          :items='subViews.filter(elem => {return elem.noDisplay === undefined})'
        >
        </SubBar>
    <router-view></router-view>
  </div>
</template>

<script>

import SubBar from '@/components/SubBar'
import OutboundCallsReport from './OutboundCallsReport'
import InboundCallsReport from './InboundCallsReport'
import MessagesHistory from '../Messaging/MessagesHistory'
import InboundSms from '../Messaging/InboundSms'
import OutboundSms from '../Messaging/OutboundSms'
import CallDetails from './CallDetails'
// import Transactions from './Transactions'
import Recordings from './Recordings'
// import Outbound from '../Connections/Outbound'

export default {
  name: 'Reporting',
  data: () => ({
    subViews: [
      { name: 'Inbound Calls', path: '/app/reporting/call', direction: 'in', component: InboundCallsReport },
      { name: 'Outbound Calls', path: '/app/reporting/outbound-call', direction: 'out', component: OutboundCallsReport },
      { name: 'Inbound SMS', path: '/app/reporting/inbound-sms', component: InboundSms },
      { name: 'Outbound SMS', path: '/app/reporting/outbound-sms', component: OutboundSms },
      { name: 'Recording', path: '/app/reporting/recordings', component: Recordings },
      { name: 'Export Log', path: '/app/reporting/sms', component: MessagesHistory },
      { name: 'Call Details', path: '/app/reporting/call/:uuid', component: CallDetails, noDisplay: true }
    ]
  }),
  components: {
    SubBar
  }
}

</script>
