import ItemBase from './itemBase.service'

export class SIPHotkeysService extends ItemBase {
  buildRequest (item) {
    return {
      hotkey: item.hotkey ?? undefined,
      is_active: typeof item.is_active === 'boolean' ? item.is_active : undefined,
      sip_control_app: typeof item.sip_control_app === 'string' || item.sip_control_app === null
        ? item.sip_control_app
        : item.sip_control_app?.uuid ?? undefined
    }
  }

  buildPatchRequest (item) {
    return {
      ...this.buildRequest(item),
      hotkey: undefined
    }
  }
}

export default new SIPHotkeysService('/rest/sip/domain')
