<template>
  <o-text-field
    :input-label='inputLabel'
    v-model="number"
    label='e.g. +1 111 111 1111'
    hint='Please enter a valid phone number'
    clearable
    v-bind="$attrs"
    v-on="listeners"
  >
  </o-text-field>
</template>

<script>
import BeautifyNumber from '../services/beautify/number.service'

export default {
  props: {
    value: null,
    inputLabel: {
      type: String
    },
    asText: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    number: {
      get () {
        const result = BeautifyNumber.asYouType(this.value)
        return result
      },
      set (value) {
        const tmp = value
          ? parseInt(value.replace(/\s/g, '')) || ''
          : value
        const result = (this.asText ? tmp.toString() : tmp)
        this.$emit('input', result)
      }
    },
    listeners () {
      // we have to remove the 'input' event as we process it ourselves
      return Object.keys(this.$listeners)
        .filter(field => field !== 'input')
        .reduce((obj, key) => {
          obj[key] = this.$listeners[key]
          return obj
        }, {})
    }
  }
}
</script>
