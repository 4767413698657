import ApiService from './api.service'

const SIPProductsAPI = {
  async requestProducts () {
    try {
      const response = await ApiService.get('/rest/sip/product')

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message ?? error.response.statusText)
    }
  }
}

export default SIPProductsAPI
