import ApiService from './api.service'

const DictAPI = {
  async requestCountries () {
    try {
      const response = await ApiService.get('/rest/dict/country')

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  },

  async requestTNSearchCountries () {
    try {
      const response = await ApiService.get('/rest/tn/search/countries')

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  },

  async requestStates () {
    try {
      const response = await ApiService.get('/rest/dict/lerg/states')

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }
}

export class DictBase {
  constructor (apiLink) {
    this.apiLink = apiLink
  }

  parseResponse (data) {
    return data
  }

  async requestList (param) {
    try {
      const k = `${this.apiLink + (param ?? '')}`
      const cached = localStorage.getItem(k)
      if (cached) {
        return JSON.parse(cached)
      }

      const response = await ApiService.get(k)
      const payload = this.parseResponse(response.data.payload)

      localStorage.setItem(k, JSON.stringify(payload))

      return payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }
}

export default DictAPI
