<template>
<v-row class='pa-0'>
  <v-col cols='12'>
    <o-text-field
      input-label='Subject'
      v-model="item.subject"
      :hide-details="false"
      :rules="[notEmpty]"
    >
    </o-text-field>
  </v-col>
  <v-col cols='12'>
    <o-textarea
      input-label='Description'
      v-model="item.description"
    >
    </o-textarea>
  </v-col>
</v-row>
</template>

<script>
export default {
  props: {
    item: null
  },
  data: () => ({
    notEmpty: val => !!val || 'Required'
  })
}
</script>
