<template>
  <v-dialog
    v-model="localValue"
    width="600"
  >
    <v-card class ='pa-5'>
      <v-form
        ref='form'
      >
        <v-row>
          <v-col>
            <h4>Add Address</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <o-text-field
              v-model="currentItem.name"
              input-label='Full Name'
              hide-details
              :rules="[notEmpty]"
            >
            </o-text-field>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row>
          <v-col cols='12' sm='6'>
            <o-select
              input-label='Country'
              hide-details
              :items="countries"
              :loading="countriesLoading"
              item-text="name"
              item-value="a2"
              v-model="currentItem.country"
              :rules="[notEmpty]"
            >
            </o-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <o-text-field
              input-label='Street Address'
              hide-details
              v-model="currentItem.address1"
              :rules="[notEmpty]"
            >
            </o-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <o-text-field
              input-label='Extended Address'
              hide-details
              v-model="currentItem.address2"
              :rules="[notEmpty]"
            >
            </o-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' sm='6'>
            <o-text-field
              input-label='City/Locality'
              hide-details
              dense
              v-model="currentItem.city"
              :rules="[notEmpty]"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' sm='6'>
            <o-text-field
              input-label='ZIP Code'
              hide-details
              v-model="currentItem.zip_code"
              :rules="[notEmpty]"
            >
            </o-text-field>
          </v-col>
        </v-row>
        <v-row justify='end'>
          <v-col cols='auto'>
            <v-btn
              text
              class="error white--text"
              :ripple="false"
              @click="localValue=false"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols='auto'>
            <v-btn
              text
              class="primary white--text"
              :ripple="false"
              @click="createOrSave"
            >
              Save Address
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    uuid: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    notEmpty: val => !!val || 'Required'
  }),
  computed: {
    ...mapGetters({
      currentItem: 'addresses/current',
      saving: 'addresses/saving',
      errorMessage: 'addresses/errorMessage',
      countries: 'dict/countries/list',
      countriesLoading: 'dict/countries/loading',
      countriesErrorMessage: 'dict/countries/errorMessage'
    }),
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },
    isNew () {
      return this.uuid === 'new'
    }
  },
  methods: {
    ...mapActions({
      create: 'addresses/create',
      patch: 'addresses/patch',
      requestOne: 'addresses/requestOne',
      requestCountries: 'dict/countries/requestList'
    }),
    ...mapMutations({
      clearCurrent: 'addresses/clearCurrent',
      setCurrent: 'addresses/setCurrent',
      setDefault: 'addresses/setDefault'
    }),
    onStart () {
      this.clearCurrent()
      if (!this.isNew) {
        if (this.list && this.list.length) {
          this.$nextTick(function () {
            this.setCurrent(this.uuid)
          })
        }
        this.requestOne(this.uuid)
      } else {
        this.setDefault()
      }
    },
    createOrSave () {
      if (this.$refs.form.validate()) {
        if (this.isNew) {
          this.create().then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.localValue = false
            }
          })
        } else {
          this.patch(this.currentItem).then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.localValue = false
            }
          })
        }
      }
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.onStart()
      }
      this.$refs.form.resetValidation()
    }
  },
  created () {
    this.setDefault()
    this.requestCountries()
    if (!this.countries) {
      this.requestCountries().then(res => {
        if (!res) {
          alert(this.countriesErrorMessage)
        }
      })
    }
  }
}
</script>
