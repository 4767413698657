<template>
  <span>
    <v-card flat class='my-5 px-4'>
      <v-container>
        <v-row>
          <v-col cols='auto'>
            <h2>Auth Tokens</h2>
            <p>Create and manage API tokens to use with the Opentact API</p>
          </v-col>
          <v-spacer/>
          <v-col cols='12' md='auto'>
            <v-btn
              :block="$vuetify.breakpoint.smAndDown"
              :loading="deleteAllLoading"
              text
              class='error white--text'
              :ripple='false'
              append
              @click="deleteAllTokens"
            >
            <v-icon class='mr-1'>mdi-delete</v-icon>
              Delete All
            </v-btn>
          </v-col>
           <v-col cols='12' md='auto'>
            <v-btn
              :block="$vuetify.breakpoint.smAndDown"
              text
              class='u_blue white--text'
              :ripple='false'
              append
              @click="createDialog=true"
            >
            <v-icon class='mr-1'>mdi-plus-circle</v-icon>
              Create API Token
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
   <v-card flat class='px-4 mt-5'>
      <v-data-table
        :headers="tableHeaders"
        :items="tokens"
        :loading="loading"
      >
        <template v-slot:item.token="{ item }">
          <div
            class='breakword'
          >
            {{item.token}}
          </div>
        </template>
        <template v-slot:item.copy="{ item }">
          <v-btn
            text
            :ripple="true"
            class="primary white--text mt-n1"
            @click="copyToClipboard(item)"
          >
            <v-icon>mdi-content-copy</v-icon>
            <u>Copy</u>
          </v-btn>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn
            block
            :ripple="false"
            text
            class='error white--text'
            :loading="item.loading"
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.expired_on="{ item }">
          {{beautifyDate(item.expired_on)}}
        </template>
        <template v-slot:no-data>
          You do not have any API Tokens yet. Use the above Create button to get started.
        </template>
      </v-data-table>
    </v-card>
    <CreateToken
      v-model="createDialog"
    >
    </CreateToken>
    <v-snackbar
      top
      color='primary'
      v-model="copiedSnackbar"
      :timeout="2000"
    >
      Copied!
      <v-btn
        text
        :ripple=false
        @click="copiedSnackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </span>
</template>

<style scoped>
.breakword {
  word-break: break-word;
}
</style>

<script>
import CreateToken from './CreateToken'
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    deleteAllLoading: false,
    copiedSnackbar: false,
    createDialog: false,
    apiKey: '',
    secret: '',
    tableHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Token', value: 'token' },
      { text: 'Expires On', value: 'expired_on' },
      { text: 'Copy', value: 'copy', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters({
      loading: 'tokens/isLoading',
      tokens: 'tokens/getTokens'
    })
  },
  methods: {
    ...mapActions({
      requestTokens: 'tokens/requestTokens',
      deleteToken: 'tokens/deleteToken',
      deleteAll: 'tokens/deleteAll'
    }),

    deleteItem (item) {
      this.$set(item, 'loading', true)
      this.deleteToken(item)
    },

    deleteAllTokens () {
      this.deleteAllLoading = true
      var that = this
      this.deleteAll().then(function () {
        that.deleteAllLoading = false
      }).catch(function (error) {
        that.deleteAllLoading = false
        console.error(error.message)
      })
    },

    copyToClipboard: function (item) {
      this.copiedSnackbar = false
      const el = document.createElement('textarea')
      el.value = item.token
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$nextTick(function () {
        this.copiedSnackbar = true
      })
    },

    beautifyDate: (unformatted) => {
      const date = new Date(unformatted)
      return date.toLocaleString()
    }
  },
  created () {
    this.requestTokens()
  },
  components: {
    CreateToken
  }
}
</script>
