<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <List
      name='Number Lease History'
      :current-module="'tnlease'"
      :search-endpoint="'/history/search'"
      :has-add="false"
      :use-search="true"
      :headers="headers"
      :has-created="true"
      :has-filtration="true"
      :has-export="true"
    >
      <template v-slot:filtration="{ item }">
        <v-row>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Status"
              v-model="item.status"
              :value="''"
              clearable
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="User"
              v-model="item.user"
              :value="''"
              clearable
            >
            </o-text-field>
          </v-col>
        </v-row>
      </template>

    </List>
  </v-card>
  <OptionsDialog
    v-model="dialog"
    :number="dialogNumber"
  >
  </OptionsDialog>
  <v-snackbar
    top
    v-model="savedLease"
    color='success'
    :timeout="2000"
  >
    Saved!
  </v-snackbar>
</span>
</template>

<script>
import axios from 'axios'
import SearchForm from '@/components/SearchForm'
import NumberFeatures from '@/components/NumberFeatures'
import OptionsDialog from './VoiceOptions/OptionsDialog'
import { mapActions, mapGetters } from 'vuex'
import List from '../../components/CRUD/List'
import BeautifyNumber from '../../services/beautify/number.service'
import NumberManageSIP from './NumberManageSIP'
import NumberMessageProfiles from './NumberMessageProfiles'

export default {
  data: () => ({
    currentModule: '',
    savedLease: false,
    dialog: false,
    dialogNumber: '',
    headers: [
      { text: 'Number', value: 'tn.tn', sortable: false },
      // { text: 'User', value: 'account.name' },
      { text: 'Purchased On', value: 'created_on' },
      { text: 'Released On', value: 'expired_on', sortable: false },
      // { text: 'SMS Feature', value: 'feature', sortable: false },
      { text: 'Cost', value: 'cost', sortable: false },
      { text: 'Status', value: 'tn_order.state', sortable: false }
    ],
    tableOptions: {
      page: 1,
      itemsPerPage: 10
    },
    getCount: false,
    list: null
  }),
  computed: {
    ...mapGetters({
      sipControlApps: 'callControl/list',
      sipConnections: 'sipConnections/list',
      scaLoading: 'callControl/loading',
      scLoading: 'sipConnections/loading'
    }),
    count () {
      return this.$store.getters[`${this.currentModule}/count`]
    }
  },
  watch: {
    count (val) {
      if (val) {
        this.getLists()
      }
    }
  },
  methods: {
    ...mapActions({
      requestSCA: 'callControl/requestList',
      requestSC: 'sipConnections/requestList'
    }),
    patchProfile (item, value) {
      this.savedLease = false
      this.patchNumbersLease({ uuid: item.uuid, profile: value }).then(res => {
        if (res) {
          this.savedLease = true
        } else {
          alert(this.errorMessage)
        }
      })
    },
    getLists () {
      axios
        .post('rest/lease/tn/search', { take: this.count })
        .then(res => {
          this.list = res.data.payload.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    exportToCsv () {
      this.getLists()
      const csvContent = 'data:text/csv;charset=utf-8,' +
        this.list.map(e => {
          return e.tn.tn
        }).join('\n')

      var link = document.createElement('a')
      link.href = csvContent
      link.download = 'my_numbers.csv'
      link.click()
    },
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  components: {
    NumberMessageProfiles,
    SearchForm,
    OptionsDialog,
    NumberFeatures,
    List,
    NumberManageSIP
  },
  created () {
    this.requestSCA()
    this.requestSC()
  }
}
</script>

<style scoped>
.numbers {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  align-items: center;
  min-width: 120px;
}
</style>
<style>
.v-autocomplete.v-select.v-input--is-focused input {
  min-width: unset !important;
}
</style>
