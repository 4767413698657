<template>
  <Details
    current-module="ttsSettings"
    @started="onStarted"
  >
    <template v-slot="{ item }">
      <v-form ref='form'>
        <v-row>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Name"
              v-model="item.name"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Engine"
              v-model="item.engine"
              :rules="[notEmpty]"
              :hide-details="false"
              :disabled="true"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="API Key"
              v-model="item.api_key"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-text-field>
          </v-col>
       </v-row>
      </v-form>
    </template>

  </Details>
</template>

<script>
import Details from '../../components/CRUD/Details'
import { mapMutations, mapGetters } from 'vuex'

export default {
  data: () => ({
    notEmpty: (val) => !!val || 'Required'
  }),
  computed: {
    ...mapGetters({
      current: 'ttsSettings/current'
    })
  },
  methods: {
    ...mapMutations({
      updateCurrent: 'ttsSettings/updateCurrent'
    }),
    onStarted () {
      this.updateCurrent({ engine: 'google', ...this.current })
    }
  },
  components: {
    Details
  }
}
</script>
