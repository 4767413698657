<template>
  <v-container class='pa-0'>
    <v-row>
      <v-col cols='12' md='6'>
        <v-card flat class='my-5 mr-5 pa-5'>
          <v-row>
            <v-col cols='auto'>
              <h1>Level 1 Verification</h1>
            </v-col>
              <v-col cols='auto'>
                  <v-btn
                          v-if="+currentItemLevel[currentItemLevel.length-1] >= 0"
                          outlined
                          :ripple='false'
                          color="green"
                  >
                      Verified
                  </v-btn>
                  <v-btn
                          v-else
                          text
                          :ripple="false"
                          class="error white-text"
                  >
                      Unverified
                  </v-btn>
              </v-col>
          </v-row>
          <v-row>
            <v-col cols='4' sm='2'>
              <v-icon color="green" size='4rem'>mdi-phone-outline</v-icon>
            </v-col>
            <v-col cols='8' sm='4' class='pa-0' align-self='center'>
              <span class='local_big ml-2'>Voice</span>
            </v-col>
            <v-col cols='4' sm='2'>
              <v-icon color="green" size='4rem'>mdi-message-processing-outline</v-icon>
            </v-col>
            <v-col cols='8' sm='4' class='pa-0' align-self='center'>
              <span class='local_big ml-2'>SMS</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12'>
              <p>With Level 1 verification, you can:</p>
              <ul>
                <li class='pb-2'>
                  Buy a number
                </li>
                <li class='pb-2'>
                  Assign a connection/messaging profile to a number
                </li>
                <li class='pb-2'>
                  Set up global messaging capabilities
                </li>
                <li class='pb-2'>
                  Set up voice capabilities in the US
                </li>
                <li class='pb-2'>
                  Create a multi-user organization
                </li>
              </ul>
<!--              <v-col cols='12' md='12' class="item-title">-->
<!--                <h5>Email verification</h5>-->
<!--                <o-text-field-->
<!--                  v-model="currentItem.email"-->
<!--                  hide-details-->
<!--                >-->
<!--                </o-text-field>-->
<!--              </v-col>-->
            </v-col>
          </v-row>
<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <v-btn-->
<!--                            text-->
<!--                            block-->
<!--                            class='primary white&#45;&#45;text mr-n3 no-left-border-radius'-->
<!--                            :ripple='false'-->
<!--                            height='40px'-->
<!--                            :loading="saving"-->
<!--                    >-->
<!--                        Request Level 1 Verification-->
<!--                    </v-btn>-->
<!--                </v-col>-->
<!--            </v-row>-->
        </v-card>
      </v-col>
      <v-col cols='12' md='6'>
        <v-card flat class='my-5 mr-5 pa-5'>
          <v-row>
            <v-col cols='auto'>
              <h1>Level 2 Verification</h1>
            </v-col>
            <v-col cols='auto'>
              <v-btn
                v-if="+currentItemLevel[currentItemLevel.length-1] >= 1"
                outlined
                :ripple='false'
                color="green"
              >
                Verified
              </v-btn>
              <v-btn
                v-else
                text
                :ripple="false"
                class="error white-text"
              >
                Unverified
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='4' sm='2'>
              <v-icon color="green" size='4rem'>mdi-earth</v-icon>
            </v-col>
            <v-col cols='8' sm='4' class='pa-0' align-self='center'>
              <span class='local_big ml-2'>International</span>
            </v-col>
            <v-col cols='4' sm='2'>
              <v-icon color="green" size='4rem'>mdi-phone-outgoing-outline</v-icon>
            </v-col>
            <v-col cols='8' sm='4' class='pa-0' align-self='center'>
              <span class='local_big ml-2'>Call Forwarding</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12'>
              <p>With Level 2 verification, you can:</p>
              <ul>
                <li class='pb-2'>
                  Set up voice capabilities outside of the US
                </li>
                <li class='pb-2'>
                  Set up call forwarding
                </li>
                <li class='pb-2'>
                  Send messages from your account at a higher rate
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-form
            ref='form1'
            v-if="!(+currentItemLevel[currentItemLevel.length-1] >= 1)"
          >
            <v-row>
              <v-col cols='12' md='12'>
                <h5>Contact First Name<b class="red-text">*</b></h5>
                <o-text-field
                  v-model="level1.contact_first_name"
                  :hide-details="false"
                  :rules="[notEmpty, moreThanThree, moreThan48]"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Last Name<b class="red-text">*</b></h5>
                <o-text-field
                  v-model="level1.contact_last_name"
                  :hide-details="false"
                  :rules="[notEmpty, moreThanThree, moreThan48]"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Contact Email<b class="red-text">*</b></h5>
                <o-text-field
                  v-model="level1.contact_email"
                  autocomplete='email'
                  type="email"
                  :rules="[notEmpty,validEmail, moreThanSix, moreThan100]"
                  :hide-details="false"
                  validate-on-blur
                ></o-text-field>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Contact Phone<b class="red-text">*</b></h5>
                <o-tel-input
                  v-model="level1.contact_phone"
                  :hide-details="false"
                  :rules="[notEmpty]"
                  autocomplete='tel'
                >
                </o-tel-input>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Company Name<b class="red-text">*</b></h5>
                <o-text-field
                  placeholder="Name"
                  v-model="level1.company_name"
                  :rules="[notEmpty, moreThanThree, moreThan48]"
                  :hide-details="false"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Company Country<b class="red-text">*</b></h5>
                <country-code-select
                  v-model="level1.company_country"
                  :rules="[notEmpty]"
                  :hide-details="false"
                  :useLabel="false">
                </country-code-select>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Company State<b class="red-text">*</b></h5>
                <o-text-field
                  v-model="level1.company_state"
                  placeholder="NY"
                  :rules="[notEmpty, moreThanTwo, moreThan48]"
                  :hide-details="false"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Company City<b class="red-text">*</b></h5>
                <o-text-field
                  v-model="level1.company_city"
                  placeholder="New York"
                  :rules="[notEmpty, moreThanThree, moreThan48]"
                  :hide-details="false"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Company Phone<b class="red-text">*</b></h5>
                <o-tel-input
                  v-model="level1.company_phone_number"
                  :rules="[notEmpty]"
                  :hide-details="false"
                  autocomplete='tel'
                >
                </o-tel-input>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Company Address 1<b class="red-text">*</b></h5>
                <o-text-field
                  v-model="level1.company_address1"
                  placeholder="20 W 34th St"
                  :rules="[notEmpty, moreThanFive, moreThan48]"
                  :hide-details="false"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='12'>
                <h5>Company Postal Code<b class="red-text">*</b></h5>
                <o-text-field
                  v-model="level1.company_postal_code"
                  placeholder="10001"
                  :rules="[notEmpty, moreThanThree, moreThan48]"
                  :hide-details="false"
                  validate-on-blur
                >
                </o-text-field>
              </v-col>
            </v-row>
<!--              <v-row>-->
<!--                  <v-col cols='12'>-->
<!--                      <h5>Search for address</h5>-->
<!--                      <o-text-field-->
<!--                              v-model="companyName"-->
<!--                              placeholder="Enter a location"-->
<!--                      >-->
<!--                      </o-text-field>-->
<!--                      <div class="span_search_address">-->
<!--                          <span>-Or enter it manually-</span>-->
<!--                      </div>-->
<!--                  </v-col>-->
<!--              </v-row>-->
<!--            <v-row>-->
<!--            <v-row>-->
<!--              <v-col cols='12' md='12'>-->
<!--                  <h5>Address<b class="red-text">*</b></h5>-->
<!--                <o-text-field-->
<!--                  v-model="address"-->
<!--                  placeholder="20 W 34th St"-->
<!--                  :rules="[moreThanThree]"-->
<!--                >-->
<!--                </o-text-field>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--              <v-row>-->
<!--              <v-col cols='12' md='12'>-->
<!--                  <h5>Extended Address<b class="red-text">*</b></h5>-->
<!--                  <o-text-field-->
<!--                          v-model="address"-->
<!--                          :rules="[moreThanThree]"-->
<!--                  >-->
<!--                  </o-text-field>-->
<!--              </v-col>-->
<!--          </v-row>-->
<!--              <v-row>-->
<!--                  <v-col cols='12' md='12'>-->
<!--                      <o-text-field-->
<!--                              v-model="numberEIN"-->
<!--                              input-label='EIN number'-->
<!--                      >-->
<!--                      </o-text-field>-->
<!--                  </v-col>-->
<!--              </v-row>-->
<!--              <v-row>-->
<!--                  <v-col cols='12' md='12'>-->
<!--                      <o-file-input-->
<!--                              input-label="Corporation Registartion"-->
<!--                              v-model='corpFile'-->
<!--                      >-->
<!--                      </o-file-input>-->
<!--                  </v-col>-->
<!--              </v-row>-->
            <v-row>
              <v-col>
                <v-btn
                  text
                  block
                  class='primary white--text mr-n3 no-left-border-radius'
                  :ripple='false'
                  height='40px'
                  @click='verify("level1",level1,"form1")'
                  :loading="saving"
                >
                  Request Level 2 Verification
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
        <v-col cols='12' md='6'>
            <v-card flat class='my-5 mr-5 pa-5'>
                <v-row>
                    <v-col cols='auto'>
                        <h1>Level 3 Verification</h1>
                    </v-col>
                    <v-col cols='auto'>
                        <v-btn
                          v-if="+currentItemLevel[currentItemLevel.length-1] >= 2"
                                outlined
                                :ripple='false'
                                color="green"
                        >
                            Verified
                        </v-btn>
                        <v-btn
                                v-else
                                text
                                :ripple="false"
                                class="error white-text"
                        >
                            Unverified
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols='4' sm='2'>
                        <v-icon color="green" size='4rem'>mdi-phone-outline</v-icon>
                    </v-col>
                    <v-col cols='8' sm='4' class='pa-0' align-self='center'>
                        <span class='local_big ml-2'>Voice</span>
                    </v-col>
                    <v-col cols='4' sm='2'>
                        <v-icon color="green" size='4rem'>mdi-message-processing-outline</v-icon>
                    </v-col>
                    <v-col cols='8' sm='4' class='pa-0' align-self='center'>
                        <span class='local_big ml-2'>SMS</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols='12'>
                        <p>With Level 1 verification, you can:</p>
                        <ul>
                            <li class='pb-2'>
                                Buy a number
                            </li>
                            <li class='pb-2'>
                                Assign a connection/messaging profile to a number
                            </li>
                            <li class='pb-2'>
                                Set up global messaging capabilities
                            </li>
                            <li class='pb-2'>
                                Set up voice capabilities in the US
                            </li>
                            <li class='pb-2'>
                                Create a multi-user organization
                            </li>
                        </ul>
                        <div class="item-title">
                            <h5>Email verification</h5>
                            <o-text-field
                                    v-model="currentItem.email"
                                    hide-details
                            >
                            </o-text-field>
                        </div>
                    </v-col>
                </v-row>
              <v-form
                ref='form2'
                v-if="!(+currentItemLevel[currentItemLevel.length-1] >= 2)"
              >
                    <v-row>
                      <v-col cols='12' md='12'>
                        <h5>Company Name<b class="red-text">*</b></h5>
                        <o-text-field
                          placeholder="Name"
                          v-model="level2.company_name"
                          :hide-details="false"
                          :rules="[notEmpty, moreThanThree, moreThan48]"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Contact First Name<b class="red-text">*</b></h5>
                        <o-text-field
                          placeholder="Name"
                          v-model="level2.contact_first_name"
                          :hide-details="false"
                          :rules="[notEmpty, moreThanThree, moreThan48]"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Contact Last Name<b class="red-text">*</b></h5>
                        <o-text-field
                          placeholder="Name"
                          v-model="level2.contact_last_name"
                          :hide-details="false"
                          :rules="[notEmpty, moreThanThree, moreThan48]"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Contact Email<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.contact_email"
                          autocomplete='email'
                          type="email"
                          :rules="[notEmpty,validEmail, moreThanSix, moreThan100]"
                          :hide-details="false"
                          validate-on-blur
                        ></o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Contact Phone<b class="red-text">*</b></h5>
                        <o-tel-input
                          v-model="level2.contact_phone"
                          :rules="[notEmpty]"
                          :hide-details="false"
                          autocomplete='tel'
                        >
                        </o-tel-input>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company Address 1<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.company_address1"
                          placeholder="20 W 34th St"
                          :rules="[notEmpty, moreThanFive, moreThan48]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company Country<b class="red-text">*</b></h5>
                        <country-code-select v-model="level2.company_country" :useLabel="false">
                        </country-code-select>
                      </v-col>
                      <v-col cols='12' md='12' >
                        <h5>Company ZIP Code<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.company_postal_code"
                          placeholder="10001"
                          :rules="[notEmpty,moreThanThree, moreThan24]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company State<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.company_state"
                          placeholder="NY"
                          :rules="[notEmpty,moreThanTwo, moreThan48]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company City<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.company_city"
                          placeholder="New York"
                          :rules="[notEmpty,moreThanThree, moreThan48]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company Phone<b class="red-text">*</b></h5>
                        <o-tel-input
                          v-model="level2.company_phone_number"
                          :rules="[notEmpty]"
                          :hide-details="false"
                          autocomplete='tel'
                        >
                        </o-tel-input>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company EIN<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.company_ein"
                          @keypress.native="alphaOnly($event)"
                          :rules="[notEmpty, moreThan100000000, lessThan999999999]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company US FCC 966<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.company_us_fcc_966_id"
                          @keypress.native="alphaOnly($event)"
                          :rules="[notEmpty, moreThan100000000, lessThan999999999]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Company FRN Number<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.company_frn_number"
                          @keypress.native="alphaOnly($event)"
                          :rules="[notEmpty, moreThan100000000, lessThan999999999]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                      <v-col cols='12' md='12'>
                        <h5>Meeting Contact<b class="red-text">*</b></h5>
                        <o-text-field
                          v-model="level2.meeting_contact"
                          :rules="[notEmpty,moreThanThree, moreThan255]"
                          :hide-details="false"
                        >
                        </o-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                    text
                                    block
                                    class='primary white--text mr-n3 no-left-border-radius'
                                    :ripple='false'
                                    height='40px'
                                    @click='verify("level2",level2,"form2")'
                                    :loading="saving"
                            >
                                Request Level 3 Verification
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.local_big {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
}
.item-title{
  padding-top: 100px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import CountryCodeSelect from '../../components/CountryCodeSelect'
import axios from 'axios'

export default {
  components: { CountryCodeSelect },
  data: () => ({
    notEmpty: val => !!val || 'Required',
    onlyNumber: val => !isNaN(val) || 'Only Number',
    moreThanTwo: val => {
      return (!val) || (val.length >= 2) || 'At least 2 characters are required'
    },
    moreThanThree: val => {
      return (!val) || (val.length >= 3) || 'At least 3 characters are required'
    },
    moreThanFive: val => {
      return (!val) || (val.length >= 5) || 'At least 5 characters are required'
    },
    moreThanSix: val => {
      return (!val) || (val.length >= 6) || 'At least 6 characters are required'
    },
    moreThanNine: val => {
      return (!val) || (val.length >= 9) || 'At least 9 characters are required'
    },
    moreThan100000000: val => {
      return (!val) || (Number(val) >= 100000000) || 'At least 100000000 or more'
    },
    lessThan999999999: val => {
      return (!val) || (Number(val) <= 999999999) || 'At least 999999999 or less'
    },
    moreThan24: val => (val && val.length <= 24) || 'Max 24 characters',
    moreThan48: val => (val && val.length <= 48) || 'Max 48 characters',
    moreThan100: val => (val && val.length <= 100) || 'Max 100 characters',
    moreThan255: val => (val && val.length <= 255) || 'Max 255 characters',
    validEmail: val => {
      return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) || 'Email is not valid'
    },
    data: {},
    l1_verified: true,
    l2_verified: false,
    currentItem: {},
    companyName: '',
    phoneNumber: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    address: '',
    numberEIN: '',
    corpFile: null,
    version3: {},
    level2: {
      company_phone_number: ''
    },
    level1: {},
    level3: {
      company_phone_number: ''
    },
    stateOptions3: []
  }),
  computed: {
    ...mapGetters('user', [
      'first_name',
      'last_name',
      'phone_number',
      'email',
      'saving',
      'userData'
    ]),
    ...mapGetters({
      statesLoading: 'dict/states/loading',
      stateOptions: 'dict/states/list',
      errorMessage: 'ticket/errorMessage',
      statesErrorMessage: 'dict/states/errorMessage'
    }),
    currentItemLevel () {
      return this.currentItem.account ? this.currentItem.account.level : ''
    }
  },
  watch: {
    userData: {
      deep: true,
      handler (val) {
        this.currentItem = JSON.parse(JSON.stringify(val))
      }
    },
    'level2.country' (val) {
      if (val) {
        this.requestStates(`?country=${val.code}`).then(res => {
          if (!res) {
            alert(this.statesErrorMessage)
          } else {
            console.log(res)
          }
        })
      }
    },
    'level3.country' (val) {
      if (val) {
        this.requestStates(`?country=${val.code}`).then(res => {
          if (!res) {
            alert(this.statesErrorMessage)
          } else {
            this.stateOptions3 = res
          }
        })
      }
    }
  },
  methods: {
    ...mapActions({
      create: 'ticket/create',
      requestStates: 'dict/states/requestList',
      notify: 'notification/notify'
    }),
    alphaOnly (e) {
      if (!/^[0-9]+$/.test(e.key)) {
        e.preventDefault()
      }
    },
    verify (level, item, form) {
      if (!item.company_phone_number) {
        this.notify({ text: 'Company Phone required', type: 'error' })
        return
      }
      if (!item.contact_phone) {
        this.notify({ text: 'Contact Phone required', type: 'error' })
        return
      }
      if (this.$refs[form].validate()) {
        const companyPhoneNumber = item.company_phone_number.includes('(') ? item.company_phone_number.slice(1, 4) + '' + item.company_phone_number.slice(6) : item.company_phone_number
        const contactPhone = item.contact_phone.includes('(') ? item.contact_phone.slice(1, 4) + '' + item.contact_phone.slice(6) : item.contact_phone
        const obj = {
          ...item,
          contact_phone: contactPhone,
          company_phone_number: companyPhoneNumber,
          state: 'draft',
          type: 'TicketAccountLevel',
          level: level === 'level1' ? 'Level1' : 'Level2'
        }
        if (level === 'level2') {
          obj.meeting_time = new Date()
        }
        if (item.company_country) {
          obj.company_country = item.company_country.code
        }
        axios
          .post('rest/ticket/account_level/' + level, obj)
          .then(res => {
            this.notify({ text: 'Successfully saved ', type: 'success' })
          })
          .catch((err) => {
            console.log(err)
            this.notify({ text: `${Object.keys(err.response.data.fields)[0]}: ${Object.values(err.response.data.fields)[0].message}`, type: 'error' })
          })
      }
      // let companyPhoneNumber = ''
      // if (item.company_phone_number) {
      //   companyPhoneNumber = item.company_phone_number.includes('(') ? item.company_phone_number.slice(1, 4) + '' + item.company_phone_number.slice(6) : item.company_phone_number
      // }
      // const obj = {
      //   level,
      //   company_name: item.company_name,
      //   company_city: item.company_city,
      //   company_phone_number: companyPhoneNumber,
      //   company_address1: item.company_address1,
      //   company_postal_code: item.company_postal_code,
      //   type: 'TicketAccountLevel',
      //   subject: 'Ticket subject',
      //   description: 'Ticket Description',
      //   doNotRedirect: true
      // }
      // this.create(obj).then(res => {
      //   if (!res) {
      //     alert(this.errorMessage)
      //   } else {
      //     this.notify({ text: 'Successfully saved ', type: 'success' })
      //   }
      // })
    }
  },
  mounted () {
    this.currentItem = JSON.parse(JSON.stringify(this.userData))
  }
}
</script>
<style scoped>
    .span_search_address{
        text-align: center;
        color: #9f9f9f;
        font-size: 12px;
    }
</style>
