import ApiService from './api.service'

export default class {
  constructor (apiLink) {
    this.apiLink = apiLink
  }

  getAPILink () {
    return this.apiLink
  }

  prepareBeginTime (val) {
    if (!val) {
      return undefined
    }

    return val
  }

  prepareEndTime (val) {
    if (!val) {
      return undefined
    }

    return val
  }

  buildSearchRequest (searchParams) {
    const filteredSearchParams = { ...searchParams }
    // eslint-disable-next-line no-prototype-builtins
    if (searchParams.hasOwnProperty('endpoint')) {
      delete filteredSearchParams.endpoint
    }
    let skip = filteredSearchParams.skip
    if (!skip) {
      if (filteredSearchParams.perPage && filteredSearchParams.page) {
        skip = filteredSearchParams.perPage * (filteredSearchParams.page - 1)
      }
    }

    return {
      skip,
      take: filteredSearchParams.take ?? filteredSearchParams.perPage,
      ...filteredSearchParams,
      perPage: undefined,
      page: undefined,
      created_on_from: this.prepareBeginTime(filteredSearchParams.created_on_from),
      created_on_to: this.prepareEndTime(filteredSearchParams.created_on_to),
      modified_on_from: this.prepareBeginTime(filteredSearchParams.modified_on_from),
      modified_on_to: this.prepareEndTime(filteredSearchParams.modified_on_to)
    }
  }

  async search (searchParams) {
    const request = this.buildSearchRequest(searchParams)
    let endpoint = null
    // eslint-disable-next-line no-prototype-builtins
    if (searchParams.hasOwnProperty('endpoint') && searchParams.endpoint) {
      endpoint = searchParams.endpoint
      delete request.tn
    }
    try {
      const response = await ApiService.post(this.apiLink + `${endpoint || '/search'}`, request)

      return response.data.payload
    } catch (error) {
      throw new Error(this.prepareError(error.response.data))
    }
  }

  async requestSingle () {
    try {
      const response = await ApiService.get(this.apiLink)

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message ?? error.response.statusText)
    }
  }

  async requestList () {
    try {
      const response = await ApiService.get(this.apiLink)

      const that = this
      return response.data.payload.map(el => that.parseResponse(el))
    } catch (error) {
      throw new Error(error.response.data.message ?? error.response.statusText)
    }
  }

  async requestOne (keyValue) {
    try {
      const response = await ApiService.get(this.buildApiLink(keyValue))

      return this.parseResponse(response.data.payload)
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }

  async create (item) {
    const request = this.buildCreateRequest(item)
    try {
      const response = await ApiService.post(this.apiLink, request)
      return this.parseResponse(response.data.payload)
    } catch (error) {
      throw new Error(this.prepareError(error.response.data))
    }
  }

  async patch (keyValue, item) {
    const request = this.buildPatchRequest(item)
    try {
      const response = await ApiService.patch(this.buildApiLink(keyValue), request)

      return this.parseResponse(response.data.payload)
    } catch (error) {
      throw new Error(this.prepareError(error.response.data))
    }
  }

  async patchSingle (item) {
    const request = this.buildPatchRequest(item)
    if (item.altBusinessIdType) {
      request.altBusinessIdType = item.altBusinessIdType
    }
    if (item.altBusinessId) {
      request.altBusinessId = item.altBusinessId
    }
    try {
      const response = await ApiService.patch(this.apiLink, request)

      return this.parseResponse(response.data.payload)
    } catch (error) {
      throw new Error(this.prepareError(error.response.data))
    }
  }

  async delete (keyValue) {
    try {
      const response = await ApiService.delete(this.buildApiLink(keyValue))

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }

  async exportCSV (params) {
    const request = this.buildSearchRequest(params)

    try {
      const response = await ApiService.post(this.apiLink + '/export', request)

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }

  buildApiLink (keyValue) {
    return `${this.apiLink}/${keyValue ?? ''}`
  }

  buildRequest (item) {
    item.identificator = undefined
    item.uuid = undefined
    item.created_on = undefined
    item.modified_on = undefined
    item.modified_by = undefined
    item.loaded = undefined
    item.loading = undefined
    return item
  }

  buildCreateRequest (item) {
    return this.buildRequest(item)
  }

  buildPatchRequest (item) {
    return this.buildRequest(item)
  }

  parseResponse (item) {
    return item
  }

  prepareError (errItem) {
    let message = 'Error:'
    if (errItem.fields) {
      for (const field in errItem.fields) {
        message += ';\n ' + field + ': ' +
          errItem.fields[field].message
      }
    } else if (errItem.message) {
      message += ' ' + errItem.message
    }

    return message
  }
}
