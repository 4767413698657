<template>
  <v-dialog
    v-model="localValue"
    width='600'
  >
    <v-card class='pa-5'>
      <v-container>
        <v-form
          ref='form'
        >
          <v-row class='px-4'>
            <v-col cols='auto'>
              <h2 v-if="isNew">Create Invite</h2>
              <h2 v-else>Modify Invite</h2>
            </v-col>
            <v-spacer/>
            <v-col cols='auto'>
              <v-btn
                text
                class='pa-0'
                dense
                :min-width="0"
                width=25
                :ripple="false"
                @click="localValue=false"
              >
                <v-icon>
                  mdi-close-thick
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12' md='12' class="pb-0">
              <o-text-field
                v-model="currentItem.name"
                input-label='Email'
                :hide-details="false"
                :condition-style="'margin-bottom: 0 !important'"
              >
              </o-text-field>
            </v-col>
<!--            <v-col cols='12' md='6'>-->
<!--              <o-date-picker-->
<!--                input-label='Expiration Date'-->
<!--                v-model="currentItem.expired_on"-->
<!--              >-->
<!--              </o-date-picker>-->
<!--            </v-col>-->
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-btn
                text
                block
                class='primary white--text mr-n3 no-left-border-radius'
                :ripple='false'
                height='40px'
                @click='createOrSave'
                :loading="saving"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import moment from 'moment'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    connectionUUID: null,
    uuid: null
  },
  computed: {
    ...mapGetters({
      list: 'sipInvites/list',
      errorMessage: 'sipInvites/errorMessage',
      saving: 'sipInvites/saving',
      currentItem: 'sipInvites/current'
    }),
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  data: () => ({
    notEmpty: val => !!val || 'Required',
    moreThanSix: val => {
      return (!val) || (val.length >= 6) || 'More than 6 chars are required'
    }
  }),
  methods: {
    ...mapActions({
      requestOne: 'sipInvites/requestOne',
      create: 'sipInvites/create',
      patch: 'sipInvites/patch',
      notify: 'notification/notify'
    }),
    ...mapMutations({
      clearCurrent: 'sipInvites/clearCurrent',
      setCurrent: 'sipInvites/setCurrent',
      setDefault: 'sipInvites/setDefault',
      setCurrentValue: 'sipInvites/setCurrentValue'
    }),
    onStart () {
      const d = new Date()
      d.setDate(d.getDate() - 7)
      // console.log(d)
      const ExpiredOn = {
        name: ''
        // expired_on: moment(new Date(d)).format('YYYY-MM-DD')
      }
      this.clearCurrent()
      if (!this.isNew) {
        if (this.list && this.list.length) {
          this.$nextTick(function () {
            this.setCurrent(this.uuid)
          })
        }
      } else {
        this.setCurrentValue(ExpiredOn)
      }
    },
    createOrSave () {
      if (this.$refs.form.validate()) {
        this.currentItem.sip_connection_uuid = this.connectionUUID
        if (this.isNew) {
          this.create().then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.notify({ text: 'Created successfully', type: 'success' })
              this.localValue = false
            }
          })
        } else {
          this.patch(this.currentItem).then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.notify({ text: 'Saved successfully', type: 'success' })
              this.localValue = false
            }
          })
        }
      }
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.onStart()
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  },
  created () {
    this.setDefault()
  }
}
</script>
