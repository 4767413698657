<template>
<span>
  <v-row justify='center'>
    <v-col cols='auto' class='pb-0'>
      <h1>OPENTACT</h1>
    </v-col>
  </v-row>
  <v-row justify='center'>
    <v-col cols='auto' class='pt-0'>
      <h4>Register a New Sip User</h4>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <o-text-field
              v-model="email"
              input-label='Email address'
              :errorMessages="emailErrors"
              autocomplete='email'
              type="email"
              :rules="[validEmail]"
              :hide-details="false"
              validate-on-blur
      >
      </o-text-field>
    </v-col>
  </v-row>
   <v-row>
            <v-col cols='12'>
              <o-text-field
                v-model="login"
                input-label='Login'
                :rules="[moreThanThree]"
                :hide-details="false"
              >
              </o-text-field>
            </v-col>
   </v-row>
  <v-form
          ref='password'
  >
    <UpdatePassword
            v-model="password"
    >
    </UpdatePassword>
  </v-form>
  <v-row>
    <v-col cols='12' class='pb-6'>
      <o-tel-input
              v-model="phoneNumber"
              input-label='Phone Number'
              autocomplete='tel'
      >
      </o-tel-input>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <o-text-field
              v-model="firstName"
              input-label='First Name'
              autocomplete='given-name'
      >
      </o-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <o-text-field
              v-model="lastName"
              input-label='Last Name'
              autocomplete='family-name'
      >
      </o-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
       <o-select
               input-label='Gender'
               v-model="gender"
               :items="genderOptions"
               hide-details
       >
       </o-select>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='12' class='pb-0'>
      <v-btn
              text
              :ripple="false"
              block
              class="primary white--text"
              @click='handleSubmit'
      >
        Register
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify='center'>
    <v-col cols='auto'>
      Already have an account? <router-link to='login'>Log In Here</router-link>
    </v-col>
  </v-row>
</span>
</template>

<script>
import { mapActions } from 'vuex'
import UpdatePassword from './UpdatePassword'
import Recaptcha from '../Recaptcha'
import axios from 'axios'

export default {
  data: () => ({
    moreThanThree: val => {
      return (!val) || (val.length >= 3) || 'At least 3 characters are required'
    },
    email: '',
    login: '',
    emailErrors: [],
    password: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    // token: '',
    gender: '',
    genderOptions: [
      { text: 'Male', value: 'Male' },
      { text: 'Female', value: 'Female' }
    ]
  }),
  props: ['token'],
  methods: {
    ...mapActions({
      notify: 'notification/notify'
    }),
    handleSubmit () {
      const params = {
        login: this.login,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone_number: this.number,
        gender: this.gender
      }

      this.submitToken(params)
    },
    submitToken: async function (params) {
      axios
        .post(`rest/app/invite/${this.token}`, params)
        .then(res => {
          this.notify({ text: 'Registered successfully', type: 'success' })
        }).catch((e) => {
          console.error(e.response)
          this.notify({ text: e.response.data.message, type: 'error' })
        })
    }
  },
  computed: {
    validToSubmit: function () {
      return this.email !== '' &&
                    (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email)) &&
                    this.password !== '' &&
                    this.firstName !== '' &&
                    this.lastName !== ''
    },
    number: function () {
      const n = this.phoneNumber.replace(/[^\d]/g, '')
      return n && n !== '' && n.length >= 3 ? n : undefined
    },
    validEmail () {
      return val => {
        return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email) || val !== this.email) || 'Email is not valid'
      }
    }
  },
  watch: {
    registrationErrorCode: function (val) {
      if (val === 400) {
        this.$router.push({ name: 'Fail', params: { errorMessage: 'Invalid Data Format' } })
      } else {
        this.$router.push({ name: 'Fail', params: { errorCode: this.registrationErrorCode, error: this.registrationError } })
      }
    },
    registrationErrorFields: function (val) {
      for (const field in val) {
        if (val[field] !== undefined) {
          this[field + 'Errors'].push(val[field].message)
        }
      }
    }
  },
  components: {
    UpdatePassword,
    Recaptcha
  }
}
</script>
