import ItemBaseAPI from '../services/itemBase.service'
import ItemBase from './itemBase'

class SIPCall extends ItemBase {
  actions () {
    const actions = super.actions()

    actions.create = () => { throw new Error('Unsupported methods create for sip call') }
    actions.get = () => { throw new Error('Unsupported methods get for sip call') }

    actions.search = async function ({ commit, getters }, searchParams) {
      commit('request')

      try {
        const items = await getters.API.search(searchParams)
        commit('setCount', items.total)
        commit('set', items.data)

        return true
      } catch (error) {
        commit('failRequest', error)
        return false
      }
    }

    return actions
  }

  getters () {
    const getters = super.getters()

    getters.count = function (state) {
      return state.count
    }

    return getters
  }

  mutations () {
    const mutations = super.mutations()

    mutations.setCount = function state (state, count) {
      state.count = count
    }

    mutations.setAPILink = function (state, API_LINK) {
      state.API = new ItemBaseAPI(API_LINK)
    }

    return mutations
  }

  state () {
    const state = super.state()

    state.count = null

    return state
  }
}

export const sipCall = new SIPCall(new ItemBaseAPI('/rest/sip/app/call')).getModule()
