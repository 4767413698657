<template>
  <Tokens>
  </Tokens>
</template>

<script>
import Tokens from './Tokens.vue'

export default {
  name: 'Auth',
  components: {
    Tokens
  }
}
</script>
