import ItemBase from '../itemBase.service'

class MessagingCampaignAPI extends ItemBase {
  buildPatchRequest (item) {
    return {
      sample1: item.sample1 || '',
      sample2: item.sample2 || '',
      sample3: item.sample3 || '',
      sample4: item.sample4 || '',
      sample5: item.sample5 || '',
      messageFlow: item.messageFlow || '',
      helpMessage: item.helpMessage || '',
      autoRenewal: item.autoRenewal
    }
  }
}

export default new MessagingCampaignAPI('/rest/sms/campaign')
