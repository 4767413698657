<template>
  <v-row justify="start">
    <v-col  cols='10'>
      <o-select
        width='250px'
        :items="sipMessageProfiles"
        :loading="smLoading"
        v-model="selectValue"
        item-text='name'
        item-value='uuid'
        :disabled="edit"
        class='select'
      >
      </o-select>
    </v-col>
    <v-col cols='1' class='pa-0' align-self='center'>
      <v-tooltip
        bottom
        v-if="!edit"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="clickPencil"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
          Save Message Profile
      </v-tooltip>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="save"
            :loading="saving"
          >
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
        </template>
          Save Message Profile
      </v-tooltip>
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<style scoped>
.select >>> .v-input {
  margin-bottom: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    lease: null,
    value: null
  },
  data: () => ({
    currentModule: 'messaging/profiles',
    edit: false,
    items: [],
    sipValue: {
      module: null,
      item: null
    },
    selectValue: ''
  }),
  computed: {
    ...mapGetters({
      scError: 'sipConnections/errorMessage',
      scaError: 'callControl/errorMessage',
      sipMessageProfiles: 'messaging/profiles/list',
      smLoading: 'messaging/profiles/loading'
    }),
    saving () {
      return this.$store.getters[`${this.sipValue.module}/saving`]
    },
    loading () {
      return this.$store.getters['sipConnections/loading'] || this.$store.getters['sipConnections/loading']
    },
    prepareSelection () {
      if (this.sipValue.module === 'sipConnections') {
        return `SCA: ${this.sipValue.item.name}`
      } else if (this.sipValue.module === 'callControl') {
        return `SC: ${this.sipValue.item.name}`
      } else if (this.sipValue.module === 'sip/domain') {
        return `SD: ${this.sipValue.item.name}`
      }

      return undefined
    }
  },
  methods: {
    ...mapActions({
      notify: 'notification/notify',
      requestSM: 'messaging/profiles/requestList'
    }),
    clickPencil () {
      this.edit = true
      this.$emit('changeMessageProfile', this.selectValue)
    },
    choose (item, el) {
      this.sipValue.module = item.value
      this.sipValue.item = el
    },
    list (module) {
      return this.$store.getters[`${module}/list`]
    },
    async save () {
      this.edit = false
      const patchItem = {
        uuid: this.sipValue.item.uuid,
        leases: {
          add: [this.lease.uuid]
        }
      }

      const res = await this.$store.dispatch(`${this.sipValue.module}/patchLeases`, patchItem)
      if (!res) {
        this.notify({ text: this.$store.getters[`${this.sipValue.module}/errorMessage`], type: 'error' })
      } else {
        this.notify({ text: 'Successfully added', type: 'success' })
      }
    },
    updateSipValue (val) {
      if (val.sip_connection) {
        this.sipValue = {
          module: 'sipConnections',
          item: val.sip_connection
        }
      }
      if (val.sip_control_app) {
        this.sipValue = {
          module: 'callControl',
          item: val.sip_control_app
        }
      }
    }
  },
  watch: {
    lease: {
      deep: true,
      handler (val) {
        this.updateSipValue(val)
      }
    },
    value (value) {
      if (value && this.selectValue !== value) {
        this.selectValue = value.uuid
      }
    }
  },
  created () {
    if (this.selectValue !== null && this.selectValue !== this.value && !!this.value) {
      this.selectValue = this.value.uuid
    }
    this.requestSM().then(res => {
      if (!res) {
        this.notify({ text: this.scaError, type: 'error' })
      }
    })
    this.updateSipValue(this.lease)
  }
}
</script>
