<template>
  <span>
    <v-card flat class='my-5 pa-5'>
      <h2>Billing</h2>
      <span>Manage balance, payment method, auto-recharge preferences and access your invoices</span>
    </v-card>
    <v-card v-if="isMakePayment" flat clas='my-5'>
      <MakePayment>
      </MakePayment>
    </v-card>
    <v-card v-else flat class='my-5 pa-0 pa-md-4'>
      <v-container>
        <v-row>
          <v-col>
            <h4>Payment Information</h4>
          </v-col>
        </v-row>
        <v-divider/>
        <v-container>
          <v-row>
            <v-col cols='4' md='3' align-self='center'>
              <b>Current Balance</b>
            </v-col>
            <v-col cols='4' md='3' align-self='center'>
              {{currentBalance}}
            </v-col>
            <v-col cols='4' md='6'>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    append
                    to='make-payment'
                  >
                    <v-icon size='2.5rem' color='primary'>
                      mdi-plus-circle
                    </v-icon>
                  </v-btn>
                </template>
                Make Payment
              </v-tooltip>
            </v-col>
          </v-row>
          <v-divider/>
        </v-container>
      </v-container>
    </v-card>
    <v-card flat class='my-5 pa-0 pa-md-4'>
      <crud-list
        current-module='billing/payment'
        :headers="invoicesHeaders"
        :has-add="false"
        :use-search="true"
        name='Payments'
      >
        <template v-slot:item.state="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="item.state==='success'"
                color='success'
                v-on="on"
              >
                mdi-check-circle
              </v-icon>
              <v-icon
                v-else-if="item.state==='pending'"
                color='warning'
                v-on="on"
              >
                mdi-circle-slice-3
              </v-icon>
              <v-icon
                v-else
                color='error'
                v-on="on"
              >
                mdi-close-circle
              </v-icon>
<!--              <v-icon-->
<!--                v-else-if="item.state==='created'"-->
<!--                color='warning'-->
<!--                v-on="on"-->
<!--              >-->
<!--                mdi-clock-->
<!--              </v-icon>-->
            </template>
            <span>{{item.state}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.reference_id="{ item }">
          <div
            class='breakword'
          >
            {{item.reference_id}}
          </div>
        </template>
        <template v-slot:item.created_on="{ item }">
          {{beautifyDate(item.created_on)}}
        </template>
        <template v-slot:item.amount="{ item }">
          $ {{item.amount}}
        </template>
<!--        <template v-slot:item.retry="{ item }">-->
<!--          <v-btn-->
<!--            v-if="item.state!=='success'"-->
<!--            text-->
<!--            class='primary white&#45;&#45;text'-->
<!--            :ripple='false'-->
<!--            :append="true"-->
<!--            @click="retry(item)"-->
<!--            :loading="item.loading"-->
<!--            :height="40"-->
<!--          >-->
<!--            Retry-->
<!--          </v-btn>-->
<!--        </template>-->
      </crud-list>
    </v-card>
  </span>
</template>

<style scoped>
.breakword {
  word-break: break-word;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import MakePayment from './MakePayment'

export default {
  computed: {
    currentBalance: function () {
      return '$' + this.getBalance + ' (USD)'
    },
    ...mapGetters({
      getBalance: 'user/balance'
    }),
    paymentMethod: function () {
      return 'No saved payment method'
    },
    isMakePayment: function () {
      return !!this.$route.matched.find(el => el.path.includes('make-payment'))
    }
  },
  data: () => ({
    invoicesHeaders: [
      { text: 'State', value: 'state' },
      { text: 'Date', value: 'created_on' },
      { text: 'Amount', value: 'amount' },
      { text: 'Provider', value: 'provider' },
      { text: 'Reference ID', value: 'reference_id' }
      // { text: 'Retry', value: 'retry' }
    ]
  }),
  methods: {
    beautifyDate: (unformatted) => {
      const date = new Date(unformatted)
      return date.toLocaleString()
    },
    ...mapActions({
      retryPayment: 'billing/payment/patch',
      requestOne: 'billing/payment/requestOne'
    }),
    async retry (item) {
      this.$set(item, 'loading', true)
      await this.retryPayment(item)
      await this.requestOne(item.uuid)
      this.$set(item, 'loading', false)
    }
  },
  components: {
    MakePayment
  }
}
</script>
