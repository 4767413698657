import store from '../store'
import { TokenService } from './storage.service'

export default class WSS {
  connect (socketURL = process.env.VUE_APP_WSS_URL) {
    this.ws = new WebSocket(socketURL)
    this.ws.onopen = this.onOpen
    this.ws.onmessage = this.onMessage
    this.ws.onclose = this.onClose
  }

  onOpen () {
    const authRequest = {
      type: 'auth',
      payload: TokenService.getToken()
    }

    this.send(JSON.stringify(authRequest))
  }

  onClose () {
    // TODO
  }

  onMessage ({ data }) {
    try {
      const message = JSON.parse(data)

      switch (message.type) {
        case 'auth':
          store.commit('user/setUserData', { ...message.payload.user, account: message.account })

        // eslint-disable-next-line no-fallthrough
        case 'balance':
          store.commit('user/setAccountBalance', { balance: message.account.balance })
          break

        case 'export':
          if (message.payload.progress === 100) {
            store.dispatch('exportModule/notifyFinished', {
              id: message.payload.id,
              link: message.payload.url
            })
          } else {
            store.dispatch('exportModule/notifyProgress', {
              id: message.payload.id,
              progress: message.payload.progress
            })
          }
          break

        case 'tn_order':
          store.commit('numbersOrders/update', message.payload)
          switch (message.payload.state) {
            case 'failed':
              store.dispatch('notification/notify', {
                text: 'Your numbers order has failed',
                type: 'error'
              })
              break
            case 'success':
              store.dispatch('notification/notify', {
                text: 'Your numbers order has succeeded',
                type: 'success'
              })
              break
            default:
              break
          }
          break

        default:
          break
      }
    } catch (e) {
      console.error(e)
    }
  }
}
