<template>
  <List
    current-module="ttsSettings"
    :headers="headers"
  >
  </List>
</template>

<script>
import List from '../../components/CRUD/List'

export default {
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Engine', value: 'engine' },
      { text: 'Edit', value: 'edit' },
      { text: 'Delete', value: 'delete' }
    ]
  }),
  components: {
    List
  }
}
</script>
