import SIPUsersAPI, { SIPUsersService } from '../services/sipUser.service'
import ItemBase from './itemBase'

class SipUsers extends ItemBase {
  mutations () {
    const mutations = super.mutations()
    mutations.setAPILink = function (state, API_LINK) {
      state.API = new SIPUsersService(API_LINK)
    }

    return mutations
  }

  actions () {
    const actions = super.actions()
    actions.search = async function ({ commit, getters }, searchParams) {
      commit('request')

      try {
        const items = await getters.API.search(searchParams)
        commit('setCount', items.total)
        commit('set', items.data)

        return true
      } catch (error) {
        commit('failRequest', error)
        return false
      }
    }
    return actions
  }
}

export const sipUsers = new SipUsers(SIPUsersAPI).getModule()
