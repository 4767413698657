<template>
  <span>
    <label v-if="hasLabel" class="input-label">
      {{inputLabel}}
      <slot name='input-label'></slot>
    </label>
    <v-text-field
      v-model="localValue"
      v-bind="$attrs"
      v-on="listeners"
      @blur="$emit('blur')"
      :hide-details="hideDetails"
      :style="conditionStyle"
      outlined
      solo
      dense
      flat
      single-line
      :placeholder="placeholder ? placeholder : ''"
    >
      <!-- bypass all slots from a parent -->
      <slot v-for="slot in Object.keys(slots)" :name="slot" :slot="slot"/>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    </v-text-field>
  </span>
</template>

<script>
export default {
  props: {
    value: null,
    conditionStyle: {
      type: String,
      default: ''
    },
    inputLabel: {
      type: String,
      default: ''
    },
    hideDetails: {
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    defaultValue: null
  },
  data: () => ({
    autofilled: false
  }),
  computed: {
    localValue: {
      get () {
        return this.defaultValue ? this.defaultValue : this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    listeners () {
      // we have to remove the 'input' event as we process it ourselves
      return Object.keys(this.$listeners)
        .filter(field => field !== 'input')
        .reduce((obj, key) => {
          obj[key] = this.$listeners[key]
          return obj
        }, {})
    },
    slots () {
      // we have to remove the 'input-label' slot as we process it ourselves
      return Object.keys(this.$slots)
        .filter(field => field !== 'input-label')
        .reduce((obj, key) => {
          obj[key] = this.$slots[key]
          return obj
        }, {})
    },
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    }
  },
  watch: {
    value: () => {
      if (this) {
        this.autofilled = true
      }
    }
  }
}
</script>
