<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon
        v-if="state==='success' || state==='sent'"
        color='success'
        v-on="on"
      >
        mdi-check-circle
      </v-icon>
      <v-icon
        v-else-if="state==='failed'"
        color='error'
        v-on="on"
      >
        mdi-close-circle
      </v-icon>
      <v-icon
        v-else-if="state==='created' || state==='sending'"
        color='warning'
        v-on="on"
      >
        mdi-clock
      </v-icon>
      <v-icon
        v-else
        color='info'
        v-on="on"
      >
        mdi-help-circle
      </v-icon>
    </template>
    <span>{{state}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: 'unknown'
    }
  }
}
</script>
