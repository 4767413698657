<template>
<span>
  <v-form
    ref='form'
    @keyup.native.enter="handleSubmit"
  >
    <v-row justify='center'>
      <v-col cols='auto'>
        <h1>Reset Password</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' class='pb-0'>
        <o-text-field
          v-model="email"
          input-label='Email address'
          :hide-details="false"
          :rules="[notEmpty]"
          autocomplete='email'
        >
        </o-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' class='pb-0'>
        <v-btn
          text
          :ripple="false"
          block
          class="primary white--text"
          @click='handleSubmit'
        >
          Send Reset Email
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' class='pb-0'>
        <v-btn
          text
          :ripple="false"
          block
          class="primary white--text mb-3"
          to="login"
        >
          Back to Login page
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    email: '',
    notEmpty: val => !!val || 'Required'
  }),
  methods: {
    ...mapActions({
      passwordReset: 'auth/passwordReset',
      notify: 'notification/notify'
    }),
    handleSubmit () {
      if (this.$refs.form.validate()) {
        this.passwordReset({ email: this.email }).then(res => {
          if (!res) {
            this.notify({ text: this.resetError, type: 'error' })
          } else {
            this.notify({ text: 'Password Reset Request sent. Check your inbox', type: 'success' })
            this.email = ''
            this.$nextTick(() => {
              this.$refs.form.resetValidation()
            })
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      reseting: 'auth/reseting',
      resetError: 'auth/resetError'
    })
  }
}
</script>
