<template>
  <router-view></router-view>
</template>

<script>
import OutboundProfilesList from './OutboundProfilesList'
import ProfileDetails from './ProfileDetails'
import ManageDomains from '../SIPDomain/ManageDomains'

export default {
  name: 'OutboundVoiceProfiles',
  displayName: 'Outbound Voice Profiles',
  data: () => ({
    subViews: [
      { name: 'OVP List', path: '/app/outbound-profiles', component: OutboundProfilesList, noDisplay: true },
      { name: 'OVP New', path: '/app/outbound-profiles/new', component: ProfileDetails, noDisplay: true },
      { name: 'OVP Edit', path: '/app/outbound-profiles/:uuid', component: ProfileDetails, noDisplay: true },
      { name: 'OVP Domains', path: '/app/outbound-profiles/:uuid/domain', component: ManageDomains, noDisplay: true, props: { currModule: 'outboundVoiceProfiles' } }
    ]
  })
}
</script>
