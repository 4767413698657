<template>
  <crud-list
    current-module='ticket'
    :use-search="true"
    :headers="headers"
    :has-created="true"
    :has-modified="true"
    :multi-sort="false"
    name='Tickets'
  >
  </crud-list>
</template>

<script>
export default {
  data: () => ({
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'State', value: 'state' },
      { text: 'Created On', value: 'created_on' },
      { text: 'Modified On', value: 'modified_on' }
    ]
  })
}
</script>
