<template>
  <v-card flat tile class='my-5'>
    <v-row class='mx-2'>
      <v-col cols='auto'>
        <h2>Manage your Users</h2>
      </v-col>
      <v-spacer/>
      <v-col cols='auto'>
        <v-btn
          text
          class='u_blue white--text'
          :ripple='false'
          append
          to="new"
        >
        <v-icon class='mr-1'>mdi-plus-circle</v-icon>
          Create New User
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="list"
          :headers="dataHeaders"
          :loading="loading"
          class='px-2'
        >
          <template v-slot:item.active="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="!item.deleted_on"
                  color='success'
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else
                  color='error'
                  v-on="on"
                >
                  mdi-close-circle
                </v-icon>
              </template>
              {{item.deleted_on ? 'Deactivated on ' + beautifyDate(item.deleted_on) : 'Active'}}
            </v-tooltip>
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.first_name + ' ' + item.last_name }}
          </template>
          <template v-slot:item.deact="{ item }">
            <v-switch
              v-if="currentUserUUID !== item.uuid"
              class='my-2'
              inset
              dense
              :input-value="!(item.deleted_on)"
              @change="activate(item, $event)"
              hide-details
              :loading="item.deleting"
            >
            </v-switch>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    dataHeaders: [
      { text: 'Is Active', value: 'active' },
      { text: 'Email', value: 'email' },
      { text: 'Name', value: 'name' },
      { text: 'Deactivate/Activate', value: 'deact' }
    ]
  }),
  computed: {
    ...mapGetters({
      currentUserUUID: 'user/uuid',
      list: 'accountUsers/list',
      loading: 'accountUsers/loading',
      errorMessage: 'accountUsers/errorMessage'
    })
  },
  methods: {
    ...mapActions({
      requestList: 'accountUsers/requestList',
      deleteItem: 'accountUsers/deleteItem',
      activateItem: 'accountUsers/activate'
    }),
    deleteI (item) {
      this.$set(item, 'deleting', true)
      this.deleteItem(item.uuid).then(res => {
        if (!res) {
          this.$set(item, 'deleting', false)
          alert(this.errorMessage)
        }
      })
    },
    beautifyDate: unformatted => unformatted
      ? (new Date(unformatted)).toLocaleString()
      : null,
    async activate (item, val) {
      this.$set(item, 'deleting', true)
      if (val) {
        await this.activateItem(item.uuid).then
      } else {
        await this.deleteItem(item.uuid)
      }
      this.$set(item, 'deleting', false)
    }
  },
  created () {
    this.requestList().then(res => {
      if (!res) {
        alert(this.errorMessage)
      }
    })
  }
}
</script>
