import ItemBase from './itemBase.service'

class SIPConnectionsAPI extends ItemBase {
  buildRequest (item) {
    return {
      name: item.name || undefined,
      product: item?.product?.id || undefined,
      port: item.port || undefined,
      ip: item.ip || undefined,
      hostname: item.hostname || undefined
    }
  }
}

export default new SIPConnectionsAPI('/rest/sip/connection')
