<template>
  <span>
    <v-card
      flat
      width='350'
      class='py-10 px-8'
    >
      <v-container class='pa-0 ma-0'>
        <v-row>
          <v-col cols='12'>
            <v-text-field
              v-model='number'
              flat
              outlined
              persistent-hint
              :hint='displayNumber'
            >
              <template v-slot:append>
                <v-btn
                  @click="number =  number.slice(0, -1)"
                  text
                  icon
                  :ripple="false"
                  class='mt-n2'
                >
                  <v-icon class='backspace-icon'>mdi-backspace-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='4'
            v-for="b in buttons"
            :key="b"
          >
            <v-row justify='center'>
            <v-col cols='auto' class='pa-0'>
            <v-btn
              @click="number+=b"
              text
              height='60'
            >
              <span class='number-button'>{{b}}</span>
            </v-btn>
              <div class='colored mx-6 mt-n3'></div>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols='4'>
          </v-col>
          <v-col cols='4'>
          </v-col>
        </v-row>
        <v-row justify='center'>
          <v-col cols='auto'>
            <v-btn
              text
              icon
              class='success white--text'
              height='60'
              width='60'
            >
              <v-icon size='2.5rem'>mdi-phone</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </span>
</template>

<style scoped>
.backspace-icon {
  font-size: 2rem !important;
}
.number-button {
  font-size: 2rem !important;
}
.colored {
  background-color: lightgray !important;
  height: 3px !important;
  width: 18px !important;
}
</style>

<script>
import { AsYouType } from 'libphonenumber-js'
export default {
  props: {
    value: null
  },
  data: () => ({
    number: '',
    buttons: [
      '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'
    ]
  }),
  computed: {
    displayNumber: function () {
      const parser = new AsYouType()
      const inputNumber = '+' + this.number
      if (this.number !== '') {
        return parser.input(inputNumber)
      } else {
        return ''
      }
    }
  }
}
</script>
