<template>
  <div>
    <SubBar
      :items='subViews.filter(elem => {return elem.noDisplay === undefined})'
    >
    </SubBar>
    <router-view></router-view>
  </div>
</template>

<script>
import SubBar from '@/components/SubBar'
import General from './General.vue'
import Billing from './Billing.vue'
// import BillingGroups from './BillingGroups'
// import Organization from './Organization'
// import NewGroup from './OrganizationNewGroup'
// import Verifications from './Verifications'
import Addresses from './Addresses'
import Users from './Users'
import UserDetails from './UserDetails'
import S3List from './S3List'
import S3Details from './S3Details'
import TTSList from './TTSList'
import TTSDetails from './TTSDetails'
import usageSummary from './usageSummary'
import Notifications from './Notifications'
import Verifications from './Verifications'
import Transactions from '../Reporting/Transactions'
// import PublicKey from './PublicKey'

export default {
  name: 'Account',
  data: () => ({
    subViews: [
      { name: 'General', path: '/app/account/general', component: General },
      { name: 'Payment', path: '/app/account/billing/make-payment', component: Billing },
      { name: 'Transaction', path: '/app/account/transaction', component: Transactions },
      { name: 'Usage Summary', path: '/app/account/usage-summary', component: usageSummary },
      { name: 'Make Payment', path: '/app/account/billing/make-payment', component: Billing, noDisplay: true },
      // { name: 'Billing Groups', path: '/app/account/billing-groups', component: BillingGroups },
      // { name: 'Organization', path: '/app/account/organizations', component: Organization },
      // { name: 'New Group', path: '/app/account/organizations/new-group', component: NewGroup, noDisplay: true },
      { name: 'Addresses', path: '/app/account/addresses', component: Addresses },
      { name: 'Account Users', path: '/app/account/users', component: Users },
      { name: 'New User', path: '/app/account/users/new', component: UserDetails, noDisplay: true },
      { name: 'S3 Settings', path: '/app/account/s3', component: S3List },
      { name: 'New S3 Settings', path: '/app/account/s3/new', component: S3Details, noDisplay: true },
      { name: 'Edit S3 Settings', path: '/app/account/s3/:uuid', component: S3Details, noDisplay: true },
      { name: 'TTS Settings', path: '/app/account/tts', component: TTSList },
      { name: 'New TTS Settings', path: '/app/account/tts/new', component: TTSDetails, noDisplay: true },
      { name: 'Edit TTS Settings Details', path: '/app/account/tts/:uuid', component: TTSDetails, noDisplay: true },
      { name: 'WSS', path: '/app/account/notifications', component: Notifications },
      { name: 'Verifications', path: '/app/account/varifications', component: Verifications }
      // { name: 'Public Key', path: '/app/account/public-key', component: PublicKey }
    ]
  }),
  components: {
    SubBar
  }
}
</script>
