<template>
  <Details
    current-module="s3settings"
  >
    <template v-slot="{ item }">
      <v-form ref='form'>
        <v-row>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Name"
              v-model="item.name"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Bucket"
              v-model="item.bucket"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Endpoint"
              v-model="item.endPoint"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <o-text-field
              input-label="Region"
              v-model="item.region"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='2' align-self='center' class='pl-4'>
            <v-switch
              label="Use SSL"
              v-model="item.useSSL"
              inset
              :hide-details="true"
            >
            </v-switch>
          </v-col>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Access Key"
              v-model="item.accessKey"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label="Secret Key"
              v-model="item.secretKey"
              :rules="[notEmpty]"
              :hide-details="false"
            >
            </o-text-field>
          </v-col>
       </v-row>
      </v-form>
    </template>

  </Details>
</template>

<script>
import Details from '../../components/CRUD/Details'

export default {
  data: () => ({
    notEmpty: (val) => !!val || 'Required'
  }),
  components: {
    Details
  }
}
</script>
