<template>
  <div>
    <SubBar
      :items='subViews.filter(elem => {return elem.noDisplay === undefined})'
    >
    </SubBar>
    <router-view></router-view>
  </div>
</template>

<script>

import SubBar from '@/components/SubBar'
import MessagingProfiles from '@/views/Messaging/MessagingProfiles'
import NewMessagingProfile from '@/views/Messaging/NewMessagingProfile'
import SendTestMessage from '@/views/Messaging/SendTestMessage'
// import RequestShortCode from '@/views/Messaging/RequestShortCode'
// import RateLimits from '@/views/Messaging/RateLimits'
import NewHostedSMSOrder from '@/views/Messaging/NewHostedSMSOrder'
import HostedSMS from '@/views/Messaging/HostedSMS'
import MessagesHistory from './MessagesHistory'
import Brand from './Brand/Brand'
import CampaignList from './Campaign/CampaignList'
import CampaignDetails from './Campaign/CampaignDetails'
import CampaignStatus from './Campaign/CampaignStatus'

export default {
  name: 'Messaging',
  data: () => ({
    subViews: [
      { name: 'Messaging Profiles', path: '/app/messaging/messaging-profiles', component: MessagingProfiles },
      { name: 'New Messaging Profile', path: '/app/messaging/messaging-profiles/new', component: NewMessagingProfile, noDisplay: true },
      { name: 'Edit Messaging Profile', path: '/app/messaging/messaging-profiles/:uuid', component: NewMessagingProfile, noDisplay: true },
      { name: 'Send Test Message', path: '/app/messaging/send-message', component: SendTestMessage },
      { name: 'History', path: '/app/messaging/history', component: MessagesHistory, noDisplay: true },
      // TODO: consider removing
      // { name: 'Request Short Code', path: '/app/messaging/short-codes-request', component: RequestShortCode },
      // { name: 'Rate Limits', path: '/app/messaging/rate-limits', component: RateLimits },
      { name: 'Brand', path: '/app/messaging/brand', component: Brand },
      { name: 'Campaign', path: '/app/messaging/campaign', component: CampaignList },
      { name: 'New Campaign', path: '/app/messaging/campaign/new', component: CampaignDetails, noDisplay: true },
      { name: 'Edit Campaign', path: '/app/messaging/campaign/:uuid', component: CampaignDetails, noDisplay: true },
      { name: 'Carrier Status', path: '/app/messaging/campaign/:uuid/status', component: CampaignStatus, noDisplay: true },
      { name: 'Hosted SMS', path: '/app/messaging/hosted-sms', component: HostedSMS, noDisplay: true },
      { name: 'Hosted SMS Number', path: '/app/messaging/hosted-sms/new', component: NewHostedSMSOrder, noDisplay: true }
    ]
  }),
  components: {
    SubBar,
    MessagingProfiles,
    SendTestMessage
  }
}

</script>
