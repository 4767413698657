const state = {
  list: [
    { status: 'Pending', event_type: 'Payment sale completed', event_id: 'hsWHDBS-4584skdfjdsf1154srdet1', date: '27 May 2021 05:35:33', summary: 'Payment completed for $10 USD', showstatus: 'Pending' },
    { status: 'Sent', event_type: 'Payment sale created', event_id: 'hsWHDBS-4584skdfjdsf115', date: '27 May 2021 05:35:33', summary: 'Payment completed for $10 USD', showstatus: 'Sent' },
    { status: 'Fail', event_type: 'Payment sale completed', event_id: '78H15DBS-4584skdfjdsf115', date: '27 May 2021 05:35:33', summary: 'Payment completed for $10 USD', showstatus: 'Fail' }
  ],
  loading: false,
  errorMessage: ''
}

const getters = {
  list: (state) => {
    return state.list
  },
  count: (state) => {
    return state.list.length
  },
  loading: (state) => {
    return state.loading
  },
  errorMessage: (state) => {
    return state.errorMessage
  }
}

const actions = {
  fetchList: function ({ commit, state }, payload) {
    return []
  },
  search: function ({ commit, state }, payload) {
    return []
  },
  requestList: function ({ commit, state }) {
    return []
  },
  deleteItem: function ({ commit, state }, uuid) {
    return []
  }
}

const mutations = {
  setList (state, list) {
    state.list = list
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setErrorMessage (state, errorMessage) {
    state.errorMessage = errorMessage
  }
}

export const webhook = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
