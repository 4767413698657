import store from '../store'
import axios from 'axios'
import { TokenService } from './storage.service'

const ApiService = {
  _401interceptor: null,

  init (baseURL) {
    axios.defaults.baseURL = baseURL
  },

  setHeader () {
    axios.defaults.headers.common['X-Auth-Token'] = `${TokenService.getToken()}`
  },

  addHeader (key, value) {
    axios.defaults.headers.common[key] = value
  },

  removeHeader () {
    axios.defaults.headers.common = {}
  },

  mount401Interceptor () {
    this._401interceptor = axios.interceptors.response.use(function (response) {
      return response
    }, async function (error) {
      if (error.response.data.status === 401 && error.config.url !== '/rest/auth') {
        store.dispatch('auth/logout')
      }
      return Promise.reject(error)
    })
  },

  unmount401Interceptor () {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor)
  },

  get (resource) {
    return axios.get(resource)
  },

  post (resource, data) {
    return axios.post(resource, data)
  },

  put (resource, data) {
    return axios.put(resource, data)
  },

  patch (resource, data) {
    return axios.patch(resource, data)
  },

  delete (resource) {
    return axios.delete(resource)
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
  **/
  customRequest (data) {
    return axios(data)
  }
}

export default ApiService
