<template>
<v-row class='pa-0'>
  <v-col cols='12'>
    <o-text-field
      input-label='Subject'
      v-model="item.subject"
      :hide-details="false"
      :rules="[notEmpty]"
    >
    </o-text-field>
  </v-col>
  <v-col cols='12' md='6'>
    <o-text-field
      input-label='Amount'
      v-model="item.amount"
      :rules="[notEmpty]"
      :number="true"
    >
    </o-text-field>
  </v-col>
  <v-col cols='12'>
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel
      >
        <v-expansion-panel-header>
          <h3>Filters</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SearchFilters
            v-model='item.params'
          >
          </SearchFilters>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</v-row>
</template>

<script>
import NumberFeatures from '@/components/NumberFeatures'
import SearchFilters from '../Numbers/SearchFilters'

export default {
  props: {
    item: null
  },
  data: () => ({
    notEmpty: val => !!val || 'Required',
    panel: [0]
  }),
  components: {
    NumberFeatures,
    SearchFilters
  }
}
</script>
