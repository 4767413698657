<template>
  <List
    current-module="s3settings"
    :headers="headers"
  >
  </List>
</template>

<script>
import List from '../../components/CRUD/List'

export default {
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Endpoint', value: 'endPoint' },
      { text: 'Bucket', value: 'bucket' },
      { text: 'Region', value: 'region' },
      { text: 'Use SSL', value: 'useSSL' },
      { text: 'Edit', value: 'edit' },
      { text: 'Delete', value: 'delete' }
    ]
  }),
  components: {
    List
  }
}
</script>
