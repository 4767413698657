<template>
<v-app>
  <v-progress-circular
    size="100"
    width="10"
    indeterminate
    class='loader'
    color='primary'
  >
  </v-progress-circular>
</v-app>
</template>

<style scoped>
.loader {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}
</style>

<script>
export default {

}
</script>
