<template>
<div style="display:inline-block; width:80px;">
  <label v-if="hasLabel" class="input-label">
    {{inputLabel}}
    <slot name='input-label'></slot>
  </label>
  <v-row>
    <template
      v-for="el in numberFeatures"
    >
      <v-col :key="el.label" cols='6' class='px-1'>
        <icon-checkbox
          :icon="el.icon"
          @input="handleChange(el.value, currentFeatures[el.value])"
          v-model="currentFeatures[el.value]"
          :editable="editable"
          :title="el.title"
        >
        </icon-checkbox>
      </v-col>
    </template>
  </v-row>
</div>
</template>

<script>
import IconCheckbox from './IconCheckbox'
export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    inputLabel: {
      type: String,
      default: ''
    },
    value: {
      type: Array
    }
  },
  data: () => ({
    numberFeatures: [
      { label: 'Voice', value: 'voice', icon: 'mdi-phone', title: 'Call', checked: false },
      { label: 'SMS', value: 'sms', icon: 'mdi-message-processing', title: 'SMS', checked: false }
    ],
    currentFeatures: { voice: false, sms: false }
  }),
  computed: {
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    },
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    handleChange (key, value) {
      this.$emit('change', { key, value })
    }
  },
  watch: {
    currentFeatures: {
      handler (val) {
        const newArr = []
        for (const field in val) {
          if (val[field]) {
            newArr.push(field)
          }
        }
        this.localValue = newArr
      },
      deep: true

    },
    value: {
      handler (val) {
        const feat = {}
        this.numberFeatures.forEach(el => {
          feat[el.value] = false
        })
        this.value.forEach(el => {
          feat[el] = true
        })

        if (JSON.stringify(feat) !== JSON.stringify(this.currentFeatures)) {
          this.currentFeatures = feat
        }
      },
      deep: true
    }
  },
  components: {
    IconCheckbox
  },
  mounted () {
    const feat = {}
    this.numberFeatures.forEach(el => {
      feat[el.value] = false
    })
    this.value.forEach(el => {
      feat[el] = true
    })
    this.currentFeatures = feat
  }
}
</script>
<style>
  .md-button{
    z-index: 0 !important;
  }
</style>
