<template>
  <span>
    <v-form
      ref='form'
      @keyup.native.enter="createOrSave"
    >
      <v-card flat tile class='my-5 pa-5'>
        <v-container>
          <v-row>
            <v-col cols='auto'>
              <h2>Create a User</h2>
            </v-col>
            <v-spacer/>
            <v-col cols='auto'>
              <v-btn
                text
                class='pa-0'
                dense
                :min-width="0"
                width=25
                :ripple="false"
                to='/app/account/users'
              >
                <v-icon>
                  mdi-close-thick
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12' md='6' class='pb-0'>
              <o-text-field
                v-model="item.email"
                input-label='Email address'
                label='Enter email'
                :rules="[notEmpty]"
              >
              </o-text-field>
            </v-col>
            <v-col cols='12' md='6' class='pb-0'>
              <o-text-field
                v-model="item.password"
                input-label='Password'
                label='Enter password'
                :rules="[notEmpty, moreThanTwo]"
              >
              </o-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12' md='6' class='pb-0'>
              <o-text-field
                v-model="item.first_name"
                input-label='First Name'
                label='Enter first name'
                :rules="[notEmpty]"
              >
              </o-text-field>
            </v-col>
            <v-col cols='12' md='6' class='pb-0'>
              <o-text-field
                v-model="item.last_name"
                input-label='Last Name'
                label='Enter last name'
                :rules="[notEmpty]"
              >
              </o-text-field>
            </v-col>
          </v-row>
          <v-row class='mt-4'>
            <v-col cols='12' class='pb-0'>
              <v-btn
                text
                :ripple="false"
                block
                class="primary white--text"
                @click='createOrSave'
                :loading="saving"
              >
                Create User
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </span>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: () => ({
    notEmpty: val => !!val,
    moreThanTwo: val => (val && val.length > 2) || 'At least 3 characters',
    item: {
      email: '',
      password: '',
      first_name: '',
      last_name: ''
    },
    userRoles: [
      'User', 'Provider', 'Admin'
    ]
  }),
  computed: {
    ...mapGetters({
      currentItem: 'accountUsers/current',
      saving: 'accountUsers/saving',
      errorMessage: 'accountUsers/errorMessage'
    }),
    isNew () {
      return this.$route.fullPath.split('/')[4] === 'new'
    },
    uuid () {
      return this.$route.params.uuid
    }
  },
  methods: {
    ...mapActions({
      create: 'accountUsers/create',
      // reactivate: 'accountUsers/reactivate',
      deleteItem: 'accountUsers/deleteItem',
      requestOne: 'accountUsers/requestOne'
    }),
    ...mapMutations({
      clearCurrent: 'accountUsers/clearCurrent',
      // setCurrent: 'accountUsers/setCurrent',
      setDefault: 'accountUsers/setDefault'
    }),
    createOrSave () {
      if (this.$refs.form.validate()) {
        if (this.isNew) {
          this.create(this.item).then(res => {
            if (!res) {
              alert(this.errorMessage)
            }
          })
        } else {
          this.patch(this.currentItem).then(res => {
            if (!res) {
              alert(this.errorMessage)
            }
          })
        }
      }
    },
    onStart () {
      this.clearCurrent()
      if (!this.isNew) {
        if (this.list && this.list.length) {
          this.$nextTick(function () {
            this.setCurrent(this.uuid)
          })
        }
        this.requestOne(this.uuid)
      } else {
        this.setDefault()
      }
      this.requestOutboundVoiceProfiles().then(res => {
        if (!res) {
          alert('Can\'t load voice profiles: ' + this.outboundVoiceProfilesError)
        }
      })
    },
    deleteApproved () {
      this.deleteItem(this.uuid)
    },
    insertEmoji (obj) {
      this.currentItem.message += obj.native
    },
    toChanged (val) {
      this.currentItem.from = val
    }
  },
  created () {
    this.onStart()
  },
  watch: {
    $route (to, from) {
      this.onStart()
    },
    currentItem: {
      handler (val) {
        if (val) {
          this.$nextTick(function () {
            this.$refs.form.resetValidation()
          })
        }
      },
      deep: true
    }
  }
}
</script>
