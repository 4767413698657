<template>
  <span>
    <v-row>
         <v-col cols='12' md='6'>
        <o-text-field
                input-label='Company Name'
                :value="item.companyName"
                :disabled="true"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Brand Name'
          :value="item.displayName"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-select
          input-label='Brand Type'
          :value="item.brand_type"
          :items="brandTypes"
          :disabled="true"
        >
        </o-select>
      </v-col>
        <v-col cols='12' md='6'>
        <o-select
                input-label='DUNS Number, GIIN, or LEI Number'
                :value="item.altBusinessIdType"
                :items="pickNumberList"
                :disabled="true"
        >
        </o-select>
      </v-col>
      <v-col cols='12' md='6'>
      <o-text-field
              input-label="Number"
              :label="item.altBusinessIdType ? item.altBusinessIdType +' Number' : ''"
              :value="item.altBusinessId"
              :disabled="true"
      >
        </o-text-field>
      </v-col>
              <v-col cols='12' md='6'>
        <o-select
                input-label='Vertical'
                :value="item.vertical"
                :items="verticalItems"
                :loading="verticalLoading"
                :disabled="true"
        >
        </o-select>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='EIN'
          :value="item.ein"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-tel-text-input
          input-label='Phone Number'
          :value='item.phone'
          :as-text="true"
          :disabled="true"
        >
        </o-tel-text-input>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Email'
          :value="item.email"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Website'
          :value="item.website"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
        <v-col cols='12' md='6'>
        <o-select
                input-label='Stock Exchange'
                v-model="item.stockExchange"
                :items="stockExchangeItems"
                :disabled="true"
        >
        </o-select>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
                input-label='Stock Symbol'
                v-model="item.stockSymbol"
                :disabled="true"
        >
        </o-text-field>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col cols='12'>
        <h4>Address</h4>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='State'
          :value="item.state"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='City'
          :value="item.city"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Postal Code'
          :value="item.postalCode"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Street'
          :value="item.street"
          :disabled="true"
        >
        </o-text-field>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    brandTypes: [
      { text: 'Private Profit', value: 'PRIVATE_PROFIT' },
      { text: 'Public Profit', value: 'PUBLIC_PROFIT' },
      { text: 'Nonprofit', value: 'NON_PROFIT' }
    ],
    pickNumberList: [
      { text: 'DUNS', value: 'DUNS' },
      { text: 'GIIN', value: 'GIIN' },
      { text: 'LEI', value: 'LEI' }
    ]
  }),
  methods: {
    ...mapActions({
      requestVertical: 'dict/brandVertical/requestList',
      requestStockExchange: 'dict/brandStockExchange/requestList'
    })
  },
  computed: {
    ...mapGetters({
      verticalItems: 'dict/brandVertical/list',
      verticalLoading: 'dict/brandVertical/loading',
      verticalError: 'dict/brandVertical/errorMessage',
      stockExchangeItems: 'dict/brandStockExchange/list'
    })
  },
  created () {
    this.requestStockExchange()
    this.requestVertical().then(res => {
      if (!res) {
        alert('Cannot load vertical options list: ' + this.verticalError)
      }
    })
  }
}
</script>
