<template>
  <v-dialog
    v-model="localValue"
    width='600'
  >
    <v-card class='pa-5'>
      <v-container>
        <v-form
          ref='form'
          v-if="currentItem"
        >
          <v-row class='px-4'>
            <v-col cols='auto'>
              <h2 v-if="isNew">Add SIP Domain</h2>
              <h2 v-else>Modify SIP Domain</h2>
            </v-col>
            <v-spacer/>
            <v-col cols='auto'>
              <v-btn
                text
                class='pa-0'
                dense
                :min-width="0"
                width=25
                :ripple="false"
                @click="localValue=false"
              >
                <v-icon>
                  mdi-close-thick
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12' md='12'>
              <domain-field
                v-model="currentItem.domain"
                :disabled="!isNew"
              >
              </domain-field>
            </v-col>

          </v-row>

          <v-spacer/>

          <CountryCodeSelect
            v-model="currentItem.countries"
            label="Allowed countries"
            label2="All countries allowed"
            select-label="a2"
            :multiple="true"
            type="chips"
          />

          <v-spacer/>

          <v-row>
            <v-col cols='12' md='12'>
              <o-select
                input-label='Outbound voice profile'
                :items="ovpList"
                :loading="itemsLoading"
                item-text='name'
                item-value='uuid'
                v-model='currentItem.outbound_voice_profile'
                :return-object="false"
                :clearable="true"
              >
              </o-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols='12' md='6'>
              <o-select
                input-label='Routing Method'
                :items="selectionOptions"
                v-model="currentSIP.opt"
                :return-object="true"
                :clearable="true"
              >
              </o-select>
            </v-col>
            <v-col cols='12' md='6'>
              <o-select
                input-label='Choose one from the list:'
                :items="items"
                :loading="itemsLoading"
                item-text='name'
                item-value='uuid'
                v-model='currentSIP.value'
                :return-object="false"
                :disabled="!currentSIP.opt"
                :clearable="true"
              >
              </o-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                text
                block
                class='primary white--text mr-n3 mt-6 no-left-border-radius'
                :ripple='false'
                height='40px'
                @click='createOrSave'
                :loading="saving"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import List from '@/components/CRUD/List.vue'
import NumberFeatures from '@/components/NumberFeatures.vue'
import CountryCodeSelect from '@/components/CountryCodeSelect.vue'
import DomainField from '@/views/SIPDomain/DomainField.vue'

export default {
  components: {
    DomainField,
    CountryCodeSelect,
    NumberFeatures,
    List
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    uuid: null
  },
  data: () => ({
    selectionOptions: [
      { text: 'SIP Connection', value: 'sipConnections', field: 'sip_connection' },
      { text: 'Call Control APP', value: 'callControl', field: 'sip_control_app' }
    ],
    currentSIP: {
      opt: null,
      value: undefined
    }
  }),
  computed: {
    ...mapGetters({
      errorMessage: 'sip/domain/errorMessage',
      saving: 'sip/domain/saving',
      currentItem: 'sip/domain/current',
      list: 'sip/domain/list',
      countriesList: 'dict/countries/list',
      ovpList: 'outboundVoiceProfiles/list'
    }),
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },

    items () {
      return this.currentSIP.opt
        ? this.$store.getters[`${this.currentSIP.opt.value}/list`]
        : undefined
    },
    itemsLoading () {
      return this.currentSIP.opt
        ? this.$store.getters[`${this.currentSIP.opt.value}/loading`]
        : undefined
    },
    itemsErrorMessage () {
      return this.currentSIP.opt
        ? this.$store.getters[`${this.currentSIP.opt.value}/errorMessage`]
        : undefined
    },

    connectionsErrorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    }
  },
  methods: {
    ...mapActions({
      requestOne: 'sip/domain/requestOne',
      create: 'sip/domain/create',
      patch: 'sip/domain/patch',
      notify: 'notification/notify'
    }),
    ...mapMutations({
      clearCurrent: 'sip/domain/clearCurrent',
      setCurrent: 'sip/domain/setCurrent',
      setDefault: 'sip/domain/setDefault'
    }),

    loginValidator (val = '') {
      if (val.length < 3 || val.length > 60) {
        return 'Length must be more than 3 and less than 60'
      }

      if (!val.match(/^[a-zA-Z][.a-zA-Z0-9_-]{2,59}$/)) {
        return 'Value is wrong'
      }

      return true
    },
    passwordValidator (val = '') {
      if (!this.isNew && val.length === 0) {
        return true
      }

      if (val.length < 3) {
        return 'Length must be more than 3'
      }

      return true
    },
    onStart () {
      this.clearCurrent()
      if (!this.isNew) {
        if (this.list && this.list.length) {
          this.$nextTick(function () {
            this.setCurrent(this.domain)
          })
        }
        this.requestOne(this.uuid)
          .then(() => {
            if (this.currentItem.sip_connection) {
              this.currentSIP = {
                opt: this.selectionOptions.find(_ => _.field === 'sip_connection'),
                value: this.currentItem.sip_connection
              }
            } else if (this.currentItem.sip_control_app) {
              this.currentSIP = {
                opt: this.selectionOptions.find(_ => _.field === 'sip_control_app'),
                value: this.currentItem.sip_control_app
              }
            } else if (this.currentItem.outbound_voice_profile) {
              this.currentSIP = {
                opt: this.selectionOptions.find(_ => _.field === 'outbound_voice_profile'),
                value: this.currentItem.outbound_voice_profile
              }
            }
          })
      } else {
        this.setDefault()
      }
    },
    createOrSave () {
      if (this.$refs.form.validate()) {
        this.selectionOptions
          .map(_ => _.field)
          .forEach(_ => delete this.currentItem[_])

        if (this.currentSIP.opt) {
          this.currentItem[this.currentSIP.opt.field] = this.currentSIP.value || null
        }

        if (this.isNew) {
          this.create().then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.notify({
                text: 'SIPDomain created successfully',
                type: 'success'
              })
              this.localValue = false
            }
          })
        } else {
          this.patch(this.currentItem).then(res => {
            if (!res) {
              alert(this.errorMessage)
            } else {
              this.notify({
                text: 'SIPDomain saved successfully',
                type: 'success'
              })
              this.localValue = false
            }
          })
        }
      }
    }
  },
  mounted () {
    // this.$refs.form.resetValidation()
    this.$store.dispatch('sipConnections/requestList').then(res => {
      if (!res) {
        this.alert('Cannot load sip connections')
      }
    })
    this.$store.dispatch('callControl/requestList').then(res => {
      if (!res) {
        this.alert('Cannot load call control apps')
      }
    })
    this.$store.dispatch('outboundVoiceProfiles/requestList').then(res => {
      if (!res) {
        this.alert('Cannot load outbound voice profiles')
      }
    })

    this.onStart()
  }
}
</script>
