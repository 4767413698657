<template>
  <div>
    <SubBar
      :items='subViews.filter(elem => {return elem.noDisplay === undefined})'
    />
    <router-view></router-view>
  </div>
</template>

<script>

import SubBar from '@/components/SubBar'

// import WebDialer from './WebDialer'
// import WebhookEvent from './WebhookEvent'
// import restfulAPI from './restfulAPI'
import onlineSIPuser from './onlineSIPuser'
export default {
  name: 'Debugging',
  data: () => ({
    subViews: [
      // { name: 'Webhook Event', path: '/app/debugging/webhook-event', component: WebhookEvent },
      // { name: 'Restful API', path: '/app/debugging/restful-api', component: restfulAPI },
      { name: 'Online SIP User', path: '/app/debugging/online-SIP-user', component: onlineSIPuser }
      // { name: 'WebRTC', path: '/app/debugging/web-RTC', component: WebDialer }
    ]
  }),
  components: {
    SubBar
  }
}

</script>
