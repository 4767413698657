<template>
  <o-select
      v-bind="$attrs"
      v-on="$listeners"
      :items="list"
      :loading="loading"
      :item-value="itemValue"
      :item-text="itemText"
      :disabled="disabled"
      :multiSelect="multiSelect"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
  </o-select>
</template>

<script>
export default {
  props: {
    module: null,
    filterList: null,
    itemValue: {
      type: String,
      default: 'identificator'
    },
    itemText: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // getters
    list () {
      const arr = this.$store.getters[`${this.module}/list`]
      if (this.filterList) {
        // eslint-disable-next-line no-eval
        return eval(this.filterList)
      }
      return arr
    },
    loading () {
      return this.$store.getters[`${this.module}/loading`]
    },
    errorMessage () {
      return this.$store.getters[`${this.module}/errorMessage`]
    }
    //
  },
  methods: {
    // actions
    async requestList (request) {
      return await this.$store.dispatch(`${this.module}/requestList`)
    }
  },
  created () {
    this.requestList().then(res => {
      if (!res) {
        alert(this.errorMessage)
      }
    })
  }
}
</script>
