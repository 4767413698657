import ItemBase from '../../itemBase.service'

class TNLeaseAPI extends ItemBase {
  create () {
    throw new Error('Create method is unsupported fot TNLeases')
  }

  buildRequest (item) {
    return {
      sip_connection: item.sip_connection,
      sip_control_app: item.sip_control_app,
      messaging_profile_uuid: item.messaging_profile_uuid
    }
  }

  buildSearchRequest (searchParams) {
    searchParams = super.buildSearchRequest(searchParams)
    searchParams.tn = `*${searchParams.tn ?? ''}*`

    return searchParams
  }
}

export default new TNLeaseAPI('/rest/lease/tn')
