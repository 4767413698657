<template>
  <v-icon
    v-on="listeners"
    @click="switchValue"
    :disabled="disabled"
    :class="localValue || disabled ? 'success--text' : 'error--text crossed'"
    :ripple="false"
    :title="title"
  >
    {{icon}}
  </v-icon>
</template>

<style scoped lang='scss'>
.crossed {
  text-decoration: line-through !important;
  text-decoration-color: #8b0000 !important;
  text-decoration-thickness: 3px !important;
}
</style>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'mdi-info'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },
    listeners () {
      // we have to remove the 'input' event as we process it ourselves
      return Object.keys(this.$listeners)
        .filter(field => field !== 'input')
        .reduce((obj, key) => {
          obj[key] = this.$listeners[key]
          return obj
        }, {})
    }
  },
  methods: {
    switchValue () {
      if (this.editable) {
        this.localValue = !this.localValue
      }
    }
  }
}
</script>
