<template>
<span>
  <v-card flat class="my-5">
    <v-container class="px-5">
      <v-row>
        <v-col>
          <h2>Send Test Message</h2>
          <p class='mb-0'>Send messages from your Opentact numbers to a real phone number.</p>
          <p class='mb-0'>Learn more about sending messages programmatically using the Opentact SDK.</p>
        </v-col>
      </v-row>
      <v-form
        ref='form'
      >
        <v-row>
          <v-col>
            <v-row>
              <v-col cols='12' md='5'>
                <v-row>
                  <v-col cols='auto' class='pt-3 pb-1'>
                    From Number <i>(required)</i>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class='pt-0'>
                     <o-text-field
                             v-model="item.from"
                             @keypress="inputValidate"
                             :hide-details="false"
                             input-label=''
                             :rules="[moreThan11]"
                     >
                         </o-text-field>
<!--                    <number-autocomplete-->
<!--                      v-model="item.from"-->
<!--                    >-->
<!--                    </number-autocomplete>-->
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='5' align-self='end'>
                <v-row>
                  <v-col class='pt-3 pb-1'>
                    <v-card flat>
                      To Number <i>(required)</i>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class='pt-0'>
                      <o-text-field
                              v-model="item.to"
                              @keypress="inputValidate"
                              :hide-details="false"
                              input-label=''
                              :rules="[moreThan11]"
                      >
                         </o-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <label class="input-label">Message</label>
                <o-textarea
                  outlined
                  flat
                  single-line
                  v-model="item.message"
                  label="Type the message text here"
                  :rules="[notEmpty]"
                  :hide-details="false"
                  :validate-on-blur="true"
                >
                </o-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :block="$vuetify.breakpoint.smAndDown"
                  width='100'
                  text
                  class='primary white--text'
                  :ripple='false'
                  :loading="sending"
                  :disabled="!validNumber"
                  @click='send'
                >
                  Send
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
  <v-card flat class="my-5">
    <v-container>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <label class="input-label"><h2>Code</h2></label>
              <v-toolbar flat class='white mt-2'
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-toolbar-items>
                  <v-btn-toggle
                    v-model="codingLang"
                    dense
                    mandatory
                    class='pt-4 local-toggle'
                    group
                  >
                    <v-btn
                      class='mx-2 cut-border-radius-all'
                      text
                      v-for='item in supportedCodes'
                      :key='item.value'
                      :ripple='false'
                      :value="item.value"
                      >
                        {{ item.name }}
                    </v-btn>
                  </v-btn-toggle>
                </v-toolbar-items>
              </v-toolbar>
              <v-menu v-else top offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    block
                    text
                    class="align-self-center mr-4 primary white--text"
                    v-on="on"
                    :ripple="false"
                  >
                    {{supportedCodes.find(el => el.value === codingLang).name}}
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in supportedCodes"
                    :key="item.value"
                    :ripple="false"
                    @click="codingLang=item.value"
                  >
                    <v-list-item-title>
                      {{item.name}}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <code-highlight>
                <pre>&nbsp;</pre>
                <code>{{codeSample}}</code>
              </code-highlight>
              Send messages programmatically through the Opentact SDK by using this code in your command-line interface or development environment.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</span>
</template>

<style lang="scss">
@import '../../../node_modules/vue-code-highlight/themes/duotone-sea.css';
.v-application code {
  background-color: inherit !important;
  box-shadow: none !important;
}
</style>

<script>
import CodeHighlight from 'vue-code-highlight/src/CodeHighlight.vue'
import { mapGetters, mapActions } from 'vuex'
import BeautifyNumber from '../../services/beautify/number.service'
import NumberAutocomplete from '../../components/NumberAutocomplete'

export default {
  data: () => ({
    item: {
      from: '',
      to: '',
      message: ''
    },
    fromNumberToggle: 'number',
    selectedProfile: null,
    codingLang: 'curl',
    supportedCodes: [
      { name: 'CURL', value: 'curl' }
    ],
    notEmpty: val => !!val || 'Required',
    moreThan11: val => (val && val.length <= 11) || 'Max 11 characters'
  }),
  components: {
    CodeHighlight,
    NumberAutocomplete
  },
  computed: {
    ...mapGetters({
      numbers: 'tnlease/list',
      sending: 'messaging/sms/saving',
      smsError: 'messaging/sms/errorMessage'
    }),
    validNumber () {
      return BeautifyNumber.isValid(this.item.to)
    },
    codeSample: function () {
      switch (this.codingLang) {
        case 'curl':
          return `
# This is a sample test secret Bearer token
# Go to Auth to generate your own secret tokens
apikey="33j3j3jrujejkw348592HHjdfh89DF3322kdfKKdf8828J2a"

curl -X POST \\
  --header "Content-Type: application/json" \\
  --header "Accept: application/json" \\
  --header "Authorization: Bearer $apikey" \\
  --data '{
    "to": "1234567890",
    "tn": 1234567890,
    "message": ""
  }' \\
  https://api.opentact.org/rest/sms`
        case 'python':
          return `import opentact

 # This is a sample test secret API Key
 # Go to Auth to generate your own secret API Keys
 opentact.api_key = "KEY0123EXAMPLE_exampleexample"

 opentact.Message.create(
   from_="",
   to="",
   text=""
 )`
        case 'ruby':
          return `require "opentact"

 # This is a sample test secret API Key
 # Go to Auth to generate your own secret API Keys
 opentact.api_key = "KEY0123EXAMPLE_exampleexample"

 opentact::Message.create(
   from: "",
   to: "",
   text: ""
 )`
        case 'node':
          return `//This is a sample test secret API Key
 //Go to Auth to generate your own secret API Keys
 const apiKey = 'KEY0123EXAMPLE_exampleexample';
 const opentact = require('opentact')(apiKey);

 opentact.messages.create({
   'from': '',
   'to': '',
   'text': ''
 }).then(function(response){
   const message = response.data; // asynchronously handled
 });`
      }
      return ' hello '
    }
  },
  methods: {
    ...mapActions({
      sendSMS: 'messaging/sms/create',
      searchMyNumbers: 'tnlease/search',
      notify: 'notification/notify'
    }),
    inputValidate (e) {
      if (!/^[0-9+]+$/.test(e.key)) {
        e.preventDefault()
      }
    },
    send () {
      if (this.$refs.form.validate()) {
        this.item.doNotRedirect = true
        this.sendSMS(this.item).then(res => {
          if (res) {
            this.notify({ text: 'Message Send Successfully', type: 'success' })
            this.item.message = ''
          } else {
            this.notify({ text: this.smsError, type: 'error' })
          }
        })
      }
    }
  }
}
</script>
