<template>
    <span>
    <v-card flat class='my-5 px-2'>
      <v-container>
        <v-row>
          <v-col cols='12' sm='12' class="text-end">
           <v-btn
             outlined
             color='info'
             :ripple='false'
           >
          Refresh
          </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
   <List
     :current-module="''"
     :has-add="false"
     :use-search="true"
     :headers="headers"
     :has-created="true"
     :has-filtration="false"
   >
      <template v-slot:item.features="{ item }">
        <span >
          {{item}}
        </span>
      </template>
      <template v-slot:item.messaging_profile="{ item }">
        <span >
          {{item}}
        </span>
      </template>
      <template v-slot:item.tn="{ item }">
        <span >
          {{item}}
        </span>
      </template>
    </List>
   </span>
</template>

<script>
import List from '@/components/CRUD/List'
export default {
  components: {
    List
  },
  data: () => ({
    date: '',
    searchString: '',
    option1: '',
    options1: [],
    option2: '',
    options2: [],
    headers: [
      { text: 'Domain', value: 'domain', sortable: false },
      { text: 'User', value: 'user', sortable: false },
      { text: 'Last Registered On', value: 'last_registered_on', sortable: false },
      { text: 'IP', value: 'ip', sortable: false }
    ]
  })
}
</script>

<style scoped>

</style>
