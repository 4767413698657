import { profiles } from './profiles.module'
import { sms } from './sms.module'
import { brand } from './brand.module'
import { campaign } from './campaign.module'

export const messaging = {
  namespaced: true,
  modules: {
    profiles,
    sms,
    brand,
    campaign
  }
}
