<template>
  <v-text-field
    v-model="simNum"
    outlined
    dense
    hide-details
    single-line
    solo
    flat
  >
    <template v-slot:append>
      <v-btn
        text
        class='primary white--text mr-n3 no-left-border-radius'
        :ripple='false'
        height='40px'
        @click='addToCart'
      >
        Add SIM to Cart
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
// TODO: add validation
export default {
  data: () => ({
    simNum: 1
  }),
  methods: {
    addToCart: function () {
      // Commit to vuex store
    }
  }
}
</script>
