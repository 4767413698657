<template>
<span>
  <v-card flat class="my-5 pa-5">
    <v-container>
      <v-row>
        <v-spacer/>
        <v-col cols='auto'>
          <v-btn
            text
            class='u_blue white--text'
            :ripple='false'
            @click.stop="addNew"
          >
          <v-icon class='mr-1'>mdi-plus-circle</v-icon>
            Add Address
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider/>
    <v-data-table
      :headers="dataHeaders"
      :items="list"
      :loading="loading"
    >
      <template v-slot:no-data>
        No addresses saved.
      </template>
      <template v-slot:item.country="{ item }">
        {{item.country.name}}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn
          :ripple="false"
          text
          class='warning white--text'
          append
          @click="edit(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn
          :ripple="false"
          text
          class='error white--text'
          :loading="item.deleting"
          @click="deleteI(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
  <AddressDetails
    v-model="addressDetails"
    :uuid="currentUUID"
  >
  </AddressDetails>
</span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddressDetails from './AddressDetails'

export default {
  data: () => ({
    currentUUID: '',
    dataHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Country', value: 'country' },
      { text: 'City', value: 'city' },
      { text: 'Address', value: 'address1' },
      { text: 'ZIP', value: 'zip_code' },
      { text: 'Edit', value: 'edit' },
      { text: 'Delete', value: 'delete' }
    ],
    showHidden: false,
    addressDetails: false
  }),
  computed: {
    ...mapGetters({
      list: 'addresses/list',
      loading: 'addresses/loading',
      errorMessage: 'addresses/errorMessage'
    })
  },
  methods: {
    ...mapActions({
      requestList: 'addresses/requestList',
      deleteItem: 'addresses/deleteItem'
    }),
    addNew () {
      this.currentUUID = 'new'
      this.addressDetails = true
    },
    edit (item) {
      this.currentUUID = item.uuid
      this.addressDetails = true
    },
    deleteI (item) {
      this.$set(item, 'deleting', true)
      this.deleteItem(item.uuid).then(res => {
        if (!res) {
          this.$set(item, 'deleting', false)
          alert(this.errorMessage)
        }
      })
    }
  },
  components: {
    AddressDetails
  },
  mounted () {
    this.requestList()
  }
}
</script>
