import Base from './base.module'
import { DictBase } from '../../services/dict.service'
import BrandVerticalDict from '../../services/dict/brandVertical.service'
import CampaignUsecaseDict from '../../services/dict/campaignUsecase.service'

export const dict = {
  namespaced: true,
  modules: {
    countries: (new Base(new DictBase('/rest/dict/country'))).getModule(),
    searchCountries: (new Base(new DictBase('/rest/tn/search/countries'))).getModule(),
    states: (new Base(new DictBase('/rest/dict/lerg/states'))).getModule(),
    ratecenters: (new Base(new DictBase('/rest/dict/lerg/ratecenters'))).getModule(),
    brandVertical: (new Base(new BrandVerticalDict('/rest/sms/brand/dict/vertical'))).getModule(),
    brandStockExchange: (new Base(new DictBase('/rest/sms/brand/dict/stock_exchange'))).getModule(),
    campaignUsecase: (new Base(new CampaignUsecaseDict('/rest/sms/campaign/dict/usecase'))).getModule()
  }
}
