import ItemBase from './itemBase.service'

export class SIPUsersService extends ItemBase {
  buildRequest (item) {
    return {
      login: item.login ?? undefined,
      password: item.password ?? undefined,
      is_active: item.is_active ?? undefined,
      same_domain_calls_only: item.same_domain_calls_only ?? undefined,
      tn_lease: typeof item.tn_lease === 'string' ? item.tn_lease : item.tn_lease?.uuid ?? undefined,
      countries: Array.isArray(item.countries)
        ? item.countries.map(_ => _.a2)
        : undefined
    }
  }

  buildPatchRequest (item) {
    return {
      password: item.password ?? undefined,
      is_active: item.is_active ?? undefined,
      same_domain_calls_only: item.same_domain_calls_only ?? undefined,
      tn_lease: typeof item.tn_lease === 'string' ? item.tn_lease : item.tn_lease?.uuid ?? undefined,
      countries: Array.isArray(item.countries)
        ? item.countries.map(_ => _.a2)
        : undefined
    }
  }
}

export default new SIPUsersService('/rest/sip/domain/user/search')
