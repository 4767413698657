import { CartStorage, CartAPI } from '../services/cart.service'
import Vue from 'vue'

const state = {
  clearingCart: false,
  itemsInCart: [],
  loadingNumbersPrices: false,
  numbersTotal: {
    price: 0,
    fee: 0,
    tax: 0,
    total: 0
  }
}

const getters = {
  getNumbersInCart (state) {
    return state.itemsInCart ?? []
  },

  isNumberInCart (state) {
    return function (item) {
      if (state.itemsInCart) {
        return !!state.itemsInCart.find(el => item.tn === el.payload.tn)
      } else {
        return null
      }
    }
  },

  getLoadingNumbersPrices (state) {
    return state.loadingNumbersPrices
  },

  getNumbersTotal (state) {
    return state.numbersTotal
  },

  clearing (state) {
    return state.clearingCart
  }
}

const mutations = {
  setNumbers (state, numbers) {
    state.itemsInCart = numbers
  },

  addItem (state, item) {
    state.itemsInCart.push(item)
  },

  updateItem (state, item) {
    const index = state.itemsInCart.findIndex(el => el.uuid === item.uuid)
    if (index > -1) {
      Vue.set(state.itemsInCart, index, item)
    }
  },

  clearNumbersCart (state) {
    state.loadingNumbersPrices = false
    state.itemsInCart = []
    state.numbersTotal = {
      nrc: 0,
      mrc: 0
    }
    state.clearingCart = false
  },

  startClearCart (state) {
    state.clearingCart = true
  },

  failClearCart (state) {
    state.clearingCart = false
  }
}

const actions = {
  async addToCart ({ commit, dispatch }, item) {
    try {
      const price = await dispatch('requestOneNumberPrice', item)
      const response = await CartStorage.addItem({ ...item, price: price })

      commit('addItem', response)

      return response
    } catch (error) {
      return []
    }
  },

  async updateInCart ({ commit, dispatch }, item) {
    try {
      const price = await dispatch('requestOneNumberPrice', item.payload)
      const response = await CartStorage.updateItem(item.uuid, { payload: { ...item.payload, price: price } })

      commit('updateItem', response)

      return response
    } catch (error) {
      return []
    }
  },

  async retreiveCart ({ commit }) {
    try {
      const numbers = await CartStorage.retreiveCart()
      commit('setNumbers', numbers)
    } catch (error) {
      console.error('Retreiving cart failed: ' + error.message)
    }
  },

  async deleteNumberFromCart ({ commit, dispatch, getters }, item) {
    try {
      await CartStorage.deleteNumber(getters.getNumbersInCart.find(el => parseInt(el.payload.tn) === parseInt(item.payload.tn)))
      await dispatch('retreiveCart')
    } catch (error) {
      console.error('Cannot delete number: ' + error.message)
    }
  },

  async requestOneNumberPrice ({ commit }, item) {
    try {
      const price = await CartAPI.requestOneNumberPrice(item)

      return price
    } catch (error) {
      commit('loadingPricesFailed')
      console.error('Cannot get numbers prices: ' + error.message)
      return null
    }
  },

  async placeNumbersOrder ({ dispatch, getters }) {
    try {
      const numbers = getters.getNumbersInCart.map(el => el.payload)
      await CartAPI.placeNumbersOrder(numbers)

      dispatch('notification/notify',
        { text: 'Your order has been placed.' },
        { root: true })
      await dispatch('clearNumbersCart')
    } catch (error) {
      throw new Error('Cannot place order: ' + error.message)
    }
  },

  async clearNumbersCart ({ commit }) {
    commit('startClearCart')
    try {
      await CartStorage.clearNumbersCart()
      commit('clearNumbersCart')
    } catch (error) {
      commit('failClearCart')
      throw new Error('Cannot clear cart: ' + error.message)
    }
  }
}

export const cart = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
