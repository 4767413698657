<template>
<span>
  <v-row>
    <v-col>
      <Details
        :current-module="currentModule"
        :no-save="true"
      >
        <template v-slot:after-name="{ item }">
          <v-row>
              <v-col cols='12' md='6'>
              <o-text-field
                      v-model="searchString"
                      label='Search...'
                      hide-details
              >
              <template v-slot:append>
                <v-btn
                        text
                        class='primary px-0 px-md-2 mt-0 no-right-border-radius no-left-border-radius'
                        :ripple='false'
                        height='40px'
                        @click="search(tableOptions)"
                >
                  Search
                </v-btn>
                <v-btn
                        @click="searchString = ''"
                        :disabled="searchString === ''"
                        text
                        class='error px-0 px-md-4 white--text mr-n3 no-left-border-radius'
                        :ripple='false'
                        height='40px'
                >
                  Clear
                </v-btn>
              </template>
            </o-text-field>
            </v-col>
<!--              <v-col cols='12' md='6'>-->
<!--                  <md-checkbox v-model="onlyNumber" class="float-end md-primary">Unassigned Numbers Only</md-checkbox>-->
<!--              </v-col>-->
            <v-col cols='12' md='3'>
              <TNLeasesAllButton
                :current-module="currentModule"
                :uuid="item.uuid"
                @done="tableOptionsChanged(tableOptions)"
              >
              </TNLeasesAllButton>
            </v-col>
            <v-col cols='12' md='3'>
              <TNLeasesAllButton
                :current-module="currentModule"
                :uuid="item.uuid"
                add-or-remove='remove'
                @done="tableOptionsChanged(tableOptions)"
              >
              </TNLeasesAllButton>
            </v-col>
            <v-col cols='12' md='3'>
              <TNLeasesCSVButton
                :current-module="currentModule"
                :uuid="item.uuid"
                @done="tableOptionsChanged(tableOptions)"
              >
              </TNLeasesCSVButton>
            </v-col>
            <v-col cols='12' md='3'>
              <TNLeasesCSVButton
                :current-module="currentModule"
                :uuid="item.uuid"
                add-or-remove='remove'
                @done="tableOptionsChanged(tableOptions)"
              >
              </TNLeasesCSVButton>
            </v-col>
          </v-row>
        </template>
        <template v-slot="context">
          <v-form
            ref='form'
            class="my-0 py-0"
          >
            <v-data-table
              :items="numbersList"
              :headers="headers"
              :loading="loadingNumbers"
              :server-items-length="count"
              :options.sync="tableOptions"
              :footer-props="{ disablePagination : !!leasesPatching,
                               disableItemsPerPage : !!leasesPatching }"
            >
              <template v-slot:item.tn="{ item }">
                {{beautifyNumber(item.tn.tn)}}
              </template>
              <template v-slot:item.is_connected="{ item }">
                <v-switch
                  :loading="loadingNumbers || loadingLeases"
                  :disabled="loadingLeases || !!leasesPatching"
                  inset
                  @change="addOrRemove(context.item, item, $event)"
                  :input-value="isConnected(context.item, item)"
                  :ref='`switch_${item.uuid}`'
                >
                </v-switch>
              </template>
            </v-data-table>
<!--            <v-btn-->
<!--              text-->
<!--              block-->
<!--              class='primary white&#45;&#45;text mr-n3'-->
<!--              :ripple='false'-->
<!--              height='40px'-->
<!--              @click='save'-->
<!--              :loading="!!leasesPatching || saving"-->
<!--              :disabled='!validToSubmit'-->
<!--            >-->
<!--              Save Numbers-->
<!--            </v-btn>-->
          </v-form>
        </template>
      </Details>
    </v-col>
  </v-row>
</span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BeautifyNumber from '../../services/beautify/number.service'
import Details from '../../components/CRUD/Details'
import TNLeasesAllButton from './TNLeasesAllButton'
import TNLeasesCSVButton from './TNLeasesCSVButton'

export default {
  data: () => ({
    addList: [],
    removeList: [],
    headers: [
      { text: 'Number', value: 'tn' },
      { text: 'Connected', value: 'is_connected' }
    ],
    onlyNumber: false,
    searchString: '',
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [
        'tn'
      ],
      sortDesc: [
        false
      ]
    }
  }),
  props: {
    currentModule: {
      type: String,
      default: 'sipConnections'
    }
  },
  methods: {
    // actions
    async patchLeases (data) {
      return await this.$store.dispatch(`${this.currentModule}/patchLeases`, data)
    },
    //
    ...mapActions({
      searchMyNumbers: 'tnlease/search',
      notify: 'notification/notify'
    }),
    beautifyNumber: number => BeautifyNumber.beautify(number),
    search (val) {
      const params = {
        page: val.page,
        perPage: val.itemsPerPage > 0 ? val.itemsPerPage : 100,
        order: {}
      }
      if (val.sortBy.length && val.sortBy[0]) {
        params.order[val.sortBy[0]] = val.sortDesc[0] ? 'DESC' : 'ASC'
      }
      this.searchMyNumbers(params).then(res => {
        if (res) {
          this.removeList.concat(this.numbersList.filter(
            el => !this.addList.find(leasesEl => leasesEl === el.uuid)
          ).map(el => el.uuid))
        } else {
          alert(this.numbersErrorMessage)
        }
      })
    },
    tableOptionsChanged (val) {
      const params = {
        page: val.page,
        perPage: val.itemsPerPage > 0 ? val.itemsPerPage : 100,
        order: {}
      }
      if (val.sortBy.length && val.sortBy[0]) {
        params.order[val.sortBy[0]] = val.sortDesc[0] ? 'DESC' : 'ASC'
      }
      this.searchMyNumbers(params).then(res => {
        if (res) {
          this.removeList.concat(this.numbersList.filter(
            el => !this.addList.find(leasesEl => leasesEl === el.uuid)
          ).map(el => el.uuid))
        } else {
          alert(this.numbersErrorMessage)
        }
      })
    },
    addOrRemove (item, number, isConnected) {
      const uuid = number.uuid
      if (isConnected) {
        const index = this.removeList.indexOf(uuid)
        if (index !== -1) {
          this.removeList.splice(index, 1)
        }

        if (!number[this.currentM] || (number[this.currentM].uuid !== item.uuid)) {
          if (this.addList.length >= 100) {
            alert('It is allowed to add maximum 100 numbers per one save. Please save and then continue.')
            this.$refs[`switch_${number.uuid}`].onChange()
          } else {
            this.addList.push(uuid)
          }
        }
      } else {
        if (number[this.currentM] && (number[this.currentM].uuid === item.uuid)) {
          if (this.removeList.length >= 100) {
            alert('It is allowed to remove maximum 100 numbers per one save. Please save and then continue.')
            this.$refs[`switch_${number.uuid}`].onChange()
          } else {
            this.removeList.push(uuid)
          }
        }

        // const index = this.addList.indexOf(uuid)
        // if (index !== -1) {
        //   this.addList.splice(index, 1)
        // }
      }
      this.save()
    },
    isConnected (item, number) {
      return number[this.currentM] && (number[this.currentM].uuid === item.uuid)
    },
    save () {
      // if (this.validToSubmit) {
      this.patchLeases({
        uuid: this.current.uuid,
        leases: {
          add: this.addList && this.addList.length ? this.addList : undefined,
          remove: this.removeList && this.removeList.length ? this.removeList : undefined
        }
      }).then(res => {
        if (!res) {
          this.notify({ text: this.connectionsErrorMessage, type: 'error' })
        } else {
          this.addList = []
          this.removeList = []
          this.search(this.tableOptions)
          this.notify({ text: 'Saved successfully', type: 'success' })
        }
      })
      // }
    }
  },
  computed: {

    validToSubmit: function () {
      return this.addList.length !== 0
    },
    // getters
    current () {
      return this.$store.getters[`${this.currentModule}/current`]
    },

    connectionsErrorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    },
    loadingLeases () {
      return this.$store.getters[`${this.currentModule}/loadingLeases`]
    },
    saving () {
      return this.$store.getters[`${this.currentModule}/saving`]
    },
    leasesPatching () {
      return this.$store.getters[`${this.currentModule}/leasesPatching`]
    },
    currentM () {
      let result = ''
      switch (this.currentModule) {
        case 'sipConnections':
          result = 'sip_connection'
          break
        case 'callControl':
          result = 'sip_control_app'
          break
        default:
          break
      }
      return result
    },
    ...mapGetters({
      numbersList: 'tnlease/list',
      numbersErrorMessage: 'tnlease/errorMessage',
      loadingNumbers: 'tnlease/loading',
      count: 'tnlease/count'
    })
  },
  watch: {
    tableOptions (val) {
      this.tableOptionsChanged(val)
    }
  },
  components: {
    Details,
    TNLeasesAllButton,
    TNLeasesCSVButton
  }
}
</script>
