<template>
<span>
  <v-card flat class='my-5 pa-5'>
    <v-row>
      <v-col>
        <md-checkbox v-model="data.wss_balance" class="md-primary">Enable Balance Change WSS</md-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <md-checkbox v-model="data.wss_ticket" class="md-primary">Enable Ticket Change WSS</md-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <md-checkbox v-model="data.wss_tn_order" class="md-primary">Enable TN Order Change WSS</md-checkbox>
      </v-col>
    </v-row>
      <v-row class="justify-center">
          <v-col cols='12' md='4'>
            <v-btn
                    block
                    text
                    class="primary white--text"
                    :ripple="false"
                    :height="40"
                    :loading="loading"
                    @click="saveNotification"
            >
              Save
            </v-btn>
          </v-col>
      </v-row>
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <h4>Low Balance</h4>-->
<!--        <span>How often would you like to be reminded about this notification when unresolved?</span>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col cols='12' sm='6'>-->
<!--        <o-select-->
<!--          v-model="notifPeriod"-->
<!--          :items="notifPeriods"-->
<!--          hide-details-->
<!--        >-->
<!--        </o-select>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col cols='12' sm='6' class='pt-0'>-->
<!--        <v-row>-->
<!--          <v-col cols='8' align-self='center'>-->
<!--            When the balance of my account falls below $-->
<!--          </v-col>-->
<!--          <v-col cols='4'>-->
<!--            <o-text-field-->
<!--              v-model="lowBound"-->
<!--              type='number'-->
<!--              step='1'-->
<!--              :disabled="notifPeriod==='never'"-->
<!--              hide-details-->
<!--            >-->
<!--            </o-text-field>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-divider/>-->
<!--    <v-row>-->
<!--      <v-col>-->
<!--        Changes will be auto-saved.-->
<!--      </v-col>-->
<!--    </v-row>-->
  </v-card>

<!--  <v-expansion-panels tile flat class='mb-5'>-->
<!--    <v-expansion-panel>-->
<!--      <v-expansion-panel-header>-->
<!--        <v-row>-->
<!--          <v-col cols='auto' align-self='center'>-->
<!--            <h4 class='mb-2'>Notification Profiles</h4>-->
<!--            <p class='mb-0'>Define the groups for your notifications</p>-->
<!--          </v-col>-->
<!--          <v-spacer/>-->
<!--          <v-col cols='auto' align-self='center' class='pr-6'>-->
<!--            <v-dialog-->
<!--              v-model="newProfileDialog"-->
<!--              width="500"-->
<!--            >-->
<!--              <template v-slot:activator="{ on }">-->
<!--                <v-btn-->
<!--                  text-->
<!--                  class="primary white&#45;&#45;text"-->
<!--                  :ripple="false"-->
<!--                  v-on="on"-->
<!--                >-->
<!--                  New Profile-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <v-card>-->
<!--                <v-container>-->
<!--                  <v-row>-->
<!--                    <v-col>-->
<!--                      <h4>New Profile</h4>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-container>-->
<!--                <v-divider/>-->
<!--                <v-container>-->
<!--                  <v-row>-->
<!--                    <v-col cols='12'>-->
<!--                      <o-text-field-->
<!--                        v-model="newProfileName"-->
<!--                        label='Name'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-text-field>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row>-->
<!--                    <v-col cols='12' class='pt-0'>-->
<!--                      <v-btn-->
<!--                        text-->
<!--                        block-->
<!--                        :ripple="false"-->
<!--                        class='primary white&#45;&#45;text'-->
<!--                        width="auto"-->
<!--                        @click="createProfile"-->
<!--                        :disabled="newProfileName===''"-->
<!--                      >-->
<!--                        Create-->
<!--                      </v-btn>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-container>-->
<!--              </v-card>-->
<!--            </v-dialog>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-expansion-panel-header>-->
<!--      <v-expansion-panel-content>-->
<!--        <v-divider/>-->
<!--        <v-data-table-->
<!--          class='pa-2'-->
<!--          :headers='profilesHeaders'-->
<!--        >-->
<!--          <template v-slot:no-data>-->
<!--            There are no notifications profiles in your account.-->
<!--          </template>-->
<!--        </v-data-table>-->
<!--      </v-expansion-panel-content>-->
<!--    </v-expansion-panel>-->
<!--  </v-expansion-panels>-->

<!--  <v-expansion-panels tile flat class='mb-5'>-->
<!--    <v-expansion-panel>-->
<!--      <v-expansion-panel-header>-->
<!--        <v-row>-->
<!--          <v-col cols='auto' align-self='center'>-->
<!--            <h4 class='mb-2'>Notification Channels</h4>-->
<!--            <p class='mb-0'>Define how you want to be notified</p>-->
<!--          </v-col>-->
<!--          <v-spacer/>-->
<!--          <v-col cols='auto' align-self='center' class='pr-6'>-->
<!--            <v-dialog-->
<!--              v-model="newChannelDialog"-->
<!--              width="500"-->
<!--            >-->
<!--              <template v-slot:activator="{ on }">-->
<!--                <v-btn-->
<!--                  text-->
<!--                  class="primary white&#45;&#45;text"-->
<!--                  :ripple="false"-->
<!--                  v-on="on"-->
<!--                >-->
<!--                  New Channel-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <v-card>-->
<!--                <v-container>-->
<!--                  <v-row>-->
<!--                    <v-col>-->
<!--                      <h4>New Setting</h4>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-container>-->
<!--                <v-divider/>-->
<!--                <v-container>-->
<!--                  <v-row>-->
<!--                    <v-col cols='12'>-->
<!--                      <o-select-->
<!--                        v-model="newChannelProfile"-->
<!--                        :items="profiles"-->
<!--                        label='Profile'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-select>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row>-->
<!--                    <v-col cols='12'>-->
<!--                      <o-select-->
<!--                        v-model="notificationType"-->
<!--                        :items="notificationTypes"-->
<!--                        label='Notification Type'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-select>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row v-if="notificationType==='sms'||notificationType==='voice'">-->
<!--                    <v-col cols='12'>-->
<!--                      <o-text-field-->
<!--                        v-model='newChannelDestinationNumber'-->
<!--                        label='Destination Number (e.g. +1 111 111 1111)'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-text-field>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row v-else-if="notificationType==='email'">-->
<!--                    <v-col cols='12'>-->
<!--                      <o-text-field-->
<!--                        v-model='newChannelDestinationEmail'-->
<!--                        label='Destination e.g. example@org.com'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-text-field>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row v-else-if="notificationType==='webhook'">-->
<!--                    <v-col cols='12'>-->
<!--                      <o-text-field-->
<!--                        v-model='newChannelDestinationWeb'-->
<!--                        label='Destination e.g. example.org'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-text-field>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row>-->
<!--                    <v-col cols='12' class='pt-0'>-->
<!--                      <v-btn-->
<!--                        text-->
<!--                        block-->
<!--                        :ripple="false"-->
<!--                        class='primary white&#45;&#45;text'-->
<!--                        width="auto"-->
<!--                        @click="createChannel"-->
<!--                        :disabled="createChannelDisabled"-->
<!--                      >-->
<!--                        Create-->
<!--                      </v-btn>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-container>-->
<!--              </v-card>-->
<!--            </v-dialog>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-expansion-panel-header>-->
<!--      <v-expansion-panel-content>-->
<!--        <v-divider/>-->
<!--        <v-data-table-->
<!--          class='pa-2'-->
<!--          :headers='channelsHeaders'-->
<!--        >-->
<!--          <template v-slot:no-data>-->
<!--            There are no notifications channels in your account.-->
<!--          </template>-->
<!--        </v-data-table>-->
<!--      </v-expansion-panel-content>-->
<!--    </v-expansion-panel>-->
<!--  </v-expansion-panels>-->

<!--  <v-expansion-panels tile flat class='mb-5'>-->
<!--    <v-expansion-panel>-->
<!--      <v-expansion-panel-header>-->
<!--        <v-row>-->
<!--          <v-col cols='auto' align-self='center'>-->
<!--            <h4 class='mb-2'>Notification Settings</h4>-->
<!--            <p class='mb-0'>Manage your notifications</p>-->
<!--          </v-col>-->
<!--          <v-spacer/>-->
<!--          <v-col cols='auto' align-self='center' class='pr-6'>-->
<!--            <v-dialog-->
<!--              v-model="newSettingDialog"-->
<!--              width="500"-->
<!--            >-->
<!--              <template v-slot:activator="{ on }">-->
<!--                <v-btn-->
<!--                  text-->
<!--                  class="primary white&#45;&#45;text"-->
<!--                  :ripple="false"-->
<!--                  v-on="on"-->
<!--                >-->
<!--                  New Setting-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <v-card>-->
<!--                <v-container>-->
<!--                  <v-row>-->
<!--                    <v-col>-->
<!--                      <h4>New Setting</h4>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-container>-->
<!--                <v-divider/>-->
<!--                <v-container>-->
<!--                  <v-row>-->
<!--                    <v-col cols='12'>-->
<!--                      <o-select-->
<!--                        v-model="newSettingEvent"-->
<!--                        :items="newSettingEvents"-->
<!--                        input-label='Event'-->
<!--                        label='Select the event'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-select>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row v-if="newSettingEvent!==null">-->
<!--                    <v-col cols='12'>-->
<!--                      <o-select-->
<!--                        v-model="newSettingProfile"-->
<!--                        :items="profiles"-->
<!--                        input-label='Profile'-->
<!--                        label='Profile'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-select>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row v-if="newSettingEvent!==null&&newSettingProfile!==null">-->
<!--                    <v-col cols='12'>-->
<!--                      <o-select-->
<!--                        v-model="newSettingsChannel"-->
<!--                        :items="channels"-->
<!--                        input-label="Channel (optional)"-->
<!--                        label='Select the channel'-->
<!--                        hide-details-->
<!--                      >-->
<!--                      </o-select>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row>-->
<!--                    <v-col cols='12' class='pt-0'>-->
<!--                      <v-btn-->
<!--                        text-->
<!--                        block-->
<!--                        :ripple="false"-->
<!--                        class='primary white&#45;&#45;text'-->
<!--                        width="auto"-->
<!--                        @click="createSetting"-->
<!--                        :disabled="newSettingEvent===null||newSettingProfile===null"-->
<!--                      >-->
<!--                        Create-->
<!--                      </v-btn>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-container>-->
<!--              </v-card>-->
<!--            </v-dialog>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-expansion-panel-header>-->
<!--      <v-expansion-panel-content>-->
<!--        <v-divider/>-->
<!--        <v-data-table-->
<!--          class='pa-2'-->
<!--          :headers='settingsHeaders'-->
<!--        >-->
<!--          <template v-slot:no-data>-->
<!--            There are no notifications settings in your account.-->
<!--          </template>-->
<!--        </v-data-table>-->
<!--      </v-expansion-panel-content>-->
<!--    </v-expansion-panel>-->
<!--  </v-expansion-panels>-->

</span>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    loading: false,
    notifPeriods: [
      { text: 'Never', value: 'never' },
      { text: '2 hours', value: '2' },
      { text: '4 hours', value: '4' },
      { text: '12 hours', value: '12' },
      { text: '24 hours', value: '24' }
    ],
    notifPeriod: '4',
    lowBound: 0,
    profiles: [],
    channels: [],

    profilesHeaders: [
      { text: 'Name', value: 'name' }
    ],
    newProfileDialog: false,
    newProfileName: '',

    channelsHeaders: [
      { text: 'Type', value: 'type' },
      { text: 'Destination', value: 'destination' },
      { text: 'Profile', value: 'profile' }
    ],
    newChannelDialog: false,
    newChannelProfile: null,
    notificationTypes: [
      { text: 'sms', value: 'sms' },
      { text: 'voice', value: 'voice' },
      { text: 'email', value: 'email' },
      { text: 'webhook', value: 'webhook' }
    ],
    notificationType: 'sms',
    newChannelDestinationNumber: '',
    newChannelDestinationEmail: '',
    newChannelDestinationWeb: '',

    settingsHeaders: [
      { text: 'Status', value: 'status' },
      { text: 'Profile', value: 'profile' },
      { text: 'Channel', value: 'channel' },
      { text: 'Event', value: 'Event' }
    ],
    newSettingDialog: false,
    newSettingEvents: [
      { text: 'When a number order completes', value: 'number_order_completes' }
    ],
    newSettingEvent: null,
    newSettingProfile: null,
    newSettingsChannel: null,
    data: {
      notifications_balance: false,
      notifications_ticket: false,
      notifications_tn_order: false
    }
  }),
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      axios
        .get('rest/user/wss')
        .then(res => {
          this.data = { ...this.data, ...res.data.payload }
        })
    },
    createProfile: function () {
      this.newProfileDialog = false
    },
    createChannel: function () {
      this.newChannelDialog = false
    },
    createSetting: function () {
      this.newSettingDialog = false
    },
    saveNotification: function () {
      this.loading = true
      const obj = {
        wss_tn_order: this.data.wss_tn_order,
        wss_balance: this.data.wss_balance,
        wss_ticket: this.data.wss_ticket
      }
      axios
        .patch('rest/user/wss', obj)
        .then(res => {
          this.loading = false
          this.fetchData()
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  computed: {
    createChannelDisabled: function () {
      if (this.newChannelProfile === null) {
        return true
      } else if (this.notificationType === 'sms' || this.notificationType === 'voice') {
        if (this.newChannelDestinationNumber === '') {
          return true
        } else {
          return false
        }
      } else if (this.notificationType === 'email' && this.newChannelDestinationEmail !== '') {
        return false
      } else if (this.notificationType === 'webhook' && this.newChannelDestinationWeb !== '') {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
