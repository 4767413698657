<template>
  <Card
    title='SIP Apps'
    :count="count"
    icon='mdi-file-phone-outline'
    :loading="loading"
  >
  </Card>
</template>

<script>
import Card from './Card'

export default {
  components: {
    Card
  },
  computed: {
    // getters
    list () {
      return this.$store.getters['callControl/list']
    },
    loading () {
      return this.$store.getters['callControl/loading']
    },
    errorMessage () {
      return this.$store.getters['callControl/errorMessage']
    },
    //

    count () {
      return this.list ? this.list.length : 0
    }
  },
  methods: {
    // actions
    requestList () {
      return this.$store.dispatch('callControl/requestList')
    }
  },
  mounted () {
    this.requestList().then(res => {
      if (!res) {
        alert(this.errorMessage)
      }
    })
  }
}
</script>
