<template>
  <v-dialog
    v-model="localValue"
    width='750'
    :retain-focus="false"
  >
    <v-card class='pa-5'>
      <Details
        @parentEventAfterSuccess="parentEventAfterSuccess"
        :is-new-dialog="true"
        :no-header="true"
        current-module="sipConnections"
      >
        <template v-slot="{ item }">
          <v-form
            ref='form'
          >
            <v-row>
              <v-col cols='12' md='4'>
                <o-text-field
                  v-model="item.name"
                  input-label='Name'
                  label='eg. Chicago Office, Manila Call Center'
                  :rules="[notEmpty]"
                  :hide-details="false"
                >
                </o-text-field>
              </v-col>
              <v-col cols='12' md='4'>
                <o-select
                  v-model="item.type"
                  input-label='Type'
                  :items="types"
                  @change="selectType(item.type)"
                >
                </o-select>
              </v-col>
              <v-col cols='12' md='6'>
                <o-select
                  v-model="item.type"
                  input-label='Type'
                  :items="types"
                  @change="selectType(item.type)"
                >
                </o-select>
              </v-col>
              <v-col cols='12' md='4'>
                <o-select
                  v-if="InboundType"
                  input-label='SIP Product'
                  :items="products"
                  :loading="loadingProducts"
                  :rules="[notEmpty]"
                  item-text="name"
                  :hide-details="false"
                  v-model="item.product"
                  :return-object="true"
                >
                </o-select>
              </v-col>
              <v-col cols='12' md='4'>
                <o-select
                  input-label='SIP Product'
                  :items="products"
                  :loading="loadingProducts"
                  :rules="[notEmpty]"
                  item-text="name"
                  :hide-details="false"
                  v-model="item.product"
                  :return-object="true"
                >
                </o-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  class="mt-0"
                  v-model="radios"
                  row
                >
                  <v-radio
                    label="IP Address"
                    value="ipAddress"
                  ></v-radio>
                  <v-radio
                    label="Hostname"
                    value="hostName"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols='12' md='5' sm="6" v-if="radios === 'ipAddress'">
                <vue-IP-Input
                  input-label='IP Address'
                  v-model="item.ip"
                />
                <!--            <o-text-field-->
                <!--              v-model="item.ip"-->
                <!--              input-label='IP Address'-->
                <!--              :rules="[hostnameOrAddress]"-->
                <!--              :hide-details="false"-->
                <!--              validate-on-blur-->
                <!--            >-->
                <!--            </o-text-field>-->
              </v-col>
              <v-col cols='8' md='4' sm="3" v-if="radios === 'hostName'">
                <o-text-field
                  v-model="item.hostname"
                  input-label='Hostname'
                  :rules="[notEmpty, validHost]"
                  :hide-details="false"
                >
                </o-text-field>
              </v-col>
              <v-col cols='4' md='2' v-if="radios === 'ipAddress'">
                <o-text-field
                  v-model="item.port"
                  input-label='Port'
                  :rules="[notEmpty, validPort]"
                  :hide-details="false"
                >
                </o-text-field>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </Details>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vueIpInput from '@/components/vueIPInput'
import Details from '../../components/CRUD/Details.vue'

const hostnameOrAddress = (val) => {
  return (!!val) || 'Either IP Address or Hostname should be set'
}
export default {
  data: () => ({
    radios: 'ipAddress',
    InboundType: false,
    hostnameOrAddress: hostnameOrAddress,
    notEmpty: val => !!val || 'Required ',
    validPort: val => !!(Number(val) && Number(val) >= 1024) || 'Should be more than 1024',
    validHost: val => (!!val && !/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$/.test(val)) || 'Should be valid Hostname',
    shakenPolicies: [0, 1, 2, 3],
    shakenLevels: ['C', 'B', 'A'],
    types: [
      {
        text: 'Inbound',
        value: 'inbound'
      },
      {
        text: 'Outbound',
        value: 'outbound'
      }
    ]
  }),
  methods: {
    ...mapActions({
      requestProducts: 'sipProducts/requestList',
      notify: 'notification/notify'
    }),
    parentEventAfterSuccess () {
      this.localValue = false
    },
    selectType (type) {
      if (type === 'inbound') {
        this.InboundType = true
      } else {
        this.InboundType = false
      }
    }
  },
  computed: {
    ...mapGetters({
      loadingProducts: 'sipProducts/loading',
      productsErrorMessage: 'sipProducts/errorMessage',
      sipProducts: 'sipProducts/list',
      current: 'sipConnections/current'
    }),
    products () {
      return this.sipProducts.filter(el => el.sip_connection_allowed)
    }
    // hostnameOrAddress () {
    //   return (val) => {
    //     if (!(this.current && (this.current.ip || this.current.hostname))) {
    //       return 'Either IP Address or Hostname should be set'
    //     } else {
    //       return true
    //     }
    //   }
    // }
  },
  created: function () {
    this.requestProducts().then(res => {
      if (!res) {
        this.notify({
          text: this.productsErrorMessage,
          type: 'error'
        })
      }
    })
  },
  components: {
    Details,
    'vue-IP-Input': vueIpInput
  }
}
</script>
