<template>
<span>
  <v-list-item :ripple="false" @click="logout">
    <v-list-item-title>Logout</v-list-item-title>
  </v-list-item>
  <meta name="google-signin-client_id" :content="client_id">
</span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import loadScript from '../services/load.service'

export default {
  computed: {
    ...mapGetters({
      secret: 'secret/google'
    }),
    client_id () {
      return this.secret ? this.secret.client_id : null
    }
  },
  created () {
    if (this.client_id) {
      this.init()
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    init () {
      loadScript('https://apis.google.com/js/platform.js').then(() => {
        window.gapi.load('auth2', () => {
          window.gapi.auth2.init()
        })
      })
    }
  },
  watch: {
    client_id (val) {
      if (val) {
        this.init()
      }
    }
  }
}
</script>
