import ItemBase from '../itemBase.service'

class CALLAPIClass extends ItemBase {
  buildSearchRequest (searchParams) {
    searchParams = super.buildSearchRequest(searchParams)
    searchParams.tn = searchParams.tn ? searchParams.tn : undefined
    searchParams.direction = searchParams.direction ?? undefined

    return searchParams
  }
}

export const CALLAPI = new CALLAPIClass('/rest/call')
