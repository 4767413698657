import ItemBase from './itemBase.service'

class TransactionAPI extends ItemBase {
  buildSearchRequest (request) {
    request = super.buildSearchRequest(request)
    request.types = request.types ? request.types : undefined
    request.type = request.type ? request.type : undefined
    request.description = request.description ? request.description : undefined
    request.tn = request.tn ? request.tn : undefined
    request.account_balance_after_max = request.account_balance_after_max ? request.account_balance_after_max : undefined
    request.account_balance_after_min = request.account_balance_after_min ? request.account_balance_after_min : undefined
    request.amount_min = request.amount_min ? request.amount_min : undefined
    request.amount_max = request.amount_max ? request.amount_max : undefined
    request.mode = request.mode ? request.mode : undefined

    return request
  }
}

export default new TransactionAPI('/rest/transaction')
