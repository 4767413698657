<template>
  <SearchForm>
    <v-form
      ref='form'
    >
      <v-container class="py-0 primary--text">
        <SearchFilters
          v-model="searchFilters"
          ref='filters'
        >
        </SearchFilters>
        <v-row>
          <v-col>
            <v-btn
              @click="searchClicked"
              text
              class="primary white--text"
              :ripple="false"
              :block="$vuetify.breakpoint.smAndDown"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <template v-slot:dataResults>
      <v-card flat class='pa-4'>
        <div v-if="loading" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div class="table-responsive" v-else>
          <table class="table table-striped table-hover aliases" v-if="searchResults.length">
            <thead>
            <tr>
              <template v-for="(item,index) in dataHeaders">
                <th rowspan="2" :key="index" v-if="!item.children">{{ item.text }}</th>
                <th :colspan="item.children ? item.children.length : 1" :key="index" v-else>{{ item.text }}</th>
              </template>
            </tr>
            <tr v-if="mapChildrens.length">
              <th v-for="(item, index) in mapChildrens" :key="index">{{ item.text }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, inx) in searchResults" :key="inx">
              <td>
                {{ beautifyNumber(item.tn) }}
              </td>
              <td>
                {{ item.state ? item.state : '---' }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'sms')?.mrc }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'sms')?.nrc }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'sms')?.per_usage_in }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'sms')?.per_usage_out }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'voice')?.mrc }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'voice')?.nrc }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'voice')?.per_usage_in }}
              </td>
              <td>
                {{ item.price.features.find(_ => _.feature === 'voice')?.per_usage_out }}
              </td>
              <td>
                <NumberFeatures
                  v-model='item.features'
                  :editable="true"
                >
                </NumberFeatures>
              </td>
              <td>
                <v-btn
                  block
                  v-if="!isNumberInCart(item)"
                  text
                  :ripple='false'
                  :loading="item.loading"
                  class='primary white--text'
                  @click="addNumberToCart(item)"
                >
                  <span><v-icon size='1.5rem'>mdi-cart</v-icon>Add to Cart</span>
                </v-btn>
                <v-btn
                  block
                  v-else
                  outlined
                  :ripple='false'
                  color='success'
                  to='/app/cart'
                >
                  Go To Cart
                </v-btn>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <!--        <v-data-table-->
        <!--          :headers="dataHeaders"-->
        <!--          :items="searchResults"-->
        <!--          :items-per-page="itemsPerPage"-->
        <!--          :loading="isInProgress"-->
        <!--          disabled-->
        <!--          :hide-default-footer="true"-->
        <!--        >-->
        <!--          <template v-slot:item.tn="{ item }">-->
        <!--            {{ beautifyNumber(item.tn) }}-->
        <!--          </template>-->
        <!--          <template v-slot:item.state="{ item }">-->
        <!--            {{ item.state ? item.state : '-&#45;&#45;' }}-->
        <!--          </template>-->
        <!--          <template v-slot:item.rate_center="{ item }">-->
        <!--            {{ item.rate_center ? item.rate_center : '-&#45;&#45;' }}-->
        <!--          </template>-->
        <!--          <template v-slot:item.features="{ item }">-->
        <!--            <NumberFeatures-->
        <!--                    v-model='item.features'-->
        <!--                    :editable="true"-->
        <!--            >-->
        <!--            </NumberFeatures>-->
        <!--          </template>-->
        <!--          <template v-slot:item.purchase="{ item }">-->
        <!--            <v-btn-->
        <!--              block-->
        <!--              v-if="!isNumberInCart(item)"-->
        <!--              text-->
        <!--              :ripple='false'-->
        <!--              :loading="item.loading"-->
        <!--              class='primary white&#45;&#45;text'-->
        <!--              @click="addNumberToCart(item)"-->
        <!--            >-->
        <!--              <span><v-icon size='1.5rem'>mdi-cart</v-icon>Add to Cart</span>-->
        <!--            </v-btn>-->
        <!--            <v-btn-->
        <!--              block-->
        <!--              v-else-->
        <!--              outlined-->
        <!--              :ripple='false'-->
        <!--              color='success'-->
        <!--              to='/app/cart'-->
        <!--            >-->
        <!--              Go To Cart-->
        <!--            </v-btn>-->
        <!--          </template>-->
        <!--        </v-data-table>-->
      </v-card>
    </template>
  </SearchForm>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Countries from './Countries'
import BeautifyNumber from '../../services/beautify/number.service'
import NumberFeatures from '../../components/NumberFeatures'
import SearchFilters from './SearchFilters'

export default {
  components: {
    SearchForm,
    Countries,
    NumberFeatures,
    SearchFilters
  },
  data: () => ({
    dataHeaders: [
      {
        text: 'Number',
        width: '160px',
        value: 'tn'
      },
      {
        text: 'State',
        value: 'state'
      },
      {
        text: 'SMS',
        value: 'SMS',
        children: [
          {
            text: 'Monthly Fee',
            value: 'price.features.find(_ => _.feature === \'sms\')?.mrc'
          },
          {
            text: 'Setup Fee',
            value: 'price.features.find(_ => _.feature === \'sms\')?.nrc'
          },
          {
            text: 'SMS In',
            value: 'price.features.find(_ => _.feature === \'sms\')?.per_usage_in'
          },
          {
            text: 'SMS out',
            value: 'price.features.find(_ => _.feature === \'sms\')?.per_usage_out'
          }
        ]
      },
      {
        text: 'Voice',
        value: 'Voice',
        children: [
          {
            text: 'Monthly Fee',
            value: 'price.features.find(_ => _.feature === \'voice\')?.mrc'
          },
          {
            text: 'Setup Fee',
            value: 'price.features.find(_ => _.feature === \'voice\')?.nrc'
          },
          {
            text: 'Inbound Min',
            value: 'price.features.find(_ => _.feature === \'voice\')?.per_usage_in'
          },
          {
            text: 'Outbound Min',
            value: 'price.features.find(_ => _.feature === \'voice\')?.per_usage_out'
          }
        ]
      },
      {
        text: 'Features',
        value: 'features',
        width: '160px'
      },
      {
        text: 'Purchase',
        value: 'purchase'
      }
    ],
    searchFilters: {},
    loading: false
  }),
  computed: {
    ...mapGetters({
      isInProgress: 'search/isInProgress',
      searchResults: 'search/getResults',
      isNumberInCart: 'cart/isNumberInCart',
      errorMessage: 'search/errorMessage'
    }),
    mapChildrens () {
      const arr = []
      this.dataHeaders.filter(item => item.children).map(item => {
        arr.push(...item.children)
      })
      return arr
    },
    itemsPerPage () {
      return 100
    }
  },
  methods: {
    validate () {
      return this.$refs.filters ? this.$refs.filters.validate() : false
    },
    ...mapActions({
      searchNumbers: 'search/searchNumbers',
      addToCart: 'cart/addToCart',
      notify: 'notification/notify'
    }),
    ...mapMutations({
      clearResults: 'search/clearResults'
    }),
    addNumberToCart (item) {
      this.$set(item, 'loading', true)
      this.addToCart({
        ...item
      })
    },
    searchClicked () {
      if (this.validate()) {
        this.loading = true
        this.searchNumbers({
          searchParams: this.searchFilters,
          take: this.itemsPerPage
        }).then(res => {
          this.loading = false
          if (typeof res === 'boolean' && !res) {
            this.notify({
              text: this.errorMessage,
              type: 'error'
            })
          }
          if (res && !res.total) {
            this.notify({
              text: 'No Data Found',
              type: 'error'
            })
          } else if (res && res.total) {
            this.notify({ text: 'number search has been done successfully' })
          }
        })
      }
    },
    beautifyNumber: number => BeautifyNumber.beautify(number)
  },
  // mounted () {
  // this.loading = true
  // this.searchNumbers({
  //   searchParams: this.searchFilters,
  //   take: this.itemsPerPage
  // }).then(res => {
  //   this.loading = false
  // })
  // },
  created: function () {
    this.clearResults()
    this.$nextTick(() => {
      this.$refs.form.resetValidation()
    })
  }
}
</script>
<style>
.table-responsive {
  border: 1px solid #dee0e3;
  -webkit-box-shadow: 0px 2px 7px 0px rgb(184 187 196 / 20%);
  box-shadow: 0px 2px 7px 0px rgb(184 187 196 / 20%);
  border-radius: 4px;
  min-width: 440px;
  margin-left: -1px;
  margin-right: -1px;
  overflow-y: hidden;
  overflow: auto;
  min-height: .01%;
  overflow-x: auto;
}

.table {
  height: 100% !important;
  width: 100%;
  max-width: 100%;
  min-width: 490px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

table.table.table-striped thead tr th:first-child {
  border-left: 0px;
}

table.table.table-striped.table-hover th {
  font-weight: normal;
  border-left: 1px solid #dee0e3;
  font-size: 13px;
  text-align: left;
}

table.table.table-striped.table-hover th {
  position: relative;
  vertical-align: middle;
  padding-right: 12px;
}

table.table.table-striped thead tr th {
  border: 1px solid #ddd;
}

table.table.table-striped tr th {
  padding: 8px 6px;
}

table.table.table-striped.table-hover th:first-child {
  text-align: left;
}

table.table.table-striped.table-hover th:first-child {
  text-align: left;
}

table.table.table-striped thead tr th:first-child {
  border-left: 0px;
}

.table > caption + thead > tr:first-child > td, .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > td, .table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

table.table.table-striped.table-hover th {
  font-weight: normal;
  border-left: 1px solid #dee0e3;
  font-size: 13px;
  text-align: left;
}

table.table.table-striped.table-hover th {
  font-weight: normal;
  border-left: 1px solid #dee0e3;
  font-size: 13px;
  text-align: left;
}

table.table.table-striped.table-hover th {
  /*border-left: 3px solid #dee0e3;*/
}

table.table.table-striped.table-hover th {
  position: relative;
  vertical-align: middle;
  padding-right: 12px;
}

table.table.table-striped thead tr th {
  border: 1px solid #ddd;
}

table.table.table-striped tr th {
  padding: 8px 6px;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #ddd;
  background: #edeff5;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #ddd;
  background: #edeff5;
}

table.table thead th {
  font-weight: normal;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table th {
  text-align: center !important;
}

table thead th {
  font-size: 13px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: left;
}

td, th {
  padding: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background: #f7f8f9;
}

table.table.table-striped.table-hover td:first-child {
  text-align: center;
}

table.table.table-striped.table-hover td:first-child {
  text-align: left;
}

table.table.table-striped.table-hover tr td, table.table.table-striped.table-hover.roles tr td {
  vertical-align: middle;
}

table.table.table-striped.table-hover td {
  /* text-align: right; */
  font-size: 13px;
}

table.table.table-striped.table-hover td {
  /* text-align: right; */
  font-size: 13px;
}

table.table tbody tr td:first-child {
  border-left: 0;
}

table.table tbody tr td {
  border: 1px solid #ddd;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table td {
  text-align: center;
}

td, th {
  padding: 0;
}
</style>
