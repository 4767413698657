import ItemBase from './itemBase'
import AccountUsersAPI from '../services/accountUsers.service'

class AccountUsers extends ItemBase {
  actions () {
    const actions = super.actions()

    actions.deleteItem = async function ({ commit, getters }, uuid) {
      try {
        const list = await getters.API.delete(uuid)
        commit('set', list)

        return true
      } catch (error) {
        commit('failDelete', error)
        return false
      }
    }

    actions.activate = async function ({ commit, getters }, uuid) {
      try {
        const list = await getters.API.activate(uuid)
        commit('set', list)

        return true
      } catch (error) {
        commit('failSaving', error)
        return false
      }
    }

    return actions
  }
}

export const accountUsers = new AccountUsers(AccountUsersAPI, '/app/account/users').getModule()
