import { DictBase } from '../dict.service'

class BrandVerticalDict extends DictBase {
  parseResponse (data) {
    const resultArr = []
    for (const field in data) {
      resultArr.push({
        value: field,
        text: data[field].displayName
      })
    }

    return resultArr
  }
}

export default BrandVerticalDict
