<template>
  <span>
    <label v-if="hasLabel" class="input-label">
      {{inputLabel}}
      <slot name='input-label'></slot>
    </label>
    <v-file-input
      v-model="localValue"
      :persistent-hint="hasHint"
      :messages="hint === '' ? 'stub' : hint"
      :label="label"
      :type="type"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
      :hide-details="!hasHint"
      :rules="rules"
      :error-messages="errorMessages"
      :prepend-icon="null"
      outlined
      solo
      dense
      flat
      single-line
      ref='fileInput'
    >
      <template v-slot:prepend-inner>
        <slot name='prepend-inner'>
        </slot>
      </template>
      <template v-slot:append-outer>
        <slot name='append-outer'>
        </slot>
      </template>
      <template v-slot:message>
        <slot name='hint'></slot>
      </template>
      <template v-slot:append>
        <v-btn
          class='primary px-0 px-sm-2 px-md-4 white--text mr-n3 no-left-border-radius'
          :height="40"
          :ripple="false"
          @click="triggerClick"
          text
        >
          Browse
        </v-btn>
      </template>
     </v-file-input>
  </span>
</template>

<script>
export default {
  props: {
    value: null,
    label: {
      type: String
    },
    inputLabel: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    type: {
      type: String
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    step: {
      type: Number
    },
    disabled: {
      type: Boolean
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array
    },
    errorMessages: {
      type: Array
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    hasLabel: function () {
      const hasSlot = !!this.$slots['input-label']
      const hasProp = this.inputLabel !== ''

      return hasSlot || hasProp
    },
    hasHint: function () {
      const hasSlot = !!this.$slots.hint
      const hasProp = this.hint !== ''

      return hasSlot || hasProp
    }
  },
  methods: {
    triggerClick: function () {
      const fileInput = this.$refs.fileInput.$el.getElementsByTagName('input')
      if (fileInput.length) {
        fileInput[0].click()
      }
    }
  }
}
</script>
