<template>
    <v-card flat tile>
      <v-container>
        <v-row class='px-0 px-md-4'>
          <v-col cols='9' sm='11'>
            <h2>Hooks</h2><h5>for {{currModuleInfo}} <b>{{ currentItem ? currentItem.name : ''}}</b></h5>
          </v-col>
          <v-spacer />
          <v-col sm='auto'>
            <v-btn
              text
              class='pa-0'
              dense
              :min-width="0"
              width=25
              :ripple="false"
              :to="$store.getters[`${this.currModule}/APP_LINK`]"
            >
              <v-icon>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
          <v-tabs
            v-model="currentTab"
            hide-slider
          >
            <v-tab
              :hidden="$vuetify.breakpoint.smAndDown"
              active-class='tab-active'
              v-for="item in tabs"
              :key="item.value"
              :ripple="false"
              :href="'#' + item.value"
            >
              <v-btn
                text
                class='grey white--text'
                :ripple="false"
              >
                {{item.text}}
              </v-btn>
            </v-tab>
            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              offset-y
              top
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  block
                  text
                  class="align-self-center mr-4 primary white--text"
                  v-on="on"
                  :ripple="false"
                >
                  {{tabs.find(el => el.value === currentTab).text}}
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in tabs"
                  :key="item.value"
                  :ripple="false"
                  @click="switchTab(item)"
                >
                  <v-list-item-title>
                    {{item.text}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tabs-items
              v-model="currentTab"
            >
              <v-tab-item
                v-for="tab in tabs"
                :key="tab.value"
                :value="tab.value"
                :transition="false"
                :reverse-transition="false"
              >
                <v-container class='pa-0 pa-md-4'>
                  <component
                    :is="tab.value"
                    :connection="currentItem"
                    :ref="tab.value"
                  />
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>

<style lang='scss' scoped>
.tab-active .v-btn {
  color: white !important;
  background-color: $primary !important;
}
</style>

<script>
import WebHook from './WebHook'
import CallFlow from './CallFlow'
import RelatedCall from './RelatedCall'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    connection: {
      type: Object,
      default: null
    },
    initialTab: {
      type: String
    },
    currModule: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    connectionName: '',
    subdomain: '',
    product: null,
    ip: '',
    port: null,
    refresh: false,
    notEmpty: val => !!val || 'Required',
    moreThanFive: val => (val && val.length >= 5) || 'More then 5 chars are required',
    tabs: [
      { text: 'External Webook', value: 'WebHook' },
      { text: 'Call Flow XML', value: 'CallFlow' }
    ],
    currentTab: 'callflow'
  }),
  methods: {
    // actions
    async requestOne (uuid) {
      return await this.$store.dispatch(`${this.currModule}/requestOne`, uuid)
    },

    // mutations
    clearCurrent () {
      this.$store.commit(`${this.currModule}/clearCurrent`)
    },
    setCurrent (uuid) {
      this.$store.commit(`${this.currModule}/setCurrent`, uuid)
    },
    setDefault () {
      this.$store.commit(`${this.currModule}/setDefault`)
    },

    switchTab: function (item) {
      this.$nextTick(() => {
        this.currentTab = item.value
      })
    },
    onStart () {
      this.clearCurrent()
      if (!this.isNew) {
        if (this.list && this.list.length) {
          this.$nextTick(function () {
            this.setCurrent(this.uuid)
          })
        }
        this.requestOne(this.uuid)
      } else {
        this.setDefault()
      }
    }
  },
  computed: {
    // getters
    currentItem () {
      return this.$store.getters[`${this.currModule}/current`]
    },
    saving () {
      return this.$store.getters[`${this.currModule}/saving`]
    },
    list () {
      return this.$store.getters[`${this.currModule}/list`]
    },
    isNew () {
      return this.$route.fullPath.split('/')[3] === 'new'
    },
    uuid () {
      return this.$route.params.uuid
    },
    //

    currModuleInfo () {
      switch (this.currModule) {
        case 'sipConnections':
          return 'SIP Connection'
        case 'callControl':
          return 'SIP Control App'
        default:
          return ''
      }
    }
  },
  watch: {
    $route (to, from) {
      this.onStart()
    }
  },
  created: function () {
    this.currentTab = this.initialTab
    this.onStart()
  },
  components: {
    WebHook,
    CallFlow,
    RelatedCall
  }
}
</script>
