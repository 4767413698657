import ItemBase from '../itemBase.service'

class MessagingProfileAPI extends ItemBase {
  buildRequest (item) {
    return {
      name: item.name,
      callback_url: item.callback_url || undefined
      // number_pool_enable: item.number_pool_enable,
      // long_code_weight: item.long_code_weight,
      // tollfree_weight: item.tollfree_weight,
      // skip_unhealthy_tns: item.skip_unhealthy_tns,
      // sticky_sender: item.sticky_sender
    }
  }
}

export default new MessagingProfileAPI('/rest/profile/messaging')
