import ApiService from './api.service'

function prepareCommonRequest (searchParams, take) {
  return {
    country: searchParams.country || 'US',
    type: searchParams.type === 'toll_free' ? 'toll_free' : 'long_code',
    npa: searchParams.npa || undefined,
    nxx: searchParams.nxx || undefined,
    line: searchParams.line || undefined,
    pattern: searchParams.type === 'toll_free' ? searchParams.pattern ?? 'xxxxxxx' : undefined,
    state: searchParams.state ? searchParams.state : undefined,
    rate_center: searchParams.rate_center ? searchParams.rate_center : undefined,
    features: searchParams.features ? searchParams.features : undefined,
    take: searchParams.type !== 'toll_free' ? take : undefined
  }
}

function prepareRequest (searchParams, take) {
  return prepareCommonRequest(searchParams, take)
}

const SearchService = {
  searchNumbers: async function (searchParams, take) {
    const requestPayload = prepareRequest(searchParams, take)

    try {
      const response = await ApiService.post('/rest/tn/search', {
        ...requestPayload
      })

      return response.data.payload
    } catch (error) {
      throw new Error(error.response.data.fields.params.message)
    }
  }
}

export default SearchService
