<template>
   <v-form
    ref='form'
  >
    <v-row>
      <v-col cols='12' md='6'>
<!--        <o-select-->
<!--          input-label='Company Name*'-->
<!--          :items="messageProfilesItems"-->
<!--          :loading="messageProfilesLoading"-->
<!--          :value="''"-->
<!--          item-text='name'-->
<!--          item-value='uuid'-->
<!--          :rules="[notEmpty]"-->
<!--        >-->
<!--        </o-select>-->
          <o-text-field
                  input-label='Company Name*'
                  :loading="messageProfilesLoading"
                  v-model="item.companyName"
                  :rules="[notEmpty]"
                  :hide-details="false"
          >
          </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Brand Name*'
          v-model="item.displayName"
          :rules="[notEmpty]"
          :hide-details="false"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-select
          input-label='Brand Type*'
          v-model="item.brand_type"
          :items="brandTypes"
          :hide-details="false"
          :rules="[notEmpty]"
        >
        </o-select>
      </v-col>
        <v-col cols='12' md='6'>
            <o-select
                    input-label='DUNS Number, GIIN, or LEI Number'
                    v-model="item.altBusinessIdType"
                    :items="pickNumberList"
            >
            </o-select>
        </v-col>
        <v-col cols='12' md='6'>
            <o-text-field
                    v-model="item.altBusinessId"
            >
                <template v-slot:input-label>
                    {{item.altBusinessIdType ? item.altBusinessIdType + ' Number' : 'DUNS Number'}}
                </template>
            </o-text-field>
        </v-col>
      <v-col cols='12' md='6'>
        <o-select
          input-label='Vertical*'
          v-model="item.vertical"
          :items="verticalItems"
          :loading="verticalLoading"
          :hide-details="false"
          :rules="[notEmpty]"
        >
        </o-select>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='EIN*'
          v-model="item.ein"
          :hide-details="false"
          :rules="[notEmpty,EINValidate]"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-tel-text-input
          input-label='Phone Number*'
          v-model='item.phone'
          :hide-details="false"
          :rules="[notEmpty, validNumber]"
          :as-text="true"
        >
        </o-tel-text-input>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Email*'
          v-model="item.email"
          autocomplete='email'
          type="email"
          :hide-details="false"
          validate-on-blur
          :rules="[notEmpty, validEmail]"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Website*'
          v-model="item.website"
          :hide-details="false"
          :rules="[notEmpty]"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-select
          input-label='Stock Exchange'
          v-model="item.stockExchange"
          :loading="stockExchangeLoading"
          :items="stockExchangeItems"
        >
        </o-select>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Stock Symbol'
          v-model="item.stockSymbol"
        >
        </o-text-field>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col cols='12'>
        <h4>Address</h4>
      </v-col>
      <v-col cols='12' md='6'>
        <o-select
          input-label='State*'
          v-model="item.state"
          :items="states"
          :loading="statesLoading"
          :hide-details="false"
          :rules="[notEmpty]"
        >
        </o-select>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='City*'
          v-model="item.city"
          :hide-details="false"
          :rules="[notEmpty]"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Postal Code*'
          v-model="item.postalCode"
          :hide-details="false"
          :rules="[notEmpty, postCodeValidate]"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='6'>
        <o-text-field
          input-label='Street*'
          :hide-details="false"
          v-model="item.street"
          :rules="[notEmpty]"
        >
        </o-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pickType: '',
    brandTypes: [
      { text: 'Private Profit', value: 'PRIVATE_PROFIT' },
      { text: 'Public Profit', value: 'PUBLIC_PROFIT' },
      { text: 'Non-Profit', value: 'NON_PROFIT' }
    ],
    notEmpty: val => !!val || 'Required',
    postCodeValidate: val => (val.length === 5 || (val.length === 10 && val[6] === '-')) || 'Must be ##### or #####-#### format',
    EINValidate: val => val.length >= 9 || 'Min 9 characters',
    validNumber: val => val.match(/^[0-9+ ]+$/) || 'Should be only numeric',
    pickNumberList: [
      { text: 'DUNS', value: 'DUNS' },
      { text: 'GIIN', value: 'GIIN' },
      { text: 'LEI', value: 'LEI' }
    ]
  }),
  methods: {
    ...mapActions({
      requestVertical: 'dict/brandVertical/requestList',
      requestStockExchange: 'dict/brandStockExchange/requestList',
      requestStates: 'dict/states/requestList',
      requestMessageProfiles: 'messaging/profiles/requestList'
    }),
    choose (item) {
      this.item.messageProfiles = item.value
    },
    validate () {
      return this.$refs.form.validate()
    }
  },
  computed: {
    ...mapGetters({
      verticalItems: 'dict/brandVertical/list',
      verticalLoading: 'dict/brandVertical/loading',
      verticalError: 'dict/brandVertical/errorMessage',
      stockExchangeItems: 'dict/brandStockExchange/list',
      stockExchangeLoading: 'dict/brandStockExchange/loading',
      stockExchangeError: 'dict/brandStockExchange/errorMessage',
      states: 'dict/states/list',
      statesLoading: 'dict/states/loading',
      statesError: 'dict/states/errorMessage',
      messageProfilesItems: 'messaging/profiles/list',
      messageProfilesLoading: 'messaging/profiles/loading',
      messageProfilesError: 'messaging/profiles/errorMessage'
    }),
    validEmail () {
      return val => {
        return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) || 'Email is not valid'
      }
    }
  },
  created () {
    this.requestVertical().then(res => {
      if (!res) {
        alert('Cannot load vertical options list: ' + this.verticalError)
      }
    })
    this.requestStockExchange().then(res => {
      if (!res) {
        alert('Cannot load stock exchange options list: ' + this.requestStockExchange)
      }
    })
    this.requestStates().then(res => {
      if (!res) {
        alert('Cannot load states list: ' + this.statesError)
      }
    })
    this.requestMessageProfiles().then(res => {
      if (!res) {
        this.notify({ text: this.scaError, type: 'error' })
      }
    })
  }
}
</script>
