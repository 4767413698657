<template>
  <Details
    current-module="callControl"
  >
    <template v-slot="{ item }">
      <v-container>
        <v-form
          ref='form'
        >
          <v-row>
            <v-col cols='12' md='4'>
              <o-text-field
                v-model="item.name"
                input-label='Name'
                label='eg. Chicago Office, Manila Call Center'
                :rules="[notEmpty]"
                :hide-details="false"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    A user-assigned name to help manage the application.
                  </v-tooltip>
                </template>
              </o-text-field>
            </v-col>
            <v-col cols='12' md='4'>
              <o-select
                v-model="item.product"
                input-label='SIP Product'
                :items="products"
                :loading="loadingProducts"
                :rules="[notEmpty]"
                item-text="name"
                :return-object="true"
              >
              </o-select>
            </v-col>
            <v-col cols='12' md='4'>
              <o-select
                v-model="item.tts_settings"
                input-label='Text-to-speech settings'
                :items="ttsSettings"
                :loading="loadingTTS"
                item-text="name"
                :return-object="true"
                lable='Select'
              >
              </o-select>
            </v-col>
            <v-col cols='12' md='4' align-self="start">
              <o-switch
                input-label='Auto Answer'
                v-model="item.auto_answer"
                :hide-details="true"
                inset
                height='31px'
              >
              </o-switch>
            </v-col>
            <v-col cols='12' md='4' align-self="start">
              <o-switch
                input-label='Record calls'
                v-model="item.record_calls"
                :hide-details="true"
                inset
                height='31px'
              >
              </o-switch>
            </v-col>
            <v-col cols='12' md='4' align-self="start">
              <o-select
                v-if="item.record_calls"
                v-model="item.s3_settings"
                input-label='S3 Settings for Call Records'
                :items="s3settings"
                :loading="loadingS3"
                item-text="name"
                :return-object="true"
              >
              </o-select>
            </v-col>
          </v-row>
          <v-expansion-panels class='mt-5' flat>
            <v-expansion-panel class='outlined'>
              <v-expansion-panel-header>
                <h2>Advanced</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="applications__advanced">
                  <v-col cols='12' md='4'>
                    <o-select
                      v-model="item.webhook_method"
                      :items="webhookMethods"
                      input-label='Webhook Method'
                      clearable
                    >
                    </o-select>
                  </v-col>
                  <v-col cols='12' md='4'>
                    <o-text-field
                      v-model="item.webhook_url"
                      input-label='Webhook URL'
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          Webhooks for this application will be sent to this URL. Must include a scheme such as 'https'.
                          'state', 'from' and 'to' will be sent as query params.
                        </v-tooltip>
                      </template>
                    </o-text-field>
                  </v-col>
                  <v-col cols='12' md='4'>
                    <o-text-field
                      v-model="item.webhook_request_timeout"
                      input-label='Webhook Request Timeout, ms'
                      type='number'
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          Timeout in ms, it resets on redirect. 0 to disable (OS limit applies)
                        </v-tooltip>
                      </template>
                    </o-text-field>
                  </v-col>
                  <v-col cols='12' md='4'>
                    <o-select
                      v-model="item.call_flow_method"
                      :items="webhookMethods"
                      input-label='Call Flow Method'
                      clearable
                    >
                    </o-select>
                  </v-col>
                  <v-col cols='12' md='4'>
                    <o-text-field
                      v-model="item.call_flow_url"
                      input-label='Call Flow URL'
                    >
                    </o-text-field>
                  </v-col>
                  <v-col cols='12' md='4'>
                    <o-text-field
                      v-model="item.call_flow_request_timeout"
                      input-label='Call Flow Request Timeout, ms'
                      type='number'
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          Timeout in ms, it resets on redirect. 0 to disable (OS limit applies)
                        </v-tooltip>
                      </template>
                    </o-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-container>
    </template>
  </Details>
</template>

<style scoped>
.v-expansion-panel.outlined {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import Details from '../../components/CRUD/Details'

export default {
  data: () => ({
    notEmpty: val => !!val || 'Required',
    webhookMethods: ['GET', 'POST'],
    shakenPolicies: [0, 1, 2, 3],
    shakenLevels: ['C', 'B', 'A']
  }),
  computed: {
    ...mapGetters({
      loadingProducts: 'sipProducts/loading',
      productsErrorMessage: 'sipProducts/errorMessage',
      sipProducts: 'sipProducts/list',
      loadingS3: 's3settings/loading',
      s3ErrorMessage: 's3settings/errorMessage',
      s3settings: 's3settings/list',
      loadingTTS: 'ttsSettings/loading',
      ttsErrorMessage: 'ttsSettings/errorMessage',
      ttsSettings: 'ttsSettings/list'
    }),
    products () {
      return this.sipProducts.filter(el => el.sip_control_app_allowed)
    }
  },
  methods: {
    ...mapActions({
      requestProducts: 'sipProducts/requestList',
      requestS3: 's3settings/requestList',
      requestTTS: 'ttsSettings/requestList'
    })
  },
  created () {
    this.requestProducts().then(res => {
      if (!res) {
        alert(this.productsErrorMessage)
      }
    })
    this.requestS3().then(res => {
      if (!res) {
        alert(this.s3ErrorMessage)
      }
    })
    this.requestTTS().then(res => {
      if (!res) {
        alert(this.ttsErrorMessage)
      }
    })
  },
  components: {
    Details
  }
}
</script>
