<template>
<span>
  <crud-details
    class='mt-5'
    current-module="call/callStore"
    custom-name='Call Details'
    :no-save="true"
  >
    <template v-slot="{ item }">
      <v-row>
        <v-col cols='12' md='6'>
          <crud-details-section
            header='Call UUID'
          >
          {{item.uuid}}
          </crud-details-section>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <crud-details-section
            header='Call Details'
          >
            <v-data-table
              :headers="headers"
              :items=[item]
            >
                <template v-slot:item.from="{ item }">
                     {{beautifyNumber(item.from)}}
                </template>
                <template v-slot:item.to="{ item }">
                     {{beautifyNumber(item.to)}}
                </template>
                <template v-slot:item.created_on="{ item }">
                     {{beautifyDate(item.created_on)}}
                </template>
                <template v-slot:item.direction="{ item }">
                    {{
                    item.direction === 'out' ? 'Outgoing'
                    : item.direction === 'in'? 'Incoming'
                    : item.type
                    }}
                </template>
            </v-data-table>
          </crud-details-section>
        </v-col>
      </v-row>
    </template>
  </crud-details>
</span>
</template>

<script>
import NumberFeatures from '../../components/NumberFeatures'
import BeautifyNumber from '../../services/beautify/number.service'
import moment from 'moment'

export default {
  data: () => ({
    headers: [
      { text: 'From', value: 'from' },
      { text: 'To', value: 'to' },
      { text: 'Type', value: 'type' },
      { text: 'Date Created', value: 'created_on' },
      { text: 'Direction', value: 'direction' },
      { text: 'Status', value: 'state' },
      { text: 'Billed duration', value: 'duration' },
      { text: 'Rate', value: 'rate' },
      { text: 'Price', value: 'price' }
    ]
  }),
  methods: {
    beautifyNumber: num => BeautifyNumber.beautify(num),
    beautifyDate (unformatted) {
      return moment(unformatted).format('YYYY-MM-DD HH-MM-SS')
    }
  },
  components: {
    NumberFeatures
  }
}
</script>
