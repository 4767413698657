<template>
  <ReportChart
    header-type='SMS'
    header-text='Outbound Messages Count'
    ref-type='sms_outgoing'
  >
  </ReportChart>
</template>

<script>
import ReportChart from './ReportChart'

export default {
  components: {
    ReportChart
  }
}
</script>
