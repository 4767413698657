<template>
  <crud-details
    current-module='messaging/campaign'
    name='Campaign'
    :no-need-name="true"
    :customName="'Add new campaign'"
    :additionalParameters="aditionalParameters"
  >
    <template v-slot="{ item }">
      <v-form
        ref='form'
      >
        <div v-if="qualify.hasOwnProperty('mrc')" class="text-end">
          <span>Price {{qualify.mrc}}</span>
        </div>
        <v-row>
          <v-col cols='12' md='6'>
            <o-select-module
              module='dict/brandVertical'
              input-label='Vertical'
              v-model="item.vertical"
              :hide-details="false"
              :rules="[notEmpty]"
              item-value='value'
              :disabled="!!item.uuid"
            >
            </o-select-module>
          </v-col>
        </v-row>
          <v-row>
              <v-col cols='12' md='6'>
                  <o-select
                          :disabled="!!item.uuid"
                          input-label='Brand'
                          :hide-details="false"
                          :items="brandOptions"
                          item-text="displayName"
                          item-value="brandId"
                          v-model="item.sms_brand"
                          :rules="[notEmpty]"
                  >
                  </o-select>
              </v-col>
          </v-row>
          <v-row>
              <template>
                  <v-col cols='12' md='6'>
                      <!--            <v-radio-->
                      <!--              :ripple="false"-->
                      <!--              label='Standard'-->
                      <!--              value='standard'-->
                      <!--            >-->
                      <!--            </v-radio>-->
                      <!--            <v-radio-->
                      <!--              :ripple="false"-->
                      <!--              label='Special'-->
                      <!--              value='special'-->
                      <!--            >-->
                      <!--            </v-radio>-->
                      <v-radio-group
                              v-model="usecaseType"
                              :disabled="!!item.uuid"
                              row
                      >
                          <v-radio
                                  label="Standard Use Case"
                                  value="standard"
                          ></v-radio>
                          <v-radio
                                  label="Special Use Case"
                                  value="special"
                          ></v-radio>
                      </v-radio-group>
                      <v-row>
                          <v-col cols='12' md='6'>
                              <o-select-module
                                      v-if="usecaseType === 'standard' || (item.usecase && !usecaseType)"
                                      module='dict/campaignUsecase'
                                      :filterList="filterListSTANDARD"
                                      input-label='Usecase'
                                      item-text="value"
                                      item-value="value"
                                      :hide-details="false"
                                      v-model="item.usecase"
                                      :rules="[notEmpty]"
                                      @change="handleChangeUsecase(item)"
                                      :disabled="!!item.uuid"
                              >
                              </o-select-module>
                              <o-select-module
                                      v-if="usecaseType === 'special'"
                                      module='dict/campaignUsecase'
                                      :filterList="filterListSPECIAL"
                                      item-text="value"
                                      item-value="value"
                                      :hide-details="false"
                                      input-label='Usecase'
                                      v-model="item.usecase"
                                      @change="handleChangeUsecase(item)"
                                      :rules="[notEmpty]"
                                      :disabled="!!item.uuid"
                              >
                              </o-select-module>
                          </v-col>
                          <v-col cols='12' md='6'>
                              <o-select-module
                                      v-if="usecaseType === 'standard' || (item.usecase && !usecaseType)"
                                      module='dict/campaignUsecase'
                                      :filterList="filterListMixSTANDARD"
                                      item-text="value"
                                      item-value="value"
                                      :multi-select="true"
                                      :hide-details="false"
                                      input-label='Subusecase'
                                      v-model="item.subUsecases"
                                      :rules="[minValidSubUsecase ,maxValidSubUsecase]"
                                      :disabled="!!item.uuid"
                              >
                              </o-select-module>
                              <o-select-module
                                      v-if="usecaseType === 'special'"
                                      module='dict/campaignUsecase'
                                      :filterList="filterListMixSPECIAL"
                                      item-text="value"
                                      item-value="value"
                                      :hide-details="false"
                                      :multi-select="true"
                                      input-label='Subusecase'
                                      v-model="item.subUsecases"
                                      :rules="[minValidSubUsecase ,maxValidSubUsecase]"
                                      :disabled="!!item.uuid"
                              >
                              </o-select-module>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col cols='12'>
                      <o-textarea
                              :disabled="!!item.uuid"
                              input-label='Description'
                              v-model="item.description"
                              :rules="[notEmpty]"
                      >
                      </o-textarea>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Auto Renewal'
                              v-model="item.autoRenewal"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Embedded Link'
                              v-model="item.embeddedLink"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Embedded Phone'
                              v-model="item.embeddedPhone"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Number Pool'
                              v-model="item.numberPool"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Age Gated'
                              v-model="item.ageGated"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Direct Lending'
                              v-model="item.directLending"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Subscriber opt-in'
                              v-model="item.subscriberOptin"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Subscriber opt-out'
                              v-model="item.subscriberOptout"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Subscriber Help'
                              v-model="item.subscriberHelp"
                      >
                      </o-switch>
                  </v-col>
                  <v-col cols='6' md='2'>
                      <o-switch
                              :disabled="!!item.uuid"
                              input-label='Affiliate Marketing'
                              v-model="item.affiliateMarketing"
                      >
                      </o-switch>
                  </v-col>
              </template>
          <v-col cols='12' v-if="type === 'new'">
            <v-expansion-panels flat class='px-0'>
              <v-expansion-panel>
                <v-expansion-panel-header class='px-0'>
                  <h3>Message Samples</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content class='mx-n4'>
                  <v-row>
                  <v-col cols='12' md='6'>
                    <o-textarea
                      input-label='Sample 1'
                      v-model="item.sample1"
                    >
                    </o-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <o-textarea
                      input-label='Sample 2'
                      v-model="item.sample2"
                    >
                    </o-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <o-textarea
                      input-label='Sample 3'
                      v-model="item.sample3"
                    >
                    </o-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <o-textarea
                      input-label='Sample 4'
                      v-model="item.sample4"
                    >
                    </o-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <o-textarea
                      input-label='Sample 5'
                      v-model="item.sample5"
                    >
                    </o-textarea>
                  </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
              <v-col cols='12' v-else>
                    <h3>Message Samples</h3>
                  <v-row>
                    <v-col cols='12' md='8'>
                        <v-row>
                            <template v-for="(el, index) in simpleMessages">
                                <v-col cols='12' md='8' :key="'Sample ' + (index + 1)">
                                    <o-textarea
                                            :input-label="'Sample ' + (index + 1)"
                                            v-model="item['sample'+(index +1)]"
                                    >
                                    </o-textarea>
                                </v-col>
                                <v-col cols='12' md='2' :key="index">
                                    <v-btn
                                            text
                                            block
                                            class='error white--text mr-n3'
                                            :ripple='false'
                                            height='40px'
                                            style="margin: 45px; min-width: 60px !important"
                                            @click='removeSimpleMessage(item,index)'
                                    >
                                        X
                                    </v-btn>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols='12' md='3'>
                      <v-btn
                              text
                              block
                              class='warning white--text mr-n3'
                              :ripple='false'
                              height='40px'
                              style="margin: 45px 0"
                              @click='addSimpleMessage(item)'
                      >
                         + Add Sample Message
                      </v-btn>
                  </v-col>
                  </v-row>
              </v-col>
        </v-row>
      </v-form>
    </template>
  </crud-details>
</template>

<script>

import axios from 'axios'

export default {
  data: () => ({
    notEmpty: val => !!val || 'Required',
    minValidSubUsecase: true,
    maxValidSubUsecase: true,
    usecaseType: null,
    type: null,
    simpleMessages: [true],
    filterListSTANDARD: "arr.filter(el => el.type === 'STANDARD')",
    filterListSPECIAL: "arr.filter(el => el.type === 'SPECIAL')",
    filterListMixSTANDARD: 'arr.filter(el => el.type === "STANDARD" && el.validSubUsecase)',
    filterListMixSPECIAL: 'arr.filter(el => el.type === "SPECIAL" && el.validSubUsecase)',
    aditionalParameters: {
      helpMessage: ''
    },
    qualify: {
      maxSubUsecases: 0,
      minSubUsecases: 0
    }
  }),
  computed: {
    brandOptions () {
      return this.$store.getters['messaging/brand/list']
    },
    current () {
      return this.$store.getters['messaging/campaign/current']
    }
  },
  watch: {
    current (val) {
      this.simpleMessagesFilter()
    }
  },
  methods: {
    handleChangeUsecase (item) {
      axios
        .get(`rest/sms/brand/dict/qualify/${item.sms_brand}/${item.usecase}`)
        .then(res => {
          if (res.data.success) {
            this.qualify = res.data.payload
            this.minValidSubUsecase = val => ((!this.qualify.maxSubUsecases && val.length === this.qualify.maxSubUsecases) || (val.length > this.qualify.minSubUsecases)) || `Need to choose cases more than ${this.qualify.minSubUsecases}`
            this.maxValidSubUsecase = val => ((val.length <= this.qualify.maxSubUsecases)) || `Need to be smaller than ${this.qualify.maxSubUsecases}`
          }
        })
      this.$set(item, 'subUsecases', [])
    },
    simpleMessagesFilter () {
      const self = this
      const arr = []
      function recArr (number) {
        const newNumber = number + 1
        if (self.current['sample' + newNumber]) {
          arr.push(true)
          recArr(newNumber)
        }
      }
      recArr(0)
      this.simpleMessages = arr
    },
    addSimpleMessage (obj) {
      this.simpleMessages.push(true)
    },

    removeSimpleMessage (obj, index) {
      this.$set(obj, `sample${index + 1}`, '')
      this.simpleMessages.splice(index, 1)
    },
    async requestList () {
      return await this.$store.dispatch('messaging/brand/search', { take: 100, skip: 0 })
    }
  },
  created () {
    this.requestList()
    this.type = this.$route.name === 'Edit Campaign' ? 'edit' : 'new'
  }
}
</script>
