<template>
  <span>
    <label
      v-if="inputLabel!==''"
      class="input-label"
    >
      {{inputLabel}}
    </label>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          label="Date"
          hide-details
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
          outlined
          dense
          single-line
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
    </v-menu>
  </span>
</template>

<script>
export default {
  props: {
    value: null,
    inputLabel: {
      type: String
    },
    items: {
      type: Array
    }
  },
  computed: {
    date: {
      get () {
        return this.value ? this.value.split('T')[0] : null
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  data: vm => ({
    dateFormatted: null,
    menu1: false,
    menu2: false
  }),
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  },
  watch: {
    date: function (val) {
      this.dateFormatted = this.formatDate(val)
    }
  },
  created () {
    this.dateFormatted = this.formatDate(this.date)
  }
}
</script>
