<template>
  <v-dialog
    v-model="localValue"
    width='600'
  >
   <v-card flat class='pa-5'>
    <v-row>
      <v-col>
        <h4>Create a New API Token</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label class='input-label'>
          Choose Time To Live
        </label>
        <v-radio-group
          mandatory
          :row="$vuetify.breakpoint.smAndUp"
          dense
          v-model="ttlOption"
        >
          <v-radio
            :ripple="false"
            label='week'
            value='week'
          >
          </v-radio>
          <v-radio
            :ripple="false"
            label='month'
            value='month'
          >
          </v-radio>
          <v-radio
            :ripple="false"
            label='year'
            value='year'
          >
          </v-radio>
          <v-radio
            :ripple="false"
            label='custom number of days'
            value='custom'
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="ttlOption==='custom'">
      <v-col cols='12' md='8'>
        <o-text-field
          v-model="numberOfDays"
          type='number'
          min='1'
          label='Enter number of days...'
        >
        </o-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' md='8'>
        <o-text-field
          input-label='Name'
          label='Enter name...'
          v-model='name'
          :rules="[rules.required]"
        >
        </o-text-field>
      </v-col>
      <v-col cols='12' md='4' align-self='center' class="mt-4">
        <v-btn
          block
          height='40'
          text
          :ripple='false'
          :disabled="name===''"
          :loading="loading"
          class='primary white--text'
          @click="create"
        >
          Create
        </v-btn>
      </v-col>
    </v-row>
   </v-card>
  </v-dialog>
</template>

<style scoped>
td >>> .v-data-table__mobile-row__cell {
  word-wrap: break-word;
}
</style>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },
    ttl () {
      const now = new Date()
      let expDate
      switch (this.ttlOption) {
        case 'week':
          expDate = new Date()
          expDate.setDate(now.getDate() + 7)
          return (expDate - now) / 1000
        case 'month':
          expDate = new Date()
          expDate.setMonth(now.getMonth() + 1)
          return (expDate - now) / 1000
        case 'year':
          expDate = new Date()
          expDate.setFullYear(now.getFullYear() + 1)
          return (expDate - now) / 1000
        case 'custom':
          expDate = new Date()
          expDate.setDate(now.getDate() + parseInt(this.numberOfDays))
          return (expDate - now) / 1000
      }
      return 60 * 60 * 24
    }
  },
  methods: {
    ...mapActions({
      createToken: 'tokens/createToken'
    }),
    async create () {
      try {
        this.loading = true
        await this.createToken({ name: this.name, ttl: parseInt(this.ttl) })
        this.name = ''
        this.loading = false
        this.localValue = false
      } catch (error) {
        this.loading = false
        alert(error.message)
      }
    }
  },
  data: () => ({
    numberOfDays: 1,
    ttlOption: 'week',
    name: '',
    loading: false,
    rules: {
      required: value => !!value || 'Required.'
    }
  })
}
</script>
