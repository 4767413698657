<template>
<span>
  <v-row>
    <v-col class='py-0'>
      <label
        v-if="inputLabel!==''"
        class="input-label"
      >
        {{inputLabel}}
        <slot name='label-input'></slot>
      </label>
    </v-col>
  </v-row>
  <v-row>
    <v-col class='py-0' cols='12' :sm="twoLine ? 12 : 6">
      <o-date-picker
        v-model="localValue.date"
      >
      </o-date-picker>
    </v-col>
    <v-col align-self='center' class='py-0' cols='12' :sm="twoLine ? 12 : 6">
      <o-time-picker
        v-model="localValue.time"
        :disabled="!localValue.date"
      >
      </o-time-picker>
    </v-col>
  </v-row>
  <v-row v-if="!noTimezone">
<!--    <v-col class='py-0' :cols="clearable ? 10 : 12">-->
<!--      <o-timezone-select-->
<!--        v-model="localValue.timezone"-->
<!--        :disabled="!localValue.date"-->
<!--      >-->
<!--      </o-timezone-select>-->
<!--    </v-col>-->
    <v-col v-if="clearable" cols='2' class='clear-button'>
      <v-btn
        @click="clearAll"
        text
        class='error white--text'
        :ripple="false"
        height='40'
      >
        Clear
      </v-btn>
    </v-col>
  </v-row>
</span>
</template>

<style scoped>
.clear-button {
  padding: 7px 8px 8px 8px;
}
.input-label {
  margin: 0px !important;
}
</style>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    inputLabel: {
      type: String
    },
    twoLine: {
      type: Boolean,
      default: true
    },
    noTimezone: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    localValue: {
      handler (val) {
        this.date = val.date
        this.time = val.time
        this.timezone = val.timezone

        const tmp = new Date(this.date)
        if (this.time) {
          if (this.timezone) {
            tmp.setUTCHours(this.time.hours + parseInt(this.timezone))
            tmp.setMinutes(this.time.minutes + 60 * (this.timezone - parseInt(this.timezone)))
          } else {
            tmp.setHours(this.time.hours)
            tmp.setMinutes(this.time.minutes)
          }
          tmp.setSeconds(0)
        }

        this.$emit('input', tmp.toISOString())
      },
      deep: true
    },
    isDisabled: function () {
      if (this.timezone === null && this.time === null && this.date === null) {
        return true
      }
      return false
    }
  },
  methods: {
    clearAll: function () {
      this.$emit('input', null)
    }
  },
  data: () => ({
    localValue: {
      date: null,
      time: null,
      timezone: null
    }
  }),
  created () {
    if (this.value) {
      const date = new Date(this.value)
      this.localValue.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.localValue.time = { hours: date.getHours(), minutes: date.getMinutes() }
      this.localValue.timezone = -(date.getTimezoneOffset() / 60)
    }
  }
}
</script>
