import ItemBase from '@/store/itemBase'
import TNLeaseAPI from '@/services/tn/tnlease/tnlease.service'
class TNLeaseModule extends ItemBase {
  actions () {
    const actions = super.actions()

    actions.create = () => { throw new Error('Unsupported methods create for tnleases') }
    actions.get = () => { throw new Error('Unsupported methods get for tnleases') }

    actions.searchAll = async function ({ getters }, searchParams) {
      const items = await getters.API.search(searchParams)

      return items
    }

    return actions
  }

  getters () {
    const getters = super.getters()

    getters.count = function (state) {
      return state.count
    }

    return getters
  }

  mutations () {
    const mutations = super.mutations()

    mutations.setCount = function state (state, count) {
      state.count = count
    }

    return mutations
  }

  state () {
    const state = super.state()

    state.count = null

    return state
  }
}

export const tnlease = new TNLeaseModule(TNLeaseAPI, '/app/numbers/my-numbers', 'tnlease').getModule()
