<template>
<Details
  current-module='callControl'
  :no-header="true"
  :no-save="true"
  ref='details'
  @started='detailsStarted'
>
  <template v-slot:default>
    <v-form ref='form'>
      <v-row>
        <v-col v-if="loadingCode">
          <v-skeleton-loader
            type='image'
          >
          </v-skeleton-loader>
        </v-col>
        <v-col v-else class='px-6'>
          <MonacoEditor
            class='editor'
            v-model='code'
            language='xml'
          />
          </v-col>
      </v-row>
      <v-row class='mx-1'>
        <v-col>
          <v-btn
            text
            block
            class='primary white--text mr-n3'
            :ripple='false'
            height='40px'
            @click='save'
            :loading="compiling"
          >
            Save XML
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </template>
</Details>
</template>

<style lang='scss'>
.editor {
  width: auto;
  min-height: 300px;
  border: solid 2px $primary;
  padding-top: 10px;
  border-radius: 2px;
}
</style>

<script>
import MonacoEditor from 'vue-monaco'
import Details from '../../components/CRUD/Details'
import { mapGetters, mapActions } from 'vuex'
import SampleFlow from '../../assets/sample_flow.xml'

export default {
  data: () => ({
    code: SampleFlow,
    loadingCode: true
  }),
  methods: {
    async save () {
      if (this.$refs.form.validate() && this.code.length) {
        try {
          const item = await this.saveFlow({
            appUUID: this.$refs.details.localItem.uuid,
            text: this.code
          })
          this.$refs.details.localItem = {
            ...this.$refs.details.localItem,
            call_flow_url: item.url
          }
          if (item.success) {
            this.notify({ text: 'Saved successfully', type: 'success' }, { root: true })
          }
        } catch (error) {
          alert(error)
        }
      } else {
        alert('Code snippet should not be empty')
      }
    },

    async detailsStarted () {
      this.$nextTick(async function () {
        try {
          const code = await this.getFlow(this.$refs.details.localItem.uuid)
          if (code === false) {
            this.notify({ text: this.errorMessage, type: 'error' })
          } else if (code === 404) {
            this.notify({ text: 'No Flow found. Inserted default call flow template' })
          } else if (code) {
            this.code = code
          }
          this.loadingCode = false
        } catch (e) {
          this.loadingCode = false
          alert(e)
        }
      })
    },
    ...mapActions({
      saveFlow: 'callFlow/save',
      getFlow: 'callFlow/get',
      deleteFlow: 'callFlow/delete',
      notify: 'notification/notify'
    })
  },
  computed: {
    ...mapGetters({
      compiling: 'callFlow/compiling',
      loading: 'callFlow/loading',
      deleting: 'callFlow/deleting',
      errorMessage: 'callFlow/errorMessage'
    })
  },
  components: {
    Details,
    MonacoEditor
  },
  mounted () {
    this.loadingCode = true
  }
}
</script>
