import ItemBase from './itemBase.service'
import ApiService from './api.service'

class AddressesAPI extends ItemBase {
  async requestList () {
    try {
      const response = await ApiService.get(this.apiLink + '/account')

      const that = this
      return response.data.payload.map(el => that.parseResponse(el))
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }

  buildRequest (item) {
    return {
      name: item.name ?? undefined,
      is_physical: item.is_physical ?? undefined,
      is_billing: item.is_billing ?? undefined,
      address1: item.address1 ?? undefined,
      address2: item.address2 ?? undefined,
      zip_code: item.zip_code ?? undefined,
      city: item.city ?? undefined,
      country: item.country ?? undefined
    }
  }
}

export default new AddressesAPI('/rest/address')
