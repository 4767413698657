<template>
<span>
  <v-btn
    v-if="!loading"
    text
    block
    class='primary white--text mr-n3'
    :ripple='false'
    height='40px'
    @click='clicked'
    :disabled="disabled"
  >
    {{ addOrRemove.charAt(0).toUpperCase() + addOrRemove.slice(1) + ' All Numbers' }}
  </v-btn>
  <v-progress-linear
    v-else
    height='40px'
    color='primary'
    :value="progress"
    rounded
    :indeterminate="!progress"
    dark
  >
      {{ 'Saving in progress' + (progress ? `: ${progress}%` : '') }}
  </v-progress-linear>
</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    currentModule: null,
    addOrRemove: {
      type: String,
      default: 'add' // alternative value = 'remove'
    },
    uuid: null
  },
  computed: {
    // getters
    ...mapGetters({
      list: 'tnlease/list',
      count: 'tnlease/count',
      leaseErrorMessage: 'tnlease/errorMessage'
    }),
    progress () {
      return this.$store.getters[`${this.currentModule}/leasesProgress`]
    },
    leasesPatching () {
      return this.$store.getters[`${this.currentModule}/leasesPatching`]
    },
    sipErrorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    },
    //

    loading () {
      return this.leasesPatching === this.addOrRemove + '_all'
    },
    disabled () {
      return !!this.leasesPatching
    }
  },
  methods: {
    // actions
    ...mapActions({
      notify: 'notification/notify'
    }),
    async assignAll (data) {
      if (data.addOrRemove === 'add') {
        return await this.$store.dispatch(`${this.currentModule}/assignAll`, data)
      } else {
        return await this.$store.dispatch(`${this.currentModule}/assignAllOld`, data)
      }
    },
    //

    async clicked () {
      try {
        await this.assignAll({ uuid: this.uuid, addOrRemove: this.addOrRemove })
      } catch (error) {
        alert(error.message)
      }

      this.$emit('done')

      if (this.addOrRemove.charAt(0).toUpperCase() + this.addOrRemove.slice(1) === 'Add') {
        this.$nextTick(() => {
          this.notify({ text: 'All numbers have been added', type: 'success' })
        })
      } else {
        this.$nextTick(() => {
          this.notify({ text: 'All numbers have been removed', type: 'fail' })
        })
      }
    }
  }
}
</script>
