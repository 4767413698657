<template>
  <v-container class="py-0 primary--text">
    <v-form
      ref='form'
    >
      <v-row>
        <v-col cols='12' class='pb-0'>
          <v-row>
            <v-col cols='12' md='3'>
              <Countries
                ref='countries'
                v-model="country"
                :rules="[notEmpty]"
              >
              </Countries>
            </v-col>
            <v-col cols='12' md='3'>
              <o-select
                v-model="type"
                input-label='TN Type'
                label='Choose Type'
                :items="types"
                item-text='name'
                :return-object="true"
                :hide-details="false"
                :rules="[notEmpty]"
              >
              </o-select>
            </v-col>
              <template v-if="hasAdditionalProps && (type && type.type === 'toll_free')">
                  <v-col cols='12' md='3'>
                      <o-select
                              v-model="npa"
                              input-label='NPA'
                              label='Choose Type'
                              :items="availabeAreaCodes"
                              :return-object="false"
                              :hide-details="false"
                      >
                      </o-select>
                  </v-col>
                  <v-col cols='12' md='3'>
                      <o-text-field
                              v-model="pattern"
                              input-label='Pattern: xxxxxxx'
                              :rules="[patternValidator]"
                              :hide-details="false"
                              :validate-on-blur="true"
                      >
                      </o-text-field>
                  </v-col>
              </template>
          </v-row>
        </v-col>
        <v-col
          v-if="hasAdditionalProps"
          cols = '12' class='pt-0'
        >
<!--          <v-row v-if="type && type.type === 'toll_free'">-->
<!--            <v-col cols='6' md='12'>-->
<!--              <o-select-->
<!--                v-model="npa"-->
<!--                input-label='NPA'-->
<!--                label='Choose Type'-->
<!--                :items="availabeAreaCodes"-->
<!--                :return-object="false"-->
<!--                :hide-details="false"-->
<!--              >-->
<!--              </o-select>-->
<!--            </v-col>-->
<!--            <v-col cols='12' md='10'>-->
<!--              <o-text-field-->
<!--                v-model="pattern"-->
<!--                input-label='Pattern: xxxxxxx'-->
<!--                :rules="[patternValidator]"-->
<!--                :hide-details="false"-->
<!--                :validate-on-blur="true"-->
<!--              >-->
<!--              </o-text-field>-->
<!--            </v-col>-->
<!--          </v-row>-->
          <v-row v-if="!(type && type.type === 'toll_free')">
            <v-col cols='6' md='2'>
              <o-select
                v-model='state'
                :items='states'
                :loading='statesLoading'
                input-label='State'
                label='Select'
                clearable
              >
              </o-select>
            </v-col>
            <v-col cols='6' md='3' v-if='state'>
              <o-select
                v-model='rateCenter'
                :items='ratecenters'
                :loading='rcLoading'
                input-label='Rate Center'
                label='Select Rate Center'
                clearable
                item-text='rate_center'
                return-object
              >
              </o-select>
            </v-col>
            <v-col cols='6' md='2' v-if='state'>
              <o-select
                v-model='npa'
                :items='filterNPA'
                input-label='NPA'
                label='Select NPA'
                clearable
              >
              </o-select>
            </v-col>
            <v-col cols='6' md='2' v-if='state'>
              <o-select
                v-model='nxx'
                :items='filterNXX'
                input-label='NXX'
                label='Select NXX'
                clearable
              >
              </o-select>
            </v-col>
            <v-col cols='6' md='3'>
              <o-text-field
                v-model='line'
                input-label='Line'
                :rules="[lineValidator]"
                :hide-details="false"
              >
              </o-text-field>
            </v-col>
            <v-col cols='6' md='3'>
              <NumberFeatures
                v-model='searchFeatures'
                :editable="true"
                input-label='Features'
              >
              </NumberFeatures>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import NumberFeatures from '../../components/NumberFeatures'
import Countries from './Countries'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    hasAdditionalProps: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    notEmpty: val => !!val || 'Required',
    country: null,
    pattern: 'xxxxxxx',
    line: 'xxxx',
    npa: null,
    nxx: null,
    state: '',
    rateCenter: '',
    searchFeatures: ['voice', 'sms'],
    availabeAreaCodes: [800, 833, 844, 855, 866, 877, 888],
    type: {
      type: 'long_code',
      name: 'Long code'
    },
    types: [
      {
        type: 'long_code',
        name: 'Long code'
      },
      {
        type: 'toll_free',
        name: 'Toll free'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      states: 'dict/states/list',
      statesLoading: 'dict/states/loading',
      statesErrorMessage: 'dict/states/errorMessage',
      ratecenters: 'dict/ratecenters/list',
      rcLoading: 'dict/ratecenters/loading',
      rcErrorMessage: 'dict/ratecenters/errorMessage'
    }),
    localValue: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },
    filterNPA () {
      const arr = [];
      (this.ratecenters.map(item => item.npa)).map(item => {
        arr.push(...item)
      })
      return this.rateCenter ? this.rateCenter.npa : [...new Set(arr)]
    },
    filterNXX () {
      let arr = [];
      (this.ratecenters.map(item => item.nxx)).map(item => {
        arr.push(...item)
      })
      if (this.npa) {
        arr = []
        const obj = this.ratecenters.filter(item => item.npa[0] === this.npa)
        obj.map(item => {
          arr.push(...item.nxx)
        })
      }
      return this.rateCenter ? this.rateCenter.nxx : [...new Set(arr)]
    },
    searchParams: function () {
      switch (this.type.type) {
        case 'long_code':
          return {
            country: this.country ? this.country.a2 : undefined,
            type: this.type ? this.type.type : undefined,
            state: this.state,
            city: this.city,
            rate_center: this.rateCenter ? this.rateCenter.rate_center : undefined,
            line: this.line,
            npa: this.npa,
            nxx: this.nxx,
            features: this.searchFeatures
          }
        case 'toll_free':
          return {
            country: this.country ? this.country.a2 : undefined,
            type: this.type ? this.type.type : undefined,
            npa: this.npa,
            pattern: undefined
          }
      }

      return {}
    },
    lineValidator () {
      const errorMessage = 'Invalid line. Should be either empty or contain exactly 4 numbers or the \'x\' symbol as a placeholder.'
      if (this.line && !this.line.match(new RegExp('^[0-9x]{4}$'))) {
        return errorMessage
      }

      return true
    },
    patternValidator () {
      const errorMessage = 'Invalid pattern. Should be either empty or contain exactly 7 numbers or the \'x\' symbol as a placeholder.'
      if (this.pattern && !this.pattern.match(new RegExp('^[0-9x]{7}$'))) {
        return errorMessage
      }

      return true
    }
  },
  watch: {
    searchParams: {
      deep: true,
      handler (val) {
        this.localValue = val
      }
    },
    state (val) {
      if (val) {
        this.requestRatecenters(`?state=${val}`).then(res => {
          if (!res) {
            alert(this.rcErrorMessage)
          }
        })
      } else {
        this.rateCenter = undefined
      }
    },
    rateCenter (val) {
      if (!val) {
        this.npa = undefined
        this.nxx = undefined
      }
    },
    country (val) {
      if (val) {
        this.requestStates(`?country=${val.a2}`).then(res => {
          if (!res) {
            alert(this.statesErrorMessage)
          }
        })
      }
    }
  },
  methods: {
    ...mapActions({
      requestStates: 'dict/states/requestList',
      requestRatecenters: 'dict/ratecenters/requestList'
    }),
    validate () {
      const countriesValid = this.$refs.countries ? this.$refs.countries.validate() : false
      const formValid = this.$refs.form ? this.$refs.form.validate() : false
      return countriesValid && formValid
    },
    resetValidation () {
      if (this.$refs.countries) {
        this.$refs.countries.resetValidation()
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  },
  components: {
    NumberFeatures,
    Countries
  },
  inject: ['form'],
  created () {
    this.form.register(this)
  }
}
</script>
