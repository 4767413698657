<template>
<span>
  <div
    class="fb-login-button"
    data-size="large"
    data-button-type="login_with"
    data-layout="default"
    data-auto-logout-link="false"
    data-use-continue-as="false"
    onlogin="checkLoginState()"
    data-width="100%"
  >
  </div>
</span>
</template>

<style scoped>
.fb-login-button {
  width: 100% !important;
}
.fb-login-button >>> iframe {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      secret: 'secret/facebook'
    }),
    appId () {
      return this.secret ? this.secret.app_id : null
    }
  },
  watch: {
    appId: async function (val) {
      if (val) {
        await this.initFacebook()
        window.checkLoginState = this.checkLoginState
        if (window.FB) {
          window.fbAsyncInit()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      facebookAuth: 'auth/facebookAuth'
    }),
    async loadFacebookSDK (d, s, id) {
      var js
      var fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },

    async initFacebook () {
      const that = this
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: that.appId,
          cookie: true,
          xfbml: true,
          version: 'v7.0'
        })
        window.FB.AppEvents.logPageView()
      }
    },

    checkLoginState () {
      var that = this
      window.FB.getLoginStatus(function (response) {
        that.facebookLoginStateChanged(response)
      })
    },

    facebookLoginStateChanged (state) {
      if (state.status === 'connected') {
        this.facebookAuth(state)
      }
    }
  },
  async mounted () {
    // Load the SDK asynchronously
    await this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
    if (this.appId) {
      await this.initFacebook()
      window.checkLoginState = this.checkLoginState
      if (window.FB) {
        window.fbAsyncInit()
      }
    }
  }
}
</script>
