<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <crud-list
      ref='list'
      name='Calls Report'
      current-module='call/callStore'
      :has-add="false"
      :use-search="true"
      :headers="headers"
      :has-created="true"
      :has-filtration="true"
      :has-export="true"
      :multi-sort="false"
      :add-search-props="addSearchProps"
      :filter-page-request="filterPageRequest"
    >
       <template v-slot:filtration="{ item }">
        <v-row>
            <v-col cols='12' md='4'>
                <v-row>
                  <v-col cols='auto' class='pt-3 pb-1'>
                    Start date (UTC)
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class='pt-0'>
                       <o-date-picker
                               input-label=""
                               v-model="item.finished_on_from"
                       >
                        </o-date-picker>
                      <!--                    <number-autocomplete-->
                      <!--                      v-model="item.from"-->
                      <!--                    >-->
                      <!--                    </number-autocomplete>-->
                  </v-col>
                </v-row>
            </v-col>
            <v-col cols='12' md='4'>
                  <v-row>
                    <v-col cols='auto' class='pt-3 pb-1'>
                      End date (UTC)
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class='pt-0'>
                        <o-date-picker
                                input-label=""
                                v-model="item.finished_on_to"
                        >
                        </o-date-picker>
                        <!--                      <number-autocomplete-->
                        <!--                        v-model="item.to"-->
                        <!--                      >-->
                        <!--                      </number-autocomplete>-->
                    </v-col>
                  </v-row>
              </v-col>
              <v-col cols='12' md='4'>
                  <v-row>
                    <v-col cols='auto' class='pt-2 pb-1'>
                      GMT
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class='pt-0'>
                       <o-timezone-select
                               v-model="item.timezone"
                       >
                            </o-timezone-select>
                    </v-col>
                  </v-row>
              </v-col>
            <v-col cols='12' md='4'>
                   <o-text-field
                           input-label='Caller ID'
                           v-model="item.uuid"
                   >
                </o-text-field>
              </v-col>
            <v-col cols='12' md='4'>
                   <o-text-field
                           input-label='Dest Num'
                           v-model="item.tn"
                   >
                </o-text-field>
              </v-col>
<!--            <v-col cols='12' md='3'>-->
<!--                 <o-select-->
<!--                         input-label="Connection"-->
<!--                         v-model="item.connection"-->
<!--                         :items="connections"-->
<!--                 >-->
<!--                </o-select>-->
<!--              </v-col>-->
            <v-col cols='12' md='4'>
                <o-select
                        input-label="Status"
                        label='Completed'
                        v-model="item.state"
                        :items="statuses"
                >
                </o-select>
              </v-col>
<!--              <v-col cols='12' md='6'>-->
<!--                <o-select-->
<!--                        input-label="Campaign"-->
<!--                        :items="campaigns"-->
<!--                >-->
<!--                </o-select>-->
<!--              </v-col>-->
<!--              <v-col cols='12' md='6'>-->
<!--                <o-select-->
<!--                        input-label="Type"-->
<!--                        v-model="item.type"-->
<!--                        :items="types"-->
<!--                >-->
<!--                </o-select>-->
<!--              </v-col>-->

       </v-row>
      </template>

        <template v-slot:item.action="{ item }">
              <v-tooltip
                bottom
              >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :ripple="false"
                      text
                      v-on="on"
                      @click="copy(item.uuid)"
                    >
                      <v-icon class="ml-3 black--text">mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  Callid
          </v-tooltip>
          <!--            <button class="button"  @click="copy(item.uuid)">-->
          <!--                 <v-icon class="ml-3 black&#45;&#45;text">mdi-content-copy</v-icon>-->
          <!--              Callid-->
          <!--            </button>-->
          <v-tooltip
            bottom
          >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :ripple="false"
                      text
                      v-on="on"
                      class="button grey--text"
                      @click="copy(item.uuid)"
                    >
                      <v-icon class="ml-3 black--text">mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  Details
          </v-tooltip>
<!--            <button class="button"  @click="copy(item.uuid)">-->
<!--                 <v-icon class="ml-3 black&#45;&#45;text">mdi-content-copy</v-icon>-->
<!--              Callid-->
<!--            </button>-->
<!--            <v-btn-->
<!--              :ripple="false"-->
<!--              text-->
<!--              append-->
<!--              :to="`${item.uuid}`"-->
<!--              class="button grey&#45;&#45;text">-->
<!--                 <v-icon class="ml-3 black&#45;&#45;text">mdi-file-document-edit-outline</v-icon>-->
<!--                 Details-->
<!--            </v-btn>-->
      </template>
      <template v-slot:item.direction="{ item }">
        {{
          item.direction === 'out' ? 'Outgoing'
            : item.direction === 'in'? 'Incoming'
            : item.type
        }}
      </template>
      <template v-slot:item.from="{ item }">
        <span style="white-space: nowrap">{{beautifyNumber(item.from)}}</span>
      </template>
      <template v-slot:item.to="{ item }">
          <span style="white-space: nowrap">{{beautifyNumber(item.to)}}</span>
      </template>

    </crud-list>
       <v-dialog
               v-model="dialog"
               width='600'
       >
    <v-card class='pa-5'>
      <v-container>
        <v-row class='px-4'>
          <v-col cols='auto'>
            <h2>{{callUUID}}</h2>
          </v-col>
          <v-spacer/>
          <v-col cols='auto'>
            <v-btn
                    text
                    class='pa-0'
                    dense
                    :min-width="0"
                    width=25
                    :ripple="false"
                    @click="dialog=false; callUUID = null"
            >
              <v-icon>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
  </v-card>
</span>
</template>

<script>
import BeautifyNumber from '../../services/beautify/number.service'
import NumberAutocomplete from '../../components/NumberAutocomplete'

export default {
  components: { NumberAutocomplete },
  data: () => ({
    headers: [
      { text: 'From', value: 'from', class: 'noWrap' },
      { text: 'To', value: 'to', class: 'noWrap' },
      { text: 'Type', value: 'type', class: 'noWrap' },
      { text: 'Date Created', value: 'created_on', width: '200px', class: 'noWrap' },
      // { text: 'Direction', value: 'direction', class: 'noWrap' },
      { text: 'Status', value: 'state', class: 'noWrap' },
      { text: 'Billed duration', value: 'duration', class: 'noWrap' },
      { text: 'Rate', value: 'rate', class: 'noWrap' },
      { text: 'Price', value: 'price', class: 'noWrap' },
      { text: 'Actions', value: 'action', width: '260px', class: 'noWrap' }
    ],
    connections: [],
    statuses: [
      { text: 'Init', value: 'init' },
      { text: 'Ringing', value: 'ringing' },
      { text: 'Live', value: 'live' },
      { text: 'Offline', value: 'offline' }
    ],
    types: [
      { text: 'Call', value: 'Call' },
      { text: 'CallSCA', value: 'CallSCA' }
    ],
    directions: [
      { text: 'in', value: 'In' },
      { text: 'out', value: 'Out' },
      { text: 'unknown ', value: 'Unknown ' }
    ],
    campaigns: [],
    addSearchProps: {
      direction: 'in'
    },
    filterPageRequest: {
      direction: 'in'
    },
    dialog: false,
    callUUID: null
  }),
  methods: {
    copy (field) {
      navigator.clipboard.writeText(field)
      this.dialog = true
      this.callUUID = field
    },
    beautifyNumber: num => BeautifyNumber.beautify(num)
  },
  mounted () {
    this.$refs.list.filterParams.direction = null
  }
}
</script>
<style>
    .noWrap{
        white-space: nowrap !important;
    }
</style>
