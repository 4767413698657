<template>
  <div>
    <SubBar
      :items='subViews.filter(elem => {return elem.noDisplay === undefined})'
    >
    </SubBar>
    <router-view></router-view>
  </div>
</template>

<script>
import SubBar from '@/components/SubBar'
import SearchNumbers from '@/views/Numbers/SearchNumbers'
import MyNumbers from '@/views/Numbers/MyNumbers'
import BulkProcess from '@/views/Numbers/BulkProcess'
// import PortNumbers from '@/views/Numbers/PortNumbers'
// import NewPortRequest from '@/views/Numbers/NewPortRequest'
// import PortOut from '@/views/Numbers/PortOut'
import Orders from '@/views/Numbers/Orders'
import OrderDetails from '@/views/Numbers/OrderDetails'
import NumberLease from './NumberLease'

export default {
  name: 'Numbers',
  data: () => ({
    subViews: [
      { name: 'Search & Buy Numbers', path: '/app/numbers/search-numbers', component: SearchNumbers },
      { name: 'My Numbers', path: '/app/numbers/my-numbers', component: MyNumbers },
      { name: 'Numbers Orders', path: '/app/numbers/orders', component: Orders },
      { name: 'Number Lease History', path: '/app/numbers/number-lease', component: NumberLease },
      { name: 'Bulk Process', path: '/app/numbers/bulk-process', component: BulkProcess },
      { name: 'Order Details', path: '/app/numbers/orders/:uuid', component: OrderDetails, noDisplay: true }
      // { name: 'Port Numbers', path: '/app/numbers/port-numbers', component: PortNumbers },
      // { name: 'New Port Request', path: '/app/numbers/port-numbers/new', noDisplay: true, component: NewPortRequest },
      // { name: 'Port Out', path: '/app/numbers/port-out', component: PortOut },
    ]
  }),
  components: {
    SubBar,
    SearchNumbers,
    MyNumbers
  }
}
</script>
