<template>
  <v-row justify="start" v-if="lease.subscriptions.find(_ => _.type === 'sms')">
    <v-col cols='10'>
      <o-select
        width='250px'
        :items="smsCampaigns"
        :loading="loading"
        v-model="selectValue"
        item-text='campaignId'
        item-value='campaignId'
        class='select'
        v-on:change="onChange"
      >
      </o-select>
    </v-col>
    <v-col cols='1' class='pa-0' align-self='center'>
      <v-tooltip
        bottom
        v-if="this.subscription?.sms_campaign?.campaignId !== selectValue"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="save"
            :disabled="!edit"
          >
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </template>
        Assign SMS Campaign
      </v-tooltip>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="clear"
          >
            <v-icon>
              mdi-backspace
            </v-icon>
          </v-btn>
        </template>
        Clear SMS Campaign
      </v-tooltip>
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<style scoped>
.select >>> .v-input {
  margin-bottom: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    lease: null
  },
  data: () => ({
    currentModule: 'messaging/campaign',
    subscription: null,
    edit: false,
    filterPageRequest: {
      is_active: true,
      take: 100,
      order: {
        created_on: 'DESC'
      }
    },
    selectValue: null
  }),
  computed: {
    ...mapGetters({
      smsCampaigns: 'messaging/campaign/list'
    }),
    tnlease () {
      return this.$store.getters['tnlease/itemByKey'](this.lease.uuid)
    },
    loading () {
      return this.$store.getters['messaging/campaign/loading'] || this.$store.getters['tnlease/loading']
    }
  },
  methods: {
    ...mapActions({
      notify: 'notification/notify',
      searchSMSCampaigns: 'messaging/campaign/search',
      loadTNLease: 'tnlease/requestOne',
      patchSubscription: 'tn/subscriptions/sms/patch'
    }),
    onChange (val) {
      if (this.subscription?.sms_campaign?.campaignId !== val) {
        this.edit = true
      } else {
        this.edit = false
      }
    },
    async save () {
      this.edit = false
      const patchItem = {
        identificator: this.tnlease.tn.tn,
        sms_campaign: this.selectValue ?? null
      }

      const res = await this.patchSubscription(patchItem)
      if (!res) {
        this.notify({
          text: this.$store.getters['tn/subscriptions/sms/errorMessage'],
          type: 'error'
        })
      } else {
        if (this.selectValue) {
          this.notify({
            text: 'Successfully assigned',
            type: 'success'
          })
        } else {
          this.notify({
            text: 'Successfully cleared',
            type: 'success'
          })
        }
      }
    },
    async clear () {
      this.selectValue = null
      await this.save()
      this.subscription.sms_campaign = null
    }
  },
  created () {
    if (this.lease.subscriptions.find(_ => _.type === 'sms')) {
      const userData = this.$store.getters['user/userData']
      this.filterPageRequest.account = userData?.account?.uuid || undefined
      this.searchSMSCampaigns(this.filterPageRequest).then(res => {
        if (!res) {
          console.error(res)
          this.notify({
            text: 'messaging/campaign/errorMessage',
            type: 'error'
          })
        }
      })

      this.loadTNLease(this.lease.uuid).then(res => {
        this.subscription = this.tnlease.subscriptions.find(_ => _.type === 'sms')
        if (this.subscription) {
          this.selectValue = this.subscription.sms_campaign?.campaignId || null
        }

        if (!res) {
          console.error(res)
          this.notify({
            text: 'tnlease/errorMessage',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
