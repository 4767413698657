<template>
  <v-card outlined class='pa-5'>
    <v-container>
      <v-row>
        <v-col>
          <label class='input-label'>Record Inbound Calls</label>
          <v-switch
            v-model="recordCalls"
            inset
            :ripple="false"
            hide-details
            :label="recordCalls ? 'On' : 'Off'"
          >
          </v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='6'>
          <o-select
            v-model="audioFileFormat"
            :items="audioFileFormats"
            input-label='Audio File Format'
            hide-details
          >
          </o-select>
        </v-col>
        <v-col cols='12' md='6'>
          <o-select
            v-model="auidoFileChangeType"
            :items="auidoFileChangeTypes"
            input-label='Audio File Channel Type'
            hide-details
          >
          </o-select>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    recordCalls: false,
    audioFileFormats: [
      { text: '.WAV', value: 'wav' },
      { text: '.MP3', value: 'mp3' }
    ],
    audioFileFormat: 'mp3',
    auidoFileChangeTypes: [
      { text: 'Single Channel', value: 'single' },
      { text: 'Dual Channel', value: 'dual' }
    ],
    auidoFileChangeType: 'single'
  })
}
</script>
