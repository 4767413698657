import { payment } from './payment.module'
import PaymentAPI from '../../services/billing/payment.service'

const state = {
  order: {
    application_context: {
      brand_name: 'Denovolab'
    },
    purchase_units: [{
      description: 'Opentact Credits',
      amount: {
        currency_code: 'USD',
        value: '1'
      }
    }]
  },
  errorMessage: '',
  processingStripe: false,
  referenceId: null
}

const getters = {
  getOrder: (state) => {
    return state.order
  },
  getOrderAmount: (state) => {
    return state.order.purchase_units.amount.value
  },
  errorMessage: (state) => {
    return state.errorMessage
  },
  processingStripe: (state) => {
    return state.processingStripe
  },
  referenceId: (state) => {
    return state.referenceId
  }
}

const mutations = {
  setAmount (state, amount) {
    state.order.purchase_units[0].amount.value = amount
  },
  setBalance (state, balance) {
    state.accountBalance = balance
  },
  stripeFailed (state, error) {
    state.processingStripe = false
    // TODO: rework it
    state.errorMessage = error.message
  },
  stripeSucceeded (state) {
    state.processingStripe = false
  },
  startStripe (state) {
    state.processingStripe = true
  },
  paypalFailed (state, error) {
    state.errorMessage = error.message
  },
  paypalSucceeded (state) {
  },
  startPaypal (state) {
    state.referenceId = null
  },
  setReferenceId (state, referenceId) {
    state.referenceId = referenceId
  }
}

const actions = {
  async createPaypal ({ commit }, amount) {
    commit('startPaypal')
    try {
      const payment = await PaymentAPI.create({ amount, provider: 'paypal' })
      commit('setReferenceId', payment.reference_id)
      return true
    } catch (e) {
      commit('paypalFailed', e)
      return false
    }
  },

  async paypalApproved ({ commit, dispatch }, { actions }) {
    try {
      const details = await actions.order.capture()
      if (details) {
        // confirm paymemnt
        await dispatch('payment/patch', { identificator: details.purchase_units[0].reference_id })
        commit('paypalSucceeded')
        return true
      }
    } catch (e) {
      return false
    }
  },

  async processStripe ({ commit, dispatch }, { amount, stripe, card }) {
    commit('startStripe')
    try {
      const payment = await PaymentAPI.create({ amount, provider: 'stripe' })

      if (!payment.meta || !payment.meta.client_secret) {
        throw new Error('Cant find payment client secret')
      }

      const stripeResponse = await stripe.confirmCardPayment(payment.meta.client_secret, { payment_method: { card } })

      if (stripeResponse.error) {
        throw new Error(`${stripeResponse.error.code}: ${stripeResponse.error.message}`)
      }

      // confirm payment
      // await dispatch('payment/patch', { identificator: payment.uuid })

      commit('stripeSucceeded')
      return true
    } catch (e) {
      commit('stripeFailed', e)
      return false
    }
  }
}

export const billing = {
  namespaced: true,
  modules: {
    payment
  },
  state,
  getters,
  mutations,
  actions
}
