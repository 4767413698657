<template>
  <span>
    <div>
      <v-card flat class='my-5 px-5'>
        <v-container>
          <v-row>
            <v-col>
              <h2>Configure your Messaging Profiles</h2>
            </v-col>
            <v-spacer/>
            <v-col cols='auto'>
              <v-btn
                text
                class='u_blue white--text'
                :ripple='false'
                append
                @click="newDialog=true"
              >
              <v-icon class='mr-1'>mdi-plus-circle</v-icon>
                Add New Profile
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card flat class='pa-5'>
        <crud-list
          :headers="dataHeaders"
          current-module='messaging/profiles'
          :has-header="false"
          :has-edit="true"
          :has-delete="true"
          :has-created="true"
          :has-modified="true"
        >
        </crud-list>
      </v-card>
    </div>
    <NewProfileDialog
      v-model="newDialog"
    >
    </NewProfileDialog>
  </span>
</template>

<script>
import NewProfileDialog from './NewProfileDialog'

export default {
  name: 'OutboundVoiceProfiles',
  displayName: 'Outbound Voice Profiles',
  data: () => ({
    dataHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Created On', value: 'created_on' },
      { text: 'Last Modified On', value: 'modified_on' },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ],
    newDialog: false
  }),

  components: {
    NewProfileDialog
  }
}
</script>
