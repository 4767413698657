<template>
<span>
  <v-card flat outlined class='my-5 mx-2'>
    <v-row class='px-0 px-md-4 py-5 mb-0 pb-0'>
      <v-col cols='6' sm='10'>
        <h2>
          {{ 'SIP Domain ' + connectionUUID }}
        </h2>
      </v-col>
      <v-spacer />
      <v-col sm='auto'>
        <v-btn
          text
          class='pa-0'
          dense
          :min-width="0"
          width=25
          :ripple="false"
          to="/app/sip-domain"
          @click="$emit('close')"
        >
          <v-icon>
            mdi-close-thick
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card flat tile outlined class='mx-5 mb-5'>
      <List
        current-module="sipSwitch"
        :headers="dataHeaders"
        :has-edit="false"
        :custom-add="true"
        name='Auth Access'
        @add="create()"
        @hook:beforeCreate="setAPI()"
      >
          <template v-slot:item.type="{ item }">
              <v-col>
               <o-select
                 v-model="item.type"
                 label='Choose'
                 :items="items"
                 class='select'
                 @change="activate(item)"
               >
               </o-select>
              </v-col>
          </template>
          <template v-slot:item.option="{ item }">
              <div class="d-flex">
                  <template v-if="item.new">
                      <v-tooltip
                              bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                                  icon
                                  v-on="on"
                                  :ripple="false"
                                  text
                                  class='warning white--text'
                                  append
                                  @click="newItem(item)"
                          >
                           <v-icon>mdi-file-check</v-icon>
                        </v-btn>
                        </template>
                       Save
                    </v-tooltip>
                  </template>
                  <template v-else>
                      <v-tooltip
                              bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                                  icon
                                  v-on="on"
                                  :ripple="false"
                                  text
                                  class='warning white--text'
                                  append
                                  @click="editItem(item)"
                          >
                           <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        </template>
                       Edit
                    </v-tooltip>
                  </template>
                <v-tooltip
                        bottom
                >
                    <template v-slot:activator="{ on }">
                       <v-btn
                               icon
                               v-on="on"
                               :ripple="false"
                               text
                               class='error white--text ml-3'
                               :loading="item.deleting"
                               @click="askToDelete(item)"
                       >
                          <v-icon>mdi-delete</v-icon>
                       </v-btn>
                    </template>
                   Delete
                </v-tooltip>
              </div>
          </template>
           <template v-slot:item.remote_domain="{ item }">
               <v-col style="min-width: 200px">
               <o-text-field
                       class="switches_hostname"
                       v-if="item.type === 'hostname'"
                       v-model="item.remote_domain"
                       :hide-details="false"
                       :validate-on-blur="true"
               >
                </o-text-field>
              <template v-else>
                  {{item.remote_domain}}
              </template>
               </v-col>
          </template>
           <template v-slot:item.name="{ item }">
               <v-col>
               <o-text-field
                       class="switches_hostname"
                       v-model="item.name"
                       :hide-details="false"
                       :validate-on-blur="true"
               >
                </o-text-field>
               </v-col>
          </template>
          <template v-slot:item.remote_ip="{ item }">
                  <v-row justify="start" v-if="item.type !== 'hostname'" style="min-width: 400px">
                      <v-col cols='12' md='8' sm="8" style="padding: 18px 0">
                          <vue-IP-Input
                                  v-model="item.remote_ip"
                          />
                      </v-col>
                      <v-col cols='12' md='3' sm="3" class='pa-0' align-self='center'>
                            <o-text-field
                                    condition-style="margin-bottom: 0 !important; margin-top: 10px;padding: 0 6px"
                                    v-model="item.port"
                                    :hide-details="false"
                                    type='number'
                                    :default-value="32"
                                    :rules="[more0, less32]"
                            >
                            </o-text-field>
                      </v-col>
                  </v-row>
              <template v-else>
                  {{item.remote_ip}}
              </template>
          </template>
          <template v-slot:item.sip="{ item }">
              <NumberManageSIP
                style="padding-bottom: 15px"
                :lease="item"
              >
              </NumberManageSIP>
          </template>
      </List>
    </v-card>
  </v-card>
  <SwitchDetailsDialog
    v-model="detailsDialog"
    :isNew="isNew"
    :connectionUUID="connectionUUID"
    :identificator="uuid"
  >
  </SwitchDetailsDialog>
    <DeleteDialog
      v-model="deleteDialog"
      :name="itemToDelete ? itemToDelete.name : null"
      @confirm="deleteI(itemToDelete)"
    >
    </DeleteDialog>
</span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import List from '../../components/CRUD/List'
import SwitchDetailsDialog from './SwitchDetailsDialog'
import DeleteDialog from '../../components/CRUD/DeleteDialog'
import NumberManageSIP from '../Numbers/NumberManageSIP'
import vueIpInput from '@/components/vueIPInput'

export default {
  data: () => ({
    more0: val => Number(val) >= 0 || 'Should be more than 0',
    less32: val => Number(val) <= 32 || 'Should be less than 32',
    deleteDialog: false,
    detailsDialog: false,
    itemToDelete: null,
    editHostname: false,
    editAddress: false,
    isNew: false,
    dataHeaders: [
      { text: '', value: 'type', sortable: false, width: 200 },
      { text: 'Name', value: 'name', sortable: false, width: 250 },
      { text: 'Hostname', value: 'remote_domain', sortable: false },
      { text: 'IP Address', value: 'remote_ip', sortable: false, width: 200 },
      // { text: 'Call Handling', value: 'sip', sortable: false, width: 250 },
      { text: '', value: 'option', sortable: false }
    ],
    items: [
      { text: 'Hostname', value: 'hostname' },
      { text: 'IP Address', value: 'ip_address' }],
    uuid: null
  }),
  methods: {
    ...mapMutations({
      setAPILink: 'sipSwitch/setAPILink'
    }),
    ...mapActions({
      deleteItem: 'sip/domain/deleteItem'
    }),
    async activate (item) {
      if (item === 'hostname') {

      } else {

      }
    },
    setAPI () {
      this.setAPILink(`/rest/sip/domain/${this.connectionUUID}/switch`)
    },
    async newItem (item) {
      return await this.$store.dispatch('sipSwitch/create', item)
    },
    create () {
      this.$store.commit('sipSwitch/addNew', { new: true })
      // this.isNew = true
      // this.detailsDialog = true
    },
    editItem (item) {
      this.isNew = false
      this.uuid = item.uuid
      this.detailsDialog = true
    },
    askToDelete (item) {
      if (item.new) {
        this.$store.commit('sipSwitch/deleted')
      } else {
        this.itemToDelete = item
        this.deleteDialog = true
      }
    },
    deleteI (item) {
      this.$set(item, 'deleting', true)
      this.deleteItem(this.connectionUUID + '/switch/' + item.identificator).then(res => {
        if (!res) {
          this.$set(item, 'deleting', false)
          alert(this.errorMessage)
        }
      })
    }
  },
  computed: {
    connectionUUID () {
      return this.$route.params.connection_uuid
    }
  },
  components: {
    NumberManageSIP,
    DeleteDialog,
    SwitchDetailsDialog,
    List,
    'vue-IP-Input': vueIpInput
  }
}
</script>
<style>
    .switches_hostname > div {
        margin-top: 28px !important;
    }
</style>
