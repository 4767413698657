<template>
<span>
  <v-skeleton-loader
    :loading="loading"
    type='card'
  >
    <v-form ref='form'>
      <o-select
        v-model="localValue"
        input-label='Country (required)'
        label='Choose a Country'
        :items="countries"
        item-text='name'
        :return-object="true"
        :rules="[notEmpty]"
      >
      </o-select>
    </v-form>
  </v-skeleton-loader>
</span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    notEmpty: val => !!val || 'Required'
  }),
  props: {
    value: null
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    ...mapGetters({
      loading: 'dict/searchCountries/loading',
      countries: 'dict/searchCountries/list'
    })
  },
  methods: {
    ...mapActions({
      requestCountries: 'dict/searchCountries/requestList'
    }),
    getProp (parameter) {
      const el = this.localValue && this.localValue.name ? this.countries.find(el => el.name === this.localValue.name) : null
      return el ? el[parameter] : 'No Info'
    },
    validate () {
      return this.$refs.form ? this.$refs.form.validate() : false
    },
    resetValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  },
  created () {
    this.requestCountries()
      .then(() => {
        if (this.countries?.length) {
          this.localValue = this.countries[0]
        }
      })
  }
}
</script>
