<template>
<span>
  <v-card flat tile class='my-5 pa-5'>
    <v-skeleton-loader
      v-if="loading"
      type='image'
    >
    </v-skeleton-loader>
    <template v-else-if="isNew">
      <v-row class='px-5'>
        <v-col cols='12' align-self='center'>
          <h4>
            No {{name}} found
          </h4>
        </v-col>
        <v-col cols='12' sm='6' md='4'>
          <v-btn
            block
            @click="dialog=true"
            text
            class='primary white--text'
            :ripple='false'
            :height="40"
          >
            Add {{name}}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-card flat tile outlined class='pa-2 pa-md-5'>
        <v-row class='px-0 px-md-4'>
          <v-col>
            <h2>
              {{ name }}
            </h2>
          </v-col>
        </v-row>
        <v-row class='px-0 px-md-4'>
          <v-col>
            <slot :item="localItem"></slot>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col>
          <v-btn
            @click="dialog=true"
            block
            text
            class='primary white--text'
            :ripple='false'
            :height="40"
          >
            Edit
          </v-btn>
        </v-col>
        <v-col v-if="hasDelete">
          <v-btn
            @click="deleteDialog=true"
            block
            text
            class='error white--text'
            :ripple='false'
            :height="40"
            :loading="deleting"
          >
            Delete Settings
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-card>
  <v-dialog
    v-model="dialog"
  >
    <v-card flat tile>
      <v-card-title fixed>
        <v-row v-if='!noHeader' class='px-0 px-md-4'>
          <v-col cols='8' sm='10'>
            <h2>
              {{ (isNew ? 'New' : 'Edit') + ' ' + (name ? name : currentModule) }}
              {{ isNew ? '' : (': ' + localItem ? localItem.name : '') }}
            </h2>
          </v-col>
          <v-spacer />
          <v-col sm='auto'>
            <v-btn
              text
              class='pa-0'
              dense
              :min-width="0"
              width=25
              :ripple="false"
              :to="$store.getters[`${this.currentModule}/APP_LINK`]"
              @click="dialog=false;$emit('close')"
            >
              <v-icon>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col class='px-4'>
            <v-card outlined class='pa-2 pa-md-5 mx-3'>
              <slot v-if="isNew" name="new" :item="localItem"></slot>
              <slot v-else name="edit" :item="localItem"></slot>
            </v-card>
          </v-col>
        </v-row>
        <v-row class='mx-1'>
          <v-col>
            <v-btn
              text
              block
              class='primary white--text mr-n3'
              :ripple='false'
              height='40px'
              @click='createOrSave'
              :loading="saving"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
    <DeleteDialog
      v-model="deleteDialog"
      :name="name"
      @confirm="deleteI(localItem)"
    >
    </DeleteDialog>
  </span>
</template>

<script>
import DeleteDialog from './DeleteDialog'
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'Item'
    },
    currentModule: null,
    noHeader: {
      type: Boolean,
      default: false
    },
    hasDelete: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dialog: false,
    isNew: true,
    deleteDialog: false
  }),
  methods: {
    ...mapActions({
      notify: 'notification/notify'
    }),

    // actions
    async create (item) {
      return await this.$store.dispatch(`${this.currentModule}/create`, item)
    },
    async patch (item) {
      return await this.$store.dispatch(`${this.currentModule}/patchSingle`, item)
    },
    async request () {
      return await this.$store.dispatch(`${this.currentModule}/requestSingle`)
    },
    async deleteItem () {
      return await this.$store.dispatch(`${this.currentModule}/deleteSingle`)
    },
    //

    // mutations
    clearCurrent () {
      return this.$store.commit(`${this.currentModule}/clearCurrent`)
    },
    setCurrent (uuid) {
      return this.$store.commit(`${this.currentModule}/setCurrent`, uuid)
    },
    setCurrentValue (item) {
      return this.$store.commit(`${this.currentModule}/setCurrentValue`, item)
    },
    setDefault () {
      return this.$store.commit(`${this.currentModule}/setDefault`)
    },
    //

    switchTab: function (item) {
      this.$nextTick(() => {
        this.currentTab = item.value
      })
    },
    onStart () {
      var that = this
      this.clearCurrent()
      this.request().then(res => {
        if (res) {
          this.isNew = false
        } else {
          this.setDefault()
        }
        that.$emit('started')
      })
    },
    validate () {
      const formSlot = this.$scopedSlots.default({ item: this.localItem })[0]
      return formSlot.context.$refs.form.validate()
    },
    createOrSave () {
      if (this.validate()) {
        if (this.isNew) {
          const obj = this.localItem
          if (this.name === 'Brand') {
            // delete obj.altBusinessId
            // delete obj.altBusinessIdType
            delete obj.brandId
            delete obj.brandRelationship
            delete obj.brandRelationship_modified_on
            delete obj.is_active
            delete obj.registrationScore
            delete obj.tag
            delete obj.universalEin
          }
          this.create(obj).then(res => {
            if (!res) {
              this.notify({ text: this.errorMessage, type: 'error' })
            } else {
              this.isNew = false
              this.dialog = false
              this.notify({ text: 'Successfully added', type: 'success' })
            }
          })
        } else {
          const obj = this.localItem
          if (!obj.altBusinessId) {
            delete obj.altBusinessId
          }
          if (!obj.altBusinessIdType) {
            delete obj.altBusinessIdType
          }
          this.patch(obj).then(res => {
            if (!res) {
              this.notify({ text: this.errorMessage, type: 'error' })
            } else {
              this.isNew = false
              this.dialog = false
              this.notify({ text: 'Successfully saved', type: 'success' })
            }
          })
        }
      }
    },
    deleteI (item) {
      this.deleteItem(item.identificator).then(res => {
        if (!res) {
          this.notify({ text: this.errorMessage, type: 'error' })
        } else {
          this.isNew = true
        }
      })
    }
  },
  computed: {
    // getters
    current () {
      return this.$store.getters[`${this.currentModule}/current`]
    },
    saving () {
      return this.$store.getters[`${this.currentModule}/saving`]
    },
    loading () {
      return this.$store.getters[`${this.currentModule}/loading`]
    },
    deleting () {
      return this.$store.getters[`${this.currentModule}/deleting`]
    },
    errorMessage () {
      return this.$store.getters[`${this.currentModule}/errorMessage`]
    },
    //

    localItem: {
      get () {
        return this.current
      },
      set (item) {
        this.setCurrentValue(item)
      }
    }
  },
  watch: {
    dialog (val) {
      const formSlot = this.$scopedSlots.new({ item: this.localItem })[0]
      const formSlotEdit = this.$scopedSlots.edit({ item: this.localItem })[0]
      if (formSlot && formSlot.context.$refs.form) {
        return formSlot.context.$refs.form.resetValidation()
      }
      if (formSlotEdit && formSlotEdit.context.$refs.form) {
        return formSlotEdit.context.$refs.form.resetValidation()
      }
    }
  },
  created: function () {
    this.onStart()
  },
  components: {
    DeleteDialog
  }
}
</script>
