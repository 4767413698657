<template>
  <List
    current-module="callControl"
    :headers="dataHeaders"
    :has-created="true"
    :has-modified="true"
    :multi-sort="false"
    name="Call Control App"
  >
    <template v-slot:item.numbers="{ item }">
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            :ripple="false"
            text
            class='secondary white--text'
            :to="`/app/call-control/applications/${item.uuid}/tnlease`"
            v-on="on"
          >
            <v-icon>mdi-dialpad</v-icon>
          </v-btn>
        </template>
        Manage SIP numbers
      </v-tooltip>
    </template>
    <template v-slot:item.domain="{ item }">
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            :ripple="false"
            text
            class='primary white--text'
            :to="`/app/call-control/applications/${item.uuid}/domain`"
            v-on="on"
          >
            <v-icon>mdi-domain</v-icon>
          </v-btn>
        </template>
        Assign domains for the item
      </v-tooltip>
    </template>
    <template v-slot:item.hook="{ item }">
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            :ripple="false"
            text
            class='primary white--text'
            :to="`/app/call-control/applications/${item.uuid}/hook`"
            v-on="on"
          >
            <v-icon>mdi-xml</v-icon>
          </v-btn>
        </template>
        Assign external hook or add call flow xml
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import List from '../../components/CRUD/List'

export default {
  data: () => ({
    firstLoad: true,
    dataHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Created on', value: 'created_on' },
      // TODO: { text: 'S3 Settings', value: 's3_settings.name' },
      // TODO: { text: 'TTS Settings', value: 'tts_settings.name' },
      { text: 'Manage Calls Hooks', value: 'hook', width: 100, align: 'center', sortable: false },
      { text: 'Domains', value: 'domain', width: 100, align: 'center', sortable: false },
      { text: 'Numbers', value: 'numbers', width: 100, align: 'center', sortable: false },
      { text: 'Edit', value: 'edit', width: 100, align: 'center', sortable: false },
      { text: 'Delete', value: 'delete', width: 100, align: 'center', sortable: false }
    ]
  }),
  components: {
    List
  }
}
</script>
