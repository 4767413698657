<template>
  <span>
    <v-card flat outlined class='my-5 mx-2 px-5'>
      <v-container>
        <v-row>
          <v-col>
            <h2>SIP Domains</h2>
          </v-col>
          <v-spacer/>
          <v-col cols='auto'>
            <v-btn
              text
              class='u_blue white--text'
              :ripple='false'
              append
              @click="create"
            >
            <v-icon class='mr-1'>mdi-plus-circle</v-icon>
              Create new SIPDomain
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-divider/>

      <List
        current-module="sip/domain"
        :headers="dataHeaders"
        :has-created="true"
        :has-edit="false"
        :has-add="false"
        :has-header="false"
        name='SIP Domains'
        ref="domainsList"
      >
      <template v-slot:item.domain="{ item }">
        <div
          class='breakword'
        >
          {{ item.domain }}
        </div>
      </template>

      <template v-slot:item.users="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              class='primary white--text'
              append
              :to="`${item.identificator}/users`"
              v-on="on"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
          </template>
          Manage SIP users
        </v-tooltip>
      </template>

      <template v-slot:item.auth_access="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              class='info white--text'
              append
              :to="`${item.identificator}/auth-access`"
              v-on="on"
            >
              <v-icon>mdi-lock</v-icon>
            </v-btn>
          </template>
          Manage Auth Access
        </v-tooltip>
      </template>

      <template v-slot:item.hotkeys="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              class='success white--text'
              append
              :to="`${item.identificator}/hotkeys`"
              v-on="on"
            >
              <v-icon>mdi-voicemail</v-icon>
            </v-btn>
          </template>
          Manage SIP Hotkeys
        </v-tooltip>
      </template>

      <template v-slot:item.edit="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              v-on="on"
              class='warning white--text'
              @click="editItem(item)"
            >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Edit
        </v-tooltip>
      </template>

      <template v-slot:item.delete="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :ripple="false"
              text
              v-on="on"
              class='error white--text'
              @click="askToDelete(item)"
            >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            Delete
        </v-tooltip>
      </template>
    </List>
    </v-card>

    <Details
      v-model="detailsDialog"
      :isNew="isNew"
      :uuid="domain"
      v-if="detailsDialog"
    />

  </span>

</template>

<script>
import List from '../../components/CRUD/List'
import Details from './Details.vue'
import Hotkeys from '@/views/SIPDomain/Hotkeys.vue'

export default {
  data: () => ({
    detailsDialog: false,
    hotkeysDialog: false,
    isNew: false,
    domain: null,
    dataHeaders: [
      {
        text: 'Domain',
        value: 'domain'
      },
      {
        text: 'Created on',
        value: 'created_on'
      },
      {
        text: 'Users',
        value: 'users',
        sortable: false
      },
      {
        text: 'Auth Access',
        value: 'auth_access',
        sortable: false
      },
      {
        text: 'Hotkeys',
        value: 'hotkeys',
        sortable: false
      },
      {
        text: 'Edit',
        value: 'edit',
        sortable: false
      },
      {
        text: 'Delete',
        value: 'delete',
        sortable: false
      }
    ]
  }),
  methods: {
    askToDelete (item) {
      this.$refs.domainsList.askToDelete(item)
    },
    create () {
      this.domain = null
      this.isNew = true
      this.detailsDialog = true
    },
    editItem (item) {
      this.domain = item.domain
      this.isNew = false
      this.detailsDialog = true
    },
    editHotkeys (item) {
      this.domain = item.domain
      this.hotkeysDialog = true
    }
  },
  components: {
    Hotkeys,
    Details,
    List
  }
}
</script>

<style scoped>
.breakword {
  word-break: break-word;
}
</style>
