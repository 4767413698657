<template>
  <v-toolbar flat class='white mt-2' :height="barHeight" ref='tb' v-resize='checkButtonsFit'>
    <v-toolbar-items
      v-if="$vuetify.breakpoint.mdAndUp && buttonsFitToolbar"
      ref='buttons'
    >
      <v-btn-toggle
        mandatory
        class='pt-4'
        group
      >
        <v-btn
          height='37'
          class='mx-2'
          text
          :ripple='false'
          v-for='item in items'
          :key='item.name'
          :to='modifyPath(item.path)'
          >
            {{ item.name }}
        </v-btn>
      </v-btn-toggle>
    </v-toolbar-items>
    <v-container v-else>
      <v-row justify='center'>
        <v-col cols='12'>
          <v-menu
            bottom
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-btn
                block
                text
                class='primary white--text'
                v-on="on"
                :ripple="false"
              >
                {{currentPage}}
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for='item in items'
                :key='item.name'
                :to='item.path'
              >
                <v-list-item-title>
                  {{item.name}}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<style lang='scss' scoped>
.v-btn-toggle--group > .v-btn.v-btn {
  background-color: #f7f7f7 !important;
  border-radius: 0.25rem !important;
}
.theme--light.v-btn--active::before {
  opacity: 1 !important;
}
.v-btn--active {
  color: white !important;
  font-weight: 600 !important;
  opacity: 1 !important;
}
.v-btn:before {
  background-color: #6658dd !important;
  color: white !important;
}
</style>

<script>

export default {
  name: 'SubBar',
  props: {
    items: Array,
    barHeight: {
      type: Number,
      default: 77
    },
    modifyRoute: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    currentPage: function () {
      return this.$route.name
    }
  },
  data: () => ({
    buttonsFitToolbar: true,
    buttonWidth: 0
  }),
  methods: {
    modifyPath: function (path) {
      if (!this.modifyRoute) { return path }
      let pathArray = path.split('/')
      pathArray = pathArray.map((subPath) => {
        if (subPath && subPath[0] === ':') {
          return this.$route.params[subPath.substring(1)] ? this.$route.params[subPath.substring(1)] : subPath
        }
        return subPath
      })
      return pathArray.join('/')
    },
    checkButtonsFit: function () {
      this.$nextTick(function () {
        this.buttonsFitToolbar = this.doButtonsFitToolbar()
      })
    },
    doButtonsFitToolbar: function () {
      if (this.$vuetify.breakpoint.mdAndUp && this.$refs.tb) {
        const tbContent = this.$refs.tb.$el.querySelector('.v-toolbar__content')
        const tbContentWidth = tbContent.getBoundingClientRect().width
        const paddingLeft = parseInt(window.getComputedStyle(tbContent, null).getPropertyValue('padding-left'))
        const paddingRight = parseInt(window.getComputedStyle(tbContent, null).getPropertyValue('padding-right'))
        if (this.buttonWidth < tbContentWidth - (paddingLeft + paddingRight)) {
          return true
        } else {
          return false
        }
      }

      return true
    }
  },
  mounted: function () {
    if (this.$refs.buttons) {
      this.buttonWidth = this.$refs.buttons.getBoundingClientRect().width
      // this.checkButtonsFit()
    }
  }
}

</script>
