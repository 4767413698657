<template>
<v-card flat outlined class='pa-3'>
  <v-row>
    <v-col>
      <h4>{{header}}</h4>
      <p v-if='inputData'>{{inputData}}</p>
      <slot></slot>
    </v-col>
  </v-row>
</v-card>
</template>

<script>
export default {
  props: {
    inputData: null,
    header: null
  }
}
</script>
