<template>
  <Details
    current-module="outboundVoiceProfiles"
    :save-by-emit='true'
    @save="save"
  >
    <template v-slot="{ item }">
      <v-form
        ref='form'
      >
        <v-row>
          <v-col cols='12' md='6'>
            <o-text-field
              input-label='Name'
              v-model="item.name"
            >
            </o-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <o-select
              v-model="item.product"
              input-label='SIP Product'
              :items="products"
              :loading="loadingProducts"
              :rules="[notEmpty]"
              item-text="name"
              item-value="id"
              :disabled="$route.name !== 'OVP New'"
            >
            </o-select>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </Details>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Details from '../../components/CRUD/Details'

export default {
  data: () => ({
    notEmpty: val => !!val || 'Required',
    shakenPolicies: [0, 1, 2, 3]
  }),
  computed: {
    ...mapGetters({
      loadingProducts: 'sipProducts/loading',
      productsErrorMessage: 'sipProducts/errorMessage',
      sipProducts: 'sipProducts/list'
    }),
    products () {
      return this.sipProducts.filter(el => el.outbound_voice_profile_allowed)
    }
  },
  methods: {
    ...mapActions({
      notify: 'notification/notify',
      createProfile: 'outboundVoiceProfiles/create',
      patchProfile: 'outboundVoiceProfiles/patch',
      requestProducts: 'sipProducts/requestList'
    }),
    save (item) {
      if (this.$route.name === 'OVP New') {
        const obj = {
          name: item.name,
          is_active: item.is_active,
          product: +item.product
        }
        this.createProfile(obj).then(res => {
          if (res) {
            this.notify({ text: 'Successfully saved ', type: 'success' })
          } else {
            this.notify({ text: this.domainErrorMessage, type: 'error' })
          }
        })
      } else {
        const obj = {
          identificator: this.$route.params.uuid,
          name: item.name,
          is_active: item.is_active
        }
        this.patchProfile(obj).then(res => {
          if (res) {
            this.notify({ text: 'Successfully saved ', type: 'success' })
          } else {
            this.notify({ text: this.domainErrorMessage, type: 'error' })
          }
        })
      }
    }
  },
  created () {
    this.requestProducts().then(res => {
      if (!res) {
        alert(this.productsErrorMessage)
      }
    })
  },
  components: {
    Details
  }
}
</script>
