import { parsePhoneNumber, AsYouType } from 'libphonenumber-js'

const BeatifyNumber = {
  beautify (number) {
    try {
      const beautified = parsePhoneNumber('+' + parseInt(number).toString())
      return beautified.formatInternational()
    } catch (error) {
      return number
    }
  },

  asYouType (number) {
    const parser = new AsYouType()
    if (number) {
      const inputNumber = '+' + parseInt(number.toString().replace(/\s/g, '')).toString()
      if (number !== '') {
        return parser.input(inputNumber)
      }
    }

    return ''
  },

  isValid (number) {
    try {
      const num = parsePhoneNumber('+' + parseInt(number).toString())
      return num.isValid()
    } catch (error) {
      return false
    }
  }
}

export default BeatifyNumber
