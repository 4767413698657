<template>
  <span>
    <v-card flat outlined class='my-5 mx-2 px-5'>
      <v-container>
        <v-row>
          <v-col>
            <h2>Hotkeys: {{domain}}</h2>
          </v-col>
          <v-spacer/>
          <v-col cols='auto'>
            <v-btn
              text
              class='u_blue white--text'
              :ripple='false'
              append
              @click="create"
            >
            <v-icon class='mr-1'>mdi-plus-circle</v-icon>
              New hotkey
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-divider/>
      <List
        current-module="sip/domain/hotkeys"
        :has-add="false"
        :use-search="false"
        :headers="dataHeaders"
        :sortByProp="['hotkey']"
        :sort-desc="[false]"
        :has-created="true"
        :has-filtration="false"
        :has-export="false"
        :has-header="false"
        :has-edit="false"
        :has-delete="true"
        :has-modified="false"
        ref="hotkeyList"
      >

        <template v-slot:item.sip_control_app="{item}">
          <CallControlApps
            v-model="item.sip_control_app"
            :disabled="saving"
            @input="patch(item)"
          />
        </template>

        <template v-slot:item.is_active="{ item }">
             <o-switch
               v-model="item.is_active"
               :disabled="saving"
               @click="patch(item)"
             />
        </template>

      <template v-slot:item.delete="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                text
                v-on="on"
                class='error white--text'
                @click="askToDelete(item)"
              >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Delete
          </v-tooltip>
        </template>

      </List>
    </v-card>

    <HotkeyDetails
      v-model="detailsDialog"
      v-if="detailsDialog"
      :domain="domain"
    />

  </span>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import List from '../../components/CRUD/List'
import CallControlApps from '@/views/CallControl/CallControlApps.vue'
import HotkeyDetails from '@/views/SIPDomain/HotkeyDetails.vue'

export default {
  data: () => ({
    detailsDialog: false,
    dataHeaders: [
      {
        text: 'Hotkey',
        value: 'hotkey'
      },
      {
        text: 'Active',
        value: 'is_active'
      },
      {
        text: 'Call Control app',
        value: 'sip_control_app'
      },
      {
        text: 'Created On',
        value: 'created_on'
      },
      {
        text: '',
        value: 'delete',
        width: 100,
        align: 'center',
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapGetters({
      list: 'sip/domain/hotkeys/list',
      saving: 'sip/domain/hotkeys/saving',
      loading: 'sip/domain/hotkeys/loading',
      errorMessage: 'sip/domain/hotkeys/errorMessage'
    }),
    domain () {
      return this.$route.params.connection_uuid
    }
  },
  methods: {
    ...mapActions({
      requestList: 'sip/domain/hotkeys/requestList',
      deleteItem: 'sip/domain/hotkeys/deleteItem',
      patch: 'sip/domain/hotkeys/patch'
    }),
    ...mapMutations({
      setAPILink: 'sip/domain/hotkeys/setAPILink'
    }),
    askToDelete (item) {
      this.$refs.hotkeyList.askToDelete(item)
    },
    create () {
      this.detailsDialog = true
    }
  },
  created () {
    this.setAPILink(`/rest/sip/domain/${this.domain}/hotkey`)
  },
  components: {
    HotkeyDetails,
    CallControlApps,
    List
  }
}
</script>
