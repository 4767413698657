import ApiService from './api.service'

const SecretServiceAPI = {
  requestSecrets: async function () {
    try {
      const response = await ApiService.get('/rest/settings')

      return response.data.payload
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

export default SecretServiceAPI
